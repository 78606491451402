import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../Layout/Layout';
import Empty from '../../../utils/page/Empty';
import Pagination from '../../Layout/Pagination';
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { MdOutlineViewQuilt } from "react-icons/md";
import { image_url, server_url } from '../../../api/api';
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { delete_teacher, get_all_teacher, update_teacher_status, messageClear, teacher_filter } from '../../../store/Reducers/administrator/teacherReducer';
import { confirmMessagge } from '../../../utils/other/aleartFunc';
import { ButtonLoader, TableLoader } from '../../../utils/Loader/LoaderComponent';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { genders, status, teacherDepartment, teacherSkill } from '../../../utils/other/data';
import { useOptionsFromData } from '../../../utils/other/returnFunction';
import { useReactToPrint } from "react-to-print";
import { exportToExcel, exportToPDF } from '../../../utils/other/fileGenerate';
import { FaRegCopy } from "react-icons/fa6";
import { RiFileExcelLine } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa";
import { GiTeacher } from "react-icons/gi";
import { FiPrinter } from "react-icons/fi";
import TeacherDetails from './TeacherDetails';



const AllTeachers = () => {
    const [open, setOpen] = useState(false);


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();


    const { searchValue } = useSelector(state => state.default);
    const { employeeCount, allEmmployee, loader, successMessage, errorMessage } = useSelector(state => state.teacher);


    const [currentPage, setCurrentPage] = useState(1)
    const [parPage, setParPage] = useState(20)

    const [teacherDetails, setTeacherDetails] = useState({});


    const departmentList = useOptionsFromData(teacherDepartment?.departments);
    const skillList = useOptionsFromData(teacherSkill?.skills);
    const genderList = useOptionsFromData(genders?.gender);
    const statusList = useOptionsFromData(status?.status);


    const [genderValue, setGenderValue] = useState(null);
    const [statusValue, setStatusValue] = useState(null);
    const [departmentValue, setDepartmentValue] = useState(null);
    const [skillsValue, setSkillsValue] = useState(null);



    // handleItemPerPageChange
    const handleItemPerPageChange = (e) => {
        setParPage(e.target.value)
    }


    // handleTeacherDetails
    const handleTeacherDetails = (data) => {
        setOpen(true)
        setTeacherDetails(data)
    }


    // handleTeacherEdit
    const handleTeacherEdit = (id) => {
        navigate(`/add-employee/${id}`)
    }


    // handleTeacherDelete
    const handleTeacherDelete = async ({ deleteId, teacherImageId }) => {

        const returnValue = await confirmMessagge('Delete');
        if (returnValue) {
            dispatch(delete_teacher({ deleteId, teacherImageId }))
        }
    }


    // handleStatus
    const handleStatus = (value, teacherId) => {
        const obj = {
            teacherId,
            value
        }
        dispatch(update_teacher_status(obj))
    }


    // handleSelect
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
    }


    //    teacherFilter
    const teacherFilter = (data) => {
        const obj = {
            department: data.department ? data.department.value : '',
            skills: data.skills ? data.skills.value : '',
            gender: data.gender ? data.gender.value : '',
            status: data.status ? data.status.value : '',
        }

        dispatch(teacher_filter(obj))

    }

    //  handleReset
    const handleReset = () => {
        setDepartmentValue({ value: "", label: "Select Department" })
        setValue("department", { label: '', value: '' })

        setSkillsValue({ value: "", label: "Select Skills" })
        setValue("skills", { label: '', value: '' })

        setGenderValue({ value: "", label: "Select Gender" })
        setValue("gender", { label: '', value: '' })

        setStatusValue({ value: "", label: "Select Status" })
        setValue("status", { label: '', value: '' })

        const obj = {
            department: '',
            skills: '',
            gender: '',
            status: '',
        }

        dispatch(teacher_filter(obj))
    }


    //  createExceclFile
    const createExceclFile = () => {
        const data = allEmmployee?.map((data, i) => ({
            Sl: i + 1 + (currentPage - 1) * parPage,

            // Basic Details
            Name: data.teacherBasicDetails?.fullName,
            Email: data.teacherBasicDetails?.email,
            Phone: data.teacherBasicDetails?.phoneNumber,
            Department: data.teacherBasicDetails?.department,
            EnrollmentNumber: data.teacherBasicDetails?.enrollmentNumber,
            Skills: data.teacherBasicDetails?.skills,
            Gender: data.teacherBasicDetails?.gender,
            BloodGroup: data.teacherBasicDetails?.bloodGroup,
            DateOfJoining: new Date(data.teacherBasicDetails?.dateOfJoining).toLocaleDateString(),
            DateOfBirth: new Date(data.teacherBasicDetails?.dateOfBirth).toLocaleDateString(),

            // Address Details
            Country: data.teacherAddress?.country,
            State: data.teacherAddress?.state,
            Pincode: data.teacherAddress?.pincode,
            City: data.teacherAddress?.city,
            FullAddress: data.teacherAddress?.fullAddress,

            // Employment Details
            JobTitle: data.employmentDetails?.jobTitle,
            Designation: data.employmentDetails?.designation,
            EmploymentDepartment: data.employmentDetails?.department,
            EmploymentType: data.employmentDetails?.employment,
            DateOfAppointment: new Date(data.employmentDetails?.dateOfAppointment).toLocaleDateString(),
            Qualification: data.employmentDetails?.qualification,
            WorkExperience: data.employmentDetails?.workExperience,

            // Bank Details
            IFSCCode: data.teacherBankDetails?.ifscCode,
            BankName: data.teacherBankDetails?.bankName,
            BranchName: data.teacherBankDetails?.branchName,
            AccountHolderName: data.teacherBankDetails?.accountHolderName,
            AccountNumber: data.teacherBankDetails?.accountNumber,

            // Additional Details
            Religion: data.teacherAdditionalDetails?.religion,
            ReligionCategory: data.teacherAdditionalDetails?.religionCategory,
            MaritalStatus: data.teacherAdditionalDetails?.maritalStatus,
            AadharNumber: data.teacherAdditionalDetails?.aadharNumber,
            PANNumber: data.teacherAdditionalDetails?.panNumber,
            EmergencyContact: data.teacherAdditionalDetails?.emergencyNumber,
            FatherName: data.teacherAdditionalDetails?.fatherName,
            MotherName: data.teacherAdditionalDetails?.motherName,

            // Previous Employment Details
            PreviousInstituteName: data.teacherPreviousDetails?.instituteName,
            PreviousLocation: data.teacherPreviousDetails?.location,
            PreviousJoiningDate: new Date(data.teacherPreviousDetails?.joiningDate).toLocaleDateString(),
            PreviousRelievingDate: new Date(data.teacherPreviousDetails?.relievingDate).toLocaleDateString(),
            ReferencePhoneNumber: data.teacherPreviousDetails?.referencePhoneNumber,
            // Role & Status
            Role: data.role,
            Status: data.status,

        }));

        exportToExcel(data, 'teacher_data.xlsx');
    };


    // createPdfFile
    const createPdfFile = () => {
        const data = allEmmployee?.map((data, i) => ({
            Sl: i + 1 + (currentPage - 1) * parPage,
            Name: data.teacherBasicDetails.fullName,
            Department: data.teacherBasicDetails.department,
            Skills: data.teacherBasicDetails.skills,
            Gender: data.teacherBasicDetails.gender,
            Phone: data.teacherBasicDetails.phoneNumber,
            Email: data.teacherBasicDetails.email
        }));
        exportToPDF(data, 'teacher_data.pdf');
    }

    const componentRef = useRef();


    // createPrint
    const createPrint = useReactToPrint({
        content: () => componentRef.current,
    });


    useEffect(() => {
        const obj = {
            parPage: parseInt(parPage),
            page: parseInt(currentPage),
            searchValue
        }
        dispatch(get_all_teacher(obj))
    }, [searchValue, currentPage, parPage, successMessage])


    useEffect(() => {

        if (errorMessage) {
            toast.error(errorMessage)
            dispatch(messageClear())
        }
        if (successMessage) {
            toast.success(successMessage)
            setOpen(false)
            dispatch(messageClear())
        }

    }, [errorMessage, successMessage])


    // console.log("allEmmployee", allEmmployee)

    return (
        <Layout>

            <div className="card mb-3">
                <div className="card-body py-">

                    <form onSubmit={handleSubmit(teacherFilter)}>

                        <div className="row">

                            <div className="col-md-6 col-lg-3">
                                <div className="form-group">
                                    <Select
                                        placeholder="Select Department"
                                        {...register('department')}
                                        value={departmentValue}
                                        onChange={(selectOption) => {
                                            setDepartmentValue(selectOption);
                                            handleSelect('department', selectOption);
                                        }}
                                        options={departmentList}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3">
                                <div className="form-group">
                                    <Select
                                        placeholder="Select Skills"
                                        {...register('skills')}
                                        value={skillsValue}
                                        onChange={(selectOption) => {
                                            setSkillsValue(selectOption);
                                            handleSelect('skills', selectOption);
                                        }}
                                        options={skillList}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3">
                                <div className="form-group">
                                    <Select
                                        placeholder="Select Gender"
                                        value={genderValue}
                                        {...register('gender')}
                                        onChange={(selectOption) => {
                                            setGenderValue(selectOption);
                                            handleSelect('gender', selectOption);
                                        }}
                                        options={genderList}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3">
                                <div className="form-group">
                                    <Select
                                        placeholder="Select Status"
                                        value={statusValue}
                                        {...register('status')}
                                        onChange={(selectOption) => {
                                            setStatusValue(selectOption);
                                            handleSelect('status', selectOption);
                                        }}
                                        options={statusList}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="mr-2 text-right">
                            <button type='button' onClick={handleReset} style={{ width: "80px" }} className="btn btn-danger px-4 py-2 mr-2 font-weight-bold">
                                Reset
                            </button>
                            <button style={{ width: "80px" }} disabled={loader ? true : false} className="btn btn-secondary px-4 py-2 font-weight-bold">
                                {
                                    loader ? <ButtonLoader /> : 'Filter'
                                }
                            </button>
                        </div>

                    </form>

                </div>
            </div>


            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">

                            <div className="page-header mb-0">
                                <GiTeacher style={{ fontSize: '21px', marginBottom: "6px", marginRight: "8px" }} />
                                <h4 style={{ fontSize: "20px" }}>Employee List</h4>

                                <span className='px-4'>
                                    <FaRegCopy title='Copy' style={{ fontSize: '20px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <RiFileExcelLine onClick={createExceclFile} title='Excel' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FaRegFilePdf onClick={createPdfFile} title='PDF' style={{ fontSize: '18px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FiPrinter onClick={createPrint} title='Print' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                </span>

                                <Link to='/add-employee' className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                    <span><i className="fa fa-plus pr-2" />Add</span>
                                </Link>
                            </div>

                            <hr className='my-3' />


                            {loader ? (
                                // Display loader while data is being fetched
                                <TableLoader />
                            ) : allEmmployee?.length > 0 ? (
                                // Render table if data exists
                                <table className="tableStyle" ref={componentRef}>
                                    <thead className=''>
                                        <tr>
                                            <th>Sl.</th>
                                            <th>Photo</th>
                                            <th>Name</th>
                                            <th>Department</th>
                                            <th>Skills</th>
                                            <th>Gender</th>
                                            <th>Phone</th>
                                            <th className='no-print'>Satus</th>
                                            <th className='no-print'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className=''>
                                        {
                                            allEmmployee?.map((d, i) => (
                                                <tr key={i}>
                                                    <td data-lable="Sl">{i + 1 + (currentPage - 1) * parPage}</td>

                                                    <td data-label="Photo">
                                                        <img style={{ width: "35px", height: "35px", borderRadius: "50%" }} src={d?.teacherImage ? `${server_url}/${d?.teacherImage}` : `${image_url}/assets/img/student/male.png`} alt="Img" />
                                                    </td>
                                                    <td data-lable="Name">{d.teacherBasicDetails.fullName}</td>
                                                    <td data-lable="Department">{d.teacherBasicDetails.department}</td>
                                                    <td data-lable="Skills">{d.teacherBasicDetails.skills}</td>
                                                    <td data-lable="Gender">{d.teacherBasicDetails.gender}</td>
                                                    <td data-lable="Phone">{d.teacherBasicDetails.phoneNumber}</td>
                                                    <td data-lable="Status" className='text-center no-print'>
                                                        <select onChange={(e) => handleStatus(e.target.value, d._id)} className={`form-select p-1 px-2 text-${d.status === 'Approved' ? 'success' : 'danger'}`} style={{ width: "100%", fontWeight: "600", fontSize: "14px" }}>
                                                            <option selected={d.status === 'Approved' ? true : false} className='text-success' value="Approved">Approved</option>
                                                            <option selected={d.status === 'Blocked' ? true : false} className='text-danger' value="Blocked">Blocked</option>
                                                        </select>
                                                    </td>
                                                    <td data-lable="Action" className='no-print'>
                                                        <span title='Details' className='mr-1' onClick={() => handleTeacherDetails(d)}>
                                                            <MdOutlineViewQuilt style={{ fontSize: '22px', color: 'blue', marginTop: "3px" }} />
                                                        </span>
                                                        <span title='Edit' className='mr-1 ml-1' onClick={() => handleTeacherEdit(d._id)}>
                                                            <FaEdit style={{ fontSize: '18px', color: 'green' }} />
                                                        </span>
                                                        <span title='Delete' className='ml-1' onClick={() => handleTeacherDelete({ deleteId: d._id, teacherImageId: d.teacherImage })}>
                                                            <MdDeleteForever style={{ fontSize: '22px', color: 'red' }} />
                                                        </span>

                                                    </td>

                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                </table>
                            ) : (
                                // Display empty state when classList is empty
                                <Empty data={`Teacher Not Found !`} />
                            )}

                        </div>

                        {
                            employeeCount > 20 && <div class="d-flex justify-content-between">
                                <div className="col-md-4 d-flex justify-content-start align-items-center itemParPageStyle">
                                    <div class="form-group  d-flex">
                                        <label for="itemPerPage" class="col-sm-auto col-form-label">Select items per page :</label>
                                        <div class="col-sm-auto">
                                            <select
                                                id='itemPerPage'
                                                style={{ width: "65px", marginLeft: "-23px", marginTop: "7px", padding: "3px" }}
                                                onChange={handleItemPerPageChange}
                                                value={parPage}
                                            >
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="5000">All</option>

                                            </select>
                                        </div>
                                    </div>

                                </div>

                                <div className='px-4 pt-2'>
                                    {
                                        employeeCount >= parPage ?
                                            <Pagination
                                                pageNumber={currentPage}
                                                setPageNumber={setCurrentPage}
                                                totalItem={employeeCount}
                                                parPage={parPage}
                                                showItem={Math.floor(employeeCount / parPage)}
                                            /> : ''
                                    }
                                </div>

                            </div>
                        }

                    </div>

                </div>

            </div>

            <TeacherDetails open={open} setOpen={setOpen} teacherDetails={teacherDetails} />


        </Layout>
    )
}

export default AllTeachers;
