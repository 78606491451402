import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


// exportToExcel
export const exportToExcel = (data, fileName = 'data.xlsx') => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(dataBlob);
  link.download = fileName;
  link.click();
};



// exportToPDF
export const exportToPDF = (data, fileName = 'data.pdf') => {
  const doc = new jsPDF();

  // Extract column names from the data keys
  const headers = Object.keys(data[0]).map(key => key.charAt(0).toUpperCase() + key.slice(1));

  // Map data to the appropriate format
  const rows = data.map(Object.values);

  // Add autoTable to the document
  doc.autoTable({
    head: [headers],
    body: rows,
  });

  // Save the generated PDF
  doc.save(fileName);
};