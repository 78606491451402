import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../Layout/Layout';
import Drawer from '@mui/material/Drawer';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { ButtonLoader } from '../../../utils/Loader/LoaderComponent';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import moment from 'moment';
import Empty from '../../../utils/page/Empty';
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { confirmMessagge } from '../../../utils/other/aleartFunc';
import { FaGraduationCap } from "react-icons/fa";
import { add_academic_year, delete_academic_year, get_academic_year, get_single_academic_year, messageClear, update_academic_year, update_academic_year_status } from '../../../store/Reducers/academic/academicReducer';
import { getDuration, useOptionsFromData } from '../../../utils/other/returnFunction';
import Pagination from '../../Layout/Pagination';
import Select from 'react-select';
import { sessionsName } from '../../../utils/other/data';



const AcademicYear = () => {

    const dispatch = useDispatch();

    const { loader, successMessage, errorMessage, academicYearList, academicYearCount, singleAcademicYear } = useSelector(state => state.academic);


    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState();

    const { searchValue } = useSelector(state => state.default);

    const { handleSubmit, control, setValue, register, formState: { errors }, reset, clearErrors } = useForm();

    const sessionsList = useOptionsFromData(sessionsName?.sessions);
    const [sessionValue, setSessionValue] = useState(null);



    const [currentPage, setCurrentPage] = useState(1)
    const [parPage, setParPage] = useState(20)


    // handleItemPerPageChange
    const handleItemPerPageChange = (e) => {
        setParPage(e.target.value)
    }


    // handleSelect
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
        clearErrors(fieldName);
    }


    // onSubmit
    const onSubmit = (data) => {
        const { sectionName, startDate, endDate } = data;
        const obj = {
            sectionName: sectionName?.value,
            startDate,
            endDate
        }
        if (isEditing) {
            dispatch(update_academic_year({ editId, obj }))
        } else {
            dispatch(add_academic_year(obj))
        }
    }

    // handleOpenDrawer
    const handleOpenDrawer = () => {
        setOpen(true);
        setIsEditing(false);
        reset({
            sectionName: '',
            startDate: '',
            endDate: '',
        });
        setSessionValue()
    }



    // handleDelete
    const handleDelete = async (deleteId) => {
        const returnValue = await confirmMessagge('Delete');
        if (returnValue) {
            dispatch(delete_academic_year(deleteId))
        }

    }


    //  handleEdit
    const handleEdit = async (editId) => {
        setOpen(true);
        setIsEditing(true);
        setEditId(editId);
        dispatch(get_single_academic_year(editId))
    }


    // handleStatus
    const handleStatus = (value, statusId) => {
        const obj = {
            statusId,
            value
        }
        dispatch(update_academic_year_status(obj))
    }



    useEffect(() => {
        const obj = {
            parPage: parseInt(parPage),
            page: parseInt(currentPage),
            searchValue,
        }
        dispatch(get_academic_year(obj))
    }, [searchValue, currentPage, parPage, successMessage])


    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage)
            dispatch(messageClear())
        }
        if (successMessage) {
            toast.success(successMessage)
            dispatch(messageClear())
            setOpen(false)
            reset({
                sectionName: '',
                startDate: '',
                endDate: '',
            });
            setSessionValue()
        }
    }, [errorMessage, successMessage])



    useEffect(() => {
        setSessionValue({
            label: singleAcademicYear?.sectionName,
            value: singleAcademicYear?.sectionName
        });
        setValue("sectionName", {
            label: singleAcademicYear?.sectionName,
            value: singleAcademicYear?.sectionName
        });
        setValue('startDate', moment(singleAcademicYear?.startDate).format('YYYY-MM-DD'));
        setValue('endDate', moment(singleAcademicYear?.endDate).format('YYYY-MM-DD'));
    }, [singleAcademicYear, isEditing])


    // console.log("singleAcademicYear", singleAcademicYear)



    return (
        <Layout>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">

                        <div className="card-body">

                            <div className="page-header mb-0">
                                <FaGraduationCap style={{ fontSize: '25px', marginBottom: "6px", marginRight: "8px" }} />
                                <h4 style={{ fontSize: "20px" }}>Academic List</h4>

                                <button onClick={handleOpenDrawer} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                    <span><i className="fa fa-plus pr-2" />Add</span>
                                </button>
                            </div>

                            <hr className='my-3' />

                            {academicYearList.length > 0 ? (
                                <table className="tableStyle">
                                    <thead className=''>
                                        <tr>
                                            <th>Sl.</th>
                                            <th>Session Name</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Duration</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className=''>
                                        {academicYearList?.map((d, i) => (
                                            <tr key={i}>
                                                <td data-lable="Sl">{i + 1}</td>
                                                <td>{d.sectionName}</td>
                                                <td>{moment(d.startDate).format('DD, MMM, YYYY')}</td>
                                                <td>{moment(d.endDate).format('DD, MMM, YYYY')}</td>
                                                <td>{getDuration(d.startDate, d.endDate)}</td>
                                                <td data-lable="Status" className='no-print'>
                                                    <select onChange={(e) => handleStatus(e.target.value, d._id)} className={`form-select p-1 px-2 text-${d.status === 'Approved' ? 'success' : 'danger'}`} style={{ width: "100%", fontWeight: "600", fontSize: "14px" }}>
                                                        <option selected={d.status === 'Approved' ? true : false} className='text-success' value="Approved">Approved</option>
                                                        <option selected={d.status === 'Blocked' ? true : false} className='text-danger' value="Blocked">Blocked</option>
                                                    </select>
                                                </td>
                                                <td data-lable="Action" className='no-print'>
                                                    <span title='Edit' className='mr-1' onClick={() => handleEdit(d._id)}>
                                                        <FaEdit style={{ fontSize: '20px', color: 'green' }} />
                                                    </span>
                                                    <span title='Delete' className='ml-1' onClick={() => handleDelete(d._id)}>
                                                        <MdDeleteForever style={{ fontSize: '24px', color: 'red' }} />
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <Empty data={`Academic Year Not Found !`} />
                            )}
                        </div>

                        {
                            academicYearCount > 20 && <div class="d-flex justify-content-between">
                                <div className="col-md-4 d-flex justify-content-start align-items-center itemParPageStyle">
                                    <div class="d-flex mb-2">
                                        <label for="itemPerPage" class="col-sm-auto col-form-label">Select items per page :</label>
                                        <div class="col-sm-auto">
                                            <select
                                                id='itemPerPage'
                                                style={{ width: "65px", marginLeft: "-23px", marginTop: "7px", padding: "3px" }}
                                                onChange={handleItemPerPageChange}
                                                value={parPage}
                                            >
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="5000">All</option>

                                            </select>
                                        </div>
                                    </div>

                                </div>

                                <div className='px-4 pt-2'>
                                    {
                                        academicYearCount >= parPage ?
                                            <Pagination
                                                pageNumber={currentPage}
                                                setPageNumber={setCurrentPage}
                                                totalItem={academicYearCount}
                                                parPage={parPage}
                                                showItem={Math.floor(academicYearCount / parPage)}
                                            /> : ''
                                    }
                                </div>

                            </div>
                        }


                    </div>
                </div>
            </div>



            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <div style={{ width: '450px' }}>
                    <DialogTitle style={{ fontSize: '18px' }}>{isEditing ? 'Edit Academic Year' : 'Add Academic Year'}</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>



                    <div className='px-2'>
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="form-group">
                                <label htmlFor="sectionName">Session Name <span className='text-danger'>*</span></label>
                                <Select
                                    {...register('sectionName', { required: 'Session is required' })}
                                    value={sessionValue}
                                    onChange={(selectOption) => {
                                        setSessionValue(selectOption);
                                        handleSelect('sectionName', selectOption);
                                    }}
                                    options={sessionsList}
                                    className={errors.sectionName ? 'is-invalid' : ''}
                                />
                                {errors.sectionName && <div className="invalid-feedback">{errors.sectionName.message}</div>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="startDate">Start Date <span className='text-danger'>*</span></label>
                                <input
                                    type="date"
                                    className={`form-control ${errors.startDate ? 'is-invalid' : ''}`}
                                    id="startDate"
                                    {...register('startDate', { required: 'Start Date is required' })}
                                />
                                {errors.startDate && <div className="invalid-feedback">{errors.startDate.message}</div>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="endDate">End Date <span className='text-danger'>*</span></label>
                                <input
                                    type="date"
                                    className={`form-control ${errors.endDate ? 'is-invalid' : ''}`}
                                    id="endDate"
                                    {...register('endDate', { required: 'End Date is required' })}
                                />
                                {errors.endDate && <div className="invalid-feedback">{errors.endDate.message}</div>}
                            </div>



                            <div className="my-3 mx-2" style={{ textAlign: "right" }}>
                                <button disabled={loader} type="submit" style={{ width: "100px" }} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                    {loader ? <ButtonLoader /> : (isEditing ? 'Edit' : 'Add')}
                                </button>
                            </div>

                        </form>
                    </div>


                </div>
            </Drawer>
        </Layout>
    );
}



export default AcademicYear;