import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import Empty from '../../../../utils/page/Empty';
import { resend_student_password, student_filter, update_student_status, messageClear, get_all_student } from '../../../../store/Reducers/student/studentReducer';
import Pagination from '../../../Layout/Pagination';
import { generatePassword, getActiveClassName, getActiveSession } from '../../../../utils/other/returnFunction';
import { MdOutlineViewQuilt } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { RiPhoneFindLine } from "react-icons/ri";
import { confirmMessagge } from '../../../../utils/other/aleartFunc';
import toast from 'react-hot-toast';
import { ButtonLoader, TableLoader } from '../../../../utils/Loader/LoaderComponent';
import { image_url, server_url } from '../../../../api/api';


const ShowAuthenticate = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();
    const { classList } = useSelector(state => state.administrator);

    const { authStudents, studentCount, errorMessage, successMessage, loader } = useSelector(state => state.student);
    const { sessionValue, searchValue } = useSelector(state => state.default);

    const [currentPage, setCurrentPage] = useState(1)
    const [parPage, setParPage] = useState(20)

    const [loadingId, setLoadingId] = useState(null);
    const [revealedPasswordId, setRevealedPasswordId] = useState(null);

    const handleMouseEnter = (id) => {
        setRevealedPasswordId(id);
    };

    const handleMouseLeave = () => {
        setRevealedPasswordId(null);
    };


    const [classListOption, setClassListOption] = useState("")
    const [sectionOption, setSectionOption] = useState("")
    const [selecteeClassId, setSelecteeClassId] = useState('');
    const [selecteeSection, setSelecteeSection] = useState('');


    const [classValue, setClassValue] = useState(null);
    const [sectionValue, setSectionValue] = useState(null);


    //  handleClassChange
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);

        if (fieldName === 'className') {
            setSelecteeClassId(selectOption.value);
            setSectionValue({ value: "", label: "Select Section" })
            setValue("section", { label: '', value: '' })
            const secOption = classList?.filter((item) => item._id === selectOption.value);
            const array = secOption[0]?.sectionNames;
            const convertedArray = array?.map(item => ({
                value: item,
                label: item
            }));
            setSectionOption(convertedArray)
            const obj = {
                sessionValue,
                className: selectOption.value,
                section: ''
            }
            dispatch(student_filter(obj))
        }

        if (fieldName === 'section') {
            setSelecteeSection(selectOption.value)
            const obj = {
                sessionValue,
                className: selecteeClassId,
                section: selectOption.value
            }
            dispatch(student_filter(obj))

        }

    }

    // handleStatus
    const handleStatus = (value, statusId) => {
        const obj = {
            statusId,
            value
        }
        dispatch(update_student_status(obj))
    }

    // handleItemPerPageChange
    const handleItemPerPageChange = (e) => {
        setParPage(e.target.value)
    }

    // handleResend
    const handleResend = async (id) => {
        setLoadingId(id)
        const returnValue = await confirmMessagge('Resend');
        if (returnValue) {
            const obj = {
                studentId: id,
                password: generatePassword()
            }
            dispatch(resend_student_password(obj))

        }

    }


    useEffect(() => {
        const obj = {
            parPage: parseInt(parPage),
            page: parseInt(currentPage),
            searchValue,
            sessionValue
        }
        dispatch(get_all_student(obj))
    }, [searchValue, currentPage, parPage, sessionValue])



    useEffect(() => {
        const obj = {
            parPage: parseInt(parPage),
            page: parseInt(currentPage),
            searchValue,
            sessionValue
        }
        if (errorMessage) {
            toast.error(errorMessage)
            dispatch(messageClear())
            dispatch(get_all_student(obj))
        }
        if (successMessage) {
            toast.success(successMessage)
            dispatch(messageClear())
            dispatch(get_all_student(obj))
        }

    }, [errorMessage, successMessage])


    useEffect(() => {
        const options = classList?.map(item => ({
            value: item._id,
            label: item.className
        }));
        setClassListOption(options)
    }, [classList])


    return (

        <>
            <div className="card mb-3">
                <div className="card-body py-">
                    <div className="row">

                        <div className="col-md-6 col-lg-6">
                            <div className="form-group pt-0">
                                <label htmlFor="className">Class <span className='text-danger'>*</span></label>
                                <Select
                                    placeholder="Select Class"
                                    value={classValue}
                                    {...register('className')}
                                    onChange={(selectOption) => {
                                        setClassValue(selectOption);
                                        handleSelect('className', selectOption);
                                    }}
                                    options={classListOption}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6">
                            <div className="form-group pt-0">
                                <label htmlFor="section">Section <span className='text-danger'>*</span></label>
                                <Select
                                    {...register('section')}
                                    placeholder="Select Section"
                                    value={sectionValue}
                                    onChange={(selectOption) => {
                                        setSectionValue(selectOption);
                                        handleSelect('section', selectOption);
                                    }}
                                    options={sectionOption}
                                    isDisabled={sectionOption?.length > 0 ? false : true}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>



            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">

                            <div className="page-header mb-0">
                                <RiPhoneFindLine style={{ fontSize: '23px', marginBottom: "6px", marginRight: "8px" }} />
                                <h4 style={{ fontSize: "19px" }}>Show Student Authenticate</h4>
                            </div>

                            <hr className='my-3' />

                            {loader ? (
                                // Display loader while data is being fetched
                                <TableLoader />
                            ) : authStudents?.length > 0 ? (
                                // Render table if data exists
                                <div>
                                    <table className="tableStyle">
                                        <thead>
                                            <tr>
                                                <th>Sl.</th>
                                                <th>Photo</th>
                                                <th>Name</th>
                                                <th>Class/Section</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>Password</th>
                                                <th className='no-print'>Status</th>
                                                <th className='no-print'>Password Resend</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                authStudents?.map((d, i) => (
                                                    <tr key={i}>
                                                        <td >{i + 1 + (currentPage - 1) * parPage}</td>
                                                        <td data-label="Photo">
                                                            <img style={{ width: "35px", height: "35px", borderRadius: "50%" }} src={d?.studentImage ? `${server_url}/${d?.studentImage}` : `${image_url}/assets/img/student/male.png`} alt="Img" />
                                                        </td>
                                                        <td data-label="Name">{d.basicDetails.fullName}</td>
                                                        <td data-label="Class">{getActiveClassName(classList, d.sessions)} ({getActiveSession(d.sessions)})</td>
                                                        <td data-label="Email">{d.basicDetails.email}</td>
                                                        <td data-label="Phone">{d.basicDetails.phoneNumber}</td>
                                                        <td
                                                            data-label="Password"
                                                            onMouseEnter={() => handleMouseEnter(d._id)}
                                                            onMouseLeave={handleMouseLeave}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <span>
                                                                {revealedPasswordId === d._id ? d?.password : '********'}
                                                            </span>
                                                        </td>
                                                        <td data-lable="Status" className='no-print'>
                                                            <select onChange={(e) => handleStatus(e.target.value, d._id)} className={`form-select p-1 px-2 text-${d.status === 'Approved' ? 'success' : 'danger'}`} style={{ width: "100%", fontWeight: "600", fontSize: "14px" }}>
                                                                <option selected={d.status === 'Approved' ? true : false} className='text-success' value="Approved">Approved</option>
                                                                <option selected={d.status === 'Blocked' ? true : false} className='text-danger' value="Blocked">Blocked</option>
                                                            </select>
                                                        </td>
                                                        <td data-label="Action" className='no-print'>

                                                            <button disabled={loader ? true : false} onClick={() => handleResend(d._id)} style={{ fontSize: "14px", fontWeight: 600, width: "80px" }} className='btn btn-danger px-2 py-1'>
                                                                {
                                                                    loadingId === d._id && loader ? <ButtonLoader /> : 'Resend'
                                                                }
                                                            </button>
                                                        </td>

                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                // Display empty state when classList is empty
                                <Empty data={`Student Not Found !`} />
                            )}

                        </div>

                    </div>

                </div>

            </div>


        </>

    )
}

export default ShowAuthenticate;