import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { generatePassword, useOptionsFromData } from '../../../../utils/other/returnFunction';
import { teacherDepartment } from '../../../../utils/other/data';
import { useForm } from 'react-hook-form';
import Pagination from '../../../Layout/Pagination';
import Empty from '../../../../utils/page/Empty';
import { ButtonLoader, TableLoader } from '../../../../utils/Loader/LoaderComponent';
import { RiPhoneFindLine } from "react-icons/ri";
import { get_all_teacher, teacher_filter, update_teacher_status, messageClear, resend_employee_password } from '../../../../store/Reducers/administrator/teacherReducer';
import toast from 'react-hot-toast';
import { confirmMessagge } from '../../../../utils/other/aleartFunc';
import { image_url, server_url } from '../../../../api/api';



const ShowAuthenticate = () => {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();

  const { searchValue } = useSelector(state => state.default);
  const { employeeCount, authEmployee, successMessage, errorMessage, loader } = useSelector(state => state.teacher);
  const departmentList = useOptionsFromData(teacherDepartment?.departments);


  const [currentPage, setCurrentPage] = useState(1)
  const [parPage, setParPage] = useState(1000000)


  const [departmentValue, setDepartmentValue] = useState(null);


  const [loadingId, setLoadingId] = useState(null);



  // handleItemPerPageChange
  const handleItemPerPageChange = (e) => {
    setParPage(e.target.value)
  }



  // handleSelect
  const handleSelect = (fieldName, selectOption) => {
    setValue(fieldName, selectOption);
  }


  // handleStatus
  const handleStatus = (value, teacherId) => {
    const obj = {
      teacherId,
      value
    }
    dispatch(update_teacher_status(obj))
  }


  // handleResend
  const handleResend = async (id) => {
    setLoadingId(id)
    const returnValue = await confirmMessagge('Resend');
    if (returnValue) {
      const obj = {
        employeeId: id,
        password: generatePassword()
      }
      dispatch(resend_employee_password(obj))

    }

  }


  useEffect(() => {
    if (departmentValue) {
      const obj = {
        department: departmentValue ? departmentValue.value : '',
      }
      dispatch(teacher_filter(obj))
    }

  }, [departmentValue])



  useEffect(() => {
    const obj = {
      parPage: parseInt(parPage),
      page: parseInt(currentPage),
      searchValue
    }
    dispatch(get_all_teacher(obj))
  }, [searchValue, currentPage, parPage, successMessage])


  useEffect(() => {

    if (errorMessage) {
      toast.error(errorMessage)
      dispatch(messageClear())
    }
    if (successMessage) {
      toast.success(successMessage)
      dispatch(messageClear())
    }

  }, [errorMessage, successMessage])


  // console.log("authEmployee", authEmployee)
  // console.log("employeeCount", employeeCount)


  return (
    <>

      <div className="card mb-3">
        <div className="card-body">
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-6 d-flex justify-content-center">
              <div style={{ width: '100%' }}>
                <div className="form-group pt-0">
                  <label htmlFor="teacher">Department</label>
                  <Select
                    placeholder="Select Department"
                    {...register('department')}
                    value={departmentValue}
                    onChange={(selectOption) => {
                      setDepartmentValue(selectOption);
                      handleSelect('department', selectOption);
                    }}
                    options={departmentList}
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">

              <div className='d-flex justify-content-between m-0 p-0'>
                <div className="page-header mb-0">
                  <RiPhoneFindLine style={{ fontSize: '23px', marginBottom: "6px", marginRight: "8px" }} />
                  <h4 style={{ fontSize: "19px" }}>Generate Employee Authenticate</h4>
                </div>

              </div>

              <hr className='my-3' />

              {loader ? (
                // Display loader while data is being fetched
                <TableLoader />
              ) : authEmployee?.length > 0 ? (
                // Render table if data exists
                <table className="tableStyle">
                  <thead className=''>
                    <tr>
                      <th>Sl.</th>
                      <th>Photo</th>
                      <th>Name</th>
                      <th>Department</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Status</th>
                      <th className='no-print'>Password Resend</th>
                    </tr>
                  </thead>
                  <tbody className=''>
                    {
                      authEmployee?.map((d, i) => (
                        <tr key={i}>
                          <td data-lable="Sl">{i + 1 + (currentPage - 1) * parPage}</td>
                          <td data-label="Photo">
                            <img style={{ width: "35px", height: "35px", borderRadius: "50%" }} src={d?.teacherImage ? `${server_url}/${d?.teacherImage}` : `${image_url}/assets/img/student/male.png`} alt="Img" />
                          </td>
                          <td data-lable="Name">{d.teacherBasicDetails.fullName}</td>
                          <td data-lable="Department">{d.teacherBasicDetails.department}</td>
                          <td data-lable="Email">{d.teacherBasicDetails.email}</td>
                          <td data-lable="Phone">{d.teacherBasicDetails.phoneNumber}</td>
                          <td data-lable="Status" className='text-center no-print'>
                            <select onChange={(e) => handleStatus(e.target.value, d._id)} className={`form-select p-1 px-2 text-${d.status === 'Approved' ? 'success' : 'danger'}`} style={{ width: "100%", fontWeight: "600", fontSize: "14px" }}>
                              <option selected={d.status === 'Approved' ? true : false} className='text-success' value="Approved">Approved</option>
                              <option selected={d.status === 'Blocked' ? true : false} className='text-danger' value="Blocked">Blocked</option>
                            </select>
                          </td>
                          <td data-label="Action" className='no-print'>

                            <button disabled={loader ? true : false} onClick={() => handleResend(d._id)} style={{ fontSize: "14px", fontWeight: 600, width: "80px" }} className='btn btn-danger px-2 py-1'>
                              {
                                loadingId === d._id && loader ? <ButtonLoader /> : 'Resend'
                              }
                            </button>
                          </td>

                        </tr>
                      ))
                    }

                  </tbody>
                </table>
              ) : (
                // Display empty state when classList is empty
                <Empty data={`Employee Not Found !`} />
              )}

            </div>

          </div>

        </div>

      </div>

    </>
  )
}



export default ShowAuthenticate;