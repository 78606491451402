/* countries */
export const countries = {
  "countries": [
    { "name": "Afghanistan" },
    { "name": "Armenia" },
    { "name": "Azerbaijan" },
    { "name": "Bahrain" },
    { "name": "Bangladesh" },
    { "name": "Bhutan" },
    { "name": "Brunei" },
    { "name": "Cambodia" },
    { "name": "China" },
    { "name": "Cyprus" },
    { "name": "Georgia" },
    { "name": "India" },
    { "name": "Indonesia" },
    { "name": "Iran" },
    { "name": "Iraq" },
    { "name": "Israel" },
    { "name": "Japan" },
    { "name": "Jordan" },
    { "name": "Kazakhstan" },
    { "name": "Kuwait" },
    { "name": "Kyrgyzstan" },
    { "name": "Laos" },
    { "name": "Lebanon" },
    { "name": "Malaysia" },
    { "name": "Maldives" },
    { "name": "Mongolia" },
    { "name": "Myanmar (Burma)" },
    { "name": "Nepal" },
    { "name": "North Korea" },
    { "name": "Oman" },
    { "name": "Pakistan" },
    { "name": "Palestine" },
    { "name": "Philippines" },
    { "name": "Qatar" },
    { "name": "Russia" },
    { "name": "Saudi Arabia" },
    { "name": "Singapore" },
    { "name": "South Korea" },
    { "name": "Sri Lanka" },
    { "name": "Syria" },
    { "name": "Taiwan" },
    { "name": "Tajikistan" },
    { "name": "Thailand" },
    { "name": "Timor-Leste" },
    { "name": "Turkey" },
    { "name": "Turkmenistan" },
    { "name": "United Arab Emirates" },
    { "name": "Uzbekistan" },
    { "name": "Vietnam" },
    { "name": "Yemen" }
  ]
};

// indiaStates
export const indiaStates = {
  "states": [
    { "name": "Andhra Pradesh" },
    { "name": "Arunachal Pradesh" },
    { "name": "Assam" },
    { "name": "Bihar" },
    { "name": "Chhattisgarh" },
    { "name": "Goa" },
    { "name": "Gujarat" },
    { "name": "Haryana" },
    { "name": "Himachal Pradesh" },
    { "name": "Jharkhand" },
    { "name": "Karnataka" },
    { "name": "Kerala" },
    { "name": "Madhya Pradesh" },
    { "name": "Maharashtra" },
    { "name": "Manipur" },
    { "name": "Meghalaya" },
    { "name": "Mizoram" },
    { "name": "Nagaland" },
    { "name": "Odisha" },
    { "name": "Punjab" },
    { "name": "Rajasthan" },
    { "name": "Sikkim" },
    { "name": "Tamil Nadu" },
    { "name": "Telangana" },
    { "name": "Tripura" },
    { "name": "Uttar Pradesh" },
    { "name": "Uttarakhand" },
    { "name": "West Bengal" }
  ]
};

// genderList
export const genders = {
  "gender": [
    { "name": "Male" },
    { "name": "Female" },
    { "name": "Others" }
  ]
};

// statusList
export const status = {
  "status": [
    { "name": "Approved" },
    { "name": "Blocked" }
  ]
};


// bloodGroups
export const bloodGroups = {
  "groups": [
    { "name": "A+" },
    { "name": "A-" },
    { "name": "B+" },
    { "name": "B-" },
    { "name": "AB+" },
    { "name": "AB-" },
    { "name": "O+" },
    { "name": "O-" }
  ]
};

// religions
export const religions = {
  "list": [
    { "name": "Hindu" },
    { "name": "Islam" },
    { "name": "Christian" },
    { "name": "Buddish" },
    { "name": "Others" }
  ]
};

// religionCategorys
export const religionCategorys = {
  "list": [
    { "name": "General" },
    { "name": "OBC - A" },
    { "name": "OBC - B" },
    { "name": "SC" },
    { "name": "ST" },
    { "name": "Others" }
  ]
};


// daysOfWeek
export const daysOfWeek = {
  list: [
    { name: "Monday" },
    { name: "Tuesday" },
    { name: "Wednesday" },
    { name: "Thursday" },
    { name: "Friday" },
    { name: "Saturday" },
    { name: "Sunday" }
  ]
};


// sectionOption
export const sectionOption = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' },
  { value: 'E', label: 'E' },
  { value: 'F', label: 'F' }
]

// fatherOccupations
export const fatherOccupations = {
  "occupations": [
    { "name": "Accountant" },
    { "name": "Anesthesiologist" },
    { "name": "Architect" },
    { "name": "Artist" },
    { "name": "Banker" },
    { "name": "Biologist" },
    { "name": "Biomedical Engineer" },
    { "name": "Businessman" },
    { "name": "Cardiologist" },
    { "name": "Chef" },
    { "name": "Chiropractor" },
    { "name": "Civil Engineer" },
    { "name": "Construction Worker" },
    { "name": "Customs Officer" },
    { "name": "Dentist" },
    { "name": "Dermatologist" },
    { "name": "Doctor" },
    { "name": "Economist" },
    { "name": "Electrician" },
    { "name": "Endocrinologist" },
    { "name": "Engineer" },
    { "name": "Environmental Scientist" },
    { "name": "Event Planner" },
    { "name": "Farmer" },
    { "name": "Family Physician" },
    { "name": "Financial Analyst" },
    { "name": "Firefighter" },
    { "name": "Flight Attendant" },
    { "name": "General Practitioner" },
    { "name": "Geologist" },
    { "name": "Graphic Designer" },
    { "name": "Hematologist" },
    { "name": "Historian" },
    { "name": "Human Resources Manager" },
    { "name": "Insurance Agent" },
    { "name": "Interior Designer" },
    { "name": "IT Specialist" },
    { "name": "Journalist" },
    { "name": "Judge" },
    { "name": "Lawyer" },
    { "name": "Legal Consultant" },
    { "name": "Librarian" },
    { "name": "Logistics Manager" },
    { "name": "Marketing Manager" },
    { "name": "Mechanic" },
    { "name": "Military Officer" },
    { "name": "Musician" },
    { "name": "Neurologist" },
    { "name": "Nurse" },
    { "name": "Occupational Therapist" },
    { "name": "Oncologist" },
    { "name": "Operations Manager" },
    { "name": "Optometrist" },
    { "name": "Orthopedic Surgeon" },
    { "name": "Pediatrician" },
    { "name": "Pharmacist" },
    { "name": "Photographer" },
    { "name": "Physical Therapist" },
    { "name": "Pilot" },
    { "name": "Plumber" },
    { "name": "Police Officer" },
    { "name": "Politician" },
    { "name": "Project Manager" },
    { "name": "Psychiatrist" },
    { "name": "Psychologist" },
    { "name": "Pulmonologist" },
    { "name": "Quality Control Inspector" },
    { "name": "Radiologist" },
    { "name": "Real Estate Agent" },
    { "name": "Retail Manager" },
    { "name": "Rheumatologist" },
    { "name": "Sales Manager" },
    { "name": "Scientist" },
    { "name": "Security Guard" },
    { "name": "Social Worker" },
    { "name": "Software Engineer" },
    { "name": "Supply Chain Manager" },
    { "name": "Surgeon" },
    { "name": "Surveyor" },
    { "name": "Taxi Driver" },
    { "name": "Teacher" },
    { "name": "Therapist" },
    { "name": "Translator" },
    { "name": "Urologist" },
    { "name": "Veterinarian" },
    { "name": "Warehouse Manager" },
    { "name": "Web Developer" },
    { "name": "Zoologist" },
    { "name": "Others" }
  ]
};


// teacherDepartment
export const teacherDepartment = {
  "departments": [
    { "name": "Teacher" },
    { "name": "Librarian" },
    { "name": "Accountant" },
    // { "name": "Non Teaching Staff" },
    // { "name": "Human Resources" },
    // { "name": "Finance Department" },
    // { "name": "Maintenance" },
    // { "name": "Admissions Office" },
    // { "name": "Cafeteria Staff" },
    // { "name": "Director" },
    // { "name": "Others" }
  ]
};


// teacherSkill
export const teacherSkill = {
  "skills": [
    { "name": "Art" },
    { "name": "Bengali" },
    { "name": "Biology" },
    { "name": "Business Studies" },
    { "name": "Chemistry" },
    { "name": "Communication Skills" },
    { "name": "Computer Science" },
    { "name": "Critical Thinking" },
    { "name": "Digital Literacy" },
    { "name": "Drama" },
    { "name": "Economics" },
    { "name": "Engineering" },
    { "name": "English" },
    { "name": "Environmental Science" },
    { "name": "Foreign Languages" },
    { "name": "Geography" },
    { "name": "Health Education" },
    { "name": "History" },
    { "name": "Law" },
    { "name": "Literature" },
    { "name": "Mathematics" },
    { "name": "Music" },
    { "name": "Philosophy" },
    { "name": "Physical Education" },
    { "name": "Political Science" },
    { "name": "Programming" },
    { "name": "Psychology" },
    { "name": "Public Speaking" },
    { "name": "Science" },
    { "name": "Sociology" },
    { "name": "Statistics" },
    { "name": "Others" },
  ]
};

// employmentType
export const employmentType = {
  "employment": [
    { "name": "Full-Time" },
    { "name": "Part-Time" },
    { "name": "Internship" },
    { "name": "Contract" },
    { "name": "Temporary" },
    { "name": "Freelance" },
    { "name": "Consultant" },
    { "name": "Seasonal" },
    { "name": "Commission" },
    { "name": "Volunteer" }
  ]
};


// indianQualifications
export const indianQualifications = {
  "qualifications": [
    { "name": "Ph.D." },
    { "name": "Post Graduate Diploma" },
    { "name": "Master's Degree" },
    { "name": "Bachelor's Degree" },
    { "name": "Higher Secondary (12th)" },
    { "name": "Secondary (10th)" },
    { "name": "Diploma" },
    { "name": "Certification" },
    { "name": "Other" }
  ]
};


// workExperienceList
export const workExperienceList = {
  "experienceLevels": [
    { "name": "Less than 1 year" },
    { "name": "1 year" },
    { "name": "2 years" },
    { "name": "3 years" },
    { "name": "4 years" },
    { "name": "5 years" },
    { "name": "6 years" },
    { "name": "7 years" },
    { "name": "8 years" },
    { "name": "9 years" },
    { "name": "10 years" },
    { "name": "11-15 years" },
    { "name": "16-20 years" },
    { "name": "21-30 years" },
    { "name": "More than 30 years" }
  ]
};


export const sessionsName = {
  "sessions": []
};

for (let year = 1990; year < 2050; year++) {
  sessionsName.sessions.push({ "name": `${year}-${year + 1}` });
}


// sessions
export const sessions = [
  // {
  //   name: "Select",
  //   id: ""
  // },
  // {
  //   name: "2015-2016",
  //   id: "3984578455444"
  // },
  // {
  //   name: "2016-2017",
  //   id: "36985435355"
  // },
  // {
  //   name: "2017-2018",
  //   id: "874521369745"
  // },
  // {
  //   name: "2018-2019",
  //   id: "123456789012"
  // },
  // {
  //   name: "2019-2020",
  //   id: "234567890123"
  // },

  {
    name: "2020-2021",
    id: "345678901234"
  },
  {
    name: "2021-2022",
    id: "456789012345"
  },
  {
    name: "2022-2023",
    id: "567890123456"
  },
  {
    name: "2023-2024",
    id: "678901234567"
  },
  {
    name: "2024-2025",
    id: "789012345678"
  },
  {
    name: "2025-2026",
    id: "890123456789"
  },
  {
    name: "2026-2027",
    id: "901234567890"
  },
  {
    name: "2027-2028",
    id: "012345678901"
  },
  {
    name: "2028-2029",
    id: "123456789012"
  },
  {
    name: "2029-2030",
    id: "234567890123"
  },
  // {
  //   name: "2030-2031",
  //   id: "345678901235"
  // },
  // {
  //   name: "2031-2032",
  //   id: "456789012346"
  // },
  // {
  //   name: "2032-2033",
  //   id: "567890123457"
  // },
  // {
  //   name: "2033-2034",
  //   id: "678901234568"
  // },
  // {
  //   name: "2034-2035",
  //   id: "789012345679"
  // }
];


// examType
export const examTypeList = {
  "examType": [
    { "name": "Marks" },
    { "name": "Grade (GPA)" },
    { "name": "Mark And Grade" }
  ]
};


export const organizationType = {
  "organizations": [
    { "name": "Non-Profit" },
    { "name": "Government" },
    { "name": "Private" },
    { "name": "Public" },
    { "name": "Multinational" },
    { "name": "Educational" },
    { "name": "Healthcare" },
    { "name": "Small Business" },
    { "name": "Startup" },
    { "name": "Cooperative" }
  ]
};



/* f25961 */