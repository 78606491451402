import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';



// administrator_login
export const administrator_login = createAsyncThunk(
    'administrator/administrator_login',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/administrator-login', info)
            localStorage.setItem('authToken', data.authToken)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// administrator_logout
export const administrator_logout = createAsyncThunk(
    'administrator/administrator_logout',
    async (_, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get('/administrator-logout')
            localStorage.removeItem('authToken');
            localStorage.removeItem('sessionValue');
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// add_class
export const add_class = createAsyncThunk(
    'administrator/add_class',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/add-class', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_all_class
export const get_all_class = createAsyncThunk(
    'administrator/get_all_class',
    async ({ searchValue, organisationId }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-all-class?searchValue=${searchValue}&&organisationId=${organisationId}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_single_class
export const get_single_class = createAsyncThunk(
    'administrator/get_single_class',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-single-class/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// update_class
export const update_class = createAsyncThunk(
    'administrator/update_class',
    async ({ editId, obj }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/update-class/${editId}`, obj)

            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// delete_class
export const delete_class = createAsyncThunk(
    'administrator/delete_class',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.delete(`/delete-class/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// add_subject
export const add_subject = createAsyncThunk(
    'administrator/add_subject',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/add-subject', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_all_subject
export const get_all_subject = createAsyncThunk(
    'administrator/get_all_subject',
    async ({ searchValue }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-all-subject?searchValue=${searchValue}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_single_subject
export const get_single_subject = createAsyncThunk(
    'administrator/get_single_subject',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-single-subject/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// update_subject
export const update_subject = createAsyncThunk(
    'administrator/update_subject',
    async ({ editId, obj }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/update-subject/${editId}`, obj)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// delete_subject
export const delete_subject = createAsyncThunk(
    'administrator/delete_subject',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.delete(`/delete-subject/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// assign_subject_student
export const assign_subject_student = createAsyncThunk(
    'administrator/assign_subject_student',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/assign-subject-student', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// update_subject_student
export const update_subject_student = createAsyncThunk(
    'administrator/update_subject_student',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/update-subject-student', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)




// returnRole
const returnRole = (token) => {
    if (token) {
        const payload = token.split('.')[1];
        const decodedPayload = JSON.parse(atob(payload));
        const expireTime = new Date(decodedPayload.exp * 1000);
        if (new Date() > expireTime) {
            localStorage.removeItem('authToken');
            return '';
        } else {
            return decodedPayload;
        }
    } else {
        return '';
    }
};


export const administratorReducer = createSlice({
    name: "administrator",
    initialState: {
        errorMessage: '',
        successMessage: '',
        logoutMessage: '',
        loader: false,
        administratorInfo: returnRole(localStorage.getItem("authToken")),
        administratorToken: localStorage.getItem("authToken"),
        classList: [],
        singleClass: {},
        subjectLists: [],
        singleSubject: {}

    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ''
            state.successMessage = ''
            state.logoutMessage = ''
        },
        tokenClear: (state, _) => {
            state.administratorInfo = ''
            state.administratorToken = ''
        },
    },
    extraReducers: {
        [administrator_login.pending]: (state, _) => {
            state.loader = true
        },
        [administrator_login.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [administrator_login.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
            state.administratorInfo = returnRole(payload.authToken)
            state.administratorToken = payload.authToken
        },
        [administrator_logout.fulfilled]: (state, { payload }) => {
            state.logoutMessage = payload.message
        },
        [add_class.pending]: (state, _) => {
            state.loader = true;
        },
        [add_class.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [add_class.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [get_all_class.pending]: (state, _) => {
            state.loader = true;
        },
        [get_all_class.fulfilled]: (state, { payload }) => {
            state.loader = false;
            state.classList = payload.classList
        },
        [get_single_class.fulfilled]: (state, { payload }) => {
            state.singleClass = payload.singleClass
        },
        [update_class.pending]: (state, _) => {
            state.loader = true;
        },
        [update_class.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [update_class.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [delete_class.rejected]: (state, { payload }) => {
            state.errorMessage = payload.error
        },
        [delete_class.fulfilled]: (state, { payload }) => {
            state.successMessage = payload.message
        },
        [add_subject.pending]: (state, _) => {
            state.loader = true;
        },
        [add_subject.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [add_subject.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [get_all_subject.pending]: (state, _) => {
            state.loader = true;
        },
        [get_all_subject.fulfilled]: (state, { payload }) => {
            state.loader = false;
            state.subjectLists = payload.subjectLists
        },
        [get_single_subject.fulfilled]: (state, { payload }) => {
            state.singleSubject = payload.singleSubject
        },
        [update_subject.pending]: (state, _) => {
            state.loader = true;
        },
        [update_subject.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [update_subject.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [delete_subject.fulfilled]: (state, { payload }) => {
            state.successMessage = payload.message
        },
        [assign_subject_student.pending]: (state, _) => {
            state.loader = true;
        },
        [assign_subject_student.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [assign_subject_student.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [update_subject_student.pending]: (state, _) => {
            state.loader = true;
        },
        [update_subject_student.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [update_subject_student.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },

    }
});


export const { messageClear, tokenClear } = administratorReducer.actions;
export default administratorReducer.reducer;