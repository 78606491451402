import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useOptionsFromData } from '../../../../utils/other/returnFunction';
import { fatherOccupations } from '../../../../utils/other/data';


const TeacherBankDetails = forwardRef(({ onSendMessage }, ref) => {

    const { teacher } = useSelector(state => state.teacher);

    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();


    // handleSelect
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
        clearErrors(fieldName);

    }


    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        }
    }));


    // onSubmit
    const onSubmit = (data) => {
        onSendMessage(true);
        sessionStorage.setItem('teacherBankDetails', JSON.stringify(data))
    };


    // removeDetails
    const removeDetails = () => {
        sessionStorage.removeItem('teacherBankDetails')
        reset({
            ifscCode: '',
            bankName: '',
            branchName: '',
            accountHolderName: '',
            accountNumber: '',
            confirmAccountNumber: ''
        });
    }


    useEffect(() => {

        if (Object.keys(teacher).length > 0) {

            setValue('ifscCode', teacher.teacherBankDetails.ifscCode);
            setValue('bankName', teacher.teacherBankDetails.bankName);
            setValue('branchName', teacher.teacherBankDetails.branchName);
            setValue('accountHolderName', teacher.teacherBankDetails.accountHolderName);
            setValue('accountNumber', teacher.teacherBankDetails.accountNumber);
            setValue('confirmAccountNumber', teacher.teacherBankDetails.accountNumber);
  
        }

    }, [teacher])



    useEffect(() => {

        const storeBankDetails = sessionStorage.getItem("teacherBankDetails");
        if (storeBankDetails) {
            const teacherBankDetails = JSON.parse(storeBankDetails);

            setValue("ifscCode", teacherBankDetails.ifscCode)
            setValue("bankName", teacherBankDetails.bankName)
            setValue("branchName", teacherBankDetails.branchName)
            setValue("accountHolderName", teacherBankDetails.accountHolderName)
            setValue("accountNumber", teacherBankDetails.accountNumber)
            setValue("confirmAccountNumber", teacherBankDetails.confirmAccountNumber)

        }

    }, [])


    // handleBankDetails
    const handleBankDetails = async (e) => {

        try {
            const { data } = await axios.get(`https://ifsc.razorpay.com/${e.target.value}`);
            if (data) {
                setValue('bankName', data.BANK)
                setValue('branchName', data.BRANCH)
            }

        } catch (error) {
            setValue('bankName', '')
            setValue('branchName', '')
        }

    }



    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card">

                    <div className="card-header d-flex justify-content-between">
                        <h4 className="card-title">Bank Details</h4>
                        <button onClick={removeDetails} className='btn btn-danger px-3 py-2 fw-bold'> Reset </button>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="ifscCode">IFSC Code <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.ifscCode ? 'is-invalid' : ''}`}
                                            id="ifscCode"
                                            {...register('ifscCode', { required: 'IFSC Code is required' })}
                                            onChange={(selectOption) => {
                                                handleBankDetails(selectOption);
                                            }}
                                        />
                                        {errors.ifscCode && <div className="invalid-feedback">{errors.ifscCode.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="bankName">Bank Name <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.bankName ? 'is-invalid' : ''}`}
                                            id="bankName"
                                            {...register('bankName', { required: 'Bank Name is required' })}
                                            readOnly
                                        />
                                        {errors.bankName && <div className="invalid-feedback">{errors.bankName.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="branchName">Branch Name <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.branchName ? 'is-invalid' : ''}`}
                                            id="branchName"
                                            {...register('branchName', { required: 'Branch Name is required' })}
                                            readOnly
                                        />
                                        {errors.branchName && <div className="invalid-feedback">{errors.branchName.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="accountHolderName">Account Holder Name <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.accountHolderName ? 'is-invalid' : ''}`}
                                            id="accountHolderName"
                                            {...register('accountHolderName', { required: 'Account Holder Name is required' })}
                                        />
                                        {errors.accountHolderName && <div className="invalid-feedback">{errors.accountHolderName.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="accountNumber">Account Number <span className='text-danger'>*</span></label>
                                        <input
                                            {...register('accountNumber', {
                                                required: 'Account Number is required',
                                                minLength: {
                                                    value: 6,
                                                    message: 'Account Number must be at least 6 characters long',
                                                },
                                                maxLength: {
                                                    value: 20,
                                                    message: 'Account Number must not exceed 20 characters',
                                                },
                                            })}
                                            id="accountNumber"
                                            name="accountNumber"
                                            type="text"
                                            className={`form-control ${errors.accountNumber ? 'is-invalid' : ''}`}
                                        />
                                        {errors.accountNumber && <p className="invalid-feedback">{errors.accountNumber.message}</p>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="confirmAccountNumber">Confirm Account Number <span className='text-danger'>*</span></label>
                                        <input
                                            {...register('confirmAccountNumber', {
                                                required: 'Confirm Account Number is required',
                                                validate: value => value === getValues('accountNumber') || 'Account Numbers do not match',
                                            })}
                                            id="confirmAccountNumber"
                                            name="confirmAccountNumber"
                                            type="text"
                                            className={`form-control ${errors.confirmAccountNumber ? 'is-invalid' : ''}`}
                                        />
                                        {errors.confirmAccountNumber && <p className="invalid-feedback">{errors.confirmAccountNumber.message}</p>}
                                    </div>
                                </div>


                            </div>
                            <button type="submit" style={{ display: 'none' }}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
});


export default TeacherBankDetails;