import React, { useState } from 'react';
import LogoBar from './LogoBar';
import Navbar from './Navbar';
import { useSelector } from 'react-redux';
import OrgWonerSidebar from './Sidebar/OrgWonerSidebar';
import OrgSidebar from './Sidebar/OrgSidebar';
import AdministratorSidebar from './Sidebar/AdministratorSidebar';
import TeacherSidebar from './Sidebar/TeacherSidebar';
import LibrarianSidebar from './Sidebar/LibrarianSidebar';
import AccountantSidebar from './Sidebar/AccountantSidebar';

const Layout = ({ children }) => {
    const { sideBar } = useSelector(state => state.default);
    const { orgWonerInfo } = useSelector(state => state.org_woner);
    const { organisationInfo } = useSelector(state => state.organization);
    const { administratorInfo } = useSelector(state => state.administrator);
    const { employeeInfo } = useSelector(state => state.employee);


    return (
        <div className={`wrapper ${sideBar ? 'sidebar_minimize' : ''}`}>
            <div className="main-header">
                <LogoBar />
                <Navbar />
            </div>
            {orgWonerInfo.role === 'Woner' && <OrgWonerSidebar />}
            {organisationInfo.role === 'Organization' && <OrgSidebar />}
            {administratorInfo.role === 'Administrator' && <AdministratorSidebar />}
            {employeeInfo.role === 'Teacher' && <TeacherSidebar />}
            {employeeInfo.role === 'Librarian' && <LibrarianSidebar />}
            {employeeInfo.role === 'Accountant' && <AccountantSidebar />}
            <div className="main-panel">
                <div className="content">
                    <div className="page-inner">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Layout;