import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import AddFeesGroup from './AddFeesGroup';
import ShowFeesGroup from './ShowFeesGroup';
import Layout from '../../../Layout/Layout';
import { useNavigate, useParams } from 'react-router-dom';


const FeesGroup = () => {
    const { groupId } = useParams();
    const navigate = useNavigate();


    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    // handleDataFromChild
    const handleDataFromChild = (childData) => {
        setTabIndex(childData);
        navigate('/fees-group')
    };


    useEffect(() => {
        if (groupId) {
            setTabIndex(1)
        } else {
            setTabIndex(0)
        }
    }, [groupId])


    return (
        <Layout>
            <div className='px-0 mb-4'>
                <div className='card mb-0'>
                    <Tabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        aria-label="teacher assignment tabs"
                        variant="fullWidth"
                        textColor="primary"
                        indicatorColor="primary"
                    >
                        <Tab label="Show Fees Group" />
                        <Tab label={groupId ? 'Edit Fees Group' : 'Add Fees Group'} />
                    </Tabs>
                </div>
            </div>
            <TabPanel value={tabIndex} index={0}>
                <ShowFeesGroup />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <AddFeesGroup groupId={groupId || ''} onSendData={handleDataFromChild} />
            </TabPanel>
        </Layout>
    );
};


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}



export default FeesGroup;