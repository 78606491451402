// src/components/AdmissionStudent.js
import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../Layout/Layout';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import BasicDetails from './BasicDetails';
import AddressDetails from './AddressDetails';
import GuardianDetails from './GuardianDetails';
import BankDetails from './BankDetails';
import MedicalDetails from './MedicalDetails';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonLoader } from '../../../utils/Loader/LoaderComponent';
import { get_single_student, messageClear, studentClear } from '../../../store/Reducers/student/studentReducer';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';



const AdmissionStudent = () => {
    const [activeStep, setActiveStep] = useState(0);
    const childFormRef = useRef();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { studentId } = useParams();


    const { loader, successMessage, errorMessage, student } = useSelector(state => state.student);


    // handleIncrement
    const handleIncrement = () => {
        if (childFormRef.current) {
            childFormRef.current.submitForm();
        }
    };

    // handleMessage
    const handleMessage = (message) => {
        // console.log("message", message)
        if (message) {
            setActiveStep((prev) => prev + 1);
        }
    }


    // handleDecrement
    const handleDecrement = () => {
        setActiveStep((prev) => prev - 1);
    };

    const steps = [
        'Basic Details',
        'Address Details',
        'Guardian Details',
        'Bank Details',
        'Medical Records',
        // 'Previous School Details'
    ];


    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage)
            dispatch(messageClear())
        }
        if (successMessage) {
            toast.success(successMessage)
            dispatch(messageClear())
            sessionStorage.clear()
            navigate('/all-students', { replace: true })
        }
    }, [errorMessage, successMessage])


    useEffect(() => {
        if (studentId) {
            dispatch(get_single_student({ studentId }))
        } else {
            dispatch(studentClear())
        }

    }, [studentId])


    // useEffect(() => {
    //     if (!studentId) {
    //         sessionStorage.clear('basicDetails')
    //         console.log("aaaaaaaaaaaaaaaaaaaaa")
    //     } else {
    //         console.log("fffffffff")
    //     }
    // }, [activeStep === 0, activeStep, studentId])


    return (
        <Layout>
            <div className="page-header">
                <div className='card py-3 mb-0' style={{ width: "100%" }}>
                    <Box>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </div>
            </div>
            <div>
                {activeStep === steps.length ? (
                    <h2>All Steps Complete</h2>
                ) : (
                    <>
                        {activeStep === 0 && <BasicDetails onSendMessage={handleMessage} ref={childFormRef} />}
                        {activeStep === 1 && <AddressDetails onSendMessage={handleMessage} ref={childFormRef} />}
                        {activeStep === 2 && <GuardianDetails onSendMessage={handleMessage} ref={childFormRef} />}
                        {activeStep === 3 && <BankDetails onSendMessage={handleMessage} ref={childFormRef} />}
                        {activeStep === 4 && <MedicalDetails onSendMessage={handleMessage} ref={childFormRef} />}
                    </>
                )}
            </div>


            <div style={{ textAlign: "right" }}>
                <button
                    disabled={activeStep === 0}
                    onClick={handleDecrement}
                    className='btn btn-primary mr-2 px-3 py-2 fw-bold'
                >
                    Previous
                </button>

                <button
                    onClick={handleIncrement}
                    className='btn btn-primary px-3 py-2 fw-bold'
                    style={{ width: "90px" }}
                    disabled={loader ? true : false}
                >
                    {activeStep === steps.length - 1 ? (loader ? <ButtonLoader
                    /> : 'Submit') : 'Next'}
                </button>

            </div>
        </Layout>
    );
};



export default AdmissionStudent;
