import React, { useEffect, useState } from 'react';
import Layout from '../../Layout/Layout';
import { useForm } from 'react-hook-form';
import { ButtonLoader, TableLoader } from '../../../utils/Loader/LoaderComponent';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import Empty from '../../../utils/page/Empty';
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import moment from 'moment';
import Pagination from '../../Layout/Pagination';
import { confirmMessagge } from '../../../utils/other/aleartFunc';
import { add_exam_hall, delete_exam_hall, get_exam_hall, get_single_exam_hall, messageClear, update_exam_hall } from '../../../store/Reducers/examMaster/examReducer';



const ExamHall = () => {

    const dispatch = useDispatch();

    const { handleSubmit, control, setValue, register, formState: { errors }, reset } = useForm();
    const { loader, successMessage, errorMessage, hallList, hallCount, singleExamHall } = useSelector(state => state.exam);
    const { searchValue } = useSelector(state => state.default);


    const [currentPage, setCurrentPage] = useState(1)
    const [parPage, setParPage] = useState(10)

    const [editId, setEditId] = useState('');



    // onSubmit
    const onSubmit = (data) => {
        const { hallNo, seatNo } = data;

        const obj = {
            hallNo,
            seatNo
        }

        if (editId) {
            dispatch(update_exam_hall({ editId, obj }))
        } else {
            dispatch(add_exam_hall(obj))
        }

    }


    // handleItemPerPageChange
    const handleItemPerPageChange = (e) => {
        setParPage(e.target.value)
    }

    //  handleHallEdit
    const handleHallEdit = (editId) => {
        setEditId(editId)
        dispatch(get_single_exam_hall(editId));
    }


    // handleHallDelete
    const handleHallDelete = async (deleteId) => {
        const returnValue = await confirmMessagge('Delete');
        if (returnValue) {
            dispatch(delete_exam_hall(deleteId))
        }
    }



    useEffect(() => {
        const obj = {
            parPage: parseInt(parPage),
            page: parseInt(currentPage),
            searchValue
        }
        dispatch(get_exam_hall(obj))
    }, [searchValue, currentPage, parPage, successMessage])



    useEffect(() => {
        if (singleExamHall) {
            setValue('hallNo', singleExamHall?.hallNo)
            setValue('seatNo', singleExamHall?.seatNo)
        }
    }, [singleExamHall])


    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage);
            dispatch(messageClear());
        }
        if (successMessage) {
            toast.success(successMessage);
            dispatch(messageClear());
            setValue('hallNo', '')
            setValue('seatNo', '')
            setEditId('')
            // dispatch(get_exam_hall(obj))
        }
    }, [errorMessage, successMessage]);



    return (
        <Layout>

            <div className="row">

                <div className="col-md-7">
                    <div className="card">
                        <div className="card-body">
                            <div className="page-header mb-0">
                                <span style={{ fontSize: "18px", padding: "0px 8px 7px 0px" }}>
                                    <i className="fas fa-list" />
                                </span>
                                <h4 style={{ fontSize: "19px" }}>Exam Hall List</h4>
                            </div>

                            <hr className='px-0 my-1 ' />

                            <div className='mt-3'>

                                {loader ? (
                                    // Display loader while data is being fetched
                                    <TableLoader />
                                ) : hallList?.length > 0 ? (
                                    // Render table if data exists
                                    <table className="tableStyle">
                                        <thead className=''>
                                            <tr>
                                                <th>Sl.</th>
                                                <th>Hall No</th>
                                                <th>No Of Seats</th>
                                                <th className='no-print'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className=''>
                                            {hallList?.map((d, i) => (
                                                <tr key={i}>
                                                    <td data-lable="Sl">{i + 1 + (currentPage - 1) * parPage}</td>
                                                    <td data-lable="Hall No">{d.hallNo}</td>
                                                    <td data-lable="No Of Seats">{d.seatNo}</td>
                                                    <td data-lable="Action" className='no-print'>
                                                        <span title='Edit' className='mr-1' onClick={() => handleHallEdit(d._id)}>
                                                            <FaEdit style={{ fontSize: '20px', color: 'green' }} />
                                                        </span>
                                                        <span title='Delete' className='ml-1' onClick={() => handleHallDelete(d._id)}>
                                                            <MdDeleteForever style={{ fontSize: '24px', color: 'red' }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    // Display empty state when classList is empty
                                    <Empty data={`Exam Hall Not Found !`} />
                                )}

                            </div>

                        </div>

                        {
                            hallCount > 10 && <div class="d-flex justify-content-between">
                                <div className="col-md-4 d-flex justify-content-start align-items-center itemParPageStyle">
                                    <div class="d-flex mb-2">
                                        <label for="itemPerPage" class="col-sm-auto col-form-label">Select items per page :</label>
                                        <div class="col-sm-auto">
                                            <select
                                                id='itemPerPage'
                                                style={{ width: "65px", marginLeft: "-23px", marginTop: "7px", padding: "3px" }}
                                                onChange={handleItemPerPageChange}
                                                value={parPage}
                                            >
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="5000">All</option>

                                            </select>
                                        </div>
                                    </div>

                                </div>

                                <div className='px-4 pt-2'>
                                    {
                                        hallCount >= parPage ?
                                            <Pagination
                                                pageNumber={currentPage}
                                                setPageNumber={setCurrentPage}
                                                totalItem={hallCount}
                                                parPage={parPage}
                                                showItem={Math.floor(hallCount / parPage)}
                                            /> : ''
                                    }
                                </div>

                            </div>
                        }

                    </div>
                </div>


                <div className="col-md-5">
                    <div className="card">
                        <div className="card-body">
                            <div className="page-header mb-0">
                                <span style={{ fontSize: "18px", padding: "0px 8px 8px 0px" }}>
                                    <i className="fas fa-edit" />
                                </span>
                                <h4 style={{ fontSize: "19px" }}>{editId ? 'Edit Exam Hall' : 'Add Exam Hall'}</h4>
                            </div>
                            <hr className='px-0 my-1 ' />
                            <div>

                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group">
                                        <label htmlFor="hallNo">Hall No <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.hallNo ? 'is-invalid' : ''}`}
                                            id="hallNo"
                                            {...register('hallNo', { required: 'Exam hall is required' })}
                                        />
                                        {errors.hallNo && <div className="invalid-feedback">{errors.hallNo.message}</div>}
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="seatNo">Number of Seats <span className='text-danger'>*</span></label>
                                        <input
                                            type="number"
                                            className={`form-control ${errors.seatNo ? 'is-invalid' : ''}`}
                                            id="seatNo"
                                            {...register('seatNo', { required: 'Number of seats is required' })}
                                        />
                                        {errors.seatNo && <div className="invalid-feedback">{errors.seatNo.message}</div>}
                                    </div>

                                    <div className="my-3 mx-2" style={{ textAlign: "right" }}>
                                        <button disabled={loader} type="submit" style={{ width: "90px" }} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                            {loader ? <ButtonLoader /> : (editId ? 'Edit' : 'Add')}
                                        </button>
                                    </div>

                                </form>

                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </Layout>
    )
}


export default ExamHall;