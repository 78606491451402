import React, { useState } from 'react';
import { ButtonLoader, TableLoader } from '../../../../utils/Loader/LoaderComponent';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import moment from 'moment';
import { getTeacherDetails, useOptionsFromData } from '../../../../utils/other/returnFunction';
import { teacherDepartment } from '../../../../utils/other/data';
import { useDispatch, useSelector } from 'react-redux';
import { get_employee_attendance } from '../../../../store/Reducers/attendance/employeeAttendance';
import Empty from '../../../../utils/page/Empty';

const ShowEmployeeAttendance = () => {

  const dispatch = useDispatch();

  const { handleSubmit, register, setValue, clearErrors, formState: { errors } } = useForm();

  const { employeeCount, allEmmployee } = useSelector(state => state.teacher);
  const { loader, successMessage, errorMessage, emploueeAttendanceForMonth } = useSelector(state => state.employeeAttendance);

  const departmentList = useOptionsFromData(teacherDepartment?.departments);
  const [employeeOption, setEmployeeOption] = useState("")


  const [departmentValue, setDepartmentValue] = useState(null);
  const [employeeValue, setEmployeeValue] = useState(null);


  // handleSelect
  const handleSelect = (fieldName, selectOption) => {
    setValue(fieldName, selectOption);
    clearErrors(fieldName);

    if (fieldName === 'department') {
      setEmployeeValue({ value: "", label: "Select Employee" })
      setValue("employee", { label: '', value: '' })

      const options = allEmmployee?.filter((d) => d.role === selectOption?.value)?.map(item => ({
        value: item._id,
        label: item.teacherBasicDetails.fullName
      }))

      setEmployeeOption(options)
    }
  }


  // filterAttendance
  const filterAttendance = (data) => {
    const { date, department, employee } = data;
    const obj = {
      department: department?.value,
      employeeId: employee ? employee?.value : '',
      date
    }
    dispatch(get_employee_attendance(obj))

  }


  console.log("emploueeAttendanceForMonth", emploueeAttendanceForMonth)


  return (
    <>

      <div className="card mb-3">
        <div className="card-body py-">

          <form onSubmit={handleSubmit(filterAttendance)}>

            <div className="row">


              <div className="col-md-4">
                <div className="form-group pt-0">
                  <label htmlFor="date">Date<span className='text-danger'>*</span></label>
                  <input
                    type="month"
                    className={`form-control ${errors.date ? 'is-invalid' : ''}`}
                    id="date"
                    {...register('date', { required: 'Date is required' })}
                  />
                  {errors.date && <div className="invalid-feedback">{errors.date.message}</div>}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group pt-0">
                  <label htmlFor="department">Department <span className="text-danger">*</span></label>
                  <Select
                    placeholder="Select Department"
                    {...register('department', { required: 'Department is required' })}
                    value={departmentValue}
                    onChange={(selectOption) => {
                      setDepartmentValue(selectOption);
                      handleSelect('department', selectOption);
                    }}
                    options={departmentList}
                    className={errors.department ? 'is-invalid' : ''}
                  />
                  {errors.department && <div className="invalid-feedback">{errors.department.message}</div>}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group pt-0">
                  <label htmlFor="employee">Employee</label>
                  <Select
                    placeholder="Select Employee"
                    {...register('employee')}
                    value={employeeValue}
                    onChange={(selectOption) => {
                      setEmployeeValue(selectOption);
                      handleSelect('employee', selectOption);
                    }}
                    options={employeeOption}
                    isDisabled={employeeOption?.length > 0 ? false : true}
                  />
                </div>
              </div>

            </div>

            <div className="mr-2 text-right">

              <button style={{ width: "80px" }} disabled={loader ? true : false} className="btn btn-secondary px-4 py-2 font-weight-bold">
                {
                  loader ? <ButtonLoader /> : 'Filter'
                }
              </button>
            </div>

          </form>

        </div>
      </div>

      <div className="card">

        <div className="card-body" style={{ padding: "20px", overflowX: "auto" }}>

          {loader ? (
            <TableLoader /> // Show loader while data is loading
          ) : emploueeAttendanceForMonth?.length > 0 ? (
            <div style={{ overflowX: 'auto' }}>
              <h4 style={{ fontSize: '19px', fontWeight: '600' }} className='py-2'>
                Attendance Sheet
                {/* {moment(emploueeAttendanceForMonth[0]?.date, 'YYYY-MM').format('MMMM YYYY')} */}
              </h4>

              <table
                style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  textAlign: 'center',
                  fontSize: '14px',
                  border: '1px solid #ddd',
                  minWidth: '1500px',
                }}
              >
                <thead>
                  <tr style={{ backgroundColor: '#f8f9fa' }}>
                    <th style={{ padding: '10px', border: '1px solid #ddd' }} rowSpan="2">
                      <span style={{ fontSize: '16px', fontWeight: '600' }}>Employees</span>
                    </th>
                    {emploueeAttendanceForMonth[0]?.attendance?.map((_, i) => (
                      <th key={i} style={{ padding: '10px', border: '1px solid #ddd' }} rowSpan="2">
                        {i + 1}
                      </th>
                    ))}
                    <th style={{ padding: '10px', border: '1px solid #ddd' }} colSpan="2">Total</th>
                  </tr>
                  <tr style={{ backgroundColor: '#f8f9fa' }}>
                    <th style={{ padding: '10px', border: '1px solid #ddd' }}>Present</th>
                    <th style={{ padding: '10px', border: '1px solid #ddd' }}>Absent</th>
                  </tr>
                </thead>
                <tbody>
                  {emploueeAttendanceForMonth?.map((employeeData, i) => {
                    const teacherDetails = getTeacherDetails(allEmmployee, employeeData?.employeeId);
                    return (
                      <tr key={i} style={{ backgroundColor: i % 2 === 0 ? '#f2f2f2' : '#ffffff' }}>
                        <td style={{ padding: '10px', border: '1px solid #ddd', fontWeight: 'bold' }}>
                          {teacherDetails?.teacherBasicDetails?.fullName}
                        </td>
                        {employeeData.attendance?.map((dayAttendance, j) => (
                          <td key={j} style={{ padding: '10px', border: '1px solid #ddd' }}>
                            {dayAttendance.attendance === 'Present' ? (
                              <i className='fas fa-check text-success fa-lg' />
                            ) : dayAttendance.attendance === 'Absent' ? (
                              <i className='fas fa-times text-danger fa-lg' />
                            ) : (
                              '-'
                            )}
                          </td>
                        ))}
                        <td style={{ padding: '10px', border: '1px solid #ddd', fontWeight: 'bold' }}>
                          {employeeData?.presentCount}
                        </td>
                        <td style={{ padding: '10px', border: '1px solid #ddd', fontWeight: 'bold' }}>
                          {employeeData?.absentCount}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <Empty data="Attendance List Not Found!" />
          )}

        </div>

        
      </div>

    </>
  )
}

export default ShowEmployeeAttendance;