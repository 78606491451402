import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useForm, Controller } from 'react-hook-form';
import { ButtonLoader } from '../../../utils/Loader/LoaderComponent';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { messageClear, organization_login } from '../../../store/Reducers/organisation/organizationReducer';
import { woner_org_logout } from '../../../store/Reducers/organisation/orgWonerReducer';
import { FaEye, FaEyeSlash } from 'react-icons/fa';


const OrganizationLogin = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false); // State to manage password visibility


    const { errorMessage, loader, successMessage, organisationToken } = useSelector(state => state.organization);
    const { handleSubmit, setValue, register, formState: { errors } } = useForm();


    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    // onSubmit
    const onSubmit = (data) => {
        const { email, password } = data;
        const obj = {
            email,
            password
        }
        dispatch(organization_login(obj))

    };


    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage)
            dispatch(messageClear())
        }
        if (successMessage) {
            toast.success(successMessage)
            dispatch(messageClear())
            navigate('/organization-dashboard', { replace: true })
        }
    }, [errorMessage, successMessage])


    // useEffect(() => {
    //     if (organisationToken) {
    //         navigate('/organization-dashboard', { replace: true })
    //     }
    // }, [])


    return (
        <div className="pt-5" style={{ display: "flex", alignItems: 'center', height: '100%' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="container">
                    <h4 className='pl-2 font-weight-bold'>Login as Organisation</h4>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <input
                                    {...register('email', {
                                        required: 'Email is required',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: 'Invalid email address',
                                        },
                                    })}
                                    id="email"
                                    name="email"
                                    type="email"
                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                    placeholder='Enter Email'
                                />
                                {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <input
                                    {...register('password', {
                                        required: 'Password is required',
                                    })}
                                    id="password"
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                    placeholder='Enter Password'
                                />
                                <span
                                    onClick={togglePasswordVisibility}
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '40px',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer'
                                    }}>
                                    {showPassword ? <FaEyeSlash size={18} /> : <FaEye size={18} />}
                                </span>
                                {errors.password && <p className="invalid-feedback">{errors.password.message}</p>}

                            </div>
                        </div>
                    </div>
                    <div className="mt-3" style={{ marginLeft: "12px" }}>
                        <button disabled={loader ? true : false} type="submit" className="btn btn-secondary col-md-12 mt-3 mt-sm-0 fw-bold mx-auto">
                            {
                                loader ? <ButtonLoader /> : 'Login'
                            }
                        </button>
                    </div>
                </div>
                <div className="login-account pt-3" style={{ textAlign: "center" }}>
                    <span className="msg">Do you want to log in as an owner ?</span>
                    <Link to='/login' id="show-login" className="link"> Login</Link>
                </div>

            </form>

        </div>
    )
}

export default OrganizationLogin;