import React from 'react';
import Layout from '../../Layout/Layout';

const LibrarianDashboard = () => {
    return (
        <Layout>
            <h1>LibrarianDashboard</h1>
        </Layout>
    )
}

export default LibrarianDashboard;