import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { assign_subject_student, messageClear, update_subject_student } from '../../../store/Reducers/administrator/administratorReducer';
import { get_all_student, student_filter } from '../../../store/Reducers/student/studentReducer';
import Layout from '../../Layout/Layout';
import { getActiveClassName, getActiveSession, getSubjectNames } from '../../../utils/other/returnFunction';
import { RiPhoneFindLine } from "react-icons/ri";
import Empty from '../../../utils/page/Empty';
import toast from 'react-hot-toast';
import { ButtonLoader } from '../../../utils/Loader/LoaderComponent';
import { useForm } from 'react-hook-form';
import { FaEdit } from "react-icons/fa";



const AssignSubject = () => {
    const dispatch = useDispatch();
    const { loader, successMessage, errorMessage, classList, subjectLists } = useSelector(state => state.administrator);

    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors } = useForm();

    const { allStudents } = useSelector(state => state.student);
    const { sessionValue } = useSelector(state => state.default);

    const [classListOption, setClassListOption] = useState([]);
    const [sectionOption, setSectionOption] = useState([]);
    const [selecteeClassId, setSelecteeClassId] = useState('');
    const [subjectOption, setSubjectOption] = useState([]);

    const [subjectValue, setSubjectValue] = useState(null);
    const [classValue, setClassValue] = useState(null);
    const [sectionValue, setSectionValue] = useState(null);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState([]);


    // state for edit
    const [editId, setEditId] = useState('');
    const [openEditModel, setOpenEditModel] = useState(false);
    const [editSubjectValue, setEditSubjectValue] = useState(null)


    // handleStudentSelect
    const handleStudentSelect = (e, student) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedStudents(prevStudents => [...prevStudents, student]);
        } else {
            setSelectedStudents(prevStudents => prevStudents.filter(s => s._id !== student._id));
        }
    }

    // handleSelectAll
    const handleSelectAll = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedStudents([...allStudents]);
        } else {
            setSelectedStudents([]);
        }
    };


    // handleSelect
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);

        if (fieldName === 'className') {
            setSelecteeClassId(selectOption.value);
            setSectionValue({ value: "", label: "Select Section" });
            setValue("section", { label: '', value: '' });
            const secOption = classList?.filter((item) => item._id === selectOption.value);
            const array = secOption[0]?.sectionNames;
            const convertedArray = array?.map(item => ({
                value: item,
                label: item
            }));
            setSectionOption(convertedArray);

            const filterSubject = subjectLists?.filter(d => d.classDetails.classId === selectOption.value).flatMap(d => d.subjects);
            if (filterSubject) {
                const options = filterSubject?.map(item => ({
                    value: item._id,
                    label: item.subjectName
                }));
                setSubjectOption(options);
            }

            const obj = {
                sessionValue,
                className: selectOption.value,
                section: ''
            }
            dispatch(student_filter(obj));
        }

        if (fieldName === 'section') {
            const obj = {
                sessionValue,
                className: selecteeClassId,
                section: selectOption.value
            }
            dispatch(student_filter(obj));
        }
    }

    // handleAssign
    const handleAssign = () => {
        const obj = {
            studentIds: selectedStudents?.map(s => s._id),
            subjectIds: subjectValue?.map(s => s.value),
            sessionId: sessionValue
        }
        dispatch(assign_subject_student(obj));
    }

    // handleEditSubmit
    const handleEditSubmit = () => {
        const obj = {
            studentId: editId,
            subjectIds: editSubjectValue?.map(s => s.value),
            sessionId: sessionValue
        }
        dispatch(update_subject_student(obj))
    }

    // handleShowModal
    const handleShowModal = (subjects) => {
        setModalData(subjects);
        setShowModal(true);
    }


    // hendleEditSubject
    const hendleEditSubject = (data) => {
        setOpenEditModel(true);
        setEditId(data._id);

        // Find the active session
        const activeSession = data.sessions.find(session => session.sessionIsActive === true);

        // If no active session, return an empty array
        if (!activeSession) {
            return [];
        }

        // Find the subjects for the class in the active session
        const convertedArray = subjectLists?.find((d) => d.classDetails.classId === activeSession.className)?.subjects;

        if (convertedArray) {
            const options = convertedArray?.map(item => ({
                value: item._id,
                label: item.subjectName
            }));
            setSubjectOption(options);
        }

        // Assume assignedSubjects is an array of subject IDs assigned to the student
        const assignedSubjects = activeSession?.assignedSubjects;

        // Filter and map the subjects to the desired format
        const matchedSubjects = convertedArray
            ?.filter(subject => assignedSubjects?.includes(subject._id))
            .map(subject => ({
                value: subject._id,
                label: subject.subjectName
            }));

        setEditSubjectValue(matchedSubjects)
    };


    const handleCloseModal = () => setShowModal(false);


    useEffect(() => {
        const obj2 = {
            parPage: 1000000000,
            page: 1,
            searchValue: "",
            sessionValue
        }
        if (errorMessage) {
            toast.error(errorMessage);
            dispatch(messageClear());
        }
        if (successMessage) {
            toast.success(successMessage);
            dispatch(messageClear());
            dispatch(get_all_student(obj2));
            setClassValue([]);
            setSectionValue([]);
            setSubjectValue([]);
            setEditSubjectValue([]);
            setOpenEditModel(false)
            setEditId('false')
        }
    }, [errorMessage, successMessage]);


    useEffect(() => {
        const options = classList?.map(item => ({
            value: item._id,
            label: item.className
        }));
        setClassListOption(options);
    }, [classList]);


    // console.log("classValue", classValue?.length)


    return (
        <Layout>

            <div className="card mb-3">
                <div className="card-body py-">

                    <div className="row">
                        {/* <h4 className="font-weight-bold">Select Ground</h4>
                        <hr className='px-0 my-1 ' /> */}
                        <div className="col-md-6 col-lg-3">
                            <div className="form-group pt-0">
                                <label htmlFor="className">Class <span className='text-danger'>*</span></label>
                                <Select
                                    placeholder="Select Class"
                                    value={classValue}
                                    {...register('className')}
                                    onChange={(selectOption) => {
                                        setClassValue(selectOption);
                                        handleSelect('className', selectOption);
                                    }}
                                    options={classListOption}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <div className="form-group pt-0">
                                <label htmlFor="section">Section </label>
                                <Select
                                    {...register('section')}
                                    placeholder="Select Section"
                                    value={sectionValue}
                                    onChange={(selectOption) => {
                                        setSectionValue(selectOption);
                                        handleSelect('section', selectOption);
                                    }}
                                    options={sectionOption}
                                    isDisabled={sectionOption?.length > 0 ? false : true}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6">
                            <div className="form-group pt-0">
                                <label htmlFor="section">Select Subject <span className='text-danger'>*</span></label>
                                <Select
                                    value={subjectValue}
                                    {...register('subjectName')}
                                    onChange={(selectOption) => {
                                        setSubjectValue(selectOption);
                                        handleSelect('subjectName', selectOption);
                                    }}
                                    isDisabled={subjectOption?.length === 0}
                                    options={subjectOption}
                                    className={`basic-multi-select`}
                                    isMulti
                                    classNamePrefix="select"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div className='d-flex justify-content-between m-0 p-0'>
                        <div className="page-header mb-0">
                            <RiPhoneFindLine style={{ fontSize: '23px', marginBottom: "6px", marginRight: "8px" }} />
                            <h4 style={{ fontSize: "19px" }}>Assign Subject To Student</h4>
                        </div>

                        {selectedStudents?.length > 0 && subjectValue?.length > 0 && (
                            <div style={{ textAlign: "right" }} className=''>
                                <button onClick={handleAssign} type="submit" className='btn btn-primary px-3 py-2 fw-bold'
                                    style={{ width: "90px" }}
                                    disabled={loader ? true : false}>
                                    {loader ? <ButtonLoader /> : 'Assign'}
                                </button>
                            </div>
                        )}
                    </div>

                    <hr className='my-3' />

                    {allStudents?.length > 0 ? (
                        <table className="tableStyle">
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            checked={selectedStudents?.length === allStudents?.length}
                                            onChange={handleSelectAll}
                                            type="checkbox"
                                            style={{ transform: "scale(1.2)" }}
                                            disabled={!classValue?.value}
                                        />
                                    </th>
                                    <th>Student Name</th>
                                    <th>Class/Section</th>
                                    <th>Subjects</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allStudents?.map((d, i) => {
                                    const subjectsToShow = getSubjectNames(d.sessions, subjectLists).slice(0, 3);
                                    const allSubjects = getSubjectNames(d.sessions, subjectLists);
                                    return (
                                        <tr key={i}>
                                            <td>
                                                <input
                                                    value={d._id}
                                                    checked={selectedStudents.some(s => s._id === d._id)}
                                                    onChange={(e) => handleStudentSelect(e, d)}
                                                    type="checkbox"
                                                    style={{ transform: "scale(1.2)" }}
                                                    disabled={!classValue?.value}
                                                />
                                            </td>
                                            <td>{d.basicDetails.fullName}</td>
                                            <td>{getActiveClassName(classList, d.sessions)} ({getActiveSession(d.sessions)})</td>
                                            <td>
                                                {
                                                    subjectsToShow.map((s, j) => (
                                                        <span
                                                            style={{ fontSize: "14px" }}
                                                            key={j}
                                                            className='bg-danger text-light px-2 py-1 mr-1 rounded'
                                                        >
                                                            {s}
                                                        </span>
                                                    ))
                                                }
                                                {allSubjects.length > 3 && (
                                                    <>
                                                        <span style={{ fontWeight: "600", background: "#444", cursor: "pointer" }} className=' text-light px-2 py-1 mr-1 rounded' onClick={() => handleShowModal(allSubjects)}>
                                                            Show All
                                                        </span>
                                                    </>
                                                )}
                                            </td>
                                            <td data-label="Action" className='no-print'>
                                                <span title='Edit' className='mr-1 ml-1' onClick={() => hendleEditSubject(d)}>
                                                    <FaEdit style={{ fontSize: '19px', color: 'green', cursor: "pointer" }} />
                                                </span>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    ) : (
                        <Empty />
                    )}

                </div>
            </div>

            {/* Modal */}
            <Modal
                open={showModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', borderRadius: '5px', boxShadow: 24, p: 2 }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Assigned Subjects
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {
                            modalData.map((s, index) => (
                                <span
                                    style={{ fontSize: "17px", display: 'block', marginBottom: '8px' }}
                                    key={index}
                                    className='bg-danger text-light px-2 py-1 mr-1 rounded'
                                >
                                    {s}
                                </span>
                            ))
                        }

                    </Typography>
                </Box>
            </Modal>


            {/* edit modal */}
            <Modal
                open={openEditModel}
                onClose={() => setOpenEditModel(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 550, bgcolor: 'background.paper', borderRadius: '5px', boxShadow: 24, p: 2 }}>
                    <Typography id="modal-modal-title" variant="h6" component="h3">
                        Edit Assigned Subjects
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenEditModel(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                        <div className="form-group pt-0 px-0 mb-3">
                            <label htmlFor="section">Select Subject </label>
                            <Select
                                value={editSubjectValue}
                                {...register('subjectName')}
                                onChange={(selectOption) => {
                                    setEditSubjectValue(selectOption);
                                    handleSelect('subjectName', selectOption);
                                }}
                                options={subjectOption}
                                className={`basic-multi-select`}
                                isMulti
                                classNamePrefix="select"
                            />
                        </div>

                        <div style={{ textAlign: "right" }} className=''>
                            <button onClick={handleEditSubmit} type="submit" className='btn btn-primary px-3 py-2 fw-bold'
                                style={{ width: "90px" }}
                                disabled={loader ? true : false}>
                                {loader ? <ButtonLoader /> : 'Edit'}
                            </button>
                        </div>

                    </Typography>
                </Box>
            </Modal>


        </Layout>
    );
}

export default AssignSubject;
