import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { useOptionsFromData } from '../../../utils/other/returnFunction';
import { countries, genders, indiaStates } from '../../../utils/other/data';
import axios from 'axios';
import { useSelector } from 'react-redux';



const AddressDetails = forwardRef(({ onSendMessage }, ref) => {

    const { student } = useSelector(state => state.student);

    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();

    const [countryData, setCountryeData] = useState("")
    const [stateData, setStateData] = useState("")
    const [isState, setIstate] = useState(false)
    const [cityData, setCityData] = useState("")
    const [pincodeData, setPincodeData] = useState("")


    const [countryValue, setCountryValue] = useState(null);
    const [stateValue, setStateValue] = useState(null);
    const [cityValue, setCityValue] = useState(null);



    // handleCountryChange
    const handleCountryChange = (selectedOption) => {
        if (selectedOption.value === 'India') {
            setIstate(true)
        } else {
            setIstate(false)
        }

        setValue('country', selectedOption);
        clearErrors('country');
    };


    // handleStateChange
    const handleStateChange = (selectedOption) => {
        setValue('state', selectedOption);
        clearErrors('state');
    };


    // handleCityChange
    const handleCityChange = (selectedOption) => {
        setValue('city', selectedOption);
        clearErrors('city')
    }


    // handlePincode
    const handlePincode = async (e) => {
        setCityValue()
        try {
            const findCity = await axios.get(`https://api.postalpincode.in/pincode/${e.target.value}`);
            if (findCity.data[0]?.PostOffice.length > 0) {
                clearErrors('pincode');
                setPincodeData(e.target.value)
                const cityOption = findCity.data[0]?.PostOffice?.map(item => ({
                    value: item.Name,
                    label: item.Name,
                }));
                setCityData(cityOption)

            } else {
                // setPincodeData(null)
            }

        } catch (error) {
            // console.log("error", error.message)
        }

    }


    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        }
    }));

    const onSubmit = (data) => {
        onSendMessage(true);
        sessionStorage.setItem('addressDetails', JSON.stringify(data))
    };


    // removeDetails
    const removeDetails = () => {
        sessionStorage.removeItem('addressDetails')
        reset({
            state: '',
            pincode: '',
            fullAddress: '',
            country: '',
            state: '',
            city: '',
        });
        setCountryValue()
        setStateValue()
        setCityValue()

    }



    useEffect(() => {

        if (Object.keys(student).length > 0) {

            setCountryValue({
                label: student.addressDetails?.country, value: student.addressDetails?.country
            })
            setValue("country", {
                label: student.addressDetails?.country, value: student.addressDetails?.country
            })

            if (student.addressDetails?.country === 'India') {
                setIstate(true)
                setStateValue({
                    label: student.addressDetails?.state, value: student.addressDetails?.state
                })
                setValue("state", {
                    label: student.addressDetails?.state, value: student.addressDetails?.state
                })
            } else {
                setValue('state', student.addressDetails.state);
            }
            setValue('pincode', student.addressDetails.pincode);

            setCityValue({
                label: student.addressDetails?.city, value: student.addressDetails?.city
            })
            setValue("city", {
                label: student.addressDetails?.city, value: student.addressDetails?.city
            })
            setValue('fullAddress', student.addressDetails.fullAddress);

        }

    }, [student])


    useEffect(() => {

        const storedBasicDetails = sessionStorage.getItem("addressDetails");
        if (storedBasicDetails) {
            const addressDetails = JSON.parse(storedBasicDetails);
            setCountryValue({
                label: addressDetails?.country.label, value: addressDetails?.country.value
            })
            setValue("country", {
                label: addressDetails?.country.label, value: addressDetails?.country.value
            })

            if (addressDetails?.country.value === 'India') {
                setStateValue({
                    label: addressDetails?.state.label, value: addressDetails?.state.value
                })
                setValue("state", {
                    label: addressDetails?.state.label, value: addressDetails?.state.value
                })
                setIstate(true)
            } else {
                setValue("state", addressDetails.state)
            }
            setValue("pincode", addressDetails.pincode)
            // setPincodeData(addressDetails.pincode)

            setCityValue({
                label: addressDetails?.city.label, value: addressDetails?.city.value
            })
            setValue("city", {
                label: addressDetails?.city.label, value: addressDetails?.city.value
            })

            setValue("fullAddress", addressDetails.fullAddress)
        }

    }, [])


    useEffect(() => {
        const countryeOption = countries?.countries.map(item => ({
            value: item.name,
            label: item.name,
        }));
        setCountryeData(countryeOption);
        const stateOption = indiaStates?.states.map(item => ({
            value: item.name,
            label: item.name,
        }));
        setStateData(stateOption);
    }, []);


    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header d-flex justify-content-between">
                        <h4 className="card-title">Address Details</h4>
                        <button onClick={removeDetails} className='btn btn-danger px-3 py-2 fw-bold'> Reset </button>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="country">Country <span className='text-danger'>*</span></label>
                                        <Select
                                            {...register('country', { required: 'Country is required' })}
                                            value={countryValue}
                                            onChange={(selectOption) => {
                                                setCountryValue(selectOption);
                                                handleCountryChange(selectOption);
                                            }}
                                            options={countryData}
                                            className={errors.country ? 'is-invalid' : ''}
                                        />
                                        {errors.country && <div className="invalid-feedback">{errors.country.message}</div>}
                                    </div>
                                </div>

                                {
                                    isState ? <div className="col-md-6 col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="state">State <span className='text-danger'>*</span></label>
                                            <Select
                                                {...register('state', { required: 'State is required' })}
                                                value={stateValue}
                                                onChange={(selectOption) => {
                                                    setStateValue(selectOption);
                                                    handleStateChange(selectOption);
                                                }}
                                                options={stateData}
                                                className={errors.state ? 'is-invalid' : ''}
                                            />
                                            {errors.state && <div className="invalid-feedback">{errors.state.message}</div>}
                                        </div>
                                    </div> : <div className="col-md-6 col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="state">State <span className='text-danger'>*</span></label>
                                            <input type="text" className={`form-control ${errors.state ? 'is-invalid' : ''}`} id="state" {...register('state', { required: 'State is required' })} />
                                            {errors.state && <div className="invalid-feedback">{errors.state.message}</div>}
                                        </div>
                                    </div>
                                }


                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="pincode">Pincode <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.pincode ? 'is-invalid' : ''}`}
                                            id="pincode"
                                            {...register('pincode', { required: 'Valid pincode is required' })}
                                            onChange={handlePincode}
                                        />
                                        {errors.pincode && <div className="invalid-feedback">{errors.pincode.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="city">City <span className='text-danger'>*</span></label>
                                        <Select
                                            {...register('city', { required: 'City is required' })}
                                            value={cityValue}
                                            onChange={(selectOption) => {
                                                setCityValue(selectOption);
                                                handleCityChange(selectOption);
                                            }}
                                            options={cityData}
                                            className={errors.city ? 'is-invalid' : ''}
                                            isDisabled={pincodeData.length === 6 ? false : true}
                                        />
                                        {errors.city && <div className="invalid-feedback">{errors.city.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="fullAddress">Full Address <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.fullAddress ? 'is-invalid' : ''}`}
                                            id="fullAddress"
                                            {...register('fullAddress', { required: 'Full Address is required' })}
                                        />
                                        {errors.fullAddress && <div className="invalid-feedback">{errors.fullAddress.message}</div>}
                                    </div>
                                </div>


                            </div>
                            <button type="submit" style={{ display: 'none' }}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
});


export default AddressDetails;