import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { get_class_schedule } from '../../../store/Reducers/class/classReducer';
import Empty from '../../../utils/page/Empty';
import { getSubjectDetails, getTeacehrName } from '../../../utils/other/returnFunction';
import moment from 'moment';
import { useReactToPrint } from "react-to-print";
import { FaUserClock } from "react-icons/fa";
import { FiPrinter } from "react-icons/fi";



const ShowClassSchedule = () => {

    const dispatch = useDispatch();

    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();

    const { classList, subjectLists } = useSelector(state => state.administrator);
    const { classSchedule } = useSelector(state => state.class);
    const { allTeachers } = useSelector(state => state.teacher);



    const [classListOption, setClassListOption] = useState("")
    const [sectionOption, setSectionOption] = useState("")

    const [selecteeClassId, setSelecteeClassId] = useState('');


    const [classValue, setClassValue] = useState(null);
    const [sectionValue, setSectionValue] = useState(null);

    //  handleClassChange
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);

        if (fieldName === 'className') {
            setSelecteeClassId(selectOption.value);

            setSectionValue({ value: "", label: "Select Section" })
            setValue("section", { label: '', value: '' })
            const secOption = classList?.filter((item) => item._id === selectOption.value);
            const array = secOption[0]?.sectionNames;
            const convertedArray = array?.map(item => ({
                value: item,
                label: item
            }));
            setSectionOption(convertedArray)
        }

        if (fieldName === 'section') {
            const obj = {
                classId: selecteeClassId,
                section: selectOption.value
            }
            dispatch(get_class_schedule(obj))

        }


    }


    useEffect(() => {
        const options = classList?.map(item => ({
            value: item._id,
            label: item.className
        }));
        setClassListOption(options)
    }, [classList])


    const componentRef = useRef();


    // createPrint
    const createPrint = useReactToPrint({
        content: () => componentRef.current,
    });


    // console.log("sectionValue", sectionValue)


    return (

        <>
            <div className="card mb-3">
                <div className="card-body py-">

                    <div className="row">

                        <div className="col-md-6 col-lg-6">
                            <div className="form-group pt-0">
                                <label htmlFor="className">Class <span className='text-danger'>*</span></label>
                                <Select
                                    placeholder="Select Class"
                                    value={classValue}
                                    {...register('className')}
                                    onChange={(selectOption) => {
                                        setClassValue(selectOption);
                                        handleSelect('className', selectOption);
                                    }}
                                    options={classListOption}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6">
                            <div className="form-group pt-0">
                                <label htmlFor="section">Section <span className='text-danger'>*</span></label>
                                <Select
                                    {...register('section')}
                                    placeholder="Select Section"
                                    value={sectionValue}
                                    onChange={(selectOption) => {
                                        setSectionValue(selectOption);
                                        handleSelect('section', selectOption);
                                    }}
                                    options={sectionOption}
                                    isDisabled={sectionOption?.length > 0 ? false : true}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body">

                    {
                        classSchedule?.length > 0 && classValue && sectionValue?.value ?

                            <>
                                <div className="page-header mb-0">
                                    <FaUserClock style={{ fontSize: '25px', marginBottom: "6px", marginRight: "8px" }} />
                                    <h4 style={{ fontSize: "19px" }}>Schedule List</h4>

                                    <span className='px-4'>
                                        <FiPrinter onClick={createPrint} title='Print' style={{ fontSize: '24px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    </span>

                                </div>

                                <hr className='my-3' />
                                <table className="tableStyle" ref={componentRef}>
                                    <tbody>
                                        {classSchedule?.map((d, i) => (
                                            d.schedule.length > 0 && ( // Check if schedule length is greater than 0
                                                <tr key={i} className='border'>
                                                    <td data-label="Day" className='border'>{d.day}</td>
                                                    {d?.schedule.map((s, j) => {
                                                        const subjectDetails = getSubjectDetails(subjectLists, s?.subjectId);
                                                        const subjectName = subjectDetails ? subjectDetails?.subjectName : '';
                                                        const teacherDetails = getTeacehrName(allTeachers, s?.teacherId);

                                                        return (
                                                            <td data-label={s.isBreak !== true ? 'Subject' : 'Break'}
                                                                className={`border ${s.isBreak === true ? 'bg-danger text-light' : ''}`}
                                                                key={j}>
                                                                <div>
                                                                    <p className='py-0 my-0' style={{ fontSize: '15px', fontWeight: '700' }}>
                                                                        {s.isBreak !== true ? subjectName : 'Break'}
                                                                    </p>
                                                                    <p className='py-0 my-0' style={{ fontSize: '12px' }}>
                                                                        ({moment(s.startingTime, "HH:mm").format("h:mm A")} - {moment(s.endingTime, "HH:mm").format("h:mm A")})
                                                                    </p>
                                                                    {s.isBreak !== true && (
                                                                        <p className='py-0 my-0' style={{ fontSize: '13px' }}>
                                                                            Teacher: {teacherDetails?.teacherBasicDetails?.fullName}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            )
                                        ))}
                                    </tbody>


                                </table>

                            </>

                            : <Empty data={`Schedule List Not Found!`} />
                    }

                </div>
            </div >

        </>

    )
}

export default ShowClassSchedule;