import io from 'socket.io-client';
import { server_url } from '../../api/api';


export const socket = io(`${server_url}`);



/* import { server_url } from '../../api/api';

import { io } from 'socket.io-client';

const Socket = io(`${server_url}`);
Socket.on("connect", onConnect);
Socket.on("disconnect", onDisconnect);

async function onConnect() {
    // ...

    Socket.io.engine.on("upgrade", (transport) => {
        // ...
    });
}


function onDisconnect() {
    // Handle Disconnect...
    Socket.off('connect');
    Socket.off('disconnect');
}


export default Socket; */