import React, { useState } from 'react';
import { Card, CardContent, Typography, Collapse } from '@mui/material';
import { styled } from '@mui/system';
import { GrHistory } from "react-icons/gr";
import Empty from '../../../../utils/page/Empty';
import moment from "moment";
import { useSelector } from 'react-redux';
import { getActiveClassName, getClassName, getSessionName } from '../../../../utils/other/returnFunction';


const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));


const PromotionHistory = ({ student }) => {
    const [value, setValue] = useState(false);

    const { classList } = useSelector(state => state.administrator);
    const { academicYearApprovedList } = useSelector(state => state.academic);


    // console.log(student?.sessions)
    // console.log('academicYearApprovedList', academicYearApprovedList)


    return (
        <div>
            <StyledCard onClick={() => setValue(!value)}>
                <div className='d-flex px-4 py-3 align-items-center' style={{ backgroundColor: "#e6e6e5", cursor: "pointer" }}>
                    <GrHistory style={{ color: "#4f49a4", fontSize: "20px" }} />
                    <span className='px-2' style={{ fontSize: "16px", color: "#000", fontWeight: "600" }}>Promotion History</span>
                </div>
            </StyledCard>
            <Collapse in={value}>
                <StyledCard>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <table className="tableStyle">
                                        <thead className=''>
                                            <tr>
                                                <th>#</th>
                                                <th>From Class / Section</th>
                                                <th>From Session</th>
                                                <th>Promoted Class / Section</th>
                                                <th>Promoted Session</th>
                                                <th>Due Amount</th>
                                                <th>Promoted Date</th>
                                            </tr>
                                        </thead>
                                        <tbody className=''>

                                            {
                                                student?.sessions?.map((d, i, arr) => (
                                                    <tr key={i} hidden={i === arr.length - 1}>
                                                        <td data-label="Sl">{i + 1}</td>
                                                        <td data-label="From Class / Section">{getClassName(classList, d.className)} ({d.section})</td>
                                                        <td data-label="From Session">{getSessionName(academicYearApprovedList, d.sessionId)}</td>
                                                        <td data-label="Promoted Class / Section">{getClassName(classList, arr[i + 1]?.className)} ({arr[i + 1]?.section})</td>
                                                        <td data-label="Promoted Session">{getSessionName(academicYearApprovedList, arr[i + 1]?.sessionId)}</td>
                                                        <td data-label="Due Amount">₹100</td>
                                                        <td data-label="Promoted Date">{moment(arr[i + 1]?.promotedDate).format('DD, MMM, YYYY')}</td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </table>

                                </div>

                            </div>

                        </div>

                    </div>

                </StyledCard>
            </Collapse>
        </div>
    );
}


export default PromotionHistory;

