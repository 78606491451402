import React, { useEffect, useState } from 'react';
import Layout from '../../Layout/Layout';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { student_filter, student_promotion, messageClear } from '../../../store/Reducers/student/studentReducer';
import Empty from '../../../utils/page/Empty';
import { getActiveClassName } from '../../../utils/other/returnFunction';
import { sessions } from '../../../utils/other/data';
import toast from 'react-hot-toast';
import { ButtonLoader } from '../../../utils/Loader/LoaderComponent';
import { useNavigate } from 'react-router-dom';
import { image_url, server_url } from '../../../api/api';



const StudentPromotion = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { classList } = useSelector(state => state.administrator);
    const { sessionValue } = useSelector(state => state.default);
    const { allStudents, successMessage, errorMessage, loader } = useSelector(state => state.student);
    const { academicYearApprovedList } = useSelector(state => state.academic);



    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();

    const [promoteSessionOption, setPromoteSessionOption] = useState([]);
    const [promoteSectionOption, setPromoteSectionOption] = useState([]);

    const [promoteSessionValue, setPromoteSessionValue] = useState(null);
    const [promoteClassValue, setPromoteClassValue] = useState(null);
    const [promoteSectionValue, setPromoteSectionValue] = useState(null);

    const [classListOption, setClassListOption] = useState([]);
    const [sectionOption, setSectionOption] = useState([]);

    const [classValue, setClassValue] = useState(null);
    const [sectionValue, setSectionValue] = useState(null);

    const [selectedClassId, setSelectedClassId] = useState('');
    const [selectedSection, setSelectedSection] = useState('');
    const [selectedStudents, setSelectedStudents] = useState([]);


    // handleStudentSelect
    const handleStudentSelect = (e, student) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedStudents(prevStudents => [...prevStudents, student]);
        } else {
            setSelectedStudents(prevStudents => prevStudents.filter(s => s._id !== student._id));
        }
    };


    // handleSelectAll
    const handleSelectAll = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedStudents([...allStudents]);
        } else {
            setSelectedStudents([]);
        }
    };


    // handleRollChange
    const handleRollChange = (e, studentId) => {
        const updatedStudents = selectedStudents.map(student =>
            student._id === studentId ? { ...student, roll: parseInt(e.target.value) || '' } : student
        );
        setSelectedStudents(updatedStudents);
    };


    // handleStatusChange
    const handleStatusChange = (studentId, isPromotion) => {
        const updatedStudents = selectedStudents.map(student =>
            student._id === studentId ? { ...student, isPrmotion: isPromotion === "true" } : student
        );
        setSelectedStudents(updatedStudents);
    };


    // handlePromoteSelect
    const handlePromoteSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
        clearErrors(fieldName);

        if (fieldName === 'promoteClassName') {
            setPromoteSectionValue([]);
            setValue("promoteSection");
            const secOption = classList?.filter((item) => item._id === selectOption.value);
            const array = secOption[0]?.sectionNames;
            const convertedArray = array?.map(item => ({
                value: item,
                label: item
            }));

            setPromoteSectionOption(convertedArray);
        }
    };


    // handleClassChange
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);

        if (fieldName === 'className') {
            setSectionValue({ value: "", label: "Select" });
            setValue("section", { label: '', value: '' });
            setSelectedClassId(selectOption.value);
            const secOption = classList?.filter((item) => item._id === selectOption.value);
            const array = secOption[0]?.sectionNames;
            const convertedArray = array?.map(item => ({
                value: item,
                label: item
            }));
            setSectionOption(convertedArray);

            const obj = {
                className: '',
                section: ''
            };
            dispatch(student_filter(obj));
        } else {
            setSelectedSection(selectOption.value)
            const obj = {
                sessionValue,
                className: selectedClassId ? selectedClassId : '',
                section: selectOption ? selectOption.value : ''
            };
            dispatch(student_filter(obj));
        }
    };


    // onSubmit
    const onSubmit = (data) => {
        const { promoteSession, promoteClassName, promoteSection } = data;

        const evaluationData = selectedStudents.map(d => {
            return {
                studentId: d._id,
                promoteSession: promoteSession.value,
                promoteClassName: d.isPrmotion === false ? selectedClassId : promoteClassName.value,
                promoteSection: d.isPrmotion === false ? selectedSection : promoteSection.value,
                roll: d.roll ? d.roll : d.sessions.find(session => session.sessionIsActive)?.roll
            }
        })

        dispatch(student_promotion(evaluationData))

    };


    // handleStudentDetails
    // const handleStudentDetails = (id) => {
    //     navigate(`/student-details/${id}`)
    // }

    const handleStudentDetails = (id) => {
        window.open(`/student-details/${id}`, '_blank');
    };



    useEffect(() => {
        const options = classList?.map(item => ({
            value: item._id,
            label: item.className
        }));
        setClassListOption(options);
    }, [classList]);



    useEffect(() => {
        const options = academicYearApprovedList?.map(item => ({
            value: item._id,
            label: item.sectionName
        }));
        setPromoteSessionOption(options);

    }, [academicYearApprovedList]);



    useEffect(() => {
        const obj = {
            className: '',
            section: ''
        };
        dispatch(student_filter(obj));
    }, []);


    useEffect(() => {

        const obj = {
            className: '',
            section: ''
        };

        if (errorMessage) {
            toast.error(errorMessage)
            dispatch(messageClear())
        }
        if (successMessage) {
            toast.success(successMessage)
            dispatch(messageClear())
            setSelectedStudents([]);
            setSectionValue([]);
            setValue("section");
            dispatch(student_filter(obj));
        }
    }, [errorMessage, successMessage])


    // console.log("allStudents", allStudents);

    return (
        <Layout>
            {/* <div className="page-header">
                <h4 className="page-title">Students Promotion</h4>
            </div> */}

            <div className="card mb-3">
                <div className="card-body">
                    <div className="row">
                        <h4 className="font-weight-bold">Select Ground</h4>
                        <hr className='px-0 my-1 ' />
                        <div className="col-md-6 col-lg-6">
                            <div className="form-group px-0">
                                <label htmlFor="className">Select Class <span className='text-danger'>*</span></label>
                                <Select
                                    value={classValue}
                                    {...register('className')}
                                    onChange={(selectOption) => {
                                        setClassValue(selectOption);
                                        handleSelect('className', selectOption);
                                    }}
                                    options={classListOption}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="form-group px-0">
                                <label htmlFor="section">Select Section <span className='text-danger'>*</span></label>
                                <Select
                                    {...register('section')}
                                    value={sectionValue}
                                    onChange={(selectOption) => {
                                        setSectionValue(selectOption);
                                        handleSelect('section', selectOption);
                                    }}
                                    options={sectionOption}
                                    isDisabled={sectionOption?.length > 0 ? false : true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row mb-3">
                                    <h4 className="font-weight-bold">The Next Session Was Transferred To The Students</h4>
                                    <hr className='px-0 my-1 ' />

                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group px-0">
                                            <label htmlFor="promoteSession">Promote To Session <span className='text-danger'>*</span></label>
                                            <Select
                                                value={promoteSessionValue}
                                                {...register('promoteSession', { required: 'Session is required' })}
                                                onChange={(selectOption) => {
                                                    setPromoteSessionValue(selectOption);
                                                    handlePromoteSelect('promoteSession', selectOption);
                                                }}
                                                options={promoteSessionOption}
                                                className={errors.promoteSession ? 'is-invalid' : ''}
                                            />
                                            {errors.promoteSession && <div className="invalid-feedback">{errors.promoteSession.message}</div>}
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group px-0">
                                            <label htmlFor="promoteClassName">Promote To Class <span className='text-danger'>*</span></label>
                                            <Select
                                                value={promoteClassValue}
                                                {...register('promoteClassName', { required: 'Class is required' })}
                                                onChange={(selectOption) => {
                                                    setPromoteClassValue(selectOption);
                                                    handlePromoteSelect('promoteClassName', selectOption);
                                                }}
                                                options={classListOption}
                                                className={errors.promoteClassName ? 'is-invalid' : ''}
                                            />
                                            {errors.promoteClassName && <div className="invalid-feedback">{errors.promoteClassName.message}</div>}
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group px-0">
                                            <label htmlFor="promoteSection">Promote To Section <span className='text-danger'>*</span></label>
                                            <Select
                                                {...register('promoteSection', { required: 'Section is required' })}
                                                value={promoteSectionValue}
                                                onChange={(selectOption) => {
                                                    setPromoteSectionValue(selectOption);
                                                    handlePromoteSelect('promoteSection', selectOption);
                                                }}
                                                options={promoteSectionOption}
                                                isDisabled={promoteSectionOption?.length > 0 ? false : true}
                                                className={errors.promoteSection ? 'is-invalid' : ''}
                                            />
                                            {errors.promoteSection && <div className="invalid-feedback">{errors.promoteSection.message}</div>}
                                        </div>
                                    </div>

                                </div>

                                {allStudents?.length > 0 ? (
                                    <table className="tableStyle">
                                        <thead className=''>
                                            <tr>
                                                <th>
                                                    <input
                                                        checked={selectedStudents.length === allStudents.length}
                                                        onChange={handleSelectAll}
                                                        type="checkbox"
                                                        style={{ transform: "scale(1.2)" }}
                                                    />
                                                </th>
                                                <th>Photo</th>
                                                <th>Name</th>
                                                <th>Register No</th>
                                                <th>Mark Summary</th>
                                                <th>Class</th>
                                                <th>Roll</th>
                                                <th>Current Due Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody className=''>
                                            {allStudents?.map((d, i) => (
                                                <tr key={i}>
                                                    <td data-label="Select">
                                                        <input
                                                            value={d._id}
                                                            checked={selectedStudents.some(s => s._id === d._id)}
                                                            onChange={(e) => handleStudentSelect(e, d)}
                                                            type="checkbox"
                                                            style={{ transform: "scale(1.2)" }}
                                                        />
                                                    </td>
                                                    <td data-label="Photo">
                                                        <img style={{ width: "35px", height: "35px", borderRadius: "50%" }} src={d?.studentImage ? `${server_url}/${d?.studentImage}` : `${image_url}/assets/img/student/male.png`} alt="Img" />
                                                    </td>
                                                    <td data-label="Name">{d.basicDetails.fullName}</td>
                                                    <td data-label="Register No">{d.basicDetails.enrollmentNumber}</td>
                                                    <td data-label="Mark Summary">
                                                        <button onClick={() => handleStudentDetails(d._id)} type='button' className='btn btn-danger font-weight-bold px-2 py-1'>View</button>
                                                    </td>
                                                    <td data-label="Class">
                                                        <select
                                                            {...register(`students[${i}].isPromotion`)}
                                                            className='form-select p-1 px-2 text'
                                                            defaultValue={d.isPromotion}
                                                            onChange={(e) => handleStatusChange(d._id, e.target.value)}
                                                            disabled={
                                                                selectedStudents.length === 0 || !selectedStudents.some(s => s._id === d._id)
                                                            }
                                                        >
                                                            <option value="true">Promoted</option>
                                                            <option value="false">Running</option>
                                                        </select>

                                                    </td>
                                                    <td data-label="Roll">
                                                        <input
                                                            style={{ width: "100%" }}
                                                            type="text"
                                                            name={`students[${i}].roll`}
                                                            value={
                                                                selectedStudents.find(s => s._id === d._id)?.roll ||
                                                                d.sessions.find(session => session.sessionIsActive)?.roll ||
                                                                ''
                                                            }
                                                            onChange={(e) => handleRollChange(e, d._id)}
                                                            className='form-control p-2'
                                                            disabled={
                                                                selectedStudents.length === 0 || !selectedStudents.some(s => s._id === d._id)
                                                            }
                                                        />
                                                    </td>
                                                    <td data-label="Due Amount">
                                                        <input style={{ width: "100%" }} type="text" name='roll' value='500' className='form-control p-2' />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <Empty data={`Student Not Found !`} />
                                )}

                                {
                                    selectedStudents?.length > 0 && allStudents?.length > 0 &&
                                    <div style={{ textAlign: "right" }} className='mt-3'>
                                        <button type="submit" className='btn btn-primary px-3 py-2 fw-bold'
                                            style={{ width: "110px" }}
                                            disabled={loader ? true : false}>
                                            {
                                                loader ? <ButtonLoader /> : 'Promotion'
                                            }
                                        </button>
                                    </div>
                                }

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};



export default StudentPromotion;
