import React from 'react';
import StudentLayout from '../../Layout/StudentLayout/StudentLayout';

const StudentExamResult = () => {
  return (
    <StudentLayout>
        <h1>StudentExamResult</h1>
    </StudentLayout>
  )
}

export default StudentExamResult;

