import React from 'react';
import { PropagateLoader, HashLoader, MoonLoader } from 'react-spinners';
import { buttonLoaderCss, hashLoaderCss } from './loaderStyle';


export const TableLoader = () => {
    return <HashLoader color="#5c55bf" cssOverride={hashLoaderCss} size={80} />;
};

export const CardLoader = () => {
    return <MoonLoader color="#444" size={70} />;
};

export const ButtonLoader = () => {
    return <PropagateLoader color="#fff" cssOverride={buttonLoaderCss} />;
};

