import React, { useEffect, useState } from 'react';
import { ButtonLoader } from '../../../utils/Loader/LoaderComponent';
import Drawer from '@mui/material/Drawer';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { add_subject, messageClear, update_subject } from '../../../store/Reducers/administrator/administratorReducer';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';



const generateObjectId = () => {
    const timestamp = Math.floor(new Date().getTime() / 1000).toString(16); // 4-byte timestamp
    const random = 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => (Math.random() * 16 | 0).toString(16)); // 8-byte random value
    return timestamp + random;
};


const AddSubject = ({ open, setOpen, isEditing, setIsEditing, editId }) => {

    const dispatch = useDispatch();

    const { loader, successMessage, errorMessage, classList, singleSubject } = useSelector(state => state.administrator);


    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors } = useForm();

    const [classListOption, setClassListOption] = useState("")
    const [classValue, setClassValue] = useState(null);

    const [fields, setFields] = useState([{ _id: generateObjectId(), subjectCode: '', subjectName: '' }]);



    const handleAddField = (event) => {
        event.preventDefault();
        setFields([...fields, { _id: generateObjectId(), subjectCode: '', subjectName: '' }]);
    };

    const handleRemoveField = (index, event) => {
        event.preventDefault();
        const newFields = [...fields];
        newFields.splice(index, 1);
        setFields(newFields);
    };

    const handleChange = (index, event) => {
        const newFields = [...fields];
        const updatedField = { ...newFields[index], [event.target.name]: event.target.value };
        newFields[index] = updatedField;
        setFields(newFields);
    };


    // handleSelect
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
        clearErrors(fieldName);
    }


    // onSubmit
    const onSubmit = (data) => {
        const { className } = data;

        const obj = {
            classId: className?.value,
            subjects: fields
        }

        if (!isEditing) {
            dispatch(add_subject(obj))
        } else {
            dispatch(update_subject({ editId, obj }))
        }

    }


    useEffect(() => {
        const options = classList?.map(item => ({
            value: item._id,
            label: item.className
        }));
        setClassListOption(options)
    }, [classList])


    useEffect(() => {
        // const obj = { searchValue: "" };

        if (errorMessage) {
            toast.error(errorMessage);
            dispatch(messageClear());
        }
        if (successMessage) {
            toast.success(successMessage);
            dispatch(messageClear());
            setOpen(false);
            setClassValue(null)
            setFields([{ subjectCode: '', subjectName: '' }])
        }
    }, [errorMessage, successMessage, dispatch]);



    useEffect(() => {
        if (Object.keys(singleSubject).length > 0) {
            setClassValue({
                label: singleSubject.classId?.className, value: singleSubject.classId?._id
            })
            setValue("className", {
                label: singleSubject.classId?.className, value: singleSubject.classId?._id
            })
            setFields(singleSubject?.subjects)
        }

    }, [singleSubject])


    useEffect(() => {
        if (!isEditing) {
            setClassValue(null)
            setFields([{ subjectCode: '', subjectName: '' }])
        }
    }, [isEditing])

    return (
        <>

            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <div style={{ width: '450px' }}>
                    <DialogTitle style={{ fontSize: '18px' }}>{isEditing ? 'Edit Subject' : 'Add Subject'}</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <div className='px-2'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <label htmlFor="className">Class <span className='text-danger'>*</span></label>
                                <Select
                                    {...register('className', { required: 'Class is required' })}
                                    value={classValue}
                                    onChange={(selectOption) => {
                                        setClassValue(selectOption);
                                        handleSelect('className', selectOption);
                                    }}
                                    options={classListOption}
                                    className={errors.className ? 'is-invalid' : ''}
                                />
                                {errors.className && <div className="invalid-feedback">{errors.className.message}</div>}
                            </div>


                            {fields?.map((field, index) => (
                                <div key={index} className='d-flex'>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className='form-control'
                                            name='subjectCode'
                                            value={field.subjectCode}
                                            placeholder='Enter Subject Code'
                                            onChange={(e) => handleChange(index, e)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className='form-control'
                                            name='subjectName'
                                            value={field.subjectName}
                                            placeholder='Enter Subject Name'
                                            onChange={(e) => handleChange(index, e)}
                                        />
                                    </div>

                                    {
                                        index > 0 ? <div style={{ marginTop: "10px" }} className='mr-2'>
                                            <button
                                                className="btn btn-danger px-3 py-2 font-weight-bold ml-auto"
                                                onClick={(e) => handleRemoveField(index, e)}
                                            >
                                                <span><i className="fa fa-minus" /></span>
                                            </button>
                                        </div> : <div style={{ marginTop: "10px" }} className='mr-2'>
                                            <button className="btn btn-warning px-3 py-2 font-weight-bold ml-auto" onClick={(e) => handleAddField(e)}>
                                                <span><i className="fa fa-plus" /></span>
                                            </button>
                                        </div>
                                    }

                                </div>
                            ))}


                            <div className="my-3 mx-2" style={{ textAlign: "right" }}>
                                <button disabled={loader} type="submit" style={{ width: "100px" }} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                    {loader ? <ButtonLoader /> : (isEditing ? 'Edit' : 'Add')}
                                </button>
                            </div>


                        </form>
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default AddSubject;