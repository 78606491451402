import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../Layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import moment from 'moment';
import { MdDeleteForever } from "react-icons/md";
import { FaEdit, FaRegFilePdf, FaRegCopy } from "react-icons/fa";
import { RiFileExcelLine } from "react-icons/ri";
import { FaBookOpen } from "react-icons/fa6";
import { confirmMessagge } from '../../../utils/other/aleartFunc';
import AddSubject from './AddSubject';
import Empty from '../../../utils/page/Empty';
import { delete_subject, get_all_subject, get_single_subject, messageClear } from '../../../store/Reducers/administrator/administratorReducer';
import { useReactToPrint } from "react-to-print";
import { exportToExcel, exportToPDF } from '../../../utils/other/fileGenerate';
import { Modal, Box, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { FiPrinter } from "react-icons/fi";
import { TableLoader } from '../../../utils/Loader/LoaderComponent';


const Subject = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState();

    const { searchValue } = useSelector(state => state.default);
    const { successMessage, subjectLists, loader } = useSelector(state => state.administrator);

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);

    const handleOpenModal = (subjectItem) => {
        setModalData(subjectItem);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalData(null);
    };

    // Handle open drawer
    const handleOpenDrawer = () => {
        setOpen(true);
        setIsEditing(false);
    };

    // Handle subject edit
    const handleSubjectEdit = (editId) => {
        setIsEditing(true);
        setOpen(true);
        setEditId(editId);
        dispatch(get_single_subject(editId));
    };

    // Handle delete subject
    const handleSubjectDelete = async (deleteId) => {
        const returnValue = await confirmMessagge('Delete');
        if (returnValue) {
            dispatch(delete_subject(deleteId));
        }
    };

    // Create Excel file
    const createExcelFile = () => {
        const data = subjectLists?.map((d, i) => ({
            Sl: i + 1,
            'Class Name': d?.classDetails?.className,
            'Subject': d.subjects?.map(s => s.subjectName).join(', '),
            'CreatedAt': moment(d.createdAt).format('DD/MM/YYYY')
        }));
        exportToExcel(data, 'subject_data.xlsx');
    };

    // Create PDF file
    const createPdfFile = () => {
        const data = subjectLists?.map((d, i) => ({
            Sl: i + 1,
            'Class Name': d?.classDetails?.className,
            'Subject': d.subjects?.map(s => s.subjectName).join(', ')
        }));
        exportToPDF(data, 'subject_data.pdf');
    };

    const componentRef = useRef();

    // Create print
    const createPrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        const obj = { searchValue };
        dispatch(get_all_subject(obj));
    }, [searchValue, open]);

    useEffect(() => {
        const obj = { searchValue: "" };
        if (successMessage) {
            dispatch(messageClear());
            dispatch(get_all_subject(obj));
        }
    }, [successMessage, dispatch]);


    return (
        <Layout>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="page-header">
                                <span style={{ fontSize: "18px", padding: "0px 8px 7px 0px" }}>
                                    <i className="fas fa-list" />
                                </span>
                                <h4 style={{ fontSize: "19px" }}>Subject List</h4>
                                <span className='px-4'>
                                    <FaRegCopy title='Copy' style={{ fontSize: '20px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <RiFileExcelLine onClick={createExcelFile} title='Excel' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FaRegFilePdf onClick={createPdfFile} title='PDF' style={{ fontSize: '18px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FiPrinter onClick={createPrint} title='Print' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                </span>
                                <button onClick={handleOpenDrawer} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                    <span><i className="fa fa-plus pr-2" />Add</span>
                                </button>
                            </div>
                            <hr className='my-3' />

                            {loader ? (
                                // Display loader while data is being fetched
                                <TableLoader />
                            ) : subjectLists?.length > 0 ? (
                                // Render table if data exists
                                <table className="tableStyle" ref={componentRef}>
                                    <thead>
                                        <tr>
                                            <th>Sl.</th>
                                            <th>Class Name</th>
                                            <th>Subject</th>
                                            <th className='no-print'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {subjectLists?.map((d, i) => (
                                            <tr key={i}>
                                                <td data-label="Sl">{i + 1}</td>
                                                <td data-label="Class Name">{d?.classDetails?.className}</td>
                                                <td data-label="Subject">
                                                    {d.subjects?.length > 3 ? (
                                                        <>
                                                            {d.subjects?.slice(0, 3).map((s, j) => (
                                                                <span
                                                                    style={{ fontSize: "14px" }}
                                                                    key={j}
                                                                    className='bg-danger text-light px-2 py-1 mr-1 rounded'
                                                                >
                                                                    {s.subjectName}
                                                                </span>
                                                            ))}
                                                            <span
                                                                style={{ fontWeight: "600", background: "#444", cursor: "pointer" }} className=' text-light px-2 py-1 mr-1 rounded'
                                                                onClick={() => handleOpenModal(d)}
                                                            >
                                                                Show All
                                                            </span>
                                                        </>
                                                    ) : (
                                                        d.subjects?.map((s, j) => (
                                                            <span
                                                                style={{ fontSize: "14px" }}
                                                                key={j}
                                                                className='bg-danger text-light px-2 py-1 mr-1 rounded'
                                                            >
                                                                {s.subjectName}
                                                            </span>
                                                        ))
                                                    )}
                                                </td>
                                                <td data-label="Action" className='no-print'>
                                                    <span title='Edit' className='mr-1' onClick={() => handleSubjectEdit(d._id)}>
                                                        <FaEdit style={{ fontSize: '20px', color: 'green' }} />
                                                    </span>
                                                    {/* <span title='Delete' className='ml-1' onClick={() => handleSubjectDelete(d._id)}>
                                                        <MdDeleteForever style={{ fontSize: '24px', color: 'red' }} />
                                                    </span> */}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <Modal
                                        open={showModal}
                                        onClose={handleCloseModal}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', borderRadius: '5px', boxShadow: 24, p: 2 }}>

                                            <Typography id="modal-title" variant="h6" component="h2">
                                                All Subjects
                                            </Typography>
                                            <IconButton
                                                aria-label="close"
                                                onClick={handleCloseModal}
                                                sx={{
                                                    position: 'absolute',
                                                    right: 8,
                                                    top: 8,
                                                    color: (theme) => theme.palette.grey[500],
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                <>
                                                    {modalData?.subjects?.map((subject, idx) => (
                                                        <span style={{ fontSize: "17px", display: 'block', marginBottom: '8px' }}
                                                            key={idx}
                                                            className='bg-danger text-light px-2 py-1 mr-1 rounded'>
                                                            {subject.subjectName}
                                                        </span>
                                                    ))}
                                                </>
                                            </Typography>

                                        </Box>
                                    </Modal>
                                </table>
                            ) : (
                                // Display empty state when classList is empty
                                <Empty data={`Subject Not Found !`} />
                            )}


                        </div>
                    </div>
                </div>
            </div>
            <AddSubject open={open} setOpen={setOpen} isEditing={isEditing} setIsEditing={setIsEditing} editId={editId} />
        </Layout>
    );
};

export default Subject;
