import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import toast from 'react-hot-toast';
import { get_exam_attendance, messageClear } from '../../../../store/Reducers/attendance/studentAttendance';
import { server_url } from '../../../../api/api';
import { ButtonLoader, TableLoader } from '../../../../utils/Loader/LoaderComponent';
import Empty from '../../../../utils/page/Empty';
import { student_filter } from '../../../../store/Reducers/student/studentReducer';
import { getSubjectDetails } from '../../../../utils/other/returnFunction';


const ShowExamAttendance = () => {

  const dispatch = useDispatch();
  const { handleSubmit, getValues, setValue, register, clearErrors, formState: { errors }, reset } = useForm();
  const { classList, subjectLists } = useSelector(state => state.administrator);
  const { allStudents } = useSelector(state => state.student);
  const { sessionValue } = useSelector(state => state.default);
  const { examSetupList, examScheduleList } = useSelector(state => state.exam);

  const { loader, successMessage, errorMessage, examAttendance } = useSelector(state => state.studentAttendance);


  const [examSetupOption, setExamSetupOption] = useState("")
  const [classListOption, setClassListOption] = useState("");
  const [sectionOption, setSectionOption] = useState("");
  const [selecteeClassId, setSelecteeClassId] = useState('');
  const [studentOption, setStudentOption] = useState("")


  const [examSetupValue, setExamSetupValue] = useState(null);
  const [classValue, setClassValue] = useState(null);
  const [sectionValue, setSectionValue] = useState(null);
  const [studentValue, setStudentValue] = useState(null);


  // handleSelect function
  const handleSelect = (fieldName, selectOption) => {
    setValue(fieldName, selectOption);
    clearErrors(fieldName);

    if (fieldName === 'className') {
      setSelecteeClassId(selectOption.value);
      setSectionValue({ value: "", label: "Select Section" });
      setValue("section", null);

      const secOption = classList?.filter((item) => item._id === selectOption.value);
      const array = secOption[0]?.sectionNames;
      const convertedArray = array?.map(item => ({
        value: item,
        label: item
      }));
      setSectionOption(convertedArray);
    }

    if (fieldName === 'section') {
      setStudentValue({ value: "", label: "Select" })
      setValue("student", null)
      const obj = {
        sessionValue,
        className: selecteeClassId,
        section: selectOption.value
      }
      dispatch(student_filter(obj));
    }

  }


  // filterAttendance
  const filterAttendance = (data) => {
    const obj = {
      sessionId: sessionValue,
      examId: data?.examName?.value,
      classId: selecteeClassId,
      section: data?.section?.value,
      studentId: data.student ? data.student?.value : '',

    }

    dispatch(get_exam_attendance(obj))

  }


  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
  }, [errorMessage, successMessage]);


  useEffect(() => {
    const options = classList?.map(item => ({
      value: item._id,
      label: item.className
    }));


    const options2 = examSetupList?.filter(d => examScheduleList?.some(c => c.examName._id === d._id))?.map(item => ({
      value: item._id,
      label: `${item.examName} (${item.examTypeName || 'N/A'})`,
    }));

    setClassListOption(options)
    setExamSetupOption(options2)
  }, [classList, examSetupList, examScheduleList])

  useEffect(() => {
    const options = allStudents?.map(item => ({
      value: item._id,
      label: item.basicDetails.fullName
    }));
    setStudentOption(options)
  }, [allStudents])


  // console.log("examAttendance", examAttendance)


  return (
    <>
      <div className="card mb-3">
        <div className="card-body py-">

          <form onSubmit={handleSubmit(filterAttendance)}>

            <div className="row">

              <div className="col-md-6 col-lg-3">
                <div className="form-group pt-0">
                  <label htmlFor="examName">Exam <span className='text-danger'>*</span></label>
                  <Select
                    placeholder="Select Class"
                    value={examSetupValue}
                    {...register('examName', { required: 'Exam is required' })}
                    onChange={(selectOption) => {
                      setExamSetupValue(selectOption);
                      handleSelect('examName', selectOption);
                    }}
                    options={examSetupOption}
                    className={errors.examName ? 'is-invalid' : ''}
                  />
                  {errors.examName && <div className="invalid-feedback">{errors.examName.message}</div>}
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="form-group pt-0">
                  <label htmlFor="className">Class <span className='text-danger'>*</span></label>
                  <Select
                    {...register('className', { required: 'Class is required' })}
                    value={classValue}
                    onChange={(selectOption) => {
                      setClassValue(selectOption);
                      handleSelect('className', selectOption);
                    }}
                    options={classListOption}
                    className={errors.className ? 'is-invalid' : ''}
                  />
                  {errors.className && <div className="invalid-feedback">{errors.className.message}</div>}
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="form-group pt-0">
                  <label htmlFor="section">Section <span className='text-danger'>*</span></label>
                  <Select
                    {...register('section', { required: 'Section is required' })}
                    value={sectionValue}
                    onChange={(selectOption) => {
                      setSectionValue(selectOption);
                      handleSelect('section', selectOption);
                    }}
                    options={sectionOption}
                    className={errors.section ? 'is-invalid' : ''}
                    isDisabled={sectionOption?.length > 0 ? false : true}
                  />
                  {errors.section && <div className="invalid-feedback">{errors.section.message}</div>}
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="form-group pt-0">
                  <label htmlFor="student">Student</label>
                  <Select
                    {...register('student')}
                    value={studentValue}
                    onChange={(selectOption) => {
                      setStudentValue(selectOption);
                      handleSelect('student', selectOption);
                    }}
                    options={studentOption}
                    isDisabled={studentOption?.length > 0 && sectionValue?.value ? false : true}
                  />
                </div>
              </div>

            </div>

            <div className="mr-2 text-right">

              <button style={{ width: "80px" }} disabled={loader ? true : false} className="btn btn-secondary px-4 py-2 font-weight-bold">
                {
                  loader ? <ButtonLoader /> : 'Filter'
                }
              </button>
            </div>

          </form>

        </div>
      </div>

      <div className="card">
        <div className="card-body" style={{ padding: '20px', overflowX: 'auto' }}>
          {loader ? (
            <TableLoader /> // Loader component when data is loading
          ) : examAttendance?.length > 0 ? (
            <div style={{ overflowX: 'auto' }}>
              <h4 style={{ fontSize: '19px', fontWeight: '600' }} className='py-2'>
                Exam Attendance Sheet
              </h4>

              {/* Extract unique subject IDs from all students' attendance */}
              {(() => {
                const uniqueSubjectIds = [...new Set(
                  examAttendance.flatMap(student => student.attendance.map(a => a.subjectId))
                )];

                return (
                  <table
                    style={{
                      width: '100%',
                      borderCollapse: 'collapse',
                      textAlign: 'center',
                      fontSize: '14px',
                      border: '1px solid #ddd',
                      minWidth: '700px',
                    }}
                  >
                    <thead>
                      <tr style={{ backgroundColor: '#f8f9fa' }}>
                        <th style={{ padding: '10px', border: '1px solid #ddd' }}>
                          <span style={{ fontSize: '16px', fontWeight: '600' }}>Students</span>
                        </th>

                        {/* Render subject headers dynamically based on unique subject IDs */}
                        {uniqueSubjectIds.map((subjectId, i) => {
                          const subjectDetails = getSubjectDetails(subjectLists, subjectId);
                          const subjectName = subjectDetails ? subjectDetails.subjectName : '';

                          return (
                            <th key={i} style={{ padding: '10px', border: '1px solid #ddd' }}>
                              {subjectName}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>

                    <tbody>
                      {/* Loop through all students in examAttendance */}
                      {examAttendance.map((student, i) => (
                        <tr key={i} style={{ backgroundColor: i % 2 === 0 ? '#f2f2f2' : '#ffffff' }}>
                          <td style={{ padding: '10px', border: '1px solid #ddd', fontWeight: 'bold' }}>
                            {student?.studentName}
                          </td>

                          {/* For each student, map through unique subjects to show attendance */}
                          {uniqueSubjectIds.map((subjectId, j) => {
                            const attendanceRecord = student.attendance.find(att => att.subjectId === subjectId);

                            return (
                              <td key={j} style={{ padding: '10px', border: '1px solid #ddd' }}>
                                {attendanceRecord ? (
                                  attendanceRecord.attendance === 'Present' ? (
                                    <i className='fas fa-check text-success fa-lg' />
                                  ) : attendanceRecord.attendance === 'Absent' ? (
                                    <i className='fas fa-times text-danger fa-lg' />
                                  ) : (
                                    '-'
                                  )
                                ) : (
                                  'N/A'
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                );
              })()}
            </div>
          ) : (
            <Empty data="Attendance List Not Found!" /> // Render if no data found
          )}
        </div>
      </div>

    </>
  );
};


export default ShowExamAttendance;
