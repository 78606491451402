import React, { useEffect, useState } from 'react';
import Layout from '../../Layout/Layout';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get_organisation, messageClear } from '../../../store/Reducers/organisation/orgWonerReducer';
import toast from 'react-hot-toast';
import { ButtonLoader, TableLoader } from '../../../utils/Loader/LoaderComponent';
import Empty from '../../../utils/page/Empty';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { messageClear2, organization_login } from '../../../store/Reducers/organisation/organizationReducer';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AllOrganization = () => {


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { errorMessage, loader, allOrganisation } = useSelector(state => state.org_woner);
  const org = useSelector(state => state.organization);


  const [password, setPassword] = useState('');
  const [email, setEamil] = useState('');
  const [open, setOpen] = useState(false);

  const handleClickOpen = (data) => {
    setOpen(true);
    setEamil(data);
    setPassword("")
  };

  const handleClose = () => {
    setOpen(false);
  };


  // handleLogin
  const handleLogin = (e) => {
    e.preventDefault();
    const obj = {
      email,
      password: password ? password : '*****'
    }
    dispatch(messageClear())
    dispatch(organization_login(obj))
  }

  useEffect(() => {
    if (org.errorMessage) {
      toast.error(org.errorMessage)
      dispatch(messageClear2())
    }
    if (org.successMessage) {
      toast.success(org.successMessage)
      dispatch(messageClear2())
      navigate('/organization-dashboard', { replace: true })
    }
  }, [org.errorMessage, org.successMessage])



  // Fetch all organizations
  useEffect(() => {
    dispatch(get_organisation());
  }, [dispatch]);

  // Display error message if any
  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [errorMessage, dispatch]);

  return (
    <Layout>
      <div className="page-header">
        <h3 className="font-weight-bold m-0">My Organization</h3>
        <Link to='/add-organisation' className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
          <span><i className="fa fa-plus pr-2" />Add</span>
        </Link>
      </div>

      <div className="row">
        {loader ? (
          <TableLoader />
        ) : allOrganisation?.length > 0 ? (
          allOrganisation?.map((org, i) => (
            <div key={i} className="col-lg-4" onClick={() => handleClickOpen(org.email)}>
              <div className="card card-stats card-danger card-round" style={{ height: "140px" }}>
                <div className="card-body ">
                  <h4 className="card-title">{org.organizationName}</h4>
                  <p className="card-category" style={{ fontSize: "18px" }}>{org.orgStatus}</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <Empty />
        )}
      </div>


      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            width: '480px',
            marginTop: '-300px',
          }
        }}
      >
        <DialogTitle style={{ fontSize: '16px' }}>Organization Login</DialogTitle>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className='px-4 pb-4'>
          <div className="form-group">
            <label htmlFor="password">Password <span className='text-danger'>*</span></label>
            <input type="password" className={`form-control`} id="password" name='password' value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>

          <div className="mr-2 mt-2" style={{ textAlign: "right" }}>
            <button disabled={org.loader ? true : false} onClick={handleLogin} type="submit" style={{ width: "100px" }} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
              {
                org.loader ? <ButtonLoader /> : 'Login'
              }
            </button>

          </div>
        </div>
      </Dialog>

    </Layout>
  )
}

export default AllOrganization;
