import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import Empty from '../../../../utils/page/Empty';
import { authenticate_student, student_filter, messageClear, get_all_student } from '../../../../store/Reducers/student/studentReducer';
import Pagination from '../../../Layout/Pagination';
import { generatePassword, getActiveClassName, getActiveSession } from '../../../../utils/other/returnFunction';
import { MdOutlineViewQuilt } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { ButtonLoader, TableLoader } from '../../../../utils/Loader/LoaderComponent';
import { RiPhoneFindLine } from "react-icons/ri";
import toast from 'react-hot-toast';
import { confirmMessagge } from '../../../../utils/other/aleartFunc';
import { image_url, server_url } from '../../../../api/api';


const AddAuthenticate = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();

    const { classList } = useSelector(state => state.administrator);
    const { searchValue, sessionValue } = useSelector(state => state.default);
    const { studentCount, allStudents, loader, successMessage, errorMessage } = useSelector(state => state.student);

    const [currentPage, setCurrentPage] = useState(1)
    const [parPage, setParPage] = useState(20)


    const [classListOption, setClassListOption] = useState("")
    const [sectionOption, setSectionOption] = useState("")
    const [selecteeClassId, setSelecteeClassId] = useState('');
    const [selecteeSection, setSelecteeSection] = useState('');

    const [classValue, setClassValue] = useState(null);
    const [sectionValue, setSectionValue] = useState(null);

    const [selectedStudents, setSelectedStudents] = useState([]);


    // handleStudentSelect
    const handleStudentSelect = (e, student) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedStudents(prevStudents => [...prevStudents, student]);
        } else {
            setSelectedStudents(prevStudents => prevStudents.filter(s => s._id !== student._id));
        }
    };


    // handleSelectAll
    const handleSelectAll = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedStudents([...allStudents]);
        } else {
            setSelectedStudents([]);
        }
    };

    //  handleClassChange
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);

        if (fieldName === 'className') {
            setSelectedStudents([]);
            setSelecteeClassId(selectOption.value);
            setSectionValue({ value: "", label: "Select Section" })
            setValue("section", { label: '', value: '' })
            const secOption = classList?.filter((item) => item._id === selectOption.value);
            const array = secOption[0]?.sectionNames;
            const convertedArray = array?.map(item => ({
                value: item,
                label: item
            }));
            setSectionOption(convertedArray)

            const obj = {
                sessionValue,
                className: selectOption.value,
                section: ''
            }
            dispatch(student_filter(obj))

        }

        if (fieldName === 'section') {
            setSelectedStudents([]);
            setSelecteeSection(selectOption.value);
            const obj = {
                sessionValue,
                className: selecteeClassId,
                section: selectOption.value
            }
            dispatch(student_filter(obj))

        }

    }

    // handleStudentDetails
    const handleStudentDetails = (id) => {
        navigate(`/student-details/${id}`)
    }


    //  handleGenerate
    const handleGenerate = async (e) => {
        e.preventDefault();

        const returnValue = await confirmMessagge('Generate');

        if (returnValue) {
            const evaluationData = selectedStudents
                ?.filter(student => student.isAuthenticate === false)
                ?.map(student => ({
                    studentId: student._id,
                    authEmail: student.basicDetails.email,
                    authPhoneNumber: student.basicDetails.phoneNumber,
                    password: generatePassword()
                }));

            dispatch(authenticate_student(evaluationData))

        }

        // // console.log("selectedStudents", selectedStudents)
        // console.log("evaluationData", evaluationData)

    }


    // handleItemPerPageChange
    const handleItemPerPageChange = (e) => {
        setParPage(e.target.value)
    }


    useEffect(() => {
        const obj = {
            parPage: parseInt(parPage),
            page: parseInt(currentPage),
            searchValue,
            sessionValue
        }
        dispatch(get_all_student(obj))
    }, [searchValue, currentPage, parPage, sessionValue])


    useEffect(() => {
        const obj = {
            parPage: parseInt(parPage),
            page: parseInt(currentPage),
            searchValue,
            sessionValue
        }

        if (errorMessage) {
            toast.error(errorMessage)
            dispatch(messageClear())
            dispatch(get_all_student(obj))
            // setClassValue([]);
            // setSectionValue([]);
            // setSelectedStudents([]);
            // setSelecteeClassId('');
            // setSelecteeSection('');
        }
        if (successMessage) {
            toast.success(successMessage)
            dispatch(messageClear())
            dispatch(get_all_student(obj))

        }

    }, [errorMessage, successMessage])


    useEffect(() => {
        const options = classList?.map(item => ({
            value: item._id,
            label: item.className
        }));
        setClassListOption(options)
    }, [classList])


    return (

        <>
            <div className="card mb-3">
                <div className="card-body py-">
                    <div className="row">

                        <div className="col-md-6 col-lg-6">
                            <div className="form-group pt-0">
                                <label htmlFor="className">Class <span className='text-danger'>*</span></label>
                                <Select
                                    placeholder="Select Class"
                                    value={classValue}
                                    {...register('className')}
                                    onChange={(selectOption) => {
                                        setClassValue(selectOption);
                                        handleSelect('className', selectOption);
                                    }}
                                    options={classListOption}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6">
                            <div className="form-group pt-0">
                                <label htmlFor="section">Section <span className='text-danger'>*</span></label>
                                <Select
                                    {...register('section')}
                                    placeholder="Select Section"
                                    value={sectionValue}
                                    onChange={(selectOption) => {
                                        setSectionValue(selectOption);
                                        handleSelect('section', selectOption);
                                    }}
                                    options={sectionOption}
                                    isDisabled={sectionOption?.length > 0 ? false : true}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">

                            <div className='d-flex justify-content-between m-0 p-0'>
                                <div className="page-header mb-0">
                                    <RiPhoneFindLine style={{ fontSize: '23px', marginBottom: "6px", marginRight: "8px" }} />
                                    <h4 style={{ fontSize: "19px" }}>Generate Student Authenticate</h4>
                                </div>

                                <>
                                    {
                                        selectedStudents?.length > 0 && allStudents?.length > 0 &&
                                        <div style={{ textAlign: "right" }} className=''>
                                            <button onClick={handleGenerate} type="submit" className='btn btn-primary px-3 py-2 fw-bold'
                                                style={{ width: "110px" }}
                                                disabled={loader ? true : false}>
                                                {
                                                    loader ? <ButtonLoader /> : 'Generate'
                                                }
                                            </button>
                                        </div>
                                    }
                                </>
                            </div>

                            <hr className='my-3' />

                            {loader ? (
                                // Display loader while data is being fetched
                                <TableLoader />
                            ) : allStudents?.length > 0 ? (
                                // Render table if data exists
                                <div>
                                    <table className="tableStyle">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input
                                                        checked={selectedStudents?.length === allStudents?.length}
                                                        onChange={handleSelectAll}
                                                        type="checkbox"
                                                        style={{ transform: "scale(1.2)" }}
                                                    />
                                                </th>
                                                <th>Photo</th>
                                                <th>Name</th>
                                                <th>Class</th>
                                                <th>Section</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>Is Authenticate</th>
                                                <th className='no-print'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                allStudents?.map((d, i) => (
                                                    <tr key={i}>
                                                        <td data-label="Select">
                                                            <input
                                                                value={d._id}
                                                                checked={selectedStudents.some(s => s._id === d._id)}
                                                                onChange={(e) => handleStudentSelect(e, d)}
                                                                type="checkbox"
                                                                style={{ transform: "scale(1.2)" }}
                                                            />
                                                        </td>
                                                        <td data-label="Photo">
                                                            <img style={{ width: "35px", height: "35px", borderRadius: "50%" }} src={d?.studentImage ? `${server_url}/${d?.studentImage}` : `${image_url}/assets/img/student/male.png`} alt="Img" />
                                                        </td>
                                                        <td data-label="Name">{d.basicDetails.fullName}</td>
                                                        <td data-label="Class">{getActiveClassName(classList, d.sessions)}</td>
                                                        <td data-label="Section">{getActiveSession(d.sessions)}</td>
                                                        <td data-label="Email">{d.basicDetails.email}</td>
                                                        <td data-label="Phone">{d.basicDetails.phoneNumber}</td>
                                                        <td data-label="isAuthenticate">
                                                            {
                                                                d?.isAuthenticate === true ? <i className='fas fa-check text-success fa-lg' /> : <i className='fas fa-times text-danger fa-lg' />
                                                            }
                                                        </td>
                                                        <td data-label="Action" className='no-print'>
                                                            <span title='Details' className='mr-1' onClick={() => handleStudentDetails(d._id)}>
                                                                <MdOutlineViewQuilt style={{ fontSize: '22px', color: 'blue', marginTop: "3px" }} />
                                                            </span>
                                                        </td>

                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                // Display empty state when classList is empty
                                <Empty data={`Student Not Found !`} />
                            )}

                        </div>


                        {
                            studentCount > 20 && <div class="d-flex justify-content-between">
                                <div className="col-md-4 d-flex justify-content-start align-items-center itemParPageStyle">
                                    <div class="d-flex mb-2">
                                        <label for="itemPerPage" class="col-sm-auto col-form-label">Select items per page :</label>
                                        <div class="col-sm-auto">
                                            <select
                                                id='itemPerPage'
                                                style={{ width: "65px", marginLeft: "-23px", marginTop: "7px", padding: "3px" }}
                                                onChange={handleItemPerPageChange}
                                                value={parPage}
                                            >
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="5000">All</option>

                                            </select>
                                        </div>
                                    </div>

                                </div>

                                <div className='px-4 pt-2'>
                                    {
                                        studentCount >= parPage ?
                                            <Pagination
                                                pageNumber={currentPage}
                                                setPageNumber={setCurrentPage}
                                                totalItem={studentCount}
                                                parPage={parPage}
                                                showItem={Math.floor(studentCount / parPage)}
                                            /> : ''
                                    }
                                </div>

                            </div>
                        }


                    </div>

                </div>

            </div>



        </>

    )
}

export default AddAuthenticate;