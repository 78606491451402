import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import { student_filter } from '../../../store/Reducers/student/studentReducer';
import Layout from '../../Layout/Layout';


const FeesAllocation = () => {

    const dispatch = useDispatch();

    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();
    const { classList } = useSelector(state => state.administrator);
    const { loader, successMessage, errorMessage, feesGroupList } = useSelector(state => state.fees);

    const { allStudents } = useSelector(state => state.student);
    const { sessionValue } = useSelector(state => state.default);


    const [classListOption, setClassListOption] = useState("")
    const [sectionOption, setSectionOption] = useState("")
    const [selecteeClassId, setSelecteeClassId] = useState('');


    const [feesGroupOption, setFeesGroupOption] = useState([]);

    const [feesGroupValue, setFeesGroupValue] = useState(null);


    const [classValue, setClassValue] = useState(null);
    const [sectionValue, setSectionValue] = useState(null);

    //  handleClassChange
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);

        if (fieldName === 'className') {
            setSelecteeClassId(selectOption.value);
            setSectionValue({ value: "", label: "Select Section" })
            setValue("section", { label: '', value: '' })
            const secOption = classList?.filter((item) => item._id === selectOption.value);
            const array = secOption[0]?.sectionNames;
            const convertedArray = array?.map(item => ({
                value: item,
                label: item
            }));
            setSectionOption(convertedArray)
        }

    }

    console.log("allStudents", allStudents)
    console.log("feesGroupList", feesGroupList)


    useEffect(() => {
        const options = classList?.map(item => ({
            value: item._id,
            label: item.className
        }));
        setClassListOption(options)
    }, [classList])

    useEffect(() => {
        const options = feesGroupList?.map(item => ({
            value: item._id,
            label: item.groupName
        }));
        setFeesGroupOption(options);
    }, [feesGroupList]);


    return (

        <Layout>
            <div className="card mb-3">
                <div className="card-body py-">

                    <div className="row">

                        <div className="col-md-6 col-lg-4">
                            <div className="form-group pt-0">
                                <label htmlFor="className">Class <span className='text-danger'>*</span></label>
                                <Select
                                    placeholder="Select Class"
                                    value={classValue}
                                    {...register('className')}
                                    onChange={(selectOption) => {
                                        setClassValue(selectOption);
                                        handleSelect('className', selectOption);
                                    }}
                                    options={classListOption}
                                />
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-4">
                            <div className="form-group pt-0">
                                <label htmlFor="section">Section <span className='text-danger'>*</span></label>
                                <Select
                                    {...register('section')}
                                    placeholder="Select Section"
                                    value={sectionValue}
                                    onChange={(selectOption) => {
                                        setSectionValue(selectOption);
                                        handleSelect('section', selectOption);
                                    }}
                                    options={sectionOption}
                                    isDisabled={sectionOption?.length > 0 ? false : true}
                                />
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-4">
                            <div className="form-group pt-0">
                                <label htmlFor="groupName">Group Name <span className='text-danger'>*</span></label>
                                <Controller
                                    name="groupName"
                                    control={control}
                                    rules={{ required: 'Group Name is required' }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            value={feesGroupValue}
                                            onChange={(selectOption) => {
                                                setFeesGroupValue(selectOption);
                                                handleSelect('groupName', selectOption);
                                            }}
                                            options={feesGroupOption}
                                        />
                                    )}
                                />
                                {errors.groupName && <div className="invalid-feedback d-block">{errors.groupName.message}</div>}
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body">

                </div>
            </div >

        </Layout>

    )
}


export default FeesAllocation;