import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import { IoTimeOutline } from "react-icons/io5";
import { ButtonLoader } from '../../../../utils/Loader/LoaderComponent';
import Empty from '../../../../utils/page/Empty';
import { add_exam_schedule, exam_schedule_filter, messageClear } from '../../../../store/Reducers/examMaster/examReducer';
import toast from 'react-hot-toast';
import { getSubjectDetails } from '../../../../utils/other/returnFunction';



const AddExamSchedule = ({ sendDataToParent }) => {

    const dispatch = useDispatch();


    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();
    const { classList, subjectLists } = useSelector(state => state.administrator);
    const { examSetupList, hallList, loader, successMessage, errorMessage, examScheduleList } = useSelector(state => state.exam);
    const { sessionValue } = useSelector(state => state.default);



    const [examSetupOption, setExamSetupOption] = useState("")
    const [classListOption, setClassListOption] = useState("")
    const [sectionOption, setSectionOption] = useState("")
    const [selecteeClassId, setSelecteeClassId] = useState('');
    const [examHallOption, setExamHallOption] = useState("")

    const [markDistribution, setMarkDistribution] = useState([])


    const [examSetupValue, setExamSetupValue] = useState(null);
    const [classValue, setClassValue] = useState(null);
    const [sectionValue, setSectionValue] = useState(null);
    const [examHallValue, setExamHallValue] = useState(null);


    const [timeTableList, setTimeTableList] = useState([]);
    const [subjectByClass, setSubjectByClass] = useState([]);


    const [startingDate, setStartingDate] = useState('');
    const [startingTime, setStartingTime] = useState('');
    const [duration, setDuration] = useState('');

    const [marks, setMarks] = useState([]);


    // handleMarkChange
    const handleMarkChange = (e, id, type) => {
        setMarks({
            ...marks,
            [id]: {
                ...marks[id],
                [type]: e.target.value
            }
        });
    };


    //  handleClassChange
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
        clearErrors(fieldName);

        if (fieldName === 'className') {
            setSelecteeClassId(selectOption.value);
            setSectionValue({ value: "", label: "Select Section" })
            setValue("section", { label: '', value: '' })
            const secOption = classList?.filter((item) => item._id === selectOption.value);
            const array = secOption[0]?.sectionNames;
            const convertedArray = array?.map(item => ({
                value: item,
                label: item
            }));
            setSectionOption(convertedArray);

        }

        if (fieldName === 'examName') {
            setMarkDistribution(selectOption?.markDistribution)
        }

    }

    // handleRoomSelect
    const handleRoomSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
        clearErrors(fieldName);
    }

    // applySchedule
    const applySchedule = () => {
        if (startingDate && startingTime && duration && examHallValue) {
            let [hours, minutes] = startingTime.split(':').map(Number);
            let currentStartTime = new Date(1970, 0, 1, hours, minutes);

            const updatedTimeTable = subjectByClass?.map((subject, index) => {
                let startTime = new Date(currentStartTime);
                let endTime = new Date(startTime.getTime() + duration * 60000);

                const formattedStartTime = startTime.toTimeString().substr(0, 5);
                const formattedEndTime = endTime.toTimeString().substr(0, 5);

                // Increment date by one day for the "one number index" case
                const dateForSubject = moment(startingDate).add(index, 'days').format('YYYY-MM-DD');

                return {
                    ...subject,
                    date: dateForSubject,
                    startingTime: formattedStartTime,
                    endingTime: formattedEndTime,
                    hallRoom: examHallValue?.label,
                    marks: marks

                };
            });

            setTimeTableList(updatedTimeTable);
        }
    };


    // handleInputChange
    const handleInputChange = (index, field, value) => {
        setTimeTableList(prevList => {
            const updatedTimeTable = prevList.map((item, i) => {
                if (i === index) {
                    return { ...item, [field]: value }
                }
                return item;
            });
            return updatedTimeTable
        })

    }


    // inputMarkChange
    const inputMarkChange = (e, subjectIndex, distId, markType) => {
        const { value } = e.target;
        setTimeTableList(prev => prev.map((item, idx) => {
            if (idx === subjectIndex) {
                return {
                    ...item,
                    marks: {
                        ...item.marks,
                        [distId]: {
                            ...item.marks?.[distId], // Ensure previous marks for distId are preserved
                            [markType]: value  // Update fullMark or passMark
                        }
                    }
                };
            }
            return item;
        }));
    };


    // submitExamSchedule
    const submitExamSchedule = (e) => {
        e.preventDefault();

        const updatedSchedule = timeTableList?.map(subject => ({
            subjectId: subject.subjectId ? subject.subjectId : subject._id,
            date: subject.date,
            startingTime: subject.startingTime,
            endingTime: subject.endingTime,
            hallRoom: subject.hallRoom,
            marks: subject.marks
        }));

        const obj = {
            sessionId: sessionValue,
            classId: selecteeClassId,
            section: sectionValue?.value,
            examName: examSetupValue?.value,
            schedule: updatedSchedule
        }

        dispatch(add_exam_schedule(obj))

    }

    useEffect(() => {

        if (sessionValue && selecteeClassId && sectionValue?.value && examSetupValue?.value) {
            const obj = {
                sessionId: sessionValue,
                classId: selecteeClassId,
                section: sectionValue?.value,
                examName: examSetupValue?.value,
            }

            dispatch(exam_schedule_filter(obj))
        }

    }, [sessionValue, selecteeClassId, sectionValue, examSetupValue])


    useEffect(() => {

        if (examScheduleList?.length > 0) {

            const updatedTimeTable = examScheduleList[0]?.schedule?.map((subject, index) => {
                const subjectDetails = getSubjectDetails(subjectLists, subject?.subjectId);
                const subjectName = subjectDetails ? subjectDetails?.subjectName : '';
                return {
                    ...subject,
                    subjectName: subjectName,
                    date: moment(subject.date).format('YYYY-MM-DD'),
                    marks: subject?.marks
                }
            });

            setTimeTableList(updatedTimeTable);

        } else {

            const filterSubject = subjectLists?.find((d) => d.classDetails.classId === selecteeClassId)?.subjects;
            setSubjectByClass(filterSubject)
            const updatedTimeTable = filterSubject?.map((subject, index) => {
                return {
                    ...subject
                }
            })
            setTimeTableList(updatedTimeTable);
        }
    }, [examScheduleList, selecteeClassId, sectionValue])


    useEffect(() => {
        const options = classList?.map(item => ({
            value: item._id,
            label: item.className
        }));


        const options2 = examSetupList?.map(item => ({
            value: item._id,
            label: `${item.examName} (${item.examTypeName || 'N/A'})`,
            markDistribution: item?.markDistributionId
        }));

        const options3 = hallList?.map(item => ({
            value: item._id,
            label: item.hallNo
        }));

        setClassListOption(options)
        setExamSetupOption(options2)
        setExamHallOption(options3)
    }, [classList, examSetupList, hallList])


    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage);
            dispatch(messageClear());
        }
        if (successMessage) {
            toast.success(successMessage);
            dispatch(messageClear());
            sendDataToParent(0)
            // setTimeTableList([])
        }
    }, [errorMessage, successMessage]);


    // console.log("markDistribution", markDistribution)
    // console.log("timeTableList", timeTableList)


    return (

        <>
            <div className="card mb-3">
                <div className="card-body py-">

                    <div className="row">


                        <div className="col-md-6 col-lg-4">
                            <div className="form-group pt-0">
                                <label htmlFor="className">Class <span className='text-danger'>*</span></label>
                                <Select
                                    placeholder="Select Class"
                                    value={classValue}
                                    {...register('className')}
                                    onChange={(selectOption) => {
                                        setClassValue(selectOption);
                                        handleSelect('className', selectOption);
                                    }}
                                    options={classListOption}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <div className="form-group pt-0">
                                <label htmlFor="section">Section <span className='text-danger'>*</span></label>
                                <Select
                                    {...register('section')}
                                    placeholder="Select Section"
                                    value={sectionValue}
                                    onChange={(selectOption) => {
                                        setSectionValue(selectOption);
                                        handleSelect('section', selectOption);
                                    }}
                                    options={sectionOption}
                                    isDisabled={sectionOption?.length > 0 ? false : true}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <div className="form-group pt-0">
                                <label htmlFor="examName">Exam Name <span className='text-danger'>*</span></label>
                                <Select
                                    placeholder="Select Class"
                                    value={examSetupValue}
                                    {...register('examName')}
                                    onChange={(selectOption) => {
                                        setExamSetupValue(selectOption);
                                        handleSelect('examName', selectOption);
                                    }}
                                    options={examSetupOption}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* ---------------------------------- */}

            {
                examSetupValue?.value && sectionValue?.value && <div className="card">
                    <div className="card-body">
                        <div className="page-header mb-0">
                            <IoTimeOutline style={{ fontSize: '25px', marginBottom: "6px", marginRight: "8px" }} />
                            <h4 style={{ fontSize: "19px" }}>Add Exam Schedule</h4>
                        </div>

                        <div className="border p-2">
                            <form onSubmit={handleSubmit(applySchedule)}>
                                <div className="row">
                                    <h4 className='px-4' style={{ fontSize: "18px", fontWeight: "600" }}>Set Parameters To Quickly Create Schedule</h4>

                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group pt-0">
                                            <label htmlFor="startingDate">Starting Date <span className='text-danger'>*</span></label>
                                            <input
                                                type="date"
                                                className={`form-control ${errors.startingDate ? 'is-invalid' : ''}`}
                                                id="startingDate"
                                                {...register('startingDate', { required: 'Starting date is required' })}
                                                value={startingDate}
                                                onChange={(e) => setStartingDate(e.target.value)}
                                            />
                                            {/* {errors.startingDate && <div className="invalid-feedback">{errors.startingDate.message}</div>} */}
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group pt-0">
                                            <label htmlFor="startingTime">Starting Time <span className='text-danger'>*</span></label>
                                            <input
                                                type="time"
                                                className={`form-control ${errors.startingTime ? 'is-invalid' : ''}`}
                                                id="startingTime"
                                                {...register('startingTime', { required: 'Starting Time is required' })}
                                                value={startingTime}
                                                onChange={(e) => setStartingTime(e.target.value)}
                                            />
                                            {/* {errors.startingTime && <div className="invalid-feedback">{errors.startingTime.message}</div>} */}
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group pt-0">
                                            <label htmlFor="duration">Duration (Minutes) <span className='text-danger'>*</span></label>
                                            <input
                                                type="number"
                                                className={`form-control ${errors.duration ? 'is-invalid' : ''}`}
                                                id="duration"
                                                {...register('duration', { required: 'Duration is required' })}
                                                value={duration}
                                                onChange={(e) => setDuration(e.target.value)}
                                            />
                                            {/* {errors.duration && <div className="invalid-feedback">{errors.duration.message}</div>} */}
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group pt-0">
                                            <label htmlFor="hallRoom">Hall Room <span className='text-danger'>*</span></label>
                                            <div className={` p-0 m-0 form-control ${errors.hallRoom ? 'is-invalid' : ''}`}>
                                                <Select
                                                    {...register('hallRoom', { required: 'Hall is required' })}
                                                    placeholder="Select Hall"
                                                    value={examHallValue}
                                                    onChange={(selectOption) => {
                                                        setExamHallValue(selectOption);
                                                        handleRoomSelect('hallRoom', selectOption);
                                                    }}
                                                    options={examHallOption}
                                                />
                                            </div>
                                            {/* {errors.hallRoom && <p className="text-danger">{errors.hallRoom.message}</p>} */}
                                        </div>
                                    </div>

                                    {markDistribution?.map((dist) => (
                                        <div key={dist._id} className="col-md-6 col-lg-3">
                                            <div className="form-group pt-0">
                                                <label htmlFor={`${dist._id}_fullMark`}>
                                                    {dist.distribution} <span className='text-danger'>*</span>
                                                </label>
                                                <div className='d-flex'>
                                                    {/* Full Mark Input */}
                                                    <input
                                                        type="number"
                                                        className={`form-control ${errors[`${dist._id}_fullMark`] ? 'is-invalid' : ''}`}
                                                        id={`${dist._id}_fullMark`}
                                                        {...register(`${dist._id}_fullMark`, { required: 'Full mark is required' })}
                                                        value={marks[dist._id]?.fullMark || ''} // Dynamically handle state
                                                        onChange={(e) => handleMarkChange(e, dist._id, 'fullMark')}
                                                        placeholder='Full Mark'
                                                        style={{ marginRight: "3px" }}
                                                    />
                                                    {/* Pass Mark Input */}
                                                    <input
                                                        type="number"
                                                        className={`form-control ${errors[`${dist._id}_passMark`] ? 'is-invalid' : ''}`}
                                                        id={`${dist._id}_passMark`}
                                                        {...register(`${dist._id}_passMark`, { required: 'Pass mark is required' })}
                                                        value={marks[dist._id]?.passMark || ''} // Dynamically handle state
                                                        onChange={(e) => handleMarkChange(e, dist._id, 'passMark')}
                                                        placeholder='Pass Mark'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}


                                </div>

                                <div className="mr-2 text-right">
                                    <button type="submit" style={{ width: "85px" }} className="btn btn-secondary py-2 font-weight-bold">
                                        Apply
                                    </button>
                                </div>

                            </form>
                        </div>

                        {/* ------------------------------------------------- */}

                        <div className="mt-4">
                            {timeTableList?.length > 0 ? (
                                <>
                                    <div style={{ overflowX: 'auto', width: '100%' }}>
                                        <table
                                            style={{ minWidth: '1350px', borderCollapse: 'collapse', width: '100%' }}
                                            className="tableStyle"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>Subject <span className='text-danger'>*</span></th>
                                                    <th>Date <span className='text-danger'>*</span></th>
                                                    <th>Starting Time <span className='text-danger'>*</span></th>
                                                    <th>Ending Time <span className='text-danger'>*</span></th>
                                                    <th>Hall Room <span className='text-danger'>*</span></th>
                                                    {markDistribution?.map(dist => (
                                                        <th key={dist._id}>{dist.distribution} <span className='text-danger'>*</span></th>
                                                    ))}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {timeTableList?.map((d, i) => {
                                                    const { marks } = d;  // Destructure `marks` for easier access

                                                    return (
                                                        <tr key={i}>
                                                            {/* ----------------------------------------- */}
                                                            <td>{d.subjectName}</td>
                                                            <td>
                                                                <input
                                                                    style={{ width: "100%" }}
                                                                    type="date"
                                                                    name="date"
                                                                    className="form-control p-2"
                                                                    value={d.date || ''}
                                                                    onChange={(e) => handleInputChange(i, 'date', e.target.value)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    style={{ width: "100%" }}
                                                                    type="time"
                                                                    name="startingTime"
                                                                    className="form-control p-2"
                                                                    value={d.startingTime || ''}
                                                                    onChange={(e) => handleInputChange(i, 'startingTime', e.target.value)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    style={{ width: "100%" }}
                                                                    type="time"
                                                                    name="endingTime"
                                                                    className="form-control p-2"
                                                                    value={d.endingTime || ''}
                                                                    onChange={(e) => handleInputChange(i, 'endingTime', e.target.value)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    style={{ width: "100%" }}
                                                                    type="text"
                                                                    name="hallRoom"
                                                                    className="form-control p-2"
                                                                    value={d.hallRoom || ''}
                                                                    onChange={(e) => handleInputChange(i, 'hallRoom', e.target.value)}
                                                                />
                                                            </td>
                                                            {/* ----------------------------------------- */}
                                                            {markDistribution?.map((dist) => {
                                                                const markForDist = marks?.[dist._id] || {};
                                                                const { fullMark = '', passMark = '' } = markForDist;

                                                                return (
                                                                    <td key={dist._id}>
                                                                        <div style={{ display: 'flex' }}>
                                                                            {/* Full Mark input */}
                                                                            <input
                                                                                style={{ width: "85px", marginRight: "3px" }}
                                                                                type="text"
                                                                                name={`fullMark_${dist._id}`}
                                                                                className="form-control p-2"
                                                                                placeholder="Full Mark"
                                                                                value={fullMark}
                                                                                onChange={(e) => inputMarkChange(e, i, dist._id, 'fullMark')}
                                                                            />
                                                                            {/* Pass Mark input */}
                                                                            <input
                                                                                style={{ width: "85px" }}
                                                                                type="text"
                                                                                name={`passMark_${dist._id}`}
                                                                                className="form-control p-2"
                                                                                placeholder="Pass Mark"
                                                                                value={passMark}
                                                                                onChange={(e) => inputMarkChange(e, i, dist._id, 'passMark')}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>

                                        </table>
                                    </div>
                                </>
                            ) : (
                                <Empty data="This Class Subject Not Found!" />
                            )}
                            <hr />
                            <div className="mt-4 text-right">
                                <button
                                    type="submit"
                                    onClick={submitExamSchedule}
                                    disabled={loader}
                                    style={{ width: "90px" }}
                                    className="btn btn-secondary py-2 font-weight-bold"
                                >
                                    {loader ? <ButtonLoader /> : (examScheduleList?.length > 0 ? 'Update' : 'Save')}
                                </button>
                            </div>
                        </div>

                        {/* ------------------------------------------------- */}

                    </div>
                </div >
            }

            {/* ---------------------------------- */}

        </>

    )
}


export default AddExamSchedule;