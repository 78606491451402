import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const defaultReducer = createSlice({
    name: "default",
    initialState: {
        sideBar: false,
        searchValue: "",
        sessionValue: localStorage.getItem("sessionValue")
    },
    reducers: {
        sidebarFunction: (state, { payload }) => {
            state.sideBar = payload
        },
        searchFunction: (state, { payload }) => {
            state.searchValue = payload
        },
        sessionFunction: (state, { payload }) => {
            state.sessionValue = payload
        },
        sessionValueClear: (state, _) => {
            state.sessionValue = ''
        },
    },
    extraReducers: {

    }
});


export const { sidebarFunction, searchFunction, sessionFunction, sessionValueClear } = defaultReducer.actions;
export default defaultReducer.reducer;