import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { useOptionsFromData } from '../../../utils/other/returnFunction';
import { genders, religions } from '../../../utils/other/data';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { studentClear } from '../../../store/Reducers/student/studentReducer';


const BasicDetails = forwardRef(({ onSendMessage }, ref) => {

    const dispatch = useDispatch();

    const { classList } = useSelector(state => state.administrator);
    const { student } = useSelector(state => state.student);

    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();

    const genderList = useOptionsFromData(genders?.gender);
    const religionList = useOptionsFromData(religions?.list);


    const [classListOption, setClassListOption] = useState("")
    const [sectionOption, setSectionOption] = useState("")


    const [genderValue, setGenderValue] = useState(null);
    const [religionValue, setReligionValue] = useState(null);
    const [classValue, setClassValue] = useState(null);
    const [sectionValue, setSectionValue] = useState(null);


    // handleSelect
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
        clearErrors(fieldName);

        if (fieldName === 'className') {
            setSectionValue(null)
            const secOption = classList?.filter((item) => item._id === selectOption.value);
            const array = secOption[0]?.sectionNames;
            const convertedArray = array?.map(item => ({
                value: item,
                label: item
            }));
            setSectionOption(convertedArray)
        }
    }


    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        }
    }));



    // onSubmit
    const onSubmit = (data) => {
        onSendMessage(true);
        sessionStorage.setItem('basicDetails', JSON.stringify(data))
    };


    // removeDetails
    const removeDetails = () => {
        sessionStorage.removeItem('basicDetails')
        reset({
            firstName: '',
            lastName: '',
            email: '',
            className: '',
            section: '',
            phoneNumber: '',
            roll: '',
            enrollmentNumber: '',
            dateOfAdmission: '',
            dateOfBirth: '',
            gender: '',
            religion: '',
        });
        setClassValue()
        setSectionValue()
        setGenderValue()
        setReligionValue()
    }


    useEffect(() => {
        const options = classList?.map(item => ({
            value: item._id,
            label: item.className
        }));
        setClassListOption(options)
    }, [classList])


    useEffect(() => {

        if (Object.keys(student).length > 0) {

            setValue('firstName', student.basicDetails.firstName);
            setValue('lastName', student.basicDetails.lastName);
            setValue('email', student.basicDetails.email);
            setValue('phoneNumber', student.basicDetails?.phoneNumber);

            setGenderValue({
                label: student.basicDetails?.gender,
                value: student.basicDetails?.gender
            });
            setValue("gender", {
                label: student.basicDetails?.gender,
                value: student.basicDetails?.gender
            });

            setReligionValue({
                label: student.basicDetails?.religion,
                value: student.basicDetails?.religion
            });
            setValue("religion", {
                label: student.basicDetails?.religion,
                value: student.basicDetails?.religion
            });


            const activeSession = student.sessions.find(session => session.sessionIsActive);
            const activeClass = classList?.find(c => c._id === activeSession?.className);

            const classValue = {
                label: activeClass?.className ?? '',
                value: activeClass?._id ?? ''
            };

            const sectionValue = {
                label: activeSession?.section ?? '',
                value: activeSession?.section ?? ''
            };

            setClassValue(classValue);
            setValue("className", classValue);

            setSectionValue(sectionValue);
            setValue("section", sectionValue);


            setValue('roll', student.sessions.find(session => session.sessionIsActive)?.roll ?? '');
            setValue('enrollmentNumber', student.basicDetails?.enrollmentNumber);
            setValue('dateOfAdmission', moment(student.basicDetails.dateOfAdmission).format('YYYY-MM-DD'));
            setValue('dateOfBirth', moment(student.basicDetails.dateOfBirth).format('YYYY-MM-DD'));

        }

    }, [student])


    useEffect(() => {

        const storedBasicDetails = sessionStorage.getItem("basicDetails");
        if (storedBasicDetails) {
            const basicDetails = JSON.parse(storedBasicDetails);
            setValue('firstName', basicDetails.firstName);
            setValue('lastName', basicDetails.lastName);
            setValue('email', basicDetails.email);
            setValue('phoneNumber', basicDetails?.phoneNumber);
            setGenderValue({
                label: basicDetails?.gender.label, value: basicDetails?.gender.value
            })
            setValue("gender", {
                label: basicDetails?.gender.label, value: basicDetails?.gender.value
            })
            setReligionValue({
                label: basicDetails?.religion.label, value: basicDetails?.religion.value
            })
            setValue("religion", {
                label: basicDetails?.religion.label, value: basicDetails?.religion.value
            })

            setClassValue({
                label: basicDetails?.className.label, value: basicDetails?.className.value
            })
            setValue("className", {
                label: basicDetails?.className.label, value: basicDetails?.className.value
            })

            setSectionValue({
                label: basicDetails?.section.label, value: basicDetails?.section.value
            })
            setValue("section", {
                label: basicDetails?.section.label, value: basicDetails?.section.value
            })

            setValue('roll', basicDetails?.roll);
            setValue('enrollmentNumber', basicDetails?.enrollmentNumber);
            setValue('dateOfAdmission', moment(basicDetails.dateOfAdmission).format('YYYY-MM-DD'));
            setValue('dateOfBirth', moment(basicDetails.dateOfBirth).format('YYYY-MM-DD'));
        }

    }, [])


    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header d-flex justify-content-between">
                        <h4 className="card-title">Basic Details</h4>
                        <button onClick={removeDetails} className='btn btn-danger px-3 py-2 fw-bold'> Reset </button>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">

                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="firstName">First Name <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                                            id="firstName"
                                            {...register('firstName', { required: 'First Name is required' })}
                                        />
                                        {errors.firstName && <div className="invalid-feedback">{errors.firstName.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="lastName">Last Name <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                                            id="lastName"
                                            {...register('lastName', { required: 'Last Name is required' })}
                                        />
                                        {errors.lastName && <div className="invalid-feedback">{errors.lastName.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="email">Email <span className='text-danger'>*</span></label>
                                        <input type="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} id="email" {...register('email', { required: 'Email is required', pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i, message: 'Invalid email address' } })} />
                                        {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="phoneNumber">Phone Number <span className='text-danger'>*</span></label>
                                        <input type="tel" className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`} id="phoneNumber" {...register('phoneNumber', {
                                            required: 'Phone Number is required',
                                            pattern: {
                                                value: /^\+?\d{10,16}$/,
                                                message: 'Invalid phone number format'
                                            }
                                        })} />
                                        {errors.phoneNumber && <div className="invalid-feedback">{errors.phoneNumber.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="className">Class <span className='text-danger'>*</span></label>
                                        <Select
                                            {...register('className', { required: 'Class is required' })}
                                            value={classValue}
                                            onChange={(selectOption) => {
                                                setClassValue(selectOption);
                                                handleSelect('className', selectOption);
                                            }}
                                            options={classListOption}
                                            className={errors.className ? 'is-invalid' : ''}
                                        />
                                        {errors.className && <div className="invalid-feedback">{errors.className.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="section">Section</label>
                                        <Select
                                            {...register('section', { required: 'Section is required' })}
                                            value={sectionValue}
                                            onChange={(selectOption) => {
                                                setSectionValue(selectOption);
                                                handleSelect('section', selectOption);
                                            }}
                                            options={sectionOption}
                                            className={errors.section ? 'is-invalid' : ''}
                                            isDisabled={sectionOption?.length > 0 ? false : true}
                                        />
                                        {errors.section && <div className="invalid-feedback">{errors.section.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="roll">Roll <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.roll ? 'is-invalid' : ''}`}
                                            id="roll"
                                            {...register('roll', {
                                                required: 'Roll is required',
                                                pattern: {
                                                    value: /^[0-9]+$/,
                                                    message: 'Roll must be a number'
                                                }
                                            })}
                                        />
                                        {errors.roll && <div className="invalid-feedback">{errors.roll.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="gender">Gender <span className='text-danger'>*</span></label>
                                        <Select
                                            {...register('gender', { required: 'Gender is required' })}
                                            value={genderValue}
                                            onChange={(selectOption) => {
                                                setGenderValue(selectOption);
                                                handleSelect('gender', selectOption);
                                            }}
                                            options={genderList}
                                            className={errors.gender ? 'is-invalid' : ''}
                                        />
                                        {errors.gender && <div className="invalid-feedback">{errors.gender.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="religion">Religion <span className='text-danger'>*</span></label>
                                        <Select
                                            {...register('religion', { required: 'Religion is required' })}
                                            value={religionValue}
                                            onChange={(selectOption) => {
                                                setReligionValue(selectOption);
                                                handleSelect('religion', selectOption);
                                            }}
                                            options={religionList}
                                            className={errors.religion ? 'is-invalid' : ''}
                                        />
                                        {errors.religion && <div className="invalid-feedback">{errors.religion.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="enrollmentNumber">Enrollment Number <span className='text-danger'>*</span></label>
                                        <input type="text" className={`form-control ${errors.enrollmentNumber ? 'is-invalid' : ''}`} id="enrollmentNumber" {...register('enrollmentNumber', {
                                            required: 'Enrollment Number is required',
                                        })} />
                                        {errors.enrollmentNumber && <div className="invalid-feedback">{errors.enrollmentNumber.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="dateOfAdmission">Date of Admission <span className='text-danger'>*</span></label>
                                        <input
                                            type="date"
                                            className={`form-control ${errors.dateOfAdmission ? 'is-invalid' : ''}`}
                                            id="dateOfAdmission"
                                            {...register('dateOfAdmission', { required: 'Date of Admission is required' })}
                                        />
                                        {errors.dateOfAdmission && <div className="invalid-feedback">{errors.dateOfAdmission.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="dateOfBirth">Date of Birth <span className='text-danger'>*</span></label>
                                        <input
                                            type="date"
                                            className={`form-control ${errors.dateOfBirth ? 'is-invalid' : ''}`}
                                            id="dateOfBirth"
                                            {...register('dateOfBirth', { required: 'Date of Birth is required' })}
                                        />
                                        {errors.dateOfBirth && <div className="invalid-feedback">{errors.dateOfBirth.message}</div>}
                                    </div>
                                </div>


                            </div>
                            <button type="submit" style={{ display: 'none' }}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default BasicDetails;
