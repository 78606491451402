
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';


// add_teacher
export const add_teacher = createAsyncThunk(
    'student/add_teacher',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/add-teacher', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_all_teacher
export const get_all_teacher = createAsyncThunk(
    'teacher/get_all_teacher',
    async ({ searchValue, page, parPage }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-all-teacher?searchValue=${searchValue}&&page=${page}&&parPage=${parPage}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_single_teacher
export const get_single_teacher = createAsyncThunk(
    'teacher/get_single_teacher',
    async ({ teacherId }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-single-teacher/${teacherId}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// teacher_update
export const teacher_update = createAsyncThunk(
    'teacher/teacher_update',
    async ({ teacherId, dataaa }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/teacher-update/${teacherId}`, dataaa);
            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// update_teacher_status
export const update_teacher_status = createAsyncThunk(
    'student/update_teacher_status',
    async ({ teacherId, value }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/update-teacher-status/${teacherId}`, { value })
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// delete_teacher
export const delete_teacher = createAsyncThunk(
    'teacher/delete_teacher',
    async ({ deleteId, teacherImageId }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.delete(`/delete-teacher/${deleteId}`, { data: { teacherImageId } });
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// teacher_filter
export const teacher_filter = createAsyncThunk(
    'teacher/teacher_filter',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post(`/teacher-filter`, info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// teacher_assign_class
export const teacher_assign_class = createAsyncThunk(
    'teacher/teacher_assign_class',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post(`/teacher-assign-class`, info);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// teacher_assign_subject
export const teacher_assign_subject = createAsyncThunk(
    'teacher/teacher_assign_subject',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post(`/teacher-assign-subject`, info);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


// authenticate_employee
export const authenticate_employee = createAsyncThunk(
    'teacher/authenticate_employee',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/authenticate-employee', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


// resend_employee_password
export const resend_employee_password = createAsyncThunk(
    'teacher/resend_employee_password',
    async ({ employeeId, password }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/resend-employee-password/${employeeId}`, { password })
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


// update_teacher_profile
export const update_teacher_profile = createAsyncThunk(
    'student/update_teacher_profile',
    async ({ teacherId, formData }, { rejectWithValue, fulfillWithValue }) => {

        try {
            const { data } = await api.post(`/update-teacher-profile/${teacherId}`, formData)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)



export const teacherReducer = createSlice({
    name: "teacher",
    initialState: {
        errorMessage: '',
        successMessage: '',
        loader: false,
        allTeachers: [],
        allEmmployee: [],
        authEmployee: [],
        employeeCount: 0,
        teacher: {}
    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ''
            state.successMessage = ''
        },
        teacherClear: (state, _) => {
            state.teacher = {}
        }
    },
    extraReducers: {
        [add_teacher.pending]: (state, _) => {
            state.loader = true
        },
        [add_teacher.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [add_teacher.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [get_all_teacher.pending]: (state, _) => {
            state.loader = true
        },
        [get_all_teacher.fulfilled]: (state, { payload }) => {
            const filterTeacher = payload?.allEmmployee?.filter((d) => d.role === 'Teacher')
            const filterAuthEmployee = payload?.allEmmployee?.filter((d) => d.isAuthenticate === true);
            state.loader = false
            state.allEmmployee = payload.allEmmployee
            state.allTeachers = filterTeacher
            state.employeeCount = payload.employeeCount
            state.authEmployee = filterAuthEmployee
        },
        [get_single_teacher.fulfilled]: (state, { payload }) => {
            state.teacher = payload.teacher
        },
        [teacher_update.pending]: (state, _) => {
            state.loader = true
        },
        [teacher_update.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [teacher_update.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [update_teacher_status.pending]: (state, _) => {
            state.loader = true
        },
        [update_teacher_status.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [update_teacher_status.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [delete_teacher.pending]: (state, _) => {
            state.loader = true
        },
        [delete_teacher.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [delete_teacher.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [teacher_filter.pending]: (state, _) => {
            state.loader = true
        },
        [teacher_filter.fulfilled]: (state, { payload }) => {
            const filterAuthEmployee = payload?.allEmmployee?.filter((d) => d.isAuthenticate === true);
            state.loader = false
            state.allEmmployee = payload.allEmmployee
            state.employeeCount = payload.employeeCount
            state.authEmployee = filterAuthEmployee
        },
        [teacher_assign_class.pending]: (state, _) => {
            state.loader = true
        },
        [teacher_assign_class.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [teacher_assign_class.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [teacher_assign_subject.pending]: (state, _) => {
            state.loader = true
        },
        [teacher_assign_subject.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [teacher_assign_subject.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [authenticate_employee.pending]: (state, _) => {
            state.loader = true
        },
        [authenticate_employee.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [authenticate_employee.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [resend_employee_password.pending]: (state, _) => {
            state.loader = true
        },
        [resend_employee_password.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [resend_employee_password.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [update_teacher_profile.pending]: (state, _) => {
            state.loader = true
        },
        [update_teacher_profile.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [update_teacher_profile.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },

    }
});


export const { messageClear, teacherClear } = teacherReducer.actions;
export default teacherReducer.reducer;