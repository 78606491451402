import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { confirmMessagge } from '../../../utils/other/aleartFunc';
import { messageClear, tokenClear, woner_org_logout } from '../../../store/Reducers/organisation/orgWonerReducer';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';



const OrgWonerSidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { logoutMessage } = useSelector(state => state.org_woner);

    const organisationrRoutes = ['/all-organisation', '/add-organisation'];
    const isOrganisationRoute = organisationrRoutes?.includes(location?.pathname);



    // logoutFunction
    const logoutFunction = async () => {
        const returnValue = await confirmMessagge('Logout');
        if (returnValue) {
            dispatch(woner_org_logout(navigate))
        }
    }

    useEffect(() => {
        if (logoutMessage) {
            toast.success(logoutMessage)
            dispatch(messageClear())
            dispatch(tokenClear())
        }
    }, [logoutMessage])



    return (
        <div className="sidebar sidebar-style-2" data-background-color="dark22">
            <div className="sidebar-wrapper scrollbar scrollbar-inner">
                <div className="sidebar-content">

                    <ul className="nav nav-primary">
                        {/* <li className="nav-section">
                            <span className="sidebar-mini-icon">
                                <i className="fa fa-ellipsis-h" />
                            </span>
                            <h4 className="text-section">Navigation</h4>
                        </li> */}

                        <li className={`nav-item ${location?.pathname === '/dashboard' ? 'active' : ''}`}>
                            <Link to={`/dashboard`}>
                                <i className="fas fa-th-large" />
                                <p>Dashboard</p>
                            </Link>
                        </li>

                        <li className={`nav-item ${isOrganisationRoute ? 'active' : ''}`}>
                            <a data-toggle="collapse" href="#myOrg">
                                <i className="fas fa-home" />
                                <p>My Organisation</p>
                                <span className="caret" />
                            </a>
                            <div className={`collapse ${isOrganisationRoute ? 'show' : ''}`} id="myOrg">
                                <ul className="nav nav-collapse p-0">
                                    <li className=''>
                                        <Link to="/all-organisation">
                                            <span className="sub-item">All Organisation</span>
                                        </Link>
                                    </li>
                                    <li className=''>
                                        <Link to="/add-organisation">
                                            <span className="sub-item">Add Organisation</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li className={`nav-item ${location?.pathname === '/head-organisation' ? 'active' : ''}`}>
                            <Link to={`/head-organisation`}>
                                <i className="fas fa-university" /> {/* Head Organization icon */}
                                <p>Head Organisation</p>
                            </Link>
                        </li>



                        <li className="nav-item">
                            <Link onClick={logoutFunction}>
                                <i className="fas fa-sign-out-alt" />
                                <p>Logout</p>
                            </Link>
                        </li>

                        {/*          <li className="nav-item">
                            <a data-toggle="collapse" href="#settings">
                                <i className="fas fa-cogs" />
                                <p>Settings</p>
                                <span className="caret" />
                            </a>
                            <div className="collapse" id="settings">
                                <ul className="nav nav-collapse p-0">
                                    <li className=''>
                                        <Link to="/">
                                            <span className="sub-item">Home</span>
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <a data-toggle="collapse" href="#activeuser">
                                            Active User
                                            <span className="caret" />
                                        </a>
                                        <div className="collapse" id="activeuser">
                                            <ul className="nav nav-collapse">
                                                <li>
                                                    <a href="/studentauth">
                                                        <span className="sub-item">Student Authenticate</span>
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li> */}

                    </ul>
                </div>
            </div>
        </div>
    )
}

export default OrgWonerSidebar;