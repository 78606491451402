import React, { useEffect, useState } from 'react';
import Layout from '../../Layout/Layout';
import { useForm } from 'react-hook-form';
import { ButtonLoader } from '../../../utils/Loader/LoaderComponent';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import Empty from '../../../utils/page/Empty';
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import moment from 'moment';
import Pagination from '../../Layout/Pagination';
import { confirmMessagge } from '../../../utils/other/aleartFunc';
import { add_exam_term, delete_exam_term, get_exam_term, get_single_exam_term, messageClear, update_exam_term } from '../../../store/Reducers/examMaster/examReducer';



const ExamTerm = () => {

    const dispatch = useDispatch();

    const { handleSubmit, control, setValue, register, formState: { errors }, reset } = useForm();
    const { loader, successMessage, errorMessage, examTermList, examTermCount, singleExamTerm } = useSelector(state => state.exam);
    const { searchValue } = useSelector(state => state.default);


    const [currentPage, setCurrentPage] = useState(1)
    const [parPage, setParPage] = useState(10)

    const [editId, setEditId] = useState('');



    // onSubmit
    const onSubmit = (data) => {
        const { termName } = data;

        const obj = {
            termName
        }

        if (editId) {
            dispatch(update_exam_term({ editId, obj }))
        } else {
            dispatch(add_exam_term(obj))
        }

    }



    // handleItemPerPageChange
    const handleItemPerPageChange = (e) => {
        setParPage(e.target.value)
    }

    //  handleClassEdit
    const handleClassEdit = (editId) => {
        setEditId(editId)
        dispatch(get_single_exam_term(editId));
    }


    // handleClassDelete
    const handleClassDelete = async (deleteId) => {
        const returnValue = await confirmMessagge('Delete');
        if (returnValue) {
            dispatch(delete_exam_term(deleteId))
        }
    }


    useEffect(() => {
        const obj = {
            parPage: parseInt(parPage),
            page: parseInt(currentPage),
            searchValue
        }
        dispatch(get_exam_term(obj))
    }, [searchValue, currentPage, parPage, successMessage])



    useEffect(() => {
        if (singleExamTerm) {
            setValue('termName', singleExamTerm?.termName)
        }
    }, [singleExamTerm])


    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage);
            dispatch(messageClear());
        }
        if (successMessage) {
            toast.success(successMessage);
            dispatch(messageClear());
            setValue('termName', '')
            setEditId('')
        }
    }, [errorMessage, successMessage]);



    return (
        <Layout>

            <div className="row">

                <div className="col-md-7">
                    <div className="card">
                        <div className="card-body">
                            <div className="page-header mb-0">
                                <span style={{ fontSize: "18px", padding: "0px 8px 7px 0px" }}>
                                    <i className="fas fa-list" />
                                </span>
                                <h4 style={{ fontSize: "19px" }}>Exam Term List</h4>
                            </div>

                            <hr className='px-0 my-1 ' />

                            <div className='mt-3'>
                                {examTermList?.length > 0 ? (
                                    <table className="tableStyle">
                                        <thead className=''>
                                            <tr>
                                                <th>Sl.</th>
                                                <th>Term Name</th>
                                                <th>CreatedAt</th>
                                                <th className='no-print'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className=''>
                                            {examTermList?.map((d, i) => (
                                                <tr key={i}>
                                                    <td data-lable="Sl">{i + 1 + (currentPage - 1) * parPage}</td>
                                                    <td data-lable="Term Name">{d.termName}</td>
                                                    <td>{moment(d.createdAt).format('DD/MM/YYYY')}</td>
                                                    <td data-lable="Action" className='no-print'>
                                                        <span title='Edit' className='mr-1' onClick={() => handleClassEdit(d._id)}>
                                                            <FaEdit style={{ fontSize: '20px', color: 'green' }} />
                                                        </span>
                                                        <span title='Delete' className='ml-1' onClick={() => handleClassDelete(d._id)}>
                                                            <MdDeleteForever style={{ fontSize: '24px', color: 'red' }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <Empty data={`Exam Term Not Found !`} />
                                )}

                            </div>

                        </div>

                        {
                            examTermCount > 10 && <div class="d-flex justify-content-between">
                                <div className="col-md-4 d-flex justify-content-start align-items-center itemParPageStyle">
                                    <div class="d-flex mb-2">
                                        <label for="itemPerPage" class="col-sm-auto col-form-label">Select items per page :</label>
                                        <div class="col-sm-auto">
                                            <select
                                                id='itemPerPage'
                                                style={{ width: "65px", marginLeft: "-23px", marginTop: "7px", padding: "3px" }}
                                                onChange={handleItemPerPageChange}
                                                value={parPage}
                                            >
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="5000">All</option>

                                            </select>
                                        </div>
                                    </div>

                                </div>

                                <div className='px-4 pt-2'>
                                    {
                                        examTermCount >= parPage ?
                                            <Pagination
                                                pageNumber={currentPage}
                                                setPageNumber={setCurrentPage}
                                                totalItem={examTermCount}
                                                parPage={parPage}
                                                showItem={Math.floor(examTermCount / parPage)}
                                            /> : ''
                                    }
                                </div>

                            </div>
                        }

                    </div>
                </div>


                <div className="col-md-5">
                    <div className="card">
                        <div className="card-body">
                            <div className="page-header mb-0">
                                <span style={{ fontSize: "18px", padding: "0px 8px 8px 0px" }}>
                                    <i className="fas fa-edit" />
                                </span>
                                <h4 style={{ fontSize: "19px" }}>{editId ? 'Edit Exam Term' : 'Add Exam Term'}</h4>
                            </div>
                            <hr className='px-0 my-1 ' />
                            <div>

                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group">
                                        <label htmlFor="termName">Term Name <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.termName ? 'is-invalid' : ''}`}
                                            id="termName"
                                            {...register('termName', { required: 'Term name is required' })}
                                        />
                                        {errors.termName && <div className="invalid-feedback">{errors.termName.message}</div>}
                                    </div>

                                    <div className="my-3 mx-2" style={{ textAlign: "right" }}>
                                        <button disabled={loader} type="submit" style={{ width: "90px" }} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                            {loader ? <ButtonLoader /> : (editId ? 'Edit' : 'Add')}
                                        </button>
                                    </div>

                                </form>

                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </Layout>
    )
}


export default ExamTerm;