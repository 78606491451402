import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { ButtonLoader } from '../../../../utils/Loader/LoaderComponent';
import Empty from '../../../../utils/page/Empty';
import Layout from '../../../Layout/Layout';
import { student_filter, student_filter_by_subject } from '../../../../store/Reducers/student/studentReducer';
import { image_url, server_url } from '../../../../api/api';
import { getActiveRoll } from '../../../../utils/other/returnFunction';
import { add_student_marks, messageClear } from '../../../../store/Reducers/examMaster/markEntryReducer';
import toast from 'react-hot-toast';
import { get_exam_attendance } from '../../../../store/Reducers/attendance/studentAttendance';



const MarkEntries = () => {

    const dispatch = useDispatch();
    const { handleSubmit, setValue, register, formState: { errors }, clearErrors } = useForm();
    const { register: registerForm2, handleSubmit: handleSubmitForm2, setValue: setValueForm2, formState: { errors: errorsForm2 } } = useForm();

    const { classList, subjectLists } = useSelector(state => state.administrator);
    const { examSetupList, examScheduleList } = useSelector(state => state.exam);
    const { sessionValue } = useSelector(state => state.default);
    const { allStudents, studentExamAttendanceList } = useSelector(state => state.student);

    const { examAttendance } = useSelector(state => state.studentAttendance);
    const { loader, successMessage, errorMessage } = useSelector(state => state.markEntry);


    const [subjectOption, setSubjectOption] = useState('');
    const [examSetupOption, setExamSetupOption] = useState("");
    const [classListOption, setClassListOption] = useState("");
    const [sectionOption, setSectionOption] = useState("");

    const [selectedExamId, setSelectedExamId] = useState('');
    const [selectedClassId, setSelectedClassId] = useState('');
    const [selectedSectionId, setSelectedSectionId] = useState('');
    const [selectedSubjectId, setSelectedSubjectId] = useState('');

    const [examSetupValue, setExamSetupValue] = useState(null);
    const [classValue, setClassValue] = useState(null);
    const [sectionValue, setSectionValue] = useState(null);
    const [subjectValue, setSubjectValue] = useState(null);

    const [schedule, setSchedule] = useState()


    const [markDistribution, setMarkDistribution] = useState([])


    // handleSelect function
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
        clearErrors(fieldName);

        if (fieldName === 'examName') {
            setSelectedExamId(selectOption.value)
            setMarkDistribution(selectOption?.markDistribution)
            setValue("section", { label: '', value: '' });
            setSubjectValue(null)
        }

        if (fieldName === 'className') {
            setSelectedClassId(selectOption.value);
            setSectionValue({ value: "", label: "Select Section" });
            setValue("section", null);
            setSubjectValue({ value: "", label: "Select Subject" })
            setValue("subject", null);

            const secOption = classList?.filter((item) => item._id === selectOption.value);
            const array = secOption[0]?.sectionNames;
            const convertedArray = array?.map(item => ({
                value: item,
                label: item
            }));
            setSectionOption(convertedArray);

            const filterSubject = subjectLists?.filter(d => d.classDetails.classId === selectOption.value).flatMap(d => d.subjects);
            if (filterSubject) {
                const options = filterSubject?.map(item => ({
                    value: item._id,
                    label: item.subjectName
                }));
                setSubjectOption(options);
            }
        }

        if (fieldName === 'section') {
            setSubjectValue({ value: "", label: "Select Subject" })
            setValue("subject", null);
            setSelectedSectionId(selectOption.value)
            const obj = {
                sessionValue,
                className: selectedClassId,
                section: selectOption.value
            }
            dispatch(student_filter(obj));


            const obj2 = {
                sessionId: sessionValue,
                examId: selectedExamId,
                classId: selectedClassId,
                section: selectOption.value
            }

            dispatch(get_exam_attendance(obj2))

        }

        if (fieldName === 'subject') {
            setSelectedSubjectId(selectOption.value)
        }



    }

    // handleFilter function
    const handleFilter = (data) => {
        const { subject } = data;

        // Step 2: Process the exam attendance and filter the students
        const result = examAttendance.map((studentAttendance) => {
            const student = allStudents.find((s) => s._id === studentAttendance.studentId);

            // Proceed only if the student is found
            if (student) {
                const subjectAttendance = studentAttendance.attendance.find(
                    (att) => att.subjectId === selectedSubjectId
                );

                const isSubjectAssigned = student.sessions.some((session) =>
                    session.sessionIsActive === true && session.assignedSubjects.includes(selectedSubjectId)
                );

                if (subjectAttendance && isSubjectAssigned) {
                    return {
                        ...student,   // Spread student properties
                        attendance: subjectAttendance.attendance === "Present",  // Merge with attendance property
                    };
                }
            }

            return null;
        }).filter((student) => student !== null); // Filter out any null results

        dispatch(student_filter_by_subject(result));


    }

    // handleSubmit2 - collect Practical and Written data
    const handleSubmit2 = (data) => {
        const studentMarks = studentExamAttendanceList?.map((student) => {
            // Create an object to hold marks for each distribution type
            const marks = {};

            // Iterate through markDistribution to collect each type of marks dynamically
            markDistribution.forEach(dist => {
                const markKey = `${dist.distribution}_${student._id}`; // Create dynamic key
                marks[dist.distribution] = parseFloat(data[markKey]) || 0; // Collect marks or set to 0 if not provided
            });

            // Prepare the marks object dynamically based on available distributions
            const marksEntry = {
                studentId: student._id,
                subtotal: Object.values(marks).reduce((acc, mark) => acc + mark, 0), // Calculate subtotal
                generate: '', // Ensure this is what you want, or modify accordingly
                isPresent: student.attendance
            };

            // Dynamically add marks to the marksEntry
            Object.keys(marks).forEach(markType => {
                marksEntry[`${markType.toLowerCase()}Marks`] = marks[markType]; // Convert to lowercase for consistent naming
            });

            return marksEntry; // Return the constructed marks entry for this student
        });

        const obj = {
            sessionId: sessionValue,
            examId: selectedExamId,
            classId: selectedClassId,
            section: selectedSectionId,
            subjectId: selectedSubjectId,
            students: studentMarks
        };

        // Uncomment this to dispatch the action
        dispatch(add_student_marks(obj));

    };


    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage);
            dispatch(messageClear());
        }
        if (successMessage) {
            toast.success(successMessage);
            dispatch(messageClear());
        }
    }, [errorMessage, successMessage]);


    useEffect(() => {
        const options = classList?.map(item => ({
            value: item._id,
            label: item.className
        }));

        const options2 = examSetupList?.filter(d => examScheduleList?.some(c => c.examName._id === d._id))?.map(item => ({
            value: item._id,
            label: `${item.examName} (${item.examTypeName || 'N/A'})`,
            markDistribution: item?.markDistributionId
        }));

        setClassListOption(options);
        setExamSetupOption(options2);
    }, [classList, examScheduleList, examSetupList]);


    useEffect(() => {
        if (Array.isArray(schedule) && selectedSubjectId) {  // Check if schedule is an array and selectedSubjectId is valid
            const data = schedule.find((d) => d?.subjectId === selectedSubjectId);

            if (data) { // Check if a matching data entry was found
                const updatedMarkDistribution = markDistribution.map(item => {
                    const markEntry = data.marks[item._id];
                    if (markEntry) {
                        return {
                            ...item,
                            fullMark: markEntry.fullMark // Update fullMark if found
                        };
                    }
                    return item; // Return the item unchanged if no match found
                });
                setMarkDistribution(updatedMarkDistribution)
            }
        }
    }, [schedule, selectedSubjectId, selectedExamId]);


    useEffect(() => {

        if (selectedExamId && selectedClassId && selectedSectionId) {
            const data = examScheduleList?.find((d) => d?.examName?._id === selectedExamId && d?.classId?._id === selectedClassId && d?.section === selectedSectionId);
            setSchedule(data?.schedule)
        }

    }, [selectedExamId, selectedClassId, selectedSectionId])

    // console.log("studentExamAttendanceList", studentExamAttendanceList)
    // console.log("markDistribution", markDistribution)


    return (
        <Layout>
            <div className="card mb-3">
                <div className="card-body py-">
                    <form onSubmit={handleSubmit(handleFilter)}>
                        <div className="row">
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group pt-0">
                                    <label htmlFor="examName">Exam Name <span className="text-danger">*</span></label>
                                    <Select
                                        placeholder="Select Exam"
                                        value={examSetupValue}
                                        {...register('examName', { required: 'Exam Name is required' })}
                                        onChange={(selectOption) => {
                                            setExamSetupValue(selectOption);
                                            handleSelect('examName', selectOption);
                                        }}
                                        options={examSetupOption}
                                        className={errors.examName ? 'is-invalid' : ''}
                                    />
                                    {errors.examName && <div className="invalid-feedback">{errors.examName.message}</div>}
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3">
                                <div className="form-group pt-0">
                                    <label htmlFor="className">Class <span className='text-danger'>*</span></label>
                                    <Select
                                        {...register('className', { required: 'Class is required' })}
                                        value={classValue}
                                        onChange={(selectOption) => {
                                            setClassValue(selectOption);
                                            handleSelect('className', selectOption);
                                        }}
                                        options={classListOption}
                                        className={errors.className ? 'is-invalid' : ''}
                                    />
                                    {errors.className && <div className="invalid-feedback">{errors.className.message}</div>}
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3">
                                <div className="form-group pt-0">
                                    <label htmlFor="section">Section <span className='text-danger'>*</span></label>
                                    <Select
                                        {...register('section', { required: 'Section is required' })}
                                        value={sectionValue}
                                        onChange={(selectOption) => {
                                            setSectionValue(selectOption);
                                            handleSelect('section', selectOption);
                                        }}
                                        options={sectionOption}
                                        className={errors.section ? 'is-invalid' : ''}
                                        isDisabled={sectionOption?.length > 0 ? false : true}
                                    />
                                    {errors.section && <div className="invalid-feedback">{errors.section.message}</div>}
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3">
                                <div className="form-group pt-0">
                                    <label htmlFor="subject">Subject <span className='text-danger'>*</span></label>
                                    <Select
                                        placeholder="Select Subject"
                                        value={subjectValue}
                                        {...register('subject', { required: 'Subject is required' })}
                                        onChange={(selectOption) => {
                                            setSubjectValue(selectOption);
                                            handleSelect('subject', selectOption);
                                        }}
                                        options={subjectOption}
                                        className={errors.subject ? 'is-invalid' : ''}
                                        isDisabled={subjectOption?.length > 0 ? false : true}
                                    />
                                    {errors.subject && <div className="invalid-feedback">{errors.subject.message}</div>}
                                </div>
                            </div>
                        </div>

                        <div className="mr-2 text-right">
                            <button style={{ width: "80px" }} disabled={loader ? true : false} className="btn btn-secondary px-4 py-2 font-weight-bold">
                                Filter
                            </button>
                        </div>
                    </form>
                </div>
            </div>


            <div className="card">
                <div className="card-body">

                    <form onSubmit={handleSubmitForm2(handleSubmit2)}>
                        {studentExamAttendanceList?.length > 0 && examAttendance?.length > 0 && sectionValue?.value && subjectValue?.value ? (
                            <div style={{ overflowX: 'auto', width: '100%' }}>
                                <table
                                    style={{ minWidth: '1400px', borderCollapse: 'collapse', width: '100%' }}
                                    className="tableStyle"
                                >
                                    <thead>
                                        <tr>
                                            <th>Sl.</th>
                                            <th>Photo</th>
                                            <th>Name</th>
                                            <th>Register No</th>
                                            <th>Roll</th>
                                            <th>IsPresent</th>
                                            {markDistribution?.map(dist => (
                                                <th key={dist._id}>{dist?.distribution} ({dist?.fullMark}) </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {studentExamAttendanceList?.map((d, i) => (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td data-label="Photo">
                                                    <img
                                                        style={{ width: "35px", height: "35px", borderRadius: "50%" }}
                                                        src={d?.studentImage ? `${server_url}/${d?.studentImage}` : `${image_url}/assets/img/student/male.png`}
                                                        alt="Student"
                                                    />
                                                </td>
                                                <td data-label="Name">{d.basicDetails.fullName}</td>
                                                <td data-label="Register No">{d.basicDetails.enrollmentNumber}</td>
                                                <td data-label="Roll">{getActiveRoll(d.sessions)}</td>
                                                <td data-label="IsPresent">
                                                    {d.attendance ? (
                                                        <i className='fas fa-check text-success fa-lg' />
                                                    ) : (
                                                        <i className='fas fa-times text-danger fa-lg' />
                                                    )}
                                                </td>

                                                {/* Dynamic Distribution Marks Inputs */}
                                                {markDistribution?.map((dist) => (
                                                    <td key={dist._id}>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            disabled={!d.attendance} // Disable if attendance is false
                                                            {...registerForm2(`${dist.distribution}_${d._id}`, {
                                                                required: d.attendance ? `${dist.distribution} Marks are required` : false,
                                                                validate: (value) => {
                                                                    if (d.attendance && (value === "" || value === null || value === undefined)) {
                                                                        return `${dist.distribution} Marks are required`;
                                                                    }
                                                                    if (value <= 0) {
                                                                        return 'Marks must be greater than 0';
                                                                    }
                                                                    return true;
                                                                },
                                                            })}
                                                        />
                                                        {d.attendance && errorsForm2[`${dist.distribution}_${d._id}`] && (
                                                            <span style={{ fontSize: "10px" }} className="text-danger">
                                                                {errorsForm2[`${dist.distribution}_${d._id}`].message}
                                                            </span>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        ) : (
                            <Empty data={`Student Not Found!`} />
                        )}

                        <div className="mt-3 text-right">
                            <button
                                style={{ width: "80px" }}
                                disabled={loader}
                                className="btn btn-secondary px-4 py-2 font-weight-bold"
                                type="submit" // Specify type explicitly
                            >
                                {loader ? <ButtonLoader /> : 'Save'}
                            </button>
                        </div>
                    </form>

                </div>
            </div>

        </Layout>
    );
};


export default MarkEntries;
