import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import ShowExamSchedule from './ShowExamSchedule';
import AddExamSchedule from './AddExamSchedule';
import Layout from '../../../Layout/Layout';



const ExamSchedule = () => {
    const [tabIndex, setTabIndex] = useState(0);

    // Function to receive data from child
    const handleDataFromChild = (data) => {
        setTabIndex(data);
    };

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    return (
        <Layout>
            <div className='px-0 mb-4'>
                <div className='card mb-0'>
                    <Tabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        aria-label="teacher assignment tabs"
                        variant="fullWidth"
                        textColor="primary"
                        indicatorColor="primary"
                    >
                        <Tab label="Show Exam Schedule" />
                        <Tab label="Add Exam Schedule" />
                    </Tabs>
                </div>
            </div>
            <TabPanel value={tabIndex} index={0}>
                <ShowExamSchedule />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <AddExamSchedule sendDataToParent={handleDataFromChild} />
            </TabPanel>
        </Layout>
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}



export default ExamSchedule;