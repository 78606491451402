import React, { useState } from 'react';
import { Card, CardContent, Typography, Collapse } from '@mui/material';
import { styled } from '@mui/system';
import moment from 'moment';
import { FaUserPen } from "react-icons/fa6";
import { getActiveClassName, getActiveSession, getActiveRoll } from '../../../../utils/other/returnFunction';
import { image_url, server_url } from '../../../../api/api';



const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));


const StudentBasicDetails = ({ student, classList }) => {
    const [value, setValue] = useState(false);


    return (
        <div>
            <StyledCard onClick={() => setValue(!value)}>
                <div className='d-flex px-4 py-3 align-items-center' style={{ backgroundColor: "#e6e6e5", cursor: "pointer" }}>
                    <FaUserPen style={{ color: "#4f49a4", fontSize: "24px" }} />
                    <span className='px-2' style={{ fontSize: "16px", color: "#000", fontWeight: "600" }}>Personal Details</span>
                </div>
            </StyledCard>
            <Collapse in={value}>
                <StyledCard>

                    <div className='p-3'>

                        <div className="row">

                            <div className="col-lg-4">
                                <div>
                                    <img style={{ width: "100%", height: "330px", borderRadius: "4px" }} src={student?.studentImage ? `${server_url}/${student?.studentImage}` : `${image_url}/assets/img/student/male.png`} alt="Img" />
                                </div>

                            </div>

                            <div className="col-lg-8">

                                <div className="card mb-0">
                                    <div className="card-body">
                                        <ul className="nav nav-pills nav-secondary" id="pills-tab" role="tablist">
                                            <li className="nav-item submenu">
                                                <a className="nav-link active show" id="pills-home-tab" data-toggle="pill" href="#Basic" role="tab" aria-selected="true">Basic</a>
                                            </li>
                                            <li className="nav-item submenu">
                                                <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#Address" role="tab" aria-selected="false">Address</a>
                                            </li>
                                            <li className="nav-item submenu">
                                                <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#Guardian" role="tab" aria-selected="false">Guardian</a>
                                            </li>
                                            <li className="nav-item submenu">
                                                <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#Bank" role="tab" aria-selected="false">Bank</a>
                                            </li>
                                            <li className="nav-item submenu">
                                                <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#Medical" role="tab" aria-selected="false">Medical</a>
                                            </li>
                                            <li className="nav-item submenu">
                                                <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#Documents" role="tab" aria-selected="false">Documents</a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>


                                <div className='card mt-3'>
                                    <div className='card-body'>
                                        <div className="tab-content mt-2 mb-3" id="pills-tabContent">

                                            <div className="tab-pane fade active show" id="Basic" role="tabpanel">

                                                <div className="d-flex flex-row">
                                                    <div className='pl-4' style={{ width: "100%" }}>

                                                        {/* <div className='d-flex justify-content-between'>
                                                            <div>
                                                                <h3 style={{ fontSize: "22px" }}>{student?.basicDetails?.fullName}</h3>
                                                            </div>
                                                        </div> */}

                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Full Name :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.basicDetails?.fullName}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Email :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.basicDetails?.email}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Phone Number :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.basicDetails?.phoneNumber}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Class :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{getActiveClassName(classList, student?.sessions)}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Section :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{getActiveSession(student?.sessions)}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Roll :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{getActiveRoll(student?.sessions)}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Gender :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.basicDetails?.gender}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Religion :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.basicDetails?.religion}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Enrollment Number :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.basicDetails?.enrollmentNumber}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Date of Admission :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{moment(student?.basicDetails?.dateOfAdmission).format('DD, MMM, YYYY')}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Date of Birth :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{moment(student?.basicDetails?.dateOfBirth).format('DD, MMM, YYYY')}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Status :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.status}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="tab-pane fade" id="Address" role="tabpanel">
                                                <div className="d-flex flex-row">
                                                    <div className='pl-4' style={{ width: "100%" }}>

                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Country :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.addressDetails?.country}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>State :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.addressDetails?.state}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Pincode :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.addressDetails?.pincode}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>City :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.addressDetails?.city}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Full Address :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.addressDetails?.fullAddress}</p>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                            <div className="tab-pane fade" id="Guardian" role="tabpanel">
                                                <div className="d-flex flex-row">
                                                    <div className='pl-4' style={{ width: "100%" }}>

                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Father's Name :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.guardianDetails?.fatherName}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Mother's Name :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.guardianDetails?.motherName}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Guardian's Phone Number :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.guardianDetails?.guardianPhoneNumber}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Guardian's Occupation :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.guardianDetails?.guardianOccupation}</p>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                            <div className="tab-pane fade" id="Bank" role="tabpanel">
                                                <div className="d-flex flex-row">

                                                    <div className='pl-4' style={{ width: "100%" }}>

                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Bank Name :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.bankDetails?.bankName}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Branch Name :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.bankDetails?.branchName}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Account Holder Name :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.bankDetails?.accountHolderName}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Account Number :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.bankDetails?.accountNumber}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>IFSC Code :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.bankDetails?.ifscCode}</p>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="Medical" role="tabpanel">
                                                <div className="d-flex flex-row">

                                                    <div className='pl-4' style={{ width: "100%" }}>

                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Weight (IN K.G) :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.medicalDetails?.weight}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Height (IN CM) :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.medicalDetails?.height}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Allergies :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.medicalDetails?.allergies}</p>
                                                        </div>
                                                        <div className='d-flex border-bottom pb-1 mb-1'>
                                                            <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Blood Group :</p>
                                                            <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{student?.medicalDetails?.bloodGroup}</p>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="Documents" role="tabpanel">
                                                <h1>Comming......</h1>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>

                    </div>
                </StyledCard>
            </Collapse>
        </div>
    );
}



export default StudentBasicDetails;
