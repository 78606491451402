import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import { ButtonLoader, TableLoader } from '../../../../utils/Loader/LoaderComponent';
import { get_student_attendance } from '../../../../store/Reducers/attendance/studentAttendance';
import Empty from '../../../../utils/page/Empty';
import { getClassName } from '../../../../utils/other/returnFunction';
import { student_filter } from '../../../../store/Reducers/student/studentReducer';

const ShowStudentAttendance = () => {

    const dispatch = useDispatch();

    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();
    const { classList } = useSelector(state => state.administrator);
    const { sessionValue } = useSelector(state => state.default);
    const { allStudents } = useSelector(state => state.student);

    const { loader, successMessage, errorMessage, attendanceForMonth } = useSelector(state => state.studentAttendance);


    const [classListOption, setClassListOption] = useState("")
    const [sectionOption, setSectionOption] = useState("")
    const [studentOption, setStudentOption] = useState("")

    const [selecteeClassId, setSelecteeClassId] = useState('');


    const [classValue, setClassValue] = useState(null);
    const [sectionValue, setSectionValue] = useState(null);
    const [studentValue, setStudentValue] = useState(null);


    //  handleClassChange
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
        clearErrors(fieldName);

        if (fieldName === 'className') {
            setSelecteeClassId(selectOption.value);
            const defaultValue = { label: "Select", value: "" };

            setSectionValue(defaultValue);
            setStudentValue(defaultValue);
            setValue("section", defaultValue);
            setValue("student", defaultValue);

            const secOption = classList?.filter((item) => item._id === selectOption.value);
            const array = secOption[0]?.sectionNames;
            const convertedArray = array?.map(item => ({
                value: item,
                label: item
            }));
            setSectionOption(convertedArray)
        }

        if (fieldName === 'section') {
            setStudentValue({ value: "", label: "Select" })
            setValue("student", { label: '', value: '' })
            const obj = {
                sessionValue,
                className: selecteeClassId,
                section: selectOption.value
            }
            dispatch(student_filter(obj));
        }
    }


    // filterAttendance
    const filterAttendance = (data) => {
        const { className, section, date, student } = data;

        const obj = {
            sessionId: sessionValue,
            classId: className?.value,
            section: section?.value,
            studentId: student ? student?.value : '',
            date
        }
        dispatch(get_student_attendance(obj))
    }


    useEffect(() => {
        const options = classList?.map(item => ({
            value: item._id,
            label: item.className
        }));
        setClassListOption(options)
    }, [classList])


    useEffect(() => {
        const options = allStudents?.map(item => ({
            value: item._id,
            label: item.basicDetails.fullName
        }));
        setStudentOption(options)
    }, [allStudents])


    console.log("attendanceForMonth", attendanceForMonth)


    return (

        <>
            <div className="card mb-3">
                <div className="card-body py-">

                    <form onSubmit={handleSubmit(filterAttendance)}>

                        <div className="row">


                            <div className="col-md-6 col-lg-3">
                                <div className="form-group pt-0">
                                    <label htmlFor="date">Date<span className='text-danger'>*</span></label>
                                    <input
                                        type="month"
                                        className={`form-control ${errors.date ? 'is-invalid' : ''}`}
                                        id="date"
                                        {...register('date', { required: 'Date is required' })}
                                    />
                                    {errors.date && <div className="invalid-feedback">{errors.date.message}</div>}
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3">
                                <div className="form-group pt-0">
                                    <label htmlFor="className">Class <span className='text-danger'>*</span></label>
                                    <Select
                                        {...register('className', { required: 'Class is required' })}
                                        value={classValue}
                                        onChange={(selectOption) => {
                                            setClassValue(selectOption);
                                            handleSelect('className', selectOption);
                                        }}
                                        options={classListOption}
                                        className={errors.className ? 'is-invalid' : ''}
                                    />
                                    {errors.className && <div className="invalid-feedback">{errors.className.message}</div>}
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3">
                                <div className="form-group pt-0">
                                    <label htmlFor="section">Section <span className='text-danger'>*</span></label>
                                    <Select
                                        {...register('section', { required: 'Section is required' })}
                                        value={sectionValue}
                                        onChange={(selectOption) => {
                                            setSectionValue(selectOption);
                                            handleSelect('section', selectOption);
                                        }}
                                        options={sectionOption}
                                        className={errors.section ? 'is-invalid' : ''}
                                        isDisabled={sectionOption?.length > 0 ? false : true}
                                    />
                                    {errors.section && <div className="invalid-feedback">{errors.section.message}</div>}
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3">
                                <div className="form-group pt-0">
                                    <label htmlFor="student">Student</label>
                                    <Select
                                        {...register('student')}
                                        value={studentValue}
                                        onChange={(selectOption) => {
                                            setStudentValue(selectOption);
                                            handleSelect('student', selectOption);
                                        }}
                                        options={studentOption}
                                        isDisabled={studentOption?.length > 0 && sectionValue?.value ? false : true}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="mr-2 text-right">

                            <button style={{ width: "80px" }} disabled={loader ? true : false} className="btn btn-secondary px-4 py-2 font-weight-bold">
                                {
                                    loader ? <ButtonLoader /> : 'Filter'
                                }
                            </button>
                        </div>

                    </form>

                </div>
            </div>

            <div className="card">

                <div className="card-body" style={{ padding: "20px", overflowX: "auto" }}>

                    {loader ? (
                        <TableLoader /> // Show loader while data is loading
                    ) : attendanceForMonth?.length > 0 ? (
                        <div style={{ overflowX: 'auto' }}>
                            <h4 style={{ fontSize: '19px', fontWeight: '600' }} className='py-2'>
                                Attendance Sheet of Class {getClassName(classList, attendanceForMonth[0]?.classId)}: Section {attendanceForMonth[0]?.section},{' '}
                                {moment(attendanceForMonth[0]?.date, 'YYYY-MM').format('MMMM YYYY')}
                            </h4>

                            <table
                                style={{
                                    width: '100%',
                                    borderCollapse: 'collapse',
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    border: '1px solid #ddd',
                                    minWidth: '1500px',
                                }}
                            >
                                <thead>
                                    <tr style={{ backgroundColor: '#f8f9fa' }}>
                                        <th style={{ padding: '10px', border: '1px solid #ddd' }} rowSpan="2">
                                            <span style={{ fontSize: '16px', fontWeight: '600' }}>Students</span>
                                        </th>
                                        {attendanceForMonth[0]?.attendance?.map((_, i) => (
                                            <th key={i} style={{ padding: '10px', border: '1px solid #ddd' }} rowSpan="2">
                                                {i + 1}
                                            </th>
                                        ))}
                                        <th style={{ padding: '10px', border: '1px solid #ddd' }} colSpan="2">Total</th>
                                    </tr>
                                    <tr style={{ backgroundColor: '#f8f9fa' }}>
                                        <th style={{ padding: '10px', border: '1px solid #ddd' }}>Present</th>
                                        <th style={{ padding: '10px', border: '1px solid #ddd' }}>Absent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {attendanceForMonth?.map((studentData, i) => {
                                        return (
                                            <tr key={i} style={{ backgroundColor: i % 2 === 0 ? '#f2f2f2' : '#ffffff' }}>
                                                <td style={{ padding: '10px', border: '1px solid #ddd', fontWeight: 'bold' }}>
                                                    {studentData?.studentName}
                                                </td>
                                                {studentData.attendance?.map((dayAttendance, j) => (
                                                    <td key={j} style={{ padding: '10px', border: '1px solid #ddd' }}>
                                                        {dayAttendance.attendance === 'Present' ? (
                                                            <i className='fas fa-check text-success fa-lg' />
                                                        ) : dayAttendance.attendance === 'Absent' ? (
                                                            <i className='fas fa-times text-danger fa-lg' />
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </td>
                                                ))}
                                                <td style={{ padding: '10px', border: '1px solid #ddd', fontWeight: 'bold' }}>
                                                    {studentData?.presentCount}
                                                </td>
                                                <td style={{ padding: '10px', border: '1px solid #ddd', fontWeight: 'bold' }}>
                                                    {studentData?.absentCount}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <Empty data="Attendance List Not Found!" />
                    )}

                </div>
            </div>

        </>

    )
}


export default ShowStudentAttendance;