import React, { useEffect, useState } from 'react';
import Layout from '../../Layout/Layout';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { TableLoader } from '../../../utils/Loader/LoaderComponent';
import Empty from '../../../utils/page/Empty';
import { get_all_administrator, update_administrator_status, messageClear, delete_administrator } from '../../../store/Reducers/organisation/organizationReducer';
import Pagination from '../../Layout/Pagination';
import { MdDeleteForever } from "react-icons/md";
import { confirmMessagge } from '../../../utils/other/aleartFunc';



const AllAdministrator = () => {

    const dispatch = useDispatch();

    const { allAdministrator, loader, administratorCount, successMessage, errorMessage } = useSelector(state => state.organization);
    const { searchValue } = useSelector(state => state.default);

    const [currentPage, setCurrentPage] = useState(1)
    const [parPage, setParPage] = useState(20)



    // handleStatus
    const handleStatus = (value, statusId) => {
        const obj = {
            statusId,
            value
        }
        dispatch(update_administrator_status(obj))
    }


    // handleClassDelete
    const handleClassDelete = async (deleteId) => {
        const returnValue = await confirmMessagge('Delete');
        if (returnValue) {
            dispatch(delete_administrator(deleteId))
        }
    }



    // handleItemPerPageChange
    const handleItemPerPageChange = (e) => {
        setParPage(e.target.value)
    }

    useEffect(() => {
        const obj = {
            parPage: parseInt(parPage),
            page: parseInt(currentPage),
            searchValue
        }
        dispatch(get_all_administrator(obj))
    }, [searchValue, currentPage, parPage])


    useEffect(() => {
        const obj = {
            parPage: parseInt(parPage),
            page: parseInt(currentPage),
            searchValue
        }

        if (errorMessage) {
            toast.error(errorMessage)
            dispatch(messageClear())
        }
        if (successMessage) {
            toast.success(successMessage)
            dispatch(messageClear())
            dispatch(get_all_administrator(obj))
        }
    }, [errorMessage, successMessage])



    return (
        <Layout>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">

                            <div className="page-header mb-0">
                                <span style={{ fontSize: "18px", padding: "0px 8px 8px 0px" }}>
                                    <i className="fas fa-list" />
                                </span>
                                <h4 style={{ fontSize: "20px", marginBottom: "9px" }}> Administrator List</h4>

                                <Link to='/add-administrator' className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                    <span><i className="fa fa-plus pr-2" />Add</span>
                                </Link>
                            </div>

                            <hr className='my-3' />

                            <div>
                                {loader ? (
                                    <TableLoader />
                                ) : allAdministrator?.length > 0 ? (
                                    // Render table if data exists
                                    <table className="tableStyle">
                                        <thead>
                                            <tr>
                                                <th>Sl.</th>
                                                <th>Full Name</th>
                                                <th>Email</th>
                                                <th>Phone Number</th>
                                                <th>Password</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allAdministrator?.map((d, i) => (
                                                <tr key={i}>
                                                    <td data-lable="Sl">{i + 1 + (currentPage - 1) * parPage}</td>
                                                    <td data-lable="Full Name">{`${d.firstName} ${d.lastName}`}</td>
                                                    <td data-lable="Email">{d.email}</td>
                                                    <td data-lable="Phone Number">{d.phoneNumber}</td>
                                                    <td data-lable="Password">{d.password}</td>
                                                    <td data-lable="Status">
                                                        <select
                                                            onChange={(e) => handleStatus(e.target.value, d._id)}
                                                            className={`form-select p-1 px-2 text-${d.status === 'Approved' ? 'success' : 'danger'}`}
                                                            style={{ width: "95%", fontWeight: "600", fontSize: "14px" }}
                                                        >
                                                            <option
                                                                selected={d.status === 'Approved'}
                                                                className="text-success"
                                                                value="Approved"
                                                            >
                                                                Approved
                                                            </option>
                                                            <option
                                                                selected={d.status === 'Blocked'}
                                                                className="text-danger"
                                                                value="Blocked"
                                                            >
                                                                Blocked
                                                            </option>
                                                        </select>
                                                    </td>
                                                    <td data-lable="Action">
                                                        <span title="Delete" className="ml-1" onClick={() => handleClassDelete(d._id)}>
                                                            <MdDeleteForever style={{ fontSize: "24px", color: "red" }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    // Render empty state when no data is available
                                    <Empty />
                                )}
                            </div>


                        </div>

                        {
                            administratorCount > 20 && <div class="d-flex justify-content-between">
                                <div className="col-md-4 d-flex justify-content-start align-items-center itemParPageStyle">
                                    <div class="form-group  d-flex">
                                        <label for="itemPerPage" class="col-sm-auto col-form-label">Select items per page :</label>
                                        <div class="col-sm-auto">
                                            <select
                                                id='itemPerPage'
                                                style={{ width: "65px", marginLeft: "-23px", marginTop: "7px", padding: "3px" }}
                                                onChange={handleItemPerPageChange}
                                                value={parPage}
                                            >
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="5000">All</option>

                                            </select>
                                        </div>
                                    </div>


                                </div>

                                <div className='px-4 pt-2'>
                                    {
                                        administratorCount >= parPage ?
                                            <Pagination
                                                pageNumber={currentPage}
                                                setPageNumber={setCurrentPage}
                                                totalItem={administratorCount}
                                                parPage={parPage}
                                                showItem={Math.floor(administratorCount / parPage)}
                                            /> : ''
                                    }
                                </div>

                            </div>
                        }

                    </div>

                </div>

            </div>


        </Layout>
    )
}


export default AllAdministrator;