

export const buttonLoaderCss = {
    display: 'flex',
    margin: '0 auto',
    height: '24px',
    justifyContent: 'center',
    alignItems: "center"
}


export const hashLoaderCss = {
    display: 'flex',
    margin: '0 auto',
    height: '70vh',
    justifyContent: 'center',
    alignItems: "center"
}
