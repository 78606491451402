import React, { useEffect, useState } from 'react';
import Layout from '../../Layout/Layout';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { ButtonLoader, TableLoader } from '../../../utils/Loader/LoaderComponent';
import { examTypeList } from '../../../utils/other/data';
import { getDistributionName, getExamTermName, useOptionsFromData } from '../../../utils/other/returnFunction';
import { FaListUl, FaRegCopy, FaRegFilePdf } from 'react-icons/fa';
import { RiFileExcelLine } from "react-icons/ri";
import toast from 'react-hot-toast';
import Empty from '../../../utils/page/Empty';
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { confirmMessagge } from '../../../utils/other/aleartFunc';
import moment from 'moment';
import Pagination from '../../Layout/Pagination';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { IoIosEye } from "react-icons/io";
import { FiPrinter } from "react-icons/fi";
import { add_exam_setup, delete_exam_setup, get_exam_setup, get_single_exam_setup, messageClear, update_exam_publish_stauts, update_exam_setup } from '../../../store/Reducers/examMaster/examReducer';



const ExamSetup = () => {

    const dispatch = useDispatch();
    const { examTermList, distributionList, loader, successMessage, errorMessage, examSetupList, examSetupCount, singleExamSetup } = useSelector(state => state.exam);
    const { searchValue } = useSelector(state => state.default);
    const { sessionValue } = useSelector(state => state.default);


    const { handleSubmit, setValue, register, formState: { errors }, reset } = useForm();


    const [currentPage, setCurrentPage] = useState(1)
    const [parPage, setParPage] = useState(10)

    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState();
    const [checked, setChecked] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);
    const [remarkData, setRemarkData] = useState('');

    // handleDialogClose
    const handleDialogClose = () => {
        setOpenDialog(false)
    }



    const [distributionOption, setDistributionOption] = useState([]);

    const [examTermOption, setExamTermOption] = useState([]);
    const examTypeOption = useOptionsFromData(examTypeList?.examType);
    const [examTermValue, setExamTermValue] = useState(null);
    const [examTypeValue, setExamTypeValue] = useState(null);
    const [distributionValue, setDistributionValue] = useState([]);

    useEffect(() => {
        if (examTermList) {
            const options = examTermList.map(item => ({
                value: item._id,
                label: item.termName
            }));
            setExamTermOption(options);
        }
    }, [examTermList]);

    useEffect(() => {
        if (distributionList) {
            const options = distributionList.map(item => ({
                value: item._id,
                label: item.distribution
            }));
            setDistributionOption(options);
        }
    }, [distributionList]);

    const handleOpenDrawer = () => {
        setOpen(true);
    };

    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
    };

    const handlePublishChange = (e) => {
        setChecked(e.target.checked);
    };


    // onSubmit
    const onSubmit = (data) => {
        const { examName, termName, examType, distribution, remark } = data;

        const obj = {
            sessionId: sessionValue,
            examName,
            examTermId: termName?.value || '',
            examTypeName: examType?.value || '',
            markDistributionId: distribution?.map(option => option.value),
            remark,
            isPublish: checked
        };

        if (editId) {
            dispatch(update_exam_setup({ editId, obj }))
        } else {
            dispatch(add_exam_setup(obj))
        }

    };


    // handleItemPerPageChange
    const handleItemPerPageChange = (e) => {
        setParPage(e.target.value)
    }


    //  handleExamSetupEdit
    const handleExamSetupEdit = (editId) => {
        setEditId(editId)
        setOpen(true)
        dispatch(get_single_exam_setup(editId))
    }


    // handleExamSetupDelete
    const handleExamSetupDelete = async (deleteId) => {
        const returnValue = await confirmMessagge('Delete');
        if (returnValue) {
            dispatch(delete_exam_setup(deleteId))
        }
    }


    // publishStausChange
    const publishStausChange = (event, id) => {
        const { checked } = event.target;
        // const updatedExamSetup = examSetupList?.map(setup =>
        //     setup._id === id ? { ...setup, isPublish: checked } : setup
        // );

        // dispatch(setExamSetupList(updatedExamSetup))

        const obj = {
            examId: id,
            value: checked
        }

        dispatch(update_exam_publish_stauts(obj))


    };



    useEffect(() => {

        if (Object.keys(singleExamSetup)?.length > 0 && editId) {

            setValue('examName', singleExamSetup.examName);

            setExamTermValue({
                label: examTermList?.find((d) => d._id === singleExamSetup.examTermId)?.termName,
                value: singleExamSetup.examTermId
            });
            setValue("termName", {
                label: examTermList?.find((d) => d._id === singleExamSetup.examTermId)?.termName,
                value: singleExamSetup.examTermId
            });

            setExamTypeValue({
                label: singleExamSetup.examTypeName,
                value: singleExamSetup.examTypeName
            })
            setValue("examType", {
                label: singleExamSetup?.examTypeName,
                value: singleExamSetup?.examTypeName
            });

            const convertedArray = singleExamSetup?.markDistributionId?.map(item => ({
                value: item,
                label: distributionList?.find((d) => d._id === item)?.distribution,
            }));

            setDistributionValue(convertedArray)
            setValue("distribution", convertedArray)

            setValue('remark', singleExamSetup.remark);

        } else {

            reset({
                examName: '',
                termName: '',
                examType: '',
                remark: '',
            });
            setExamTermValue([])
            setExamTypeValue([])
            setDistributionValue([])
            setValue("distribution", [])
        }

    }, [singleExamSetup, setValue, editId])


    useEffect(() => {
        const obj = {
            parPage: parseInt(parPage),
            page: parseInt(currentPage),
            searchValue,
            sessionValue
        }
        dispatch(get_exam_setup(obj))
    }, [searchValue, currentPage, parPage, sessionValue, successMessage])



    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage);
            dispatch(messageClear());
        }
        if (successMessage) {
            toast.success(successMessage);
            dispatch(messageClear());
            setOpen(false)
        }
    }, [errorMessage, successMessage]);


    // console.log("examSetupList", examSetupList)



    return (
        <Layout>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="page-header mb-0">
                                <span style={{ fontSize: "18px", padding: "0px 8px 7px 0px" }}>
                                    <i className="fas fa-list" />
                                </span>
                                <h4 style={{ fontSize: "19px" }}>Exam List</h4>
                                <span className='px-4'>
                                    <FaRegCopy title='Copy' style={{ fontSize: '20px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <RiFileExcelLine title='Excel' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FaRegFilePdf title='PDF' style={{ fontSize: '18px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FiPrinter title='Print' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                </span>
                                <button onClick={() => { handleOpenDrawer(); setEditId(''); }} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                    <span><i className="fa fa-plus pr-2" />Add</span>
                                </button>
                            </div>
                            <hr className='my-3' />

                            <div className='mt-3'>

                                {loader ? (
                                    // Display loader while data is being fetched
                                    <TableLoader />
                                ) : examSetupList?.length > 0 ? (
                                    // Render table if data exists
                                    <table className="tableStyle">
                                        <thead className=''>
                                            <tr>
                                                <th>Sl.</th>
                                                <th>Exam Name</th>
                                                <th>Exam Type</th>
                                                <th>Term</th>
                                                <th>Mark Distribution</th>
                                                <th>Publish</th>
                                                <th>Remark</th>
                                                <th className='no-print'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className=''>
                                            {examSetupList?.map((d, i) => (
                                                <tr key={i}>
                                                    <td data-lable="Sl">{i + 1 + (currentPage - 1) * parPage}</td>
                                                    <td data-lable="Exam Name">{d.examName}</td>
                                                    <td data-lable="Exam Type">{d.examTypeName || 'N/A'}</td>
                                                    <td data-lable="Term">{getExamTermName(examTermList, d.examTermId) || 'N/A'}</td>

                                                    <td data-label="Mark Distribution">
                                                        {
                                                            d.markDistributionId?.map((name, index) => {
                                                               return <span key={index}>
                                                                    {`- ${name.distribution}`}
                                                                    <br />
                                                                </span>
                                                            })
                                                        }
                                                    </td>

                                                    <td data-lable="Publish">
                                                        <Switch checked={d.isPublish} onChange={(e) => publishStausChange(e, d._id)} name="publish" />
                                                    </td>
                                                    <td data-lable="Exam Name">
                                                        <span title='View' className='mr-1' onClick={() => { setOpenDialog(true); setRemarkData(d.remark); }}>
                                                            <IoIosEye style={{ fontSize: '24px', color: 'blue', marginTop: "3px", cursor: "pointer" }} />
                                                        </span>
                                                    </td>
                                                    <td data-lable="Action" className='no-print'>
                                                        <span title='Edit' className='mr-1' onClick={() => handleExamSetupEdit(d._id)}>
                                                            <FaEdit style={{ fontSize: '20px', color: 'green' }} />
                                                        </span>
                                                        <span title='Delete' className='ml-1' onClick={() => handleExamSetupDelete(d._id)}>
                                                            <MdDeleteForever style={{ fontSize: '24px', color: 'red' }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    // Display empty state when classList is empty
                                    <Empty data={`Exam Setup Not Found !`} />
                                )}

                            </div>

                        </div>

                        {
                            examSetupCount > 10 && <div class="d-flex justify-content-between">
                                <div className="col-md-4 d-flex justify-content-start align-items-center itemParPageStyle">
                                    <div class="d-flex mb-2">
                                        <label for="itemPerPage" class="col-sm-auto col-form-label">Select items per page :</label>
                                        <div class="col-sm-auto">
                                            <select
                                                id='itemPerPage'
                                                style={{ width: "65px", marginLeft: "-23px", marginTop: "7px", padding: "3px" }}
                                                onChange={handleItemPerPageChange}
                                                value={parPage}
                                            >
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="5000">All</option>

                                            </select>
                                        </div>
                                    </div>

                                </div>

                                <div className='px-4 pt-2'>
                                    {
                                        examSetupCount >= parPage ?
                                            <Pagination
                                                pageNumber={currentPage}
                                                setPageNumber={setCurrentPage}
                                                totalItem={examSetupCount}
                                                parPage={parPage}
                                                showItem={Math.floor(examSetupCount / parPage)}
                                            /> : ''
                                    }
                                </div>

                            </div>
                        }

                    </div>
                </div>
            </div>

            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <div style={{ width: '550px' }}>
                    <DialogTitle style={{ fontSize: '20px' }}>{editId ? 'Edit Exam' : 'Create Exam'}</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <div className='px-2'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <label htmlFor="examName">Exam Name <span className='text-danger'>*</span></label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.examName ? 'is-invalid' : ''}`}
                                    id="examName"
                                    {...register('examName', { required: 'Exam name is required' })}
                                />
                                {errors.examName && <div className="invalid-feedback">{errors.examName.message}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="termName">Exam Term</label>
                                <Select
                                    {...register('termName')}
                                    value={examTermValue}
                                    onChange={(selectOption) => {
                                        setExamTermValue(selectOption);
                                        handleSelect('termName', selectOption);
                                    }}
                                    options={examTermOption}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="examType">Exam Type</label>
                                <Select
                                    {...register('examType')}
                                    value={examTypeValue}
                                    onChange={(selectOption) => {
                                        setExamTypeValue(selectOption);
                                        handleSelect('examType', selectOption);
                                    }}
                                    options={examTypeOption}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="distribution">Mark Distribution</label>
                                <Select
                                    {...register('distribution')}
                                    value={distributionValue}
                                    onChange={(selectOption) => {
                                        setDistributionValue(selectOption);
                                        handleSelect('distribution', selectOption);
                                    }}
                                    options={distributionOption}
                                    isMulti // Enable multiple selection
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="remark">Remark</label>
                                <textarea
                                    className="form-control"
                                    id="remark"
                                    style={{ width: "100%" }}
                                    rows={3}
                                    {...register('remark')}
                                ></textarea>
                            </div>

                            {
                                !editId && <div className="form-group">
                                    <label htmlFor="publish" className='pr-2'>Publish</label>
                                    <Switch checked={checked} onChange={handlePublishChange} />
                                </div>
                            }

                            <div className="my-3 mx-2" style={{ textAlign: "right" }}>
                                <button disabled={loader} type="submit" style={{ width: "90px" }} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                    {loader ? <ButtonLoader /> : (editId ? 'Edit' : 'Add')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Drawer>


            <Dialog open={openDialog} onClose={handleDialogClose}>
                <div style={{ width: "500px" }}>
                    <DialogTitle>Remark</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenDialog(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        <Typography>
                            {remarkData || ''}
                        </Typography>
                    </DialogContent>
                    <DialogActions>

                    </DialogActions>
                </div>
            </Dialog>
        </Layout>
    );
};



export default ExamSetup;
