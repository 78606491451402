import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

const Textt = () => {
  const [items, setItems] = useState(Array.from({ length: 20 }));
  const [hasMore, setHasMore] = useState(true);

  // Function to fetch more data
  const fetchMoreData = () => {
    if (items.length >= 200) { // For example, we limit it to 100 items
      setHasMore(false);
      return;
    }

    // Simulate a fetch request with a timeout
    setTimeout(() => {
      setItems(items.concat(Array.from({ length: 20 }))); // Add 20 more items
    }, 700);
  };

  return (
    <div>
      <h1>Infinite Scroll Example</h1>
      <InfiniteScroll
        dataLength={items.length} // This is important to track the scroll
        next={fetchMoreData} // Function to fetch more data
        hasMore={hasMore} // Whether there is more data to load
        loader={<h4>Loading...</h4>} // Loader component while data is being fetched
        endMessage={ // Message to display when all data is loaded
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        {items.map((_, index) => (
          <div key={index}>
            Item #{index + 1}
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};


export default Textt