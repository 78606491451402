import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';


// admission_student
export const admission_student = createAsyncThunk(
    'student/admission_student',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/admission-student', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_all_student
export const get_all_student = createAsyncThunk(
    'student/get_all_student',
    async ({ searchValue, page, parPage, sessionValue }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-all-student?searchValue=${searchValue}&&page=${page}&&parPage=${parPage}&&sessionValue=${sessionValue}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_single_student
export const get_single_student = createAsyncThunk(
    'student/get_single_student',
    async ({ studentId }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-single-student/${studentId}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// delete_student
export const delete_student = createAsyncThunk(
    'student/delete_student',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.delete(`/delete-student/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// admission_student_update
export const admission_student_update = createAsyncThunk(
    'student/admission_student_update',
    async ({ studentId, dataa }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/admission-student-update/${studentId}`, dataa);
            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// update_student_status
export const update_student_status = createAsyncThunk(
    'student/update_student_status',
    async ({ statusId, value }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/update-student-status/${statusId}`, { value })
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// student_filter
export const student_filter = createAsyncThunk(
    'student/student_filter',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post(`/student-filter`, info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// student_promotion
export const student_promotion = createAsyncThunk(
    'student/student_promotion',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post(`/student-promotion`, info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// authenticate_student
export const authenticate_student = createAsyncThunk(
    'student/authenticate_student',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/authenticate-student', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// resend_student_password
export const resend_student_password = createAsyncThunk(
    'student/resend_student_password',
    async ({ studentId, password }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/resend-student-password/${studentId}`, { password })
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// update_student_profile
export const update_student_profile = createAsyncThunk(
    'student/update_student_profile',
    async ({ studentId, formData }, { rejectWithValue, fulfillWithValue }) => {

        try {
            const { data } = await api.post(`/update-student-profile/${studentId}`, formData)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// import_students
export const import_students = createAsyncThunk(
    'student/import_students',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post(`/import-students`, info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const studentReducer = createSlice({
    name: "student",
    initialState: {
        errorMessage: '',
        successMessage: '',
        loader: false,
        allStudents: [],
        authStudents: [],
        studentCount: 0,
        student: {},
        studentExamAttendanceList: [],
    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ''
            state.successMessage = ''
        },
        studentClear: (state, _) => {
            state.student = {}
        },
        student_filter_by_subject: (state, { payload }) => {
            state.studentExamAttendanceList = payload
        }
    },
    extraReducers: {
        [admission_student.pending]: (state, _) => {
            state.loader = true
        },
        [admission_student.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [admission_student.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [get_all_student.pending]: (state, _) => {
            state.loader = true
        },
        [get_all_student.fulfilled]: (state, { payload }) => {
            const filterAuthStudent = payload?.allStudents?.filter((d) => d.isAuthenticate === true);
            state.loader = false
            state.allStudents = payload.allStudents
            state.studentCount = payload.studentCount
            state.authStudents = filterAuthStudent
        },
        [get_single_student.fulfilled]: (state, { payload }) => {
            state.student = payload.student
        },
        [delete_student.pending]: (state, _) => {
            state.loader = true
        },
        [delete_student.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [delete_student.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [admission_student_update.pending]: (state, _) => {
            state.loader = true
        },
        [admission_student_update.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [admission_student_update.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [update_student_status.pending]: (state, _) => {
            state.loader = true
        },
        [update_student_status.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [update_student_status.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [student_filter.pending]: (state, _) => {
            state.loader = true
        },
        [student_filter.fulfilled]: (state, { payload }) => {
            const filterAuthStudent = payload?.allStudents?.filter((d) => d.isAuthenticate === true);
            state.loader = false
            state.allStudents = payload.allStudents
            state.studentCount = payload.studentCount
            state.authStudents = filterAuthStudent
        },
        [student_promotion.pending]: (state, _) => {
            state.loader = true
        },
        [student_promotion.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [student_promotion.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [authenticate_student.pending]: (state, _) => {
            state.loader = true
        },
        [authenticate_student.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [authenticate_student.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [resend_student_password.pending]: (state, _) => {
            state.loader = true
        },
        [resend_student_password.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [resend_student_password.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [update_student_profile.pending]: (state, _) => {
            state.loader = true
        },
        [update_student_profile.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [update_student_profile.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [import_students.pending]: (state, _) => {
            state.loader = true
        },
        [import_students.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [import_students.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        }
    }
});


export const { messageClear, studentClear, student_filter_by_subject } = studentReducer.actions;
export default studentReducer.reducer;