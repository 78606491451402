import React, { useEffect, useState } from 'react';
import Layout from '../../Layout/Layout';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ButtonLoader } from '../../../utils/Loader/LoaderComponent';
import Select from 'react-select';
import { countries, indiaStates, organizationType } from '../../../utils/other/data';
import { useDispatch, useSelector } from 'react-redux';
import { add_organisation, messageClear } from '../../../store/Reducers/organisation/orgWonerReducer';
import toast from 'react-hot-toast';
import { BsImage } from 'react-icons/bs';
import { server_url } from '../../../api/api';
import { useOptionsFromData } from '../../../utils/other/returnFunction';


const AddOrganization = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { errorMessage, loader, successMessage, headOrgList, headOrgCount } = useSelector(state => state.org_woner);

    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors } = useForm();

    const [countryData, setCountryeData] = useState("")
    const [stateDate, setStateData] = useState("")
    const [isState, setIstate] = useState(false)


    const [imageShow, setImage] = useState('');
    const [orgImage, setOrgImage] = useState('');

    const organizationTypeList = useOptionsFromData(organizationType?.organizations);

    const [headOrgOption, setHeadOrgOption] = useState("")

    const [organizationTypeValue, setOrganizationTypeValue] = useState(null);
    const [headOrgOptionValue, setHeadOrgOptionValue] = useState(null);


    // imageHandle
    const imageHandle = (e) => {
        let files = e.target.files;
        if (files?.length > 0) {
            setImage(URL.createObjectURL(files[0]))
            setOrgImage(e.target.files[0])
        }

    }


    //  handleSelect
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
        clearErrors(fieldName);
    }


    // onSubmit
    const onSubmit = (data) => {
        const { organizationType, headOrganization, organizationName, registrationNumber, mailingName, panNumber, financialYearBegins, booksBegins, email, phoneNumber, country, state, city, address, pincode } = data;


        const formData = new FormData();

        // Manually append each field, one by one
        formData.append('orgImage', orgImage);
        formData.append('organizationType', organizationType?.value);
        formData.append('headOrganization', headOrganization?.value);
        formData.append('mailingName', mailingName);
        formData.append('panNumber', panNumber);
        formData.append('financialYearBegins', financialYearBegins);
        formData.append('booksBegins', booksBegins);
        formData.append('registrationNumber', registrationNumber);
        formData.append('organizationName', organizationName);
        formData.append('email', email);
        formData.append('phoneNumber', phoneNumber);
        formData.append('country', country?.value);
        formData.append('state', isState === true ? state?.value : state);
        formData.append('city', city);
        formData.append('address', address);
        formData.append('pincode', pincode);


        // Now formData contains all the fields, and you can use it for submitting

        dispatch(add_organisation(formData))

    }


    // handleCountryChange
    const handleCountryChange = (selectedOption) => {
        if (selectedOption.value === 'India') {
            setIstate(true)
        } else {
            setIstate(false)
        }

        setValue('country', selectedOption);
        clearErrors('country');
    };

    // handleStateChange
    const handleStateChange = (selectedOption) => {
        setValue('state', selectedOption);
        clearErrors('state');
    };

    useEffect(() => {
        const countryeOption = countries?.countries.map(item => ({
            value: item.name,
            label: item.name,
        }));
        setCountryeData(countryeOption);
        const stateOption = indiaStates?.states.map(item => ({
            value: item.name,
            label: item.name,
        }));
        setStateData(stateOption);
    }, []);


    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage)
            dispatch(messageClear())
        }
        if (successMessage) {
            toast.success(successMessage)
            dispatch(messageClear())
            navigate('/all-organisation', { replace: true })
        }
    }, [errorMessage, successMessage])


    useEffect(() => {
        const options = headOrgList?.map(item => ({
            value: item._id,
            label: item.headOrg
        }));

        setHeadOrgOption(options)
    }, [headOrgList])


    return (
        <Layout>


            <div className="row">
                <div className="col-md-12">
                    <div className="card">

                        <div className="card-body">

                            <div className="page-header mb-0">
                                <span style={{ fontSize: "18px", padding: "0px 8px 8px 0px" }}>
                                    <i className="fas fa-edit" />
                                </span>
                                <h4 style={{ fontSize: "19px" }}>Add Organization</h4>

                                <Link to='/all-organisation' className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                    <span><i className="far fa-list-alt pr-2" />All</span>
                                </Link>
                            </div>

                            <hr className='my-3' />

                            <form onSubmit={handleSubmit(onSubmit)}>

                                <div className="row">


                                    <div className="col-lg-3">
                                        <div className='mt-2'>
                                            <label style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '230px', cursor: 'pointer', border: '1px dashed #d0d2d6', ':hover': { border: '1px solid #6610f2' }, width: '100%', borderColor: '#d0d2d6' }} htmlFor="image">
                                                {
                                                    false && !imageShow ? <div className="studentProfile">
                                                        <img style={{ width: "100%" }} alt="Student" />
                                                    </div> : <>
                                                        {
                                                            imageShow ? <img style={{ width: '100%', height: '100%' }} src={imageShow} alt="Selected Image" />
                                                                : <>
                                                                    <span style={{ fontSize: '24px' }}><BsImage /></span>
                                                                    <span style={{ fontSize: '16px' }}>Select Logo</span>
                                                                </>
                                                        }
                                                    </>
                                                }

                                            </label>

                                            <input onChange={imageHandle} style={{ display: 'none' }} type="file" accept="image/*" name="image" id="image" />

                                        </div>
                                    </div>


                                    <div className="col-lg-9">
                                        <div className="row">

                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="organizationName">Organization Type <span className='text-danger'>*</span></label>
                                                    <Select
                                                        {...register('organizationType', { required: 'Organization type is required' })}
                                                        value={organizationTypeValue}
                                                        onChange={(selectOption) => {
                                                            setOrganizationTypeValue(selectOption);
                                                            handleSelect('organizationType', selectOption);
                                                        }}
                                                        options={organizationTypeList}
                                                        className={errors.organizationType ? 'is-invalid' : ''}
                                                    />
                                                    {errors.organizationType && <div className="invalid-feedback">{errors.organizationType.message}</div>}
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="headOrganization">Head Organization<span className='text-danger'>*</span></label>
                                                    <Select
                                                        {...register('headOrganization', { required: 'Head organization is required' })}
                                                        value={headOrgOptionValue}
                                                        onChange={(selectOption) => {
                                                            setHeadOrgOptionValue(selectOption);
                                                            handleSelect('headOrganization', selectOption);
                                                        }}
                                                        options={headOrgOption}
                                                        className={errors.headOrganization ? 'is-invalid' : ''}
                                                    />
                                                    {errors.headOrganization && <div className="invalid-feedback">{errors.headOrganization.message}</div>}
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="organizationName">Organization Name <span className='text-danger'>*</span></label>
                                                    <input type="text" className={`form-control ${errors.organizationName ? 'is-invalid' : ''}`} id="organizationName" {...register('organizationName', { required: 'Organization Name is required' })} />
                                                    {errors.organizationName && <div className="invalid-feedback">{errors.organizationName.message}</div>}
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="registrationNumber">Registration Number <span className='text-danger'>*</span></label>
                                                    <input type="text" className={`form-control ${errors.registrationNumber ? 'is-invalid' : ''}`} id="registrationNumber" {...register('registrationNumber', { required: 'Registration Number is required' })} />
                                                    {errors.registrationNumber && <div className="invalid-feedback">{errors.registrationNumber.message}</div>}
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="mailingName">Mailing Name<span className='text-danger'>*</span></label>
                                                    <input type="text" className={`form-control ${errors.mailingName ? 'is-invalid' : ''}`} id="mailingName" {...register('mailingName', { required: 'Mailing Name is required' })} />
                                                    {errors.mailingName && <div className="invalid-feedback">{errors.mailingName.message}</div>}
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="panNumber">
                                                        PAN Number<span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.panNumber ? 'is-invalid' : ''}`}
                                                        id="panNumber"
                                                        {...register('panNumber', {
                                                            required: 'PAN Number is required',
                                                            pattern: {
                                                                value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                                                                message: 'Invalid PAN Number format (e.g., AAAAA9999A)'
                                                            }
                                                        })}
                                                    />
                                                    {errors.panNumber && (
                                                        <div className="invalid-feedback">{errors.panNumber.message}</div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="financialYearBegins">Financial Year Begins <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="date"
                                                        className={`form-control ${errors.financialYearBegins ? 'is-invalid' : ''}`}
                                                        id="financialYearBegins"
                                                        {...register('financialYearBegins', { required: 'Date is required' })}
                                                    />
                                                    {errors.financialYearBegins && <div className="invalid-feedback">{errors.financialYearBegins.message}</div>}
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="booksBegins">Books Begins <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="date"
                                                        className={`form-control ${errors.booksBegins ? 'is-invalid' : ''}`}
                                                        id="booksBegins"
                                                        {...register('booksBegins', { required: 'Date is required' })}
                                                    />
                                                    {errors.booksBegins && <div className="invalid-feedback">{errors.booksBegins.message}</div>}
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <hr className='my-3' />

                                <div className="row">

                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="email">Email Address <span className='text-danger'>*</span></label>
                                            <input type="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} id="email" {...register('email', { required: 'Email Address is required', pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i, message: 'Invalid email address' } })} />
                                            {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="phoneNumber">Phone Number <span className='text-danger'>*</span></label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                                                id="phoneNumber"
                                                {...register('phoneNumber', {
                                                    required: 'Phone number is required',
                                                    minLength: {
                                                        value: 10,
                                                        message: 'Phone number must be at least 10 digits long',
                                                    },
                                                    maxLength: {
                                                        value: 15,
                                                        message: 'Phone number must be at most 15 digits long',
                                                    },
                                                    pattern: {
                                                        value: /^[0-9]+$/,
                                                        message: 'Phone number must contain only digits',
                                                    },
                                                })}
                                            />
                                            {errors.phoneNumber && (
                                                <div className="invalid-feedback">{errors.phoneNumber.message}</div>
                                            )}
                                        </div>
                                    </div>


                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="country">Country <span className='text-danger'>*</span></label>
                                            <Select
                                                {...register('country', { required: 'Country is required' })}
                                                onChange={handleCountryChange}
                                                options={countryData}
                                                className={errors.country ? 'is-invalid' : ''}
                                            />
                                            {errors.country && <div className="invalid-feedback">{errors.country.message}</div>}
                                        </div>
                                    </div>


                                    {
                                        isState ? <div className="col-md-6 col-lg-3">
                                            <div className="form-group">
                                                <label htmlFor="state">State <span className='text-danger'>*</span></label>
                                                <Select
                                                    {...register('state', { required: 'State is required' })}
                                                    onChange={handleStateChange}
                                                    options={stateDate}
                                                    className={errors.state ? 'is-invalid' : ''}
                                                />
                                                {errors.state && <div className="invalid-feedback">{errors.state.message}</div>}
                                            </div>
                                        </div> : <div className="col-md-6 col-lg-3">
                                            <div className="form-group">
                                                <label htmlFor="state">State <span className='text-danger'>*</span></label>
                                                <input type="text" className={`form-control ${errors.state ? 'is-invalid' : ''}`} id="state" {...register('state', { required: 'State is required' })} />
                                                {errors.state && <div className="invalid-feedback">{errors.state.message}</div>}
                                            </div>
                                        </div>

                                    }


                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="city">City <span className='text-danger'>*</span></label>
                                            <input type="text" className={`form-control ${errors.city ? 'is-invalid' : ''}`} id="city" {...register('city', { required: 'City is required' })} />
                                            {errors.city && <div className="invalid-feedback">{errors.city.message}</div>}
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="address">Address <span className='text-danger'>*</span></label>
                                            <input type="text" className={`form-control ${errors.address ? 'is-invalid' : ''}`} id="address" {...register('address', { required: 'Address is required' })} />
                                            {errors.address && <div className="invalid-feedback">{errors.address.message}</div>}
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="pincode">Pincode <span className='text-danger'>*</span></label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.pincode ? 'is-invalid' : ''}`}
                                                id="pincode"
                                                {...register('pincode', {
                                                    required: 'Pincode is required',
                                                    pattern: {
                                                        value: /^[0-9]{6}$/,
                                                        message: 'Invalid pincode'
                                                    },
                                                    minLength: {
                                                        value: 6,
                                                        message: 'Pincode must be exactly 6 characters long'
                                                    },
                                                    maxLength: {
                                                        value: 6,
                                                        message: 'Pincode must be exactly 6 characters long'
                                                    }
                                                })}
                                            />
                                            {errors.pincode && <div className="invalid-feedback">{errors.pincode.message}</div>}
                                        </div>
                                    </div>

                                </div>


                                <div className="m-3" style={{ textAlign: "right" }}>
                                    <button disabled={loader ? true : false} type="submit" style={{ width: "100px" }} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                        {
                                            loader ? <ButtonLoader /> : 'Register'
                                        }
                                    </button>

                                </div>
                            </form>


                        </div>
                    </div>
                </div>

            </div>

        </Layout>
    )
}

export default AddOrganization;
