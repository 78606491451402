import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import './auth.css';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { ButtonLoader } from '../../../utils/Loader/LoaderComponent';
import { messageClear, org_woner_register } from '../../../store/Reducers/organisation/orgWonerReducer';

const OrgWonerRegister = () => {

  const { errorMessage, loader, successMessage } = useSelector(state => state.org_woner);


  const [showPassword, setShowPassword] = useState(false);
  const { handleSubmit, getValues, control, setValue, register, formState: { errors } } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // onSubmit
  const onSubmit = (data) => {
    const { ownerName, email, password } = data;
    const obj = {
      ownerName,
      email,
      password
    };
    dispatch(org_woner_register(obj));
  };

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage)
      dispatch(messageClear())
    }
    if (successMessage) {
      toast.success(successMessage)
      dispatch(messageClear())
      navigate('/email-verify', { replace: true })
    }
  }, [errorMessage, successMessage])


  return (
    <div className='registerStyle py-5'>
      <div className="wrapper-login wrapperRegister">
        <div className="container container-login animated fadeIn">
          <h3 className='font-weight-bold text-center'>Organisation Woner Register</h3>

          <form onSubmit={handleSubmit(onSubmit)} className="login-form">
            <div className="form-group row">
              <div className="col-md-12 mb-3">
                <input
                  {...register('ownerName', {
                    required: 'Name is required',
                  })}
                  id="ownerName"
                  name="ownerName"
                  type="text"
                  className={`form-control ${errors.ownerName ? 'is-invalid' : ''}`}
                  placeholder='Enter your name'
                />
                {errors.ownerName && <p className="invalid-feedback">{errors.ownerName.message}</p>}
              </div>

              <div className="col-md-12 mb-3">
                <input
                  {...register('email', {
                    required: 'Email is required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Invalid email address',
                    },
                  })}
                  id="email"
                  name="email"
                  type="email"
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  placeholder='Enter email'
                />
                {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
              </div>
              <div className="col-md-6 mb-3">
                <div className="input-group">
                  <input
                    {...register('password', {
                      required: 'Password is required',
                      minLength: {
                        value: 6,
                        message: 'Password must be at least 6 characters long',
                      },
                      maxLength: {
                        value: 10,
                        message: 'Password must not exceed 10 characters',
                      },
                    })}
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                    placeholder='Password'
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? 'Hide' : 'Show'}
                    </button>
                  </div>
                  {errors.password && <p className="invalid-feedback">{errors.password.message}</p>}
                </div>
              </div>

              <div className="col-md-6 mb-3">
                <input
                  {...register('confirmPassword', {
                    required: 'Confirm Password is required',
                    validate: value => value === getValues('password') || 'Passwords do not match',
                  })}
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                  placeholder='Confirm password'
                />
                {errors.confirmPassword && <p className="invalid-feedback">{errors.confirmPassword.message}</p>}
              </div>

            </div>

            <div className="form-group form-action-d-flex mb-1 text-center">
              <button disabled={loader ? true : false} type="submit" className="btn btn-secondary col-md-12 mt-3 mt-sm-0 fw-bold mx-auto">
                {
                  loader ? <ButtonLoader /> : 'Register'
                }
              </button>
            </div>
            <div className="login-account" style={{ textAlign: "center" }}>
              <span className="msg">Already have an account?</span>
              <Link to='/' id="show-login" className="link"> Login</Link>
            </div>
          </form>
          
        </div>
      </div>
    </div>
  );
};


export default OrgWonerRegister;