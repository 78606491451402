import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';


const ProtectOrgWoner = () => {

    const { orgWonerInfo: { role }, orgWonerToken } = useSelector(state => state.org_woner);

    // console.log("role", role)
    // console.log("orgWonerToken", orgWonerToken)


    if (role === 'Woner' && orgWonerToken) {
        return <Outlet />
    } else {
        return <Navigate to="/login" />;
    }


}

export default ProtectOrgWoner;