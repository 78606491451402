import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';


import ProtectOrganization from './components/auth/protectRoute/ProtectOrganization';
import ProtectOrgWoner from './components/auth/protectRoute/ProtectOrgWoner';
import ProtectAdministrator from './components/auth/protectRoute/ProtectAdministrator';


import AllLogin from './components/auth/Login/AllLogin';
import OrgWonerRegister from './components/auth/Register/OrgWonerRegister';
import OrWonerLogin from './components/auth/Login/OrWonerLogin';
import EmailVerify from './components/auth/Register/EmailVerify';


import OrgWonerDashboard from './components/organization/woner/OrgWonerDashboard';
import AddOrganization from './components/organization/woner/AddOrganization';
import AllOrganization from './components/organization/woner/AllOrganization';
import HeadOrganization from './components/organization/woner/HeadOrganization';


import OrganisationDashbaord from './components/organization/organization/OrganisationDashbaord';
import AddAdministrator from './components/organization/organization/AddAdministrator';
import AllAdministrator from './components/organization/organization/AllAdministrator';


import AdministratorDashboard from './components/administrator/dashboard/AdministratorDashboard';
import AdmissionStudent from './components/administrator/students/AdmissionStudent';
import ClassList from './components/administrator/class/ClassList';
import AllStudents from './components/administrator/students/AllStudents';
import StudentPromotion from './components/administrator/students/StudentPromotion';
import AddTeacher from './components/administrator/teacher/addteacher/AddTeacher';
import AllTeachers from './components/administrator/teacher/AllTeachers';
import Subject from './components/administrator/subject/Subject';
import TeacherAssign from './components/administrator/teacher/TeacherAssign';
import StudentDetails from './components/administrator/students/studentDetails/StudentDetails';
import ClassSchedule from './components/administrator/class/ClassSchedule';
import TeacherSchedule from './components/administrator/teacher/TeacherSchedule';

import Textt from './pages/Textt';
import StudentAuthenticate from './components/administrator/students/studentAuthenticate/StudentAuthenticate';
import ProtectStudent from './components/auth/protectRoute/ProtectStudent';
import StudentDashboard from './components/administrator/dashboard/StudentDashboard';
import AcademicYear from './components/administrator/academicYear/AcademicYear';
import { socket } from './utils/socket/socket';
import StudentProfile from './components/student/StudentProfile';
import StudentClassSchedule from './components/student/academic/StudentClassSchedule';
import ExamTerm from './components/administrator/examMaster/ExamTerm';
import ExamHall from './components/administrator/examMaster/ExamHall';
import Distribution from './components/administrator/examMaster/Distribution';
import ExamSetup from './components/administrator/examMaster/ExamSetup';
import ExamSchedule from './components/administrator/examMaster/examSchedule/ExamSchedule';
import StudentAllSubject from './components/student/academic/StudentAllSubject';
import StudentExamSchedule from './components/student/examMaster/StudentExamSchedule';
import StudentExamResult from './components/student/examMaster/StudentExamResult';
import EmployeeAuthenticate from './components/administrator/teacher/EmployeeAuthenticate/EmployeeAuthenticate';
import ProtectTeacher from './components/auth/protectRoute/ProtectTeacher';
import TeacherDashboard from './components/administrator/dashboard/TeacherDashboard';
import ProtectAccountant from './components/auth/protectRoute/ProtectAccountant';
import AccountantDashboard from './components/administrator/dashboard/AccountantDashboard';
import ProtectLibrarian from './components/auth/protectRoute/ProtectLibrarian';
import LibrarianDashboard from './components/administrator/dashboard/LibrarianDashboard';
import AssignSubject from './components/administrator/subject/AssignSubject';
import StudentAttendance from './components/administrator/attendance/studentAttendance/StudentAttendance';
import SingleStudentAttendance from './components/student/attendance/SingleStudentAttendance';

import Accounting from './components/administrator/accounting/Accounting';
import FeesType from './components/administrator/accounting/FeesType';
import FeesGroup from './components/administrator/accounting/feesGroup/FeesGroup';
import FineSetup from './components/administrator/accounting/FineSetup';
import FeesAllocation from './components/administrator/accounting/FeesAllocation';
import EmployeeAttendance from './components/administrator/attendance/employeeAttendance/EmployeeAttendance';
import ExamAttendance from './components/administrator/attendance/examAttendance/ExamAttendance';
import MarkEntries from './components/administrator/examMaster/examMarks/MarkEntries';
import ReportCard from './components/administrator/examMaster/ReportCard';
import GradeRange from './components/administrator/examMaster/examMarks/GradeRange';


const App = () => {

  useEffect(() => {

    socket.emit('online', { message: 'Hi I am Santo Biswas' })

  }, [])

  return (
    <>
      <Routes>

        <Route path='/' element={<AllLogin />} />
        <Route path='/register' element={<OrgWonerRegister />} />
        <Route path='/login' element={<OrWonerLogin />} />
        <Route path='/email-verify' element={<EmailVerify />} />


        {/* ProtectOrgWoner start here */}
        <Route path='/' element={<ProtectOrgWoner />}>
          <Route path='dashboard' element={<OrgWonerDashboard />} />
          <Route path='add-organisation' element={<AddOrganization />} />
          <Route path='all-organisation' element={<AllOrganization />} />
          <Route path='head-organisation' element={<HeadOrganization />} />
        </Route>
        {/* ProtectOrgWoner end here */}


        {/* ProtectOrganization start here */}
        <Route path='/' element={<ProtectOrganization />}>
          <Route path='organization-dashboard' element={<OrganisationDashbaord />} />
          <Route path='add-administrator' element={<AddAdministrator />} />
          <Route path='all-administrator' element={<AllAdministrator />} />
        </Route>
        {/* ProtectOrganization end here */}



        {/* ProtectAdministrator start here */}
        <Route path='/' element={<ProtectAdministrator />}>
          <Route path='administrator-dashboard' element={<AdministratorDashboard />} />
          <Route path='admission-students' element={<AdmissionStudent />} />
          <Route path='admission-students/:studentId' element={<AdmissionStudent />} />
          <Route path='all-students' element={<AllStudents />} />
          <Route path='student-promotion' element={<StudentPromotion />} />
          <Route path='student-details/:studentId' element={<StudentDetails />} />
          <Route path='students-authenticate' element={<StudentAuthenticate />} />

          <Route path='add-employee' element={<AddTeacher />} />
          <Route path='add-employee/:teacherId' element={<AddTeacher />} />
          <Route path='all-employee' element={<AllTeachers />} />
          <Route path='teachers-assign' element={<TeacherAssign />} />
          <Route path='teacher-schedule' element={<TeacherSchedule />} />
          <Route path='employee-authenticate' element={<EmployeeAuthenticate />} />

          <Route path='class-list' element={<ClassList />} />
          <Route path='class-schedule' element={<ClassSchedule />} />
          <Route path='subject-register' element={<Subject />} />
          <Route path='subjects-assign-student' element={<AssignSubject />} />

          <Route path='exam-term' element={<ExamTerm />} />
          <Route path='exam-hall' element={<ExamHall />} />
          <Route path='mark-distribution' element={<Distribution />} />
          <Route path='exam-setup' element={<ExamSetup />} />
          <Route path='exam-schedule' element={<ExamSchedule />} />
          <Route path='report-card' element={<ReportCard />} />
          <Route path='student-attendance' element={<StudentAttendance />} />
          <Route path='employee-attendance' element={<EmployeeAttendance />} />
          <Route path='exam-attendance' element={<ExamAttendance />} />
          <Route path='mark-entries' element={<MarkEntries />} />
          <Route path='grade-range' element={<GradeRange />} />

          <Route path='fees-type' element={<FeesType />} />
          <Route path='fees-group' element={<FeesGroup />} />
          <Route path='edit-fees-group/:groupId' element={<FeesGroup />} />
          <Route path='fine-setup' element={<FineSetup />} />
          <Route path='fees-allocation' element={<FeesAllocation />} />

          <Route path='office-accounting' element={<Accounting />} />

          <Route path='add-academic-year' element={<AcademicYear />} />

        </Route>

        {/* ProtectAdministrator end here */}


        {/* ProtectStudent start here */}
        <Route path='/' element={<ProtectStudent />}>
          <Route path='student-dashboard' element={<StudentDashboard />} />
          <Route path='student-profile' element={<StudentProfile />} />
          <Route path='student-all-subject' element={<StudentAllSubject />} />
          <Route path='student-class-schedule' element={<StudentClassSchedule />} />
          <Route path='student-exam-schedule' element={<StudentExamSchedule />} />
          <Route path='student-exam-result' element={<StudentExamResult />} />
          <Route path='single-student-attendance' element={<SingleStudentAttendance />} />

        </Route>
        {/* ProtectStudent end here */}



        {/* ProtectTeacher start here */}
        <Route path='/' element={<ProtectTeacher />}>
          <Route path='teacher-dashboard' element={<TeacherDashboard />} />
          <Route path='student_attendance' element={<StudentAttendance />} />

        </Route>
        {/* ProtectTeacher end here */}


        {/* ProtectAccountant start here */}
        <Route path='/' element={<ProtectAccountant />}>
          <Route path='accountant-dashboard' element={<AccountantDashboard />} />

        </Route>
        {/* ProtectAccountant end here */}


        {/* ProtectAccountant start here */}
        <Route path='/' element={<ProtectLibrarian />}>
          <Route path='librarian-dashboard' element={<LibrarianDashboard />} />

        </Route>
        {/* ProtectAccountant end here */}

        <Route path='/select' element={<Textt />} />


      </Routes>
    </>
  )
}

export default App;


/* 

                                {loader ? (
                                    // Display loader while data is being fetched
                                    <TableLoader />
                                ) : classList?.length > 0 ? (
                                    // Render table if data exists
                                      <>
                                      </>
                                ) : (
                                    // Display empty state when classList is empty
                                    <Empty data={`Class Not Found !`} />
                                )}
*/