import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import ShowEmployeeAttendance from './ShowEmployeeAttendance';
import AddEmployeeAttendance from './AddEmployeeAttendance';
import Layout from '../../../Layout/Layout';



const EmployeeAttendance = () => {
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    return (
        <Layout>
            <div className='px-0 mb-4'>
                <div className='card mb-0'>
                    <Tabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        aria-label="teacher assignment tabs"
                        variant="fullWidth"
                        textColor="primary"
                        indicatorColor="primary"
                    >
                        <Tab label="Show Employee Attendance" />
                        <Tab label="Add Employee Attendance" />
                    </Tabs>
                </div>
            </div>
            <TabPanel value={tabIndex} index={0}>
                <ShowEmployeeAttendance />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <AddEmployeeAttendance />
            </TabPanel>
        </Layout>
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


export default EmployeeAttendance;