import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { confirmMessagge } from '../../../utils/other/aleartFunc';
import { sessionValueClear } from '../../../store/Reducers/defaultReducer';
import { employee_logout, messageClear, tokenClear } from '../../../store/Reducers/employee/employeeAuthReducer';


const AccountantSidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const { employeeInfo: { role }, logoutMessage } = useSelector(state => state.employee);



    // logoutFunction
    const logoutFunction = async () => {
        const returnValue = await confirmMessagge('Logout');
        if (returnValue) {
            dispatch(employee_logout())
        }
    }


    useEffect(() => {
        if (logoutMessage) {
            toast.success(logoutMessage)
            dispatch(messageClear())
            dispatch(tokenClear())
            dispatch(sessionValueClear())
        }
    }, [logoutMessage])



    return (
        <div className="sidebar sidebar-style-2" data-background-color="dark22">
            <div className="sidebar-wrapper scrollbar scrollbar-inner">
                <div className="sidebar-content">

                    <ul className="nav nav-primary">
                        <li className="nav-section">
                            <span className="sidebar-mini-icon">
                                <i className="fa fa-ellipsis-h" />
                            </span>
                            <h4 className="text-section">Navigation</h4>
                        </li>

                        <li className={`nav-item ${location?.pathname === '/accountant-dashboard' ? 'active' : ''}`}>
                            <Link to='/accountant-dashboard'>
                                <i className="fas fa-th-large" />
                                <p>Dashboard</p>
                            </Link>
                        </li>


                        <li className='nav-item'>
                            <Link to="/add-academic-year">
                                <i className="fas fa-calendar-alt" />
                                <span className="sub-item">Add Academic Year</span>
                            </Link>
                        </li>


                        <li className='nav-item'>
                            <Link onClick={logoutFunction}>
                                <i className="fas fa-sign-out-alt" />
                                <p>Logout</p>
                            </Link>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    )
}



export default AccountantSidebar;