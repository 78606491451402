import React, { useEffect, useRef, useState } from 'react';
import Empty from '../../../utils/page/Empty';
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { ButtonLoader, TableLoader } from '../../../utils/Loader/LoaderComponent';
import { get_all_teacher, messageClear, teacher_assign_subject } from '../../../store/Reducers/administrator/teacherReducer';
import toast from 'react-hot-toast';
import { get_all_class } from '../../../store/Reducers/administrator/administratorReducer';
import { getSubjectDetails, getClassName } from '../../../utils/other/returnFunction';
import { FaGraduationCap } from "react-icons/fa";
import { useReactToPrint } from "react-to-print";
import { FaRegCopy } from "react-icons/fa6";
import { RiFileExcelLine } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa";
import { exportToExcel, exportToPDF } from '../../../utils/other/fileGenerate';
import { Modal, Box, Button, Typography } from '@mui/material';
import { FiPrinter } from "react-icons/fi";



const TeacherAssignSubject = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const { classList, subjectLists } = useSelector(state => state.administrator);
    const { allTeachers, loader, errorMessage, successMessage } = useSelector(state => state.teacher);


    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors } = useForm();

    const [classListOption, setClassListOption] = useState("")
    const [teacherOption, setTeacherOption] = useState("")
    const [subjectOption, setSubjectOption] = useState("")

    const [classValue, setClassValue] = useState(null)
    const [teacherValue, setTeacherValue] = useState(null)
    const [subjectValue, setSubjectValue] = useState(null)

    const [selectClasstId, setSelectClasstId] = useState('');


    // handleOpenDrawer
    const handleOpenDrawer = () => {
        setOpen(true);
        setIsEditing(false);
    }

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState([]);

    const handleShowModal = (subjects) => {
        setModalData(subjects);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalData([]);
    };


    // handleSelect
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
        clearErrors(fieldName);
        if (fieldName === 'className') {
            setSelectClasstId(selectOption.value)
            setSubjectValue([])
            setTeacherValue('')

            const filterSubject = subjectLists?.filter(d => d.classDetails.classId === selectOption.value).flatMap(d => d.subjects);
            if (filterSubject) {
                const options = filterSubject?.map(item => ({
                    value: item._id,
                    label: item.subjectName
                }));
                setSubjectOption(options);
            }
            const filterTeacher = classList?.filter(v => v._id === selectOption.value).flatMap(d => d.assignTeacher);
            if (filterTeacher) {
                const options2 = filterTeacher?.map(item => ({
                    value: item._id,
                    label: `${item?.teacherBasicDetails?.fullName}`
                }));
                setTeacherOption(options2);
            }
        }

        if (fieldName === 'teacherName') {

            const selectedSubject = allTeachers?.filter((d) => d._id === selectOption.value)[0]?.assignSubject;

            const convertedArray = subjectLists
                .filter((d) => d.classDetails.classId === selectClasstId)
                .flatMap((d) => d.subjects.map(subject => ({ ...subject, classId: d.classDetails.classId })))
                .filter((s) => selectedSubject?.some(selected => selected.subjectId === s._id && selected.classId === s.classId))
                .map((s) => ({
                    value: s._id,
                    label: s.subjectName
                }));

            setSubjectValue(convertedArray);

        }

    }

    // handleAssignEdit
    const handleAssignEdit = (id) => {
        console.log("Id", id)
    }


    // onSubmit
    const onSubmit = (data) => {
        const { className, teacherName, subjectName } = data;
        const subjectNames = subjectName?.map(sub => sub.value)

        const payload = {
            classId: className.value,
            teacherId: teacherName.value,
            subjectIds: subjectNames

        };

        dispatch(teacher_assign_subject(payload))
    }


    //  createExceclFile
    const createExceclFile = () => {
        const data = allTeachers?.map((d, i) => {
            const firstAssignedSubjectId = d.assignSubject?.[0];
            const { className } = getSubjectDetails(subjectLists, firstAssignedSubjectId) || {};

            return {
                Sl: i + 1,
                'Teacher Name': d.teacherBasicDetails.fullName,
                'Class Name': className || '',
                'Subject Names': d.assignSubject?.map(subjectId => {
                    const { subjectName } = getSubjectDetails(subjectLists, subjectId);
                    return subjectName;
                }).join(', ')
            };
        });
        exportToExcel(data, 'subject_assign_data.xlsx');
    }



    // createPdfFile
    const createPdfFile = () => {
        const data = allTeachers?.map((d, i) => {
            const firstAssignedSubjectId = d.assignSubject?.[0];
            const { className } = getSubjectDetails(subjectLists, firstAssignedSubjectId) || {};

            return {
                Sl: i + 1,
                'Teacher Name': d.teacherBasicDetails.fullName,
                'Class Name': className || '',
                'Subject Names': d.assignSubject?.map(subjectId => {
                    const { subjectName } = getSubjectDetails(subjectLists, subjectId);
                    return subjectName;
                }).join(', ')
            };
        });

        exportToPDF(data, 'subject_assign_data.pdf');
    };


    const componentRef = useRef();


    // createPrint
    const createPrint = useReactToPrint({
        content: () => componentRef.current,
    });



    useEffect(() => {
        if (classList) {
            const options = classList?.map(item => ({
                value: item._id,
                label: item.className
            }));
            setClassListOption(options);
        }
    }, [classList, setClassListOption]);


    useEffect(() => {
        const obj = {
            parPage: 20,
            page: 1,
            searchValue: ""
        }

        if (errorMessage) {
            toast.error(errorMessage)
            dispatch(messageClear())
        }
        if (successMessage) {
            toast.success(successMessage)
            dispatch(messageClear())
            setOpen(false);
            setSubjectValue([])
            setTeacherValue('')
            setClassValue('')
            dispatch(get_all_teacher(obj))
        }
    }, [errorMessage, successMessage])


    // console.log("allTeachers", allTeachers)
    // console.log("classList", classList)


    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="page-header mb-0">
                                <span style={{ fontSize: "18px", padding: "0px 8px 8px 0px" }}>
                                    <i className="fas fa-list" />
                                </span>
                                <h4 style={{ fontSize: "20px", marginBottom: "9px" }}>Assign Subject List</h4>

                                <span className='px-4'>
                                    <FaRegCopy title='Copy' style={{ fontSize: '20px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <RiFileExcelLine onClick={createExceclFile} title='Excel' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FaRegFilePdf onClick={createPdfFile} title='PDF' style={{ fontSize: '18px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FiPrinter onClick={createPrint} title='Print' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                </span>


                                <button onClick={handleOpenDrawer} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                    <span><i className="fa fa-plus pr-2" />Add</span>
                                </button>
                            </div>

                            <hr className='my-3' />

                            <div>
                                {loader ? (
                                    <TableLoader /> // Show loader while loading
                                ) : allTeachers?.length > 0 ? (
                                    <table className="tableStyle" ref={componentRef}>
                                        <thead className=''>
                                            <tr>
                                                <th>Sl.</th>
                                                <th>Teacher Name</th>
                                                <th>Subject Name</th>
                                            </tr>
                                        </thead>
                                        <>
                                            <tbody>
                                                {allTeachers?.map((d, i) => {
                                                    const subjectsToShow = d.assignSubject?.slice(0, 2);

                                                    return (
                                                        <tr key={d._id}>
                                                            <td>{i + 1}</td>
                                                            <td data-label="Teacher Name">{d?.teacherBasicDetails?.fullName}</td>
                                                            <td data-label="Subject Name">
                                                                {subjectsToShow?.map((data, j) => {
                                                                    const { subjectName } = getSubjectDetails(subjectLists, data.subjectId);
                                                                    return (
                                                                        <span
                                                                            style={{ fontSize: "14px" }}
                                                                            key={j}
                                                                            className='bg-danger text-light px-2 py-1 mr-1 rounded'
                                                                        >
                                                                            {subjectName} ({getClassName(classList, data.classId)})
                                                                        </span>
                                                                    );
                                                                })}
                                                                {d.assignSubject.length > 2 && (
                                                                    <span style={{ fontWeight: "600", background: "#444", cursor: "pointer" }} className=' text-light px-2 py-1 mr-1 rounded' onClick={() => handleShowModal(d.assignSubject)}>
                                                                        Show All
                                                                    </span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>

                                            <Modal
                                                open={showModal}
                                                onClose={handleCloseModal}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', borderRadius: '5px', boxShadow: 24, p: 2 }}>
                                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                                        Assigned Subjects
                                                    </Typography>
                                                    <IconButton
                                                        aria-label="close"
                                                        onClick={handleCloseModal}
                                                        sx={{
                                                            position: 'absolute',
                                                            right: 8,
                                                            top: 8,
                                                            color: (theme) => theme.palette.grey[500],
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                        {modalData.map((data, index) => {
                                                            const { subjectName } = getSubjectDetails(subjectLists, data.subjectId);
                                                            return (
                                                                <span
                                                                    style={{ fontSize: "17px", display: 'block', marginBottom: '8px' }}
                                                                    key={index}
                                                                    className='bg-danger text-light px-2 py-1 mr-1 rounded'
                                                                >
                                                                    {subjectName} ({getClassName(classList, data.classId)})
                                                                </span>
                                                            );
                                                        })}
                                                    </Typography>
                                                </Box>
                                            </Modal>
                                        </>
                                    </table>
                                ) : (
                                    <Empty data="Teachers Not Found!" />
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <div style={{ width: '600px' }}>
                    <DialogTitle style={{ fontSize: '19px' }}>Assign Teacher Subject</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <div className='px-2'>
                        <form onSubmit={handleSubmit(onSubmit)}>


                            <div className="form-group d-flex">
                                <label className='pt-1' style={{ width: "18%" }} htmlFor="className">Select Class <span className='text-danger'>*</span></label>
                                <div style={{ width: "82%" }}>
                                    <Select
                                        value={classValue}
                                        {...register('className', { required: 'Class is required' })}
                                        onChange={(selectOption) => {
                                            setClassValue(selectOption)
                                            handleSelect('className', selectOption);
                                        }}
                                        options={classListOption}
                                        className={errors.className ? 'is-invalid' : ''}
                                    />
                                    {errors.className && <div className="invalid-feedback">{errors.className.message}</div>}
                                </div>

                            </div>

                            <div className="form-group d-flex">
                                <label className='pt-1' style={{ width: "18%" }} htmlFor="teacherName">Select Teacher <span className='text-danger'>*</span></label>
                                <div style={{ width: "82%" }}>
                                    <Select
                                        value={teacherValue}
                                        {...register('teacherName', { required: 'Teacher is required' })}
                                        onChange={(selectOption) => {
                                            setTeacherValue(selectOption)
                                            handleSelect('teacherName', selectOption);
                                        }}
                                        isDisabled={teacherOption?.length === 0}
                                        options={teacherOption}
                                        className={errors.teacherName ? 'is-invalid' : ''}
                                    />
                                    {errors.teacherName && <div className="invalid-feedback">{errors.teacherName.message}</div>}
                                </div>

                            </div>

                            <div className="form-group d-flex">
                                <label className='pt-1' style={{ width: "18%" }} htmlFor="subjectName">Select Subject</label>
                                <div style={{ width: "82%" }}>
                                    <Select
                                        value={subjectValue}
                                        {...register('subjectName')}
                                        onChange={(selectOption) => {
                                            setSubjectValue(selectOption);
                                            handleSelect('subjectName', selectOption);
                                        }}
                                        isDisabled={subjectOption?.length === 0}
                                        options={subjectOption}
                                        className={`basic-multi-select`}
                                        isMulti
                                        classNamePrefix="select"
                                    />


                                    {/* {errors.subjectName && <div className="invalid-feedback">{errors.subjectName.message}</div>} */}
                                </div>

                            </div>


                            <div className="mt-2 mx-2" style={{ textAlign: "right" }}>
                                <button disabled={loader} type="submit" style={{ width: "100px" }} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                    {loader ? <ButtonLoader /> : (isEditing ? 'Edit' : 'Assign')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Drawer>

        </>
    )
}


export default TeacherAssignSubject;