import React, { useEffect, useState } from 'react';
import Layout from '../../Layout/Layout';
import Drawer from '@mui/material/Drawer';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { FaGraduationCap } from "react-icons/fa";
import { FaRegCopy } from "react-icons/fa6";
import { FiPrinter } from "react-icons/fi";
import { RiFileExcelLine } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa";
import { ButtonLoader, TableLoader } from '../../../utils/Loader/LoaderComponent';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { add_fine, delete_fine, get_fine, get_single_fine, messageClear, update_fine } from '../../../store/Reducers/accounting/feesReducer';
import toast from 'react-hot-toast';
import Pagination from '../../Layout/Pagination';
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import Empty from '../../../utils/page/Empty';
import { confirmMessagge } from '../../../utils/other/aleartFunc';


const FineSetup = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const { handleSubmit, control, setValue, register, formState: { errors }, reset } = useForm();
    const { loader, successMessage, errorMessage, feesGroupList, fineList, fineCount, singleFine } = useSelector(state => state.fees);

    const { searchValue } = useSelector(state => state.default);


    const [currentPage, setCurrentPage] = useState(1)
    const [parPage, setParPage] = useState(10)


    const [editId, setEditId] = useState('');


    const [feesGroupOption, setFeesGroupOption] = useState([]);
    const [feesTypeOption, setFeesTypeOption] = useState([]);

    const [feesGroupValue, setFeesGroupValue] = useState(null);
    const [feesTypeValue, setFeesTypeValue] = useState(null);

    // handleSelect
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption, { shouldValidate: true });

        if (fieldName === 'groupName') {
            setFeesTypeValue(null);
            setValue('feesType', '')

            const selectedGroup = feesGroupList?.find((group) => group._id === selectOption.value);

            if (selectedGroup?.feesList) {
                const options = selectedGroup?.feesList?.map((item) => ({
                    value: item.feesId?._id,
                    label: item.feesId?.feesName
                }));

                setFeesTypeOption(options);
            }
        }
    };


    // handleOpenDrawer
    const handleOpenDrawer = () => {
        setOpen(true)
        setFeesGroupValue(null)
        setFeesTypeValue(null)
        setEditId('')

        setValue('groupName', '')
        setValue('feesType', '')
        setValue('fineType', '')
        setValue('fineValue', '')
        setValue('lateFeeFrequency', '')
    };


    // onSubmit
    const onSubmit = (data) => {

        const { groupName, feesType, fineType, fineValue, lateFeeFrequency } = data;

        const obj = {
            groupName: groupName.value,
            feesType: feesType.value,
            fineType,
            fineValue: Number(fineValue),
            lateFeeFrequency
        }

        if (editId) {
            dispatch(update_fine({ editId, obj }))
        } else {
            dispatch(add_fine(obj))
        }

    };



    // handleItemPerPageChange
    const handleItemPerPageChange = (e) => {
        setParPage(e.target.value)
    }


    //  handleFeesEdit
    const handleFeesEdit = (editId) => {
        setEditId(editId)
        setOpen(true)
        dispatch(get_single_fine(editId));
    }


    // handleHallDelete
    const handleHallDelete = async (deleteId) => {
        const returnValue = await confirmMessagge('Delete');
        if (returnValue) {
            dispatch(delete_fine(deleteId))
        }

    }


    useEffect(() => {
        if (Object.keys(singleFine).length > 0) {

            setFeesGroupValue({
                label: singleFine?.groupName?.groupName,
                value: singleFine?.groupName?._id
            });
            setValue("groupName", {
                label: singleFine?.groupName?.groupName,
                value: singleFine?.groupName?._id
            });


            setFeesTypeValue({
                label: singleFine?.feesType?.feesName,
                value: singleFine?.feesType?._id
            });
            setValue("feesType", {
                label: singleFine?.feesType?.feesName,
                value: singleFine?.feesType?._id
            });

            setValue("fineType", singleFine?.fineType)
            setValue("fineValue", singleFine?.fineValue)
            setValue("lateFeeFrequency", singleFine?.lateFeeFrequency)

        }

    }, [singleFine])



    useEffect(() => {
        const obj = {
            parPage: parseInt(parPage),
            page: parseInt(currentPage),
            searchValue
        }
        dispatch(get_fine(obj))
    }, [searchValue, currentPage, parPage, successMessage])


    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage);
            dispatch(messageClear());
        }
        if (successMessage) {
            toast.success(successMessage);
            dispatch(messageClear());
            setOpen(false)
            setFeesGroupValue(null)
            setFeesTypeValue(null)
            setEditId('')

            setValue('groupName', '')
            setValue('feesType', '')
            setValue('fineType', '')
            setValue('fineValue', '')
            setValue('lateFeeFrequency', '')

        }
    }, [errorMessage, successMessage]);


    useEffect(() => {
        const options = feesGroupList?.map(item => ({
            value: item._id,
            label: item.groupName
        }));
        setFeesGroupOption(options);
    }, [feesGroupList]);


    // console.log("singleFine", singleFine)


    return (
        <Layout>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="page-header mb-0">
                                <span style={{ fontSize: "18px", padding: "0px 8px 7px 0px" }}>
                                    <i className="fas fa-list" />
                                </span>
                                <h4 style={{ fontSize: "20px" }}>Fine List</h4>

                                <span className='px-4'>
                                    <FaRegCopy title='Copy' style={{ fontSize: '20px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <RiFileExcelLine title='Excel' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FaRegFilePdf title='PDF' style={{ fontSize: '18px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FiPrinter title='Print' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                </span>

                                <button onClick={handleOpenDrawer} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                    <span><i className="fa fa-plus pr-2" />Add</span>
                                </button>
                            </div>
                            <hr className='my-3' />


                            <div className='mt-3'>

                                {loader ? (
                                    // Display loader while data is being fetched
                                    <TableLoader />
                                ) : fineList?.length > 0 ? (
                                    // Render table if data exists
                                    <table className="tableStyle">
                                        <thead className=''>
                                            <tr>
                                                <th>Sl.</th>
                                                <th>Group Name</th>
                                                <th>Fees Type Name</th>
                                                <th>Fine Type</th>
                                                <th>Fine Value</th>
                                                <th>Late Fee Frequency</th>
                                                <th className='no-print'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className=''>
                                            {fineList?.map((d, i) => (
                                                <tr key={i}>
                                                    <td data-lable="Sl">{i + 1 + (currentPage - 1) * parPage}</td>
                                                    <td data-lable="Group Name">{d.groupName?.groupName}</td>
                                                    <td data-lable="Fees Type Name">{d.feesType?.feesName}</td>
                                                    <td data-lable="Fine Type">{d.fineType}</td>
                                                    <td data-lable="Fine Value">{d.fineValue}</td>
                                                    <td data-lable="Late Fee Frequency">{d.lateFeeFrequency}</td>
                                                    <td data-lable="Action" className='no-print'>
                                                        <span title='Edit' className='mr-1' onClick={() => handleFeesEdit(d._id)}>
                                                            <FaEdit style={{ fontSize: '20px', color: 'green' }} />
                                                        </span>
                                                        <span title='Delete' className='ml-1' onClick={() => handleHallDelete(d._id)}>
                                                            <MdDeleteForever style={{ fontSize: '24px', color: 'red' }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    // Display empty state when classList is empty
                                    <Empty data={`Fine Data Not Found !`} />
                                )}

                            </div>

                        </div>


                        {
                            fineCount > 10 && <div class="d-flex justify-content-between">
                                <div className="col-md-4 d-flex justify-content-start align-items-center itemParPageStyle">
                                    <div class="d-flex mb-2">
                                        <label for="itemPerPage" class="col-sm-auto col-form-label">Select items per page :</label>
                                        <div class="col-sm-auto">
                                            <select
                                                id='itemPerPage'
                                                style={{ width: "65px", marginLeft: "-23px", marginTop: "7px", padding: "3px" }}
                                                onChange={handleItemPerPageChange}
                                                value={parPage}
                                            >
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="5000">All</option>

                                            </select>
                                        </div>
                                    </div>

                                </div>

                                <div className='px-4 pt-2'>
                                    {
                                        fineCount >= parPage ?
                                            <Pagination
                                                pageNumber={currentPage}
                                                setPageNumber={setCurrentPage}
                                                totalItem={fineCount}
                                                parPage={parPage}
                                                showItem={Math.floor(fineCount / parPage)}
                                            /> : ''
                                    }
                                </div>

                            </div>
                        }

                    </div>
                </div>
            </div>

            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <div style={{ width: '500px' }}>
                    <DialogTitle style={{ fontSize: '18px' }}>{editId ? 'Edit Fine' : 'Add Fine'}</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <div className='px-2'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <label htmlFor="groupName">Group Name <span className='text-danger'>*</span></label>
                                <Controller
                                    name="groupName"
                                    control={control}
                                    rules={{ required: 'Group Name is required' }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            value={feesGroupValue}
                                            onChange={(selectOption) => {
                                                setFeesGroupValue(selectOption);
                                                handleSelect('groupName', selectOption);
                                            }}
                                            options={feesGroupOption}
                                        />
                                    )}
                                />
                                {errors.groupName && <div className="invalid-feedback d-block">{errors.groupName.message}</div>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="feesType">Fees Type <span className='text-danger'>*</span></label>
                                <Controller
                                    name="feesType"
                                    control={control}
                                    rules={{ required: 'Fees Type is required' }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            value={feesTypeValue}
                                            onChange={(selectOption) => {
                                                setFeesTypeValue(selectOption);
                                                handleSelect('feesType', selectOption);
                                            }}
                                            options={feesTypeOption}
                                            isDisabled={feesTypeOption?.length === 0}
                                        />
                                    )}
                                />
                                {errors.feesType && <div className="invalid-feedback d-block">{errors.feesType.message}</div>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="fineType">Fine Type <span className='text-danger'>*</span></label>
                                <select
                                    className={`form-select ${errors.fineType ? 'is-invalid' : ''}`}
                                    style={{ fontSize: "16px", padding: "6px" }}
                                    {...register("fineType", { required: 'Fine Type is required' })}
                                >
                                    <option value="">Select</option>
                                    <option value="Fixed Amount">Fixed Amount</option>
                                    <option value="Percentage">Percentage</option>
                                </select>
                                {errors.fineType && <div className="invalid-feedback">{errors.fineType.message}</div>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="fineValue">Fine Value <span className='text-danger'>*</span></label>
                                <input
                                    type="number"
                                    className={`form-control ${errors.fineValue ? 'is-invalid' : ''}`}
                                    id="fineValue"
                                    {...register('fineValue', {
                                        required: 'Fine value is required',
                                        validate: value => value > 0 || 'Fine value must be greater than zero'
                                    })}
                                />
                                {errors.fineValue && <div className="invalid-feedback">{errors.fineValue.message}</div>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="lateFeeFrequency">Late Fee Frequency <span className='text-danger'>*</span></label>
                                <select
                                    className={`form-select ${errors.lateFeeFrequency ? 'is-invalid' : ''}`}
                                    style={{ fontSize: "16px", padding: "6px" }}
                                    {...register("lateFeeFrequency", { required: 'Late Fee Frequency is required' })}
                                >
                                    <option value="">Select</option>
                                    <option value="Fixed">Fixed</option>
                                    <option value="Daily">Daily</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Annually">Annually</option>
                                </select>
                                {errors.lateFeeFrequency && <div className="invalid-feedback">{errors.lateFeeFrequency.message}</div>}
                            </div>

                            <div className="my-3 mx-2" style={{ textAlign: "right" }}>
                                <button disabled={loader} type="submit" style={{ width: "90px" }} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                    {loader ? <ButtonLoader /> : (editId ? 'Update' : 'Save')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Drawer>
        </Layout>
    )
}


export default FineSetup;
