import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import store from './store';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback="Loading...">

        <App />
        <Toaster toastOptions={{
          position: 'top-right',
          style: {
            background: 'white',
            color: 'black'
          }
        }} />
      </Suspense>

    </Provider>
  </BrowserRouter>
);
