import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchFunction, sessionFunction } from '../../store/Reducers/defaultReducer';
import { get_all_class, get_all_subject } from '../../store/Reducers/administrator/administratorReducer';
import { get_all_teacher } from '../../store/Reducers/administrator/teacherReducer';
import { sessions } from '../../utils/other/data';
import { get_academic_year } from '../../store/Reducers/academic/academicReducer';
import { get_exam_hall, get_exam_schedule, get_exam_setup, get_exam_term, get_mark_distribution } from '../../store/Reducers/examMaster/examReducer';
import { get_all_student } from '../../store/Reducers/student/studentReducer';
import { get_fees_type, get_group_type } from '../../store/Reducers/accounting/feesReducer';
import { get_head_organization } from '../../store/Reducers/organisation/orgWonerReducer';


const Navbar = () => {

    const dispatch = useDispatch();

    const { sessionValue } = useSelector(state => state.default);
    const { administratorInfo: { role, organisationId } } = useSelector(state => state.administrator);
    const { academicYearApprovedList } = useSelector(state => state.academic);


    const [searchTerm, setSearchTerm] = useState('');
    const [timeoutId, setTimeoutId] = useState(null);


    // handleSearch
    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchTerm(value);

        if (timeoutId) {
            clearTimeout(timeoutId); // Clear the previous timeout
        }

        const newTimeoutId = setTimeout(() => {
            dispatch(searchFunction(value));
        }, 500); // Delay of 500ms

        setTimeoutId(newTimeoutId); // Save the new timeout ID
    };


    // handlesessionValue
    const handlesessionValue = (e) => {
        localStorage.setItem("sessionValue", e.target.value)
        dispatch(sessionFunction(e.target.value))
    }



    useEffect(() => {
        const obj = {
            parPage: 1000000000,
            page: 1,
            searchValue: ""
        }
        const obj2 = {
            parPage: 1000000000,
            page: 1,
            searchValue: "",
            sessionValue,
            classId: "",
            section: ""
        }
        const data = {
            searchValue: '',
            organisationId: organisationId
        }


        dispatch(get_mark_distribution(obj))
        dispatch(get_exam_hall(obj))
        dispatch(get_exam_term(obj))
        dispatch(get_academic_year(obj))
        dispatch(get_all_subject(obj))
        dispatch(get_all_teacher(obj))
        dispatch(get_fees_type(obj))
        dispatch(get_group_type(obj))
        dispatch(get_head_organization(obj))


        dispatch(get_exam_setup(obj2))
        dispatch(get_all_student(obj2))
        dispatch(get_exam_schedule(obj2))


        dispatch(get_all_class(data))
    }, [dispatch, sessionValue]);


    // console.log("academicYearApprovedList", academicYearApprovedList)

    return (
        <>
            <nav className="navbar navbar-header navbar-expand-lg" data-background-color="purple2">
                <div className="container-fluid d-flex">

                    <div className="collapse" id="search-nav">
                        <form className="navbar-left navbar-form nav-search mr-md-3">
                            <div className="input-group mx-5">
                                <div className="input-group-prepend">
                                    <button type="submit" className="btn btn-search pr-1">
                                        <i className="fa fa-search search-icon text-danger" />
                                    </button>
                                </div>
                                <input value={searchTerm} onChange={handleSearch} type="text" placeholder="Search ..." className="form-control" />
                            </div>
                        </form>
                    </div>


                    <div>
                        <select onChange={handlesessionValue} className='form-select p-1 px-2 text' style={{ width: "140px", fontWeight: "600", fontSize: "17px" }}>
                            {
                                academicYearApprovedList?.map((d) => (
                                    <option selected={d._id === sessionValue} value={d._id}>{d.sectionName}</option>
                                ))
                            }
                        </select>
                    </div>



                    {/*           <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
                        <li className="nav-item toggle-nav-search hidden-caret">
                            <a className="nav-link" data-toggle="collapse" href="#search-nav" role="button" aria-expanded="false" aria-controls="search-nav">
                                <i className="fa fa-search" />
                            </a>
                        </li>
                        <li>


                        </li>
                        <li className="nav-item dropdown hidden-caret">
                            <a className="nav-link dropdown-toggle" href="#" id="messageDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-envelope" />
                            </a>
                            <ul className="dropdown-menu messages-notif-box animated fadeIn" aria-labelledby="messageDropdown">
                                <li>
                                    <div className="dropdown-title d-flex justify-content-between align-items-center">
                                        Messages
                                        <a href="#" className="small">Mark all as read</a>
                                    </div>
                                </li>
                                <li>
                                    <div className="message-notif-scroll scrollbar-outer">
                                        <div className="notif-center">
                                            <a href="#">
                                                <div className="notif-img">
                                                    <img src="../assets/img/jm_denis.jpg" alt="Img Profile" />
                                                </div>
                                                <div className="notif-content">
                                                    <span className="subject">Jimmy Denis</span>
                                                    <span className="block">
                                                        How are you ?
                                                    </span>
                                                    <span className="time">5 minutes ago</span>
                                                </div>
                                            </a>
                                            <a href="#">
                                                <div className="notif-img">
                                                    <img src="../assets/img/chadengle.jpg" alt="Img Profile" />
                                                </div>
                                                <div className="notif-content">
                                                    <span className="subject">Chad</span>
                                                    <span className="block">
                                                        Ok, Thanks !
                                                    </span>
                                                    <span className="time">12 minutes ago</span>
                                                </div>
                                            </a>
                                            <a href="#">
                                                <div className="notif-img">
                                                    <img src="../assets/img/mlane.jpg" alt="Img Profile" />
                                                </div>
                                                <div className="notif-content">
                                                    <span className="subject">Jhon Doe</span>
                                                    <span className="block">
                                                        Ready for the meeting today...
                                                    </span>
                                                    <span className="time">12 minutes ago</span>
                                                </div>
                                            </a>
                                            <a href="#">
                                                <div className="notif-img">
                                                    <img src="../assets/img/talha.jpg" alt="Img Profile" />
                                                </div>
                                                <div className="notif-content">
                                                    <span className="subject">Talha</span>
                                                    <span className="block">
                                                        Hi, Apa Kabar ?
                                                    </span>
                                                    <span className="time">17 minutes ago</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <a className="see-all" href="javascript:void(0);">See all messages<i className="fa fa-angle-right" /> </a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown hidden-caret">
                            <a className="nav-link dropdown-toggle" href="#" id="notifDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-bell" />
                                <span className="notification">4</span>
                            </a>
                            <ul className="dropdown-menu notif-box animated fadeIn" aria-labelledby="notifDropdown">
                                <li>
                                    <div className="dropdown-title">You have 4 new notification</div>
                                </li>
                                <li>
                                    <div className="notif-scroll scrollbar-outer">
                                        <div className="notif-center">
                                            <a href="#">
                                                <div className="notif-icon notif-primary"> <i className="fa fa-user-plus" /> </div>
                                                <div className="notif-content">
                                                    <span className="block">
                                                        New user registered
                                                    </span>
                                                    <span className="time">5 minutes ago</span>
                                                </div>
                                            </a>
                                            <a href="#">
                                                <div className="notif-icon notif-success"> <i className="fa fa-comment" /> </div>
                                                <div className="notif-content">
                                                    <span className="block">
                                                        Rahmad commented on Admin
                                                    </span>
                                                    <span className="time">12 minutes ago</span>
                                                </div>
                                            </a>
                                            <a href="#">
                                                <div className="notif-img">
                                                    <img src="../assets/img/profile2.jpg" alt="Img Profile" />
                                                </div>
                                                <div className="notif-content">
                                                    <span className="block">
                                                        Reza send messages to you
                                                    </span>
                                                    <span className="time">12 minutes ago</span>
                                                </div>
                                            </a>
                                            <a href="#">
                                                <div className="notif-icon notif-danger"> <i className="fa fa-heart" /> </div>
                                                <div className="notif-content">
                                                    <span className="block">
                                                        Farrah liked Admin
                                                    </span>
                                                    <span className="time">17 minutes ago</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <a className="see-all" href="javascript:void(0);">See all notifications<i className="fa fa-angle-right" /> </a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown hidden-caret">
                            <a className="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
                                <i className="fas fa-layer-group" />
                            </a>
                            <div className="dropdown-menu quick-actions quick-actions-info animated fadeIn">
                                <div className="quick-actions-header">
                                    <span className="title mb-1">Quick Actions</span>
                                    <span className="subtitle op-8">Shortcuts</span>
                                </div>
                                <div className="quick-actions-scroll scrollbar-outer">
                                    <div className="quick-actions-items">
                                        <div className="row m-0">
                                            <a className="col-6 col-md-4 p-0" href="#">
                                                <div className="quick-actions-item">
                                                    <i className="flaticon-file-1" />
                                                    <span className="text">Generated Report</span>
                                                </div>
                                            </a>
                                            <a className="col-6 col-md-4 p-0" href="#">
                                                <div className="quick-actions-item">
                                                    <i className="flaticon-database" />
                                                    <span className="text">Create New Database</span>
                                                </div>
                                            </a>
                                            <a className="col-6 col-md-4 p-0" href="#">
                                                <div className="quick-actions-item">
                                                    <i className="flaticon-pen" />
                                                    <span className="text">Create New Post</span>
                                                </div>
                                            </a>
                                            <a className="col-6 col-md-4 p-0" href="#">
                                                <div className="quick-actions-item">
                                                    <i className="flaticon-interface-1" />
                                                    <span className="text">Create New Task</span>
                                                </div>
                                            </a>
                                            <a className="col-6 col-md-4 p-0" href="#">
                                                <div className="quick-actions-item">
                                                    <i className="flaticon-list" />
                                                    <span className="text">Completed Tasks</span>
                                                </div>
                                            </a>
                                            <a className="col-6 col-md-4 p-0" href="#">
                                                <div className="quick-actions-item">
                                                    <i className="flaticon-file" />
                                                    <span className="text">Create New Invoice</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown hidden-caret">
                            <a className="dropdown-toggle profile-pic" data-toggle="dropdown" href="#" aria-expanded="false">
                                <div className="avatar-sm">
                                    <img src="../assets/img/profile.jpg" alt="..." className="avatar-img rounded-circle" />
                                </div>
                            </a>
                            <ul className="dropdown-menu dropdown-user animated fadeIn">
                                <div className="dropdown-user-scroll scrollbar-outer">
                                    <li>
                                        <div className="user-box">
                                            <div className="avatar-lg"><img src="../assets/img/profile.jpg" alt="image profile" className="avatar-img rounded" /></div>
                                            <div className="u-text">
                                                <h4>Hizrian</h4>
                                                <p className="text-muted">santo@gmail.com</p><a href="profile.html" className="btn btn-xs btn-secondary btn-sm">View Profile</a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="dropdown-divider" />
                                        <a className="dropdown-item" href="#">My Profile</a>
                                        <a className="dropdown-item" href="#">My Balance</a>
                                        <a className="dropdown-item" href="#">Inbox</a>
                                        <div className="dropdown-divider" />
                                        <a className="dropdown-item" href="#">Account Setting</a>
                                        <div className="dropdown-divider" />
                                    </li>
                                </div>
                            </ul>
                        </li>
                    </ul> */}


                </div>
            </nav>
        </>
    )
}

export default Navbar;