import React from 'react';
import Layout from '../../Layout/Layout';

const OrgWonerDashboard = () => {
    return (
        <Layout>
            <h1>OrgWonerDashboard</h1>
        </Layout>
    )
}

export default OrgWonerDashboard;