import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import Layout from '../../../Layout/Layout';
import TeacherBalicDetails from './TeacherBalicDetails';
import { ButtonLoader } from '../../../../utils/Loader/LoaderComponent';
import TeacherAddress from './TeacherAddress';
import EmploymentDetails from './EmploymentDetails';
import TeacherBankDetails from './TeacherBankDetails';
import TeacherAdditionalDetails from './TeacherAdditionalDetails';
import TeacherPreviousExpriance from './TeacherPreviousExpriance';
import { get_single_teacher, messageClear, teacherClear } from '../../../../store/Reducers/administrator/teacherReducer';


const AddTeacher = () => {

    const [activeStep, setActiveStep] = useState(0);
    const childFormRef = useRef();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { teacherId } = useParams();


    const { loader, successMessage, errorMessage, teacher } = useSelector(state => state.teacher);


    // handleIncrement
    const handleIncrement = () => {
        if (childFormRef.current) {
            childFormRef.current.submitForm();
        }
    };


    // handleMessage
    const handleMessage = (message) => {
        // console.log("message", message)
        if (message) {
            setActiveStep((prev) => prev + 1);
        }
    }


    // handleDecrement
    const handleDecrement = () => {
        setActiveStep((prev) => prev - 1);
    };


    // steps
    const steps = [
        'Basic Details',
        'Address Details',
        'Employment Details',
        'Bank Details',
        'Additional Details',
        'Previous Exprience',
    ];


    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage)
            dispatch(messageClear())
        }
        if (successMessage) {
            toast.success(successMessage)
            dispatch(messageClear())
            sessionStorage.clear()
            navigate('/all-employee', { replace: true })
        }
    }, [errorMessage, successMessage])



    useEffect(() => {
        if (teacherId) {
            dispatch(get_single_teacher({ teacherId }))
        } else {
            dispatch(teacherClear())
        }
    }, [teacherId])

    return (
        <Layout>
            <div className="page-header">
                <div className='card py-3 mb-0' style={{ width: "100%" }}>
                    <Box>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </div>
            </div>
            <div>
                {activeStep === steps.length ? (
                    <h2>All Steps Complete</h2>
                ) : (
                    <>
                        {activeStep === 0 && <TeacherBalicDetails onSendMessage={handleMessage} ref={childFormRef} />}
                        {activeStep === 1 && <TeacherAddress onSendMessage={handleMessage} ref={childFormRef} />}
                        {activeStep === 2 && <EmploymentDetails onSendMessage={handleMessage} ref={childFormRef} />}
                        {activeStep === 3 && <TeacherBankDetails onSendMessage={handleMessage} ref={childFormRef} />}
                        {activeStep === 4 && <TeacherAdditionalDetails onSendMessage={handleMessage} ref={childFormRef} />}
                        {activeStep === 5 && <TeacherPreviousExpriance onSendMessage={handleMessage} ref={childFormRef} />}

                    </>
                )}
            </div>


            <div style={{ textAlign: "right" }}>
                <button
                    disabled={activeStep === 0}
                    onClick={handleDecrement}
                    className='btn btn-primary mr-2 px-3 py-2 fw-bold'
                >
                    Previous
                </button>

                <button
                    onClick={handleIncrement}
                    className='btn btn-primary px-3 py-2 fw-bold'
                    style={{ width: "90px" }}
                    disabled={loader ? true : false}
                >
                    {activeStep === steps.length - 1 ? (loader ? <ButtonLoader
                    /> : 'Submit') : 'Next'}
                </button>

            </div>
        </Layout>
    )
}

export default AddTeacher;