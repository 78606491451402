import React, { useState } from 'react';
import { Card, CardContent, Typography, Collapse } from '@mui/material';
import { styled } from '@mui/system';

import { IoDocumentTextSharp } from "react-icons/io5";



const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));


const Documents = () => {
    const [value, setValue] = useState(false);

    return (
        <div>
            <StyledCard onClick={() => setValue(!value)}>
                <div className='d-flex px-4 py-3 align-items-center' style={{ backgroundColor: "#e6e6e5", cursor: "pointer" }}>
                    <IoDocumentTextSharp style={{ color: "#4f49a4", fontSize: "22px" }} />
                    <span className='px-2' style={{ fontSize: "16px", color: "#000", fontWeight: "600" }}>Documents</span>
                </div>
            </StyledCard>
            <Collapse in={value}>
                <StyledCard>

                    <div className='p-3'>
                        <h2> Comming......</h2>
                    </div>
                </StyledCard>
            </Collapse>
        </div>
    );
}



export default Documents;