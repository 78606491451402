import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';
// import jwt from 'jwt-decode';


// org_woner_register
export const org_woner_register = createAsyncThunk(
    'org_woner/org_woner_register',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/org-woner-register', info);
            localStorage.setItem("verifyEmailToken", data.verifyEmailToken)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// verify_email
export const verify_email = createAsyncThunk(
    'org_woner/verify_email',
    async (obj, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/verify-email', obj);
            return fulfillWithValue(data)
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

// org_woner_login
export const org_woner_login = createAsyncThunk(
    'org_woner/org_woner_login',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/org-woner-login', info)
            localStorage.setItem('authToken', data.authToken)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// woner_org_logout
export const woner_org_logout = createAsyncThunk(
    'org_woner/woner_org_logout',
    async (navigate, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get('/woner-org-logout')
            localStorage.removeItem('authToken');
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


// add_organisation
export const add_organisation = createAsyncThunk(
    'org_woner/add_organisation',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/add-organisation', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_organisation
export const get_organisation = createAsyncThunk(
    'org_woner/get_organisation',
    async (_, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get('/get-organisation')
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


// add_head_organization
export const add_head_organization = createAsyncThunk(
    'org_woner/add_head_organization',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/add-head-organization', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_head_organization
export const get_head_organization = createAsyncThunk(
    'org_woner/get_head_organization',
    async ({ searchValue, page, parPage }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-head-organization?searchValue=${searchValue}&&page=${page}&&parPage=${parPage}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


// delete_head_organization
export const delete_head_organization = createAsyncThunk(
    'org_woner/delete_head_organization',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.delete(`/delete-head-organization/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)



// returnRole
const returnRole = (token) => {
    if (token) {
        const payload = token.split('.')[1];
        const decodedPayload = JSON.parse(atob(payload));
        const expireTime = new Date(decodedPayload.exp * 1000);
        if (new Date() > expireTime) {
            localStorage.removeItem('authToken');
            return '';
        } else {
            return decodedPayload;
        }
    } else {
        return '';
    }
};


export const orgWonerReducer = createSlice({
    name: "org_woner",
    initialState: {
        errorMessage: '',
        successMessage: '',
        logoutMessage: '',
        loader: false,
        verifyEmailToken: "",
        orgWonerInfo: returnRole(localStorage.getItem("authToken")),
        orgWonerToken: localStorage.getItem("authToken"),
        allOrganisation: [],
        headOrgList: [],
        headOrgCount: 0
    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ''
            state.successMessage = ''
            state.logoutMessage = ''
        },
        tokenClear: (state, _) => {
            state.orgWonerInfo = ''
            state.orgWonerToken = ''
        }
    },
    extraReducers: {
        [org_woner_register.pending]: (state, _) => {
            state.loader = true
        },
        [org_woner_register.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [org_woner_register.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
            state.verifyEmailToken = payload.verifyEmailToken
        },
        [verify_email.pending]: (state, _) => {
            state.loader = true
        },
        [verify_email.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [verify_email.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [org_woner_login.pending]: (state, _) => {
            state.loader = true
        },
        [org_woner_login.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [org_woner_login.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
            state.orgWonerInfo = returnRole(payload.authToken)
            state.orgWonerToken = payload.authToken
        },
        [woner_org_logout.fulfilled]: (state, { payload }) => {
            state.logoutMessage = payload.message
        },
        [add_organisation.pending]: (state, _) => {
            state.loader = true
        },
        [add_organisation.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [add_organisation.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [get_organisation.pending]: (state, _) => {
            state.loader = true
        },
        [get_organisation.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.allOrganisation = payload.allOrganisation
        },
        [add_head_organization.pending]: (state, _) => {
            state.loader = true
        },
        [add_head_organization.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [add_head_organization.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [get_head_organization.pending]: (state, _) => {
            state.loader = true
        },
        [get_head_organization.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.headOrgList = payload.headOrgList
            state.headOrgCount = payload.headOrgCount
        },
        [delete_head_organization.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [delete_head_organization.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
    }
});


export const { messageClear, tokenClear } = orgWonerReducer.actions;
export default orgWonerReducer.reducer;