import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../Layout/Layout';
import Drawer from '@mui/material/Drawer';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { ButtonLoader, TableLoader } from '../../../utils/Loader/LoaderComponent';
import Select from 'react-select';
import { sectionOption } from '../../../utils/other/data';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import moment from 'moment';
import Empty from '../../../utils/page/Empty';
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { confirmMessagge } from '../../../utils/other/aleartFunc';
import { FaGraduationCap } from "react-icons/fa";
import { useReactToPrint } from "react-to-print";
import { FaRegCopy } from "react-icons/fa6";
import { FiPrinter } from "react-icons/fi";
import { RiFileExcelLine } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa";
import { exportToExcel, exportToPDF } from '../../../utils/other/fileGenerate';
import { add_class, delete_class, get_all_class, get_single_class, messageClear, update_class } from '../../../store/Reducers/administrator/administratorReducer';



const ClassList = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState();

    const { searchValue } = useSelector(state => state.default);
    const { loader, successMessage, errorMessage, classList, singleClass, administratorInfo: { organisationId } } = useSelector(state => state.administrator);


    const { handleSubmit, control, setValue, register, formState: { errors }, reset } = useForm();

    const [defaultSection, setDetaultSection] = useState(null)


    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
    }


    // onSubmit
    const onSubmit = (data) => {
        const { className, sectionName } = data;
        const sectionNames = sectionName?.map(section => section.value);
        const obj = {
            className,
            sectionNames: sectionNames?.length > 0 ? sectionNames : []
        }

        if (isEditing) {
            dispatch(update_class({ editId, obj }))
        } else {
            dispatch(add_class(obj));
        }

    }


    // handleClassEdit
    const handleClassEdit = (editId) => {
        setIsEditing(true);
        setEditId(editId);
        dispatch(get_single_class(editId));
    }

    // handleOpenDrawer
    const handleOpenDrawer = () => {
        setOpen(true);
        setIsEditing(false);
        setDetaultSection(null)
    }


    // handleClassDelete
    const handleClassDelete = async (deleteId) => {
        const returnValue = await confirmMessagge('Delete');
        if (returnValue) {
            dispatch(delete_class(deleteId))
        }
    }


    //  createExceclFile
    const createExceclFile = () => {
        const data = classList?.map((d, i) => ({
            Sl: i + 1,
            'Class Name': d.className,
            'Sections': d.sectionNames.join(', '),
            'CreatedAt': moment(d.createdAt).format('DD/MM/YYYY'),
        }));
        exportToExcel(data, 'class_data.xlsx');
    }

    // createPdfFile
    const createPdfFile = () => {
        const data = classList?.map((d, i) => ({
            Sl: i + 1,
            'Class Name': d.className,
            'Sections': d.sectionNames.join(', '),
            'CreatedAt': moment(d.createdAt).format('DD/MM/YYYY'),
        }));

        exportToPDF(data, 'class_data.pdf');
    };

    const componentRef = useRef();


    // createPrint
    const createPrint = useReactToPrint({
        content: () => componentRef.current,
    });


    useEffect(() => {

        if (Object.keys(singleClass).length > 0 && isEditing) {
            setOpen(true);
            setValue('className', singleClass?.className);
            const array = singleClass?.sectionNames;

            const convertedArray = array?.map(item => ({
                value: item,
                label: item
            }));

            // console.log("convertedArray", convertedArray)

            setDetaultSection(convertedArray)
            setValue("sectionName", convertedArray)

        } else {
            setValue('className', '');
            setValue('sectionName', []);
        }
    }, [singleClass, setValue, isEditing]);


    useEffect(() => {
        const obj = {
            searchValue: '',
            organisationId: organisationId
        };
        dispatch(get_all_class(obj))
    }, [searchValue, successMessage, organisationId]);


    useEffect(() => {
        if (open && !isEditing) {
            reset();
        }
    }, [open, reset, isEditing]);


    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage);
            dispatch(messageClear());
        }
        if (successMessage) {
            toast.success(successMessage);
            dispatch(messageClear());
            setOpen(false);
            setIsEditing(false);
        }
    }, [errorMessage, successMessage, dispatch]);



    return (
        <Layout>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">

                            <div className="page-header mb-0">
                                <span style={{ fontSize: "18px", padding: "0px 8px 8px 0px" }}>
                                    <i className="fas fa-list" />
                                </span>
                                <h4 style={{ fontSize: "20px", marginBottom: "9px" }}>Class List</h4>

                                <span className='px-4'>
                                    <FaRegCopy title='Copy' style={{ fontSize: '20px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <RiFileExcelLine onClick={createExceclFile} title='Excel' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FaRegFilePdf onClick={createPdfFile} title='PDF' style={{ fontSize: '18px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FiPrinter onClick={createPrint} title='Print' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                </span>

                                <button onClick={handleOpenDrawer} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                    <span><i className="fa fa-plus pr-2" />Add</span>
                                </button>
                            </div>

                            <hr className='my-3' />

                            <div>
                                {loader ? (
                                    // Display loader while data is being fetched
                                    <TableLoader />
                                ) : classList?.length > 0 ? (
                                    // Render table if data exists
                                    <table className="tableStyle" ref={componentRef}>
                                        <thead>
                                            <tr>
                                                <th>Sl.</th>
                                                <th>Class Name</th>
                                                <th>Sections</th>
                                                <th>Created At</th>
                                                <th className="no-print">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {classList?.map((d, i) => (
                                                <tr key={i}>
                                                    <td data-label="Sl">{i + 1}</td>
                                                    <td data-label="Class Name">{d.className}</td>
                                                    <td data-label="Sections">
                                                        {d.sectionNames?.map((s, i) => (
                                                            <span
                                                                style={{ fontSize: "14px" }}
                                                                key={i}
                                                                className="bg-danger text-light px-2 py-1 mr-1 rounded"
                                                            >
                                                                {s}
                                                            </span>
                                                        ))}
                                                    </td>
                                                    <td>{moment(d.createdAt).format("DD/MM/YYYY")}</td>
                                                    <td data-label="Action" className="no-print">
                                                        <span title="Edit" className="mr-1" onClick={() => handleClassEdit(d._id)}>
                                                            <FaEdit style={{ fontSize: "20px", color: "green", cursor: "pointer" }} />
                                                        </span>
                                                        <span title="Delete" className="ml-1" onClick={() => handleClassDelete(d._id)}>
                                                            <MdDeleteForever style={{ fontSize: "24px", color: "red", cursor: "pointer" }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    // Display empty state when classList is empty
                                    <Empty data={`Class Not Found !`} />
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <Drawer anchor="right" open={open} onClose={() => setOpen(false)} style={{ width: '300px' }}>
                <div style={{ width: '400px' }}>
                    <DialogTitle style={{ fontSize: '16px' }}>{isEditing ? 'Edit Class' : 'Add Class'}</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <div className='px-2'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <label htmlFor="className">Class Name <span className='text-danger'>*</span></label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.className ? 'is-invalid' : ''}`}
                                    id="className"
                                    {...register('className', { required: 'Class Name is required' })}
                                />
                                {errors.className && <div className="invalid-feedback">{errors.className.message}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="sectionName">Section Name </label>
                                <Select
                                    value={defaultSection}
                                    isMulti
                                    name="sectionName"
                                    options={sectionOption}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    {...register('sectionName')}
                                    onChange={(selectOption) => {
                                        setDetaultSection(selectOption);
                                        handleSelect('sectionName', selectOption);
                                    }}
                                />
                            </div>
                            <div className="my-3 mx-2" style={{ textAlign: "right" }}>
                                <button disabled={loader} type="submit" style={{ width: "90px" }} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                    {loader ? <ButtonLoader /> : (isEditing ? 'Edit' : 'Add')}
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </Drawer>
        </Layout>
    );
}



export default ClassList;
