import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import toast from 'react-hot-toast';
import { useReactToPrint } from "react-to-print";
import { FaUserClock } from "react-icons/fa";
import moment from 'moment';
import { RiFileExcelLine } from "react-icons/ri";
import { IoIosEye } from "react-icons/io";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FaListUl, FaRegCopy, FaRegFilePdf } from 'react-icons/fa';
import { FiPrinter } from "react-icons/fi";
import StudentLayout from '../../Layout/StudentLayout/StudentLayout';
import { get_exam_schedule } from '../../../store/Reducers/examMaster/examReducer';
import Pagination from '../../Layout/Pagination';
import Empty from '../../../utils/page/Empty';
import { exportToExcel, exportToPDF } from '../../../utils/other/fileGenerate';
import { getSubjectDetails } from '../../../utils/other/returnFunction';



const StudentExamSchedule = () => {

    const dispatch = useDispatch();

    const { sessionValue } = useSelector(state => state.default);
    const { studentInfo: { studentId, role, organisationId, classId, section } } = useSelector(state => state.personalStudent);
    const { studentExamScheduleList, examScheduleCount } = useSelector(state => state.exam);
    const { searchValue } = useSelector(state => state.default);
    const { classList, subjectLists } = useSelector(state => state.administrator);



    const [currentPage, setCurrentPage] = useState(1)
    const [parPage, setParPage] = useState(1000000)


    const [openDialog, setOpenDialog] = useState(false);
    const [scheduleDetails, setScheduleDetails] = useState([]);

    // handleDialogClose
    const handleDialogClose = () => {
        setOpenDialog(false)
    }


    //  createExceclFile
    const createExceclFile = () => {
        const data = studentExamScheduleList?.map((d, i) => ({
            'Sl.': i + 1 + (currentPage - 1) * parPage,
            'Class': d.classId.className,
            'Section': d.section,
            'Exam Name': `${d.examName.examName} (${d.examName.examTypeName || 'N/A'})`
        }));
        exportToExcel(data, 'exam_schedule_data.xlsx');
    }



    // createPdfFile
    const createPdfFile = () => {
        const data = studentExamScheduleList?.map((d, i) => ({
            'Sl.': i + 1 + (currentPage - 1) * parPage,
            'Class': d.classId.className,
            'Section': d.section,
            'Exam Name': `${d.examName.examName} (${d.examName.examTypeName || 'N/A'})`
        }));
        exportToPDF(data, 'exam_schedule_data.pdf');
    }



    // handleItemPerPageChange
    const handleItemPerPageChange = (e) => {
        setParPage(e.target.value)
    }


    const componentRef = useRef();
    const componentRef2 = useRef();

    // createPrint
    const createPrint = useReactToPrint({
        content: () => componentRef.current,
    });

    // createSchedulePrint
    const createSchedulePrint = useReactToPrint({
        content: () => componentRef2.current
    })


    useEffect(() => {
        const obj = {
            parPage: parseInt(parPage),
            page: parseInt(currentPage),
            searchValue,
            sessionValue,
            classId,
            section,
        }
        dispatch(get_exam_schedule(obj))
    }, [searchValue, currentPage, parPage, sessionValue])


    // console.log("studentExamScheduleList", studentExamScheduleList)


    return (
        <StudentLayout>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">

                            <div className="page-header mb-0 d-flex">
                                <FaUserClock style={{ fontSize: '25px', marginBottom: "6px", marginRight: "8px" }} />
                                <h4 style={{ fontSize: "20px" }}>Schedule List</h4>

                                <span className='px-4'>
                                    <FaRegCopy title='Copy' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <RiFileExcelLine onClick={studentExamScheduleList?.length > 0 ? createExceclFile : null} title='Excel' style={{ fontSize: '24px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FaRegFilePdf onClick={studentExamScheduleList?.length > 0 ? createPdfFile : null} title='PDF' style={{ fontSize: '20px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FiPrinter onClick={createPrint} title='Print' style={{ fontSize: '24px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                </span>

                            </div>

                            <hr className='my-3' />

                            <div ref={componentRef}>
                                {
                                    studentExamScheduleList?.length > 0 ? (
                                        <table className="tableStyle print-content">
                                            <thead className=''>
                                                <tr>
                                                    <th>Sl.</th>
                                                    <th>Class</th>
                                                    <th>Section</th>
                                                    <th>Exam Name</th>
                                                    <th className='no-print'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    studentExamScheduleList?.map((d, i) => (
                                                        <tr key={i}>
                                                            <td data-lable="Sl">{i + 1 + (currentPage - 1) * parPage}</td>
                                                            <td data-lable="Class">{d.classId.className}</td>
                                                            <td data-lable="Section">{d.section}</td>
                                                            <td data-lable="ExamName">{`${d.examName.examName} (${d.examName.examTypeName || 'N/A'})`}</td>

                                                            <td data-lable="Action" className='no-print'>
                                                                <span title='Details' className='mr-1' onClick={() => { setOpenDialog(true); setScheduleDetails(d); }}>
                                                                    <IoIosEye style={{ fontSize: '24px', color: 'blue', marginTop: "3px", cursor: "pointer" }} />
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    ) : (
                                        <Empty data={`Schedule Not Found!`} />
                                    )
                                }
                            </div>

                        </div>

                        {/* {
                            examScheduleCount > 10 && <div class="d-flex justify-content-between">
                                <div className="col-md-4 d-flex justify-content-start align-items-center itemParPageStyle">
                                    <div class="d-flex mb-2">
                                        <label for="itemPerPage" class="col-sm-auto col-form-label">Select items per page :</label>
                                        <div class="col-sm-auto">
                                            <select
                                                id='itemPerPage'
                                                style={{ width: "65px", marginLeft: "-23px", marginTop: "7px", padding: "3px" }}
                                                onChange={handleItemPerPageChange}
                                                value={parPage}
                                            >
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="50000">All</option>

                                            </select>
                                        </div>
                                    </div>

                                </div>

                                <div className='px-4 pt-2'>
                                    {
                                        examScheduleCount >= parPage ?
                                            <Pagination
                                                pageNumber={currentPage}
                                                setPageNumber={setCurrentPage}
                                                totalItem={examScheduleCount}
                                                parPage={parPage}
                                                showItem={Math.floor(examScheduleCount / parPage)}
                                            /> : ''
                                    }
                                </div>

                            </div>
                        } */}

                    </div>

                </div>

            </div>



            <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="md" fullWidth>
                <DialogTitle>
                    <div className="page-header mb-0 d-flex">
                        <FaListUl style={{ fontSize: '20px', marginRight: "8px", marginTop: "4px" }} />
                        <h4 style={{ fontSize: "20px" }}>Schedule Details</h4>
                        <span className='px-4'>
                            <FiPrinter onClick={createSchedulePrint} title='Print' style={{ fontSize: '24px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                        </span>
                    </div>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setOpenDialog(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography ref={componentRef2}>
                        <div className='print-content'>

                            <div className='text-center'>
                                <p style={{ fontSize: "17px", fontWeight: "600" }} className='mb-0'>Exam : {scheduleDetails?.examName?.examName} ({scheduleDetails?.examName?.examTypeName || 'N/A'})</p>
                                <p style={{ fontSize: "16px", fontWeight: "600" }}>Class : {scheduleDetails?.classId?.className} ({scheduleDetails?.section})</p>
                            </div>

                            {Object.keys(scheduleDetails).length > 0 ? (
                                <table className="tableStyle">
                                    <thead className=''>
                                        <tr>
                                            <th>Subject</th>
                                            <th>Date</th>
                                            <th>Starting Time</th>
                                            <th>Ending Time</th>
                                            <th>Hall Room</th>
                                        </tr>
                                    </thead>
                                    <tbody className=''>
                                        {scheduleDetails?.schedule?.map((d, i) => {
                                            const subjectDetails = getSubjectDetails(subjectLists, d?.subjectId);
                                            const subjectName = subjectDetails ? subjectDetails?.subjectName : '';

                                            return <tr key={i}>
                                                <td>{subjectName}</td>
                                                <td>{moment(d.date).format('DD, MMM, YYYY')}</td>
                                                <td>{moment(d.startingTime, "HH:mm").format("h:mm A")}</td>
                                                <td>{moment(d.endingTime, "HH:mm").format("h:mm A")}</td>
                                                <td>{d.hallRoom}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            ) : (
                                <Empty data={`Date Not Found !`} />
                            )}
                        </div>
                    </Typography>
                </DialogContent>
            </Dialog>


        </StudentLayout>
    )
}

export default StudentExamSchedule;