import React from 'react';
import Layout from '../../Layout/Layout';

const Accounting = () => {
    return (
        <Layout>
            <h1>Accounting</h1>
        </Layout>
    )
}

export default Accounting;