import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../Layout/Layout';
import Empty from '../../../utils/page/Empty';
import Pagination from '../../Layout/Pagination';
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { MdOutlineViewQuilt } from "react-icons/md";
import { image_url, server_url } from '../../../api/api';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { getActiveClassName, getActiveRoll, getActiveSession, useOptionsFromData } from '../../../utils/other/returnFunction';
import { get_all_student, update_student_status, messageClear, student_filter, delete_student } from '../../../store/Reducers/student/studentReducer';
import { genders, status } from '../../../utils/other/data';
import { ButtonLoader, TableLoader } from '../../../utils/Loader/LoaderComponent';
import { FaUserGraduate } from "react-icons/fa";
import { FaRegCopy } from "react-icons/fa6";
import { RiFileExcelLine } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa";
import { exportToExcel, exportToPDF } from '../../../utils/other/fileGenerate';
import { useReactToPrint } from "react-to-print";
import ClipboardJS from 'clipboard';
import { FiPrinter } from "react-icons/fi";
import { confirmMessagge } from '../../../utils/other/aleartFunc';
import { FaFileImport } from "react-icons/fa";
import ImportStudents from './ImportStudents';


const AllStudents = () => {
    const [openModel, setOpenModel] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();


    const { studentCount, allStudents, loader, successMessage, errorMessage } = useSelector(state => state.student);
    const { searchValue } = useSelector(state => state.default);
    const { classList } = useSelector(state => state.administrator);
    const { sessionValue } = useSelector(state => state.default);

    const [currentPage, setCurrentPage] = useState(1)
    const [parPage, setParPage] = useState(20)
    const [studentDetails, setStudetnDetails] = useState({});


    const [classListOption, setClassListOption] = useState("")
    const [sectionOption, setSectionOption] = useState("")
    const genderList = useOptionsFromData(genders?.gender);
    const statusList = useOptionsFromData(status?.status);


    const [classValue, setClassValue] = useState(null);
    const [sectionValue, setSectionValue] = useState(null);
    const [genderValue, setGenderValue] = useState(null);
    const [statusValue, setStatusValue] = useState(null);



    //  handleClassChange
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);

        if (fieldName === 'className') {
            setSectionValue({ value: "", label: "Select Section" })
            setValue("section", { label: '', value: '' })
            const secOption = classList?.filter((item) => item._id === selectOption.value);
            const array = secOption[0]?.sectionNames;
            const convertedArray = array?.map(item => ({
                value: item,
                label: item
            }));
            setSectionOption(convertedArray)
        }
    }


    // handleItemPerPageChange
    const handleItemPerPageChange = (e) => {
        setParPage(e.target.value)
    }


    // handleStudentDetails
    const handleStudentDetails = (id) => {
        navigate(`/student-details/${id}`)
        // setOpen(true)
        // setStudetnDetails(data)
    }


    // handleStudentEdit
    const handleStudentEdit = (id) => {
        navigate(`/admission-students/${id}`)
    }

    // handleClassDelete
    const handleClassDelete = async (deleteId) => {
        const returnValue = await confirmMessagge('Delete');
        if (returnValue) {
            dispatch(delete_student(deleteId))
        }
    }

    // handleStatus
    const handleStatus = (value, statusId) => {
        const obj = {
            statusId,
            value
        }
        dispatch(update_student_status(obj))
    }


    // studentFilter
    const studentFilter = (data) => {
        const obj = {
            sessionValue,
            className: data.className ? data.className.value : '',
            section: data.section ? data.section.value : '',
            gender: data.gender ? data.gender.value : '',
            status: data.status ? data.status.value : '',
        }

        console.log("Click")

        dispatch(student_filter(obj))
    }


    // handleReset
    const handleReset = () => {
        setClassValue({ value: "", label: "Select Class" })
        setValue("className", { label: '', value: '' })

        setSectionValue({ value: "", label: "Select Section" })
        setValue("section", { label: '', value: '' })
        setSectionOption([])

        setGenderValue({ value: "", label: "Select Gender" })
        setValue("gender", { label: '', value: '' })

        setStatusValue({ value: "", label: "Select Status" })
        setValue("status", { label: '', value: '' })

        const obj = {
            sessionValue,
            className: '',
            section: '',
            gender: '',
            status: '',
        }

        dispatch(student_filter(obj))

    }

    //  createExceclFile
    const createExceclFile = () => {
        const data = allStudents?.map((student, i) => ({
            Sl: i + 1 + (currentPage - 1) * parPage,
            FirstName: student.basicDetails.firstName,
            LastName: student.basicDetails.lastName,
            Class: getActiveClassName(classList, student.sessions),
            Section: getActiveSession(student.sessions),
            Roll: getActiveRoll(student.sessions),
            Email: student.basicDetails.email,
            PhoneNumber: student.basicDetails.phoneNumber,
            Gender: student.basicDetails.gender,
            DateOfBirth: new Date(student.basicDetails.dateOfBirth).toLocaleDateString(),
            EnrollmentNumber: student.basicDetails.enrollmentNumber,
            DateOfAdmission: new Date(student.basicDetails.dateOfAdmission).toLocaleDateString(),
            Religion: student.basicDetails.religion,
            FatherName: student.guardianDetails.fatherName,
            MotherName: student.guardianDetails.motherName,
            GuardianPhoneNumber: student.guardianDetails.guardianPhoneNumber,
            GuardianOccupation: student.guardianDetails.guardianOccupation,
            BankName: student.bankDetails.bankName,
            BranchName: student.bankDetails.branchName,
            IFSCCode: student.bankDetails.ifscCode,
            AccountHolderName: student.bankDetails.accountHolderName,
            AccountNumber: student.bankDetails.accountNumber,
            Country: student.addressDetails.country,
            State: student.addressDetails.state,
            City: student.addressDetails.city,
            Pincode: student.addressDetails.pincode,
            FullAddress: student.addressDetails.fullAddress,
            Weight: student.medicalDetails.weight,
            Height: student.medicalDetails.height,
            Allergies: student.medicalDetails.allergies,
            BloodGroup: student.medicalDetails.bloodGroup,
            Status: student.status,
            Role: student.role
        }));

        exportToExcel(data, 'student_data.xlsx');
    }


    // createPdfFile
    const createPdfFile = () => {
        const data = allStudents?.map((student, i) => ({
            Sl: i + 1 + (currentPage - 1) * parPage,
            Name: student.basicDetails.fullName,
            Class: getActiveClassName(classList, student.sessions),
            Section: getActiveSession(student.sessions),
            Roll: getActiveRoll(student.sessions),
            Gender: student.basicDetails.gender,
            Phone: student.basicDetails.phoneNumber,
        }));
        exportToPDF(data, 'student_data.pdf');
    }

    const componentRef = useRef();


    // createPrint
    const createPrint = useReactToPrint({
        content: () => componentRef.current,
    });


    const tableRef = useRef(null);

    // handleCopy
    const handleCopy = () => {
        const clipboard = new ClipboardJS('.btn-copy', {
            target: () => tableRef.current,
        });

        clipboard.on('success', (e) => {
            e.clearSelection();
            alert('Table data copied to clipboard');
        });

        clipboard.on('error', (e) => {
            alert('Copy failed. Please select the table data manually and copy it.');
        });

        return () => {
            clipboard.destroy();
        };
    }


    useEffect(() => {
        const options = classList?.map(item => ({
            value: item._id,
            label: item.className
        }));
        setClassListOption(options)
    }, [classList])

    useEffect(() => {
        const obj = {
            parPage: parseInt(parPage),
            page: parseInt(currentPage),
            searchValue,
            sessionValue
        }
        dispatch(get_all_student(obj))
    }, [searchValue, currentPage, parPage, sessionValue])


    useEffect(() => {
        const obj = {
            parPage: parseInt(parPage),
            page: parseInt(currentPage),
            searchValue,
            sessionValue
        }

        if (errorMessage) {
            toast.error(errorMessage)
            dispatch(messageClear())
        }
        if (successMessage) {
            toast.success(successMessage)
            dispatch(messageClear())
            dispatch(get_all_student(obj))
            setOpenModel(false)
        }

    }, [errorMessage, successMessage])


    // console.log("allStudents", allStudents)

    return (
        <Layout>

            <div className="card mb-3">
                <div className="card-body py-">

                    <form onSubmit={handleSubmit(studentFilter)}>

                        <div className="row">

                            <div className="col-md-6 col-lg-3">
                                <div className="form-group">
                                    <Select
                                        placeholder="Select Class"
                                        value={classValue}
                                        {...register('className')}
                                        onChange={(selectOption) => {
                                            setClassValue(selectOption);
                                            handleSelect('className', selectOption);
                                        }}
                                        options={classListOption}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3">
                                <div className="form-group">
                                    <Select
                                        {...register('section')}
                                        placeholder="Select Section"
                                        value={sectionValue}
                                        onChange={(selectOption) => {
                                            setSectionValue(selectOption);
                                            handleSelect('section', selectOption);
                                        }}
                                        options={sectionOption}
                                        isDisabled={sectionOption?.length > 0 ? false : true}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3">
                                <div className="form-group">
                                    <Select
                                        placeholder="Select Gender"
                                        value={genderValue}
                                        {...register('gender')}
                                        onChange={(selectOption) => {
                                            setGenderValue(selectOption);
                                            handleSelect('gender', selectOption);
                                        }}
                                        options={genderList}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3">
                                <div className="form-group">
                                    <Select
                                        placeholder="Select Status"
                                        value={statusValue}
                                        {...register('status')}
                                        onChange={(selectOption) => {
                                            setStatusValue(selectOption);
                                            handleSelect('status', selectOption);
                                        }}
                                        options={statusList}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="mr-2 text-right">
                            <button type='button' onClick={handleReset} style={{ width: "80px" }} className="btn btn-danger px-4 py-2 mr-2 font-weight-bold">
                                Reset
                            </button>
                            <button style={{ width: "80px" }} disabled={loader ? true : false} className="btn btn-secondary px-4 py-2 font-weight-bold">
                                {/* {
                                    loader ? <ButtonLoader /> : 'Filter'
                                } */}
                                Filter
                            </button>
                        </div>

                    </form>

                </div>
            </div>


            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">

                            <div className="page-header mb-0">
                                <FaUserGraduate style={{ fontSize: '21px', marginBottom: "6px", marginRight: "8px" }} />
                                <h4 style={{ fontSize: "20px" }}>Student List</h4>

                                <span className='px-4'>
                                    <FaRegCopy onClick={handleCopy} title='Copy' style={{ fontSize: '20px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <RiFileExcelLine onClick={createExceclFile} title='Excel' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FaRegFilePdf onClick={createPdfFile} title='PDF' style={{ fontSize: '18px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FiPrinter onClick={createPrint} title='Print' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                </span>

                                <div className='mb-2 mx-4' onClick={() => setOpenModel(true)}>
                                    <FaFileImport title='Import Student' style={{ fontSize: '23px', cursor: "pointer" }} />
                                </div>

                                <Link to='/admission-students' className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                    <span><i className="fa fa-plus pr-2" />Add</span>
                                </Link>
                            </div>

                            <hr className='my-3' />

                            {loader ? (
                                // Display loader while data is being fetched
                                <TableLoader />
                            ) : allStudents?.length > 0 ? (
                                // Render table if data exists
                                <div ref={tableRef}>
                                    <table className="tableStyle" ref={componentRef}>
                                        <thead>
                                            <tr>
                                                <th>Sl.</th>
                                                <th>Photo</th>
                                                <th>Name</th>
                                                <th>Class</th>
                                                <th>Section</th>
                                                <th>Roll</th>
                                                <th>Gender</th>
                                                <th>Phone</th>
                                                <th className='no-print'>Status</th>
                                                <th className='no-print'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                allStudents?.map((d, i) => (
                                                    <tr key={i}>
                                                        <td >{i + 1 + (currentPage - 1) * parPage}</td>
                                                        <td data-label="Photo">
                                                            <img style={{ width: "35px", height: "35px", borderRadius: "50%" }} src={d?.studentImage ? `${server_url}/${d?.studentImage}` : `${image_url}/assets/img/student/male.png`} alt="Img" />
                                                        </td>
                                                        <td data-label="Name">{d.basicDetails.fullName}</td>
                                                        <td data-label="Class">{getActiveClassName(classList, d.sessions)}</td>
                                                        <td data-label="Section">{getActiveSession(d.sessions)}</td>
                                                        <td data-label="Roll">{getActiveRoll(d.sessions)}</td>
                                                        <td data-label="Gender">{d.basicDetails.gender}</td>
                                                        <td data-label="Phone">{d.basicDetails.phoneNumber}</td>
                                                        <td data-lable="Status" className='no-print'>
                                                            <select onChange={(e) => handleStatus(e.target.value, d._id)} className={`form-select p-1 px-2 text-${d.status === 'Approved' ? 'success' : 'danger'}`} style={{ width: "100%", fontWeight: "600", fontSize: "14px" }}>
                                                                <option selected={d.status === 'Approved' ? true : false} className='text-success' value="Approved">Approved</option>
                                                                <option selected={d.status === 'Blocked' ? true : false} className='text-danger' value="Blocked">Blocked</option>
                                                            </select>
                                                        </td>
                                                        <td data-label="Action" className='no-print'>
                                                            <span title='Details' className='mr-1' onClick={() => handleStudentDetails(d._id)}>
                                                                <MdOutlineViewQuilt style={{ fontSize: '22px', color: 'blue', marginTop: "3px" }} />
                                                            </span>
                                                            <span title='Edit' className='mr-1 ml-1' onClick={() => handleStudentEdit(d._id)}>
                                                                <FaEdit style={{ fontSize: '18px', color: 'green' }} />
                                                            </span>
                                                            <span title='Delete' className='ml-1' onClick={() => handleClassDelete(d._id)}>
                                                                <MdDeleteForever style={{ fontSize: '22px', color: 'red' }} />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                // Display empty state when classList is empty
                                <Empty data={`Student Not Found!`} />
                            )}

                        </div>

                        {
                            studentCount > 20 && <div class="d-flex justify-content-between">
                                <div className="col-md-4 d-flex justify-content-start align-items-center itemParPageStyle">
                                    <div class="d-flex mb-2">
                                        <label for="itemPerPage" class="col-sm-auto col-form-label">Select items per page :</label>
                                        <div class="col-sm-auto">
                                            <select
                                                id='itemPerPage'
                                                style={{ width: "65px", marginLeft: "-23px", marginTop: "7px", padding: "3px" }}
                                                onChange={handleItemPerPageChange}
                                                value={parPage}
                                            >
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="5000">All</option>

                                            </select>
                                        </div>
                                    </div>

                                </div>

                                <div className='px-4 pt-2'>
                                    {
                                        studentCount >= parPage ?
                                            <Pagination
                                                pageNumber={currentPage}
                                                setPageNumber={setCurrentPage}
                                                totalItem={studentCount}
                                                parPage={parPage}
                                                showItem={Math.floor(studentCount / parPage)}
                                            /> : ''
                                    }
                                </div>

                            </div>
                        }

                    </div>

                </div>

            </div>


            <ImportStudents openModel={openModel} setOpenModel={setOpenModel} />

        </Layout>
    )
}


export default AllStudents;