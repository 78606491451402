import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { confirmMessagge } from '../../../utils/other/aleartFunc';
import { useDispatch, useSelector } from 'react-redux';
import { messageClear, organization_logout, tokenClear } from '../../../store/Reducers/organisation/organizationReducer';
import toast from 'react-hot-toast';


const OrgSidebar = () => {
    const location = useLocation();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { logoutMessage } = useSelector(state => state.organization);

    const administratorRoutes = ['/all-administrator', '/add-administrator'];
    const isAdministratorRoute = administratorRoutes?.includes(location?.pathname);


    // logoutFunction
    const logoutFunction = async () => {
        const returnValue = await confirmMessagge('Logout');
        if (returnValue) {
            dispatch(organization_logout())
        }
    }

    useEffect(() => {
        if (logoutMessage) {
            toast.success(logoutMessage)
            dispatch(messageClear())
            dispatch(tokenClear())
        }
    }, [logoutMessage])



    return (
        <div className="sidebar sidebar-style-2" data-background-color="dark22">
            <div className="sidebar-wrapper scrollbar scrollbar-inner">
                <div className="sidebar-content">

                    <ul className="nav nav-primary">
                        {/* <li className="nav-section">
                            <span className="sidebar-mini-icon">
                                <i className="fa fa-ellipsis-h" />
                            </span>
                            <h4 className="text-section">Navigation</h4>
                        </li> */}

                        <li className={`nav-item ${location?.pathname === '/organization-dashboard' ? 'active' : ''}`}>
                            <Link to={`/organization-dashboard`}>
                                <i className="fas fa-th-large" />
                                <p>Dashboard</p>
                            </Link>
                        </li>

                        <li className={`nav-item ${isAdministratorRoute ? 'active' : ''}`}>
                            <a data-toggle="collapse" href="#myOrg">
                                <i className="fas fa-home" />
                                <p>Administrator</p>
                                <span className="caret" />
                            </a>
                            <div className={`collapse ${isAdministratorRoute ? 'show' : ''}`} id="myOrg">
                                <ul className="nav nav-collapse p-0">
                                    <li className=''>
                                        <Link to="/all-administrator">
                                            <span className="sub-item">All Administrator</span>
                                        </Link>
                                    </li>
                                    <li className=''>
                                        <Link to="/add-administrator">
                                            <span className="sub-item">Add Administrator</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>


                        <li className="nav-item">
                            <Link onClick={logoutFunction}>
                                <i className="fas fa-sign-out-alt" />
                                <p>Logout</p>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default OrgSidebar;