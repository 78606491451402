import React, { useEffect, useRef } from 'react';
import StudentLayout from '../../Layout/StudentLayout/StudentLayout';
import { useDispatch, useSelector } from 'react-redux';
import { get_class_schedule } from '../../../store/Reducers/class/classReducer';
import Empty from '../../../utils/page/Empty';
import { getSubjectDetails, getTeacehrName } from '../../../utils/other/returnFunction';
import moment from 'moment';
import { useReactToPrint } from "react-to-print";
import { FaUserClock } from "react-icons/fa";
import { FaPrint } from "react-icons/fa";
import { FiPrinter } from "react-icons/fi";


const StudentClassSchedule = () => {

    const dispatch = useDispatch();

    const { studentInfo: { studentId, role, organisationId, classId, section } } = useSelector(state => state.personalStudent);
    const { classList, subjectLists } = useSelector(state => state.administrator);
    const { classSchedule } = useSelector(state => state.class);
    const { allTeachers } = useSelector(state => state.teacher);

    const componentRef = useRef();

    const createPrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        const obj = {
            classId,
            section
        }
        dispatch(get_class_schedule(obj))
    }, [classId, section]);


    // console.log("classSchedule", classSchedule[5].schedule.length)

    return (
        <StudentLayout>
            <div className="card">
                <div className="card-body">
                    {classSchedule?.length > 0 ? (
                        <>
                            <div className="page-header mb-0 d-flex">
                                <FaUserClock style={{ fontSize: '25px', marginBottom: "6px", marginRight: "8px" }} />
                                <h4 style={{ fontSize: "19px" }}>Schedule List</h4>
                                <span className='px-4'>
                                    <FiPrinter onClick={createPrint} title='Print' className='faPrintStyle' />
                                </span>
                            </div>
                            <hr className='my-3 hrStyle' />
                            <table className="newTableStyle" ref={componentRef}>
                                <tbody>
                                    {classSchedule?.map((d, i) => (
                                        d.schedule.length > 0 && ( // Check if schedule length is greater than 0
                                            <tr key={i} className='border'>
                                                <td data-label="Day" className='border'>{d.day}</td>
                                                {d?.schedule.map((s, j) => {
                                                    const subjectDetails = getSubjectDetails(subjectLists, s?.subjectId);
                                                    const subjectName = subjectDetails ? subjectDetails?.subjectName : '';
                                                    const teacherDetails = getTeacehrName(allTeachers, s?.teacherId);

                                                    return (
                                                        <td data-label={s.isBreak !== true ? 'Subject' : 'Break'}
                                                            className={`border ${s.isBreak === true ? 'bg-danger text-light' : ''}`}
                                                            key={j}>
                                                            <div>
                                                                <p className='py-0 my-0' style={{ fontSize: '15px', fontWeight: '700' }}>
                                                                    {s.isBreak !== true ? subjectName : 'Break'}
                                                                </p>
                                                                <p className='py-0 my-0' style={{ fontSize: '12px' }}>
                                                                    ({moment(s.startingTime, "HH:mm").format("h:mm A")} - {moment(s.endingTime, "HH:mm").format("h:mm A")})
                                                                </p>
                                                                {s.isBreak !== true && (
                                                                    <p className='py-0 my-0' style={{ fontSize: '13px' }}>
                                                                        Teacher: {teacherDetails?.teacherBasicDetails?.fullName}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        )
                                    ))}
                                </tbody>

                            </table>
                        </>
                    ) : (
                        <Empty data={`Schedule List Not Found!`} />
                    )}
                </div>
            </div>
        </StudentLayout>
    );
}

export default StudentClassSchedule;
