import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { administrator_logout, tokenClear, messageClear } from '../../../store/Reducers/administrator/administratorReducer';
import { confirmMessagge } from '../../../utils/other/aleartFunc';
import { FaRegUser } from "react-icons/fa";
import { sessionValueClear } from '../../../store/Reducers/defaultReducer';


const AdministratorSidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { logoutMessage, administratorInfo: { role, organisationId } } = useSelector(state => state.administrator);

    const studentRoutes = ['/all-students', '/admission-students', '/students-authenticate'];
    const isStudentRoute = studentRoutes?.includes(location?.pathname);

    const teacherRoutes = ['/all-employee', '/add-employee', '/employee-authenticate'];
    const isTeacherRoute = teacherRoutes?.includes(location?.pathname);

    const academicRoutes = ['/class-list', '/teachers-assign', '/subject-register', '/subjects-assign-student', '/class-schedule', '/teacher-schedule', '/student-promotion'];
    const isAcademicRoute = academicRoutes?.includes(location?.pathname);

    const classRoutes = ['/class-list', '/teachers-assign'];
    const isClassRoute = classRoutes?.includes(location?.pathname);

    const subjectRoutes = ['/subject-register', '/subjects-assign-student'];
    const isSubjectRoute = subjectRoutes?.includes(location?.pathname);

    const examMasterRoutes = ['/exam-term', '/exam-hall', '/mark-distribution', '/exam-setup', '/exam-schedule', '/mark-entries', '/grade-range', '/report-card'];
    const isExamMasterRoutes = examMasterRoutes?.includes(location?.pathname);

    const examRoutes = ['/exam-term', '/exam-hall', '/mark-distribution', '/exam-setup'];
    const isExamRoutes = examRoutes?.includes(location?.pathname);

    const marksRoutes = ['/mark-entries', '/grade-range'];
    const isMarksRoutes = marksRoutes?.includes(location?.pathname);

    const attendanceRoutes = ['/student-attendance', '/employee-attendance', '/exam-attendance'];
    const isAttendanceRoutes = attendanceRoutes?.includes(location?.pathname);

    const feesRoutes = ['/fees-type', '/fees-group', '/edit-fees-group', '/fine-setup', '/fees-allocation'];
    const isFeesTypeRoutes = feesRoutes.some(route => location.pathname.startsWith(route));


    // logoutFunction
    const logoutFunction = async () => {
        const returnValue = await confirmMessagge('Logout');
        if (returnValue) {
            dispatch(administrator_logout())
        }
    }

    useEffect(() => {
        if (logoutMessage) {
            toast.success(logoutMessage)
            dispatch(messageClear())
            dispatch(tokenClear())
            dispatch(sessionValueClear())
        }
    }, [logoutMessage])


    return (
        <div className="sidebar sidebar-style-2" data-background-color="dark22">
            <div className="sidebar-wrapper scrollbar scrollbar-inner">
                <div className="sidebar-content">

                    <ul className="nav nav-primary">
                        <li className="nav-section">
                            <span className="sidebar-mini-icon">
                                <i className="fa fa-ellipsis-h" />
                            </span>
                            <h4 className="text-section">Navigation</h4>
                        </li>

                        <li className={`nav-item ${location?.pathname === '/administrator-dashboard' ? 'active' : ''}`}>
                            <Link to='/administrator-dashboard'>
                                <i className="fas fa-th-large" />
                                <p>Dashboard</p>
                            </Link>
                        </li>

                        <li className={`nav-item ${isAcademicRoute ? 'active' : ''}`}>
                            <a data-toggle="collapse" href="#Academic">
                                <i className="fas fa-home" />
                                <p>Academic</p>
                                <span className="caret" />
                            </a>
                            <div className={`collapse ${isAcademicRoute ? 'show' : ''}`} id="Academic">
                                <ul className="nav nav-collapse p-0">

                                    <li className={`nav-item m-0 p-0`}>
                                        <a data-toggle="collapse" href="#classSection">
                                            {/* <i className="fas fa-list" /> */}
                                            Class & Section
                                            <span className="caret" />
                                        </a>
                                        <div className={`collapse ${isClassRoute ? 'show' : ''}`} id="classSection">
                                            <ul className="nav nav-collapse p-0" style={{ paddingBottom: "0" }}>
                                                <li className='p-0 m-0'>
                                                    <Link to='/class-list'>
                                                        <span className="sub-item">Class List</span>
                                                    </Link>
                                                </li>

                                                <li className='p-0 m-0'>
                                                    <Link to='/teachers-assign'>
                                                        <span className="sub-item">Assign Class Teacher</span>
                                                    </Link>
                                                </li>

                                            </ul>
                                        </div>
                                    </li>

                                    <li className={`nav-item m-0 p-0`}>
                                        <a data-toggle="collapse" href="#subjectt">
                                            Create Subject
                                            <span className="caret" />
                                        </a>
                                        <div className={`collapse ${isSubjectRoute ? 'show' : ''}`} id="subjectt">
                                            <ul className="nav nav-collapse p-0" style={{ paddingBottom: "0" }}>
                                                <li className='p-0 m-0'>
                                                    <Link to='/subject-register'>
                                                        <span className="sub-item">Subject Register</span>
                                                    </Link>
                                                </li>

                                                <li className='p-0 m-0'>
                                                    <Link to='/subjects-assign-student'>
                                                        <span className="sub-item">Subject Assign</span>
                                                    </Link>
                                                </li>

                                            </ul>
                                        </div>
                                    </li>

                                    <li className='p-0 m-0'>
                                        <Link to="/class-schedule">
                                            <span className="sub-item">Class Schedule</span>
                                        </Link>
                                    </li>
                                    <li className='p-0 m-0'>
                                        <Link to="/teacher-schedule">
                                            <span className="sub-item">Teacher Schedule</span>
                                        </Link>
                                    </li>
                                    <li className='p-0 m-0'>
                                        <Link to="/student-promotion">
                                            <span className="sub-item">Student Promotion</span>
                                        </Link>
                                    </li>

                                </ul>
                            </div>
                        </li>

                        <li className={`nav-item ${isStudentRoute ? 'active' : ''}`}>
                            <a data-toggle="collapse" href="#student">
                                <i className="fas fa-user-friends" />
                                <p>Student</p>
                                <span className="caret" />
                            </a>
                            <div className={`collapse ${isStudentRoute ? 'show' : ''}`} id="student">
                                <ul className="nav nav-collapse p-0">

                                    <li className='p-0 m-0'>
                                        <Link to="/admission-students">
                                            <span className="sub-item">Admission Form</span>
                                        </Link>
                                    </li>

                                    <li className='p-0 m-0'>
                                        <Link to="/all-students">
                                            <span className="sub-item">All Student</span>
                                        </Link>
                                    </li>

                                    <li className='p-0 m-0'>
                                        <Link to="/students-authenticate">
                                            <span className="sub-item">Student Authenticate</span>
                                        </Link>
                                    </li>

                                </ul>
                            </div>
                        </li>

                        <li className={`nav-item ${isTeacherRoute ? 'active' : ''}`}>
                            <a data-toggle="collapse" href="#teacher">
                                <i className="fas fa-user-tie" />
                                <p>Employee</p>
                                <span className="caret" />
                            </a>
                            <div className={`collapse ${isTeacherRoute ? 'show' : ''}`} id="teacher">
                                <ul className="nav nav-collapse p-0">
                                    <li className='p-0 m-0'>
                                        <Link to="/all-employee">
                                            <span className="sub-item">All Employee</span>
                                        </Link>
                                    </li>

                                    <li className='p-0 m-0'>
                                        <Link to="/add-employee">
                                            <span className="sub-item">Add Employee</span>
                                        </Link>
                                    </li>

                                    <li className='p-0 m-0'>
                                        <Link to="/employee-authenticate">
                                            <span className="sub-item">Authenticate</span>
                                        </Link>
                                    </li>

                                </ul>
                            </div>

                        </li>

                        <li className={`nav-item ${isExamMasterRoutes ? 'active' : ''}`}>
                            <a data-toggle="collapse" href="#examMaster">
                                <i className="fas fa-file-alt" />
                                <p>Exam Master</p>
                                <span className="caret" />
                            </a>
                            <div className={`collapse ${isExamMasterRoutes ? 'show' : ''}`} id="examMaster">

                                <ul className="nav nav-collapse p-0">

                                    <li className={`nav-item m-0 p-0`}>

                                        <a data-toggle="collapse" href="#exam">
                                            Exam
                                            <span className="caret" />
                                        </a>
                                        <div className={`collapse ${isExamRoutes ? 'show' : ''}`} id="exam">

                                            <ul className="nav nav-collapse p-0">
                                                <li className='p-0 m-0'>
                                                    <Link to="/exam-term">
                                                        <span className="sub-item">Exam Term</span>
                                                    </Link>
                                                </li>

                                                <li className='p-0 m-0'>
                                                    <Link to="/exam-hall">
                                                        <span className="sub-item">Exam Hall</span>
                                                    </Link>
                                                </li>
                                                <li className='p-0 m-0'>
                                                    <Link to="/mark-distribution">
                                                        <span className="sub-item">Mark Distribution</span>
                                                    </Link>
                                                </li>
                                                <li className='p-0 m-0'>
                                                    <Link to="/exam-setup">
                                                        <span className="sub-item">Exam Setup</span>
                                                    </Link>
                                                </li>

                                            </ul>
                                        </div>
                                    </li>

                                    <li className={`nav-item m-0 p-0`}>

                                        <a data-toggle="collapse" href="#examMarks">
                                            Exam Marks
                                            <span className="caret" />
                                        </a>
                                        <div className={`collapse ${isMarksRoutes ? 'show' : ''}`} id="examMarks">

                                            <ul className="nav nav-collapse p-0">
                                                <li className='p-0 m-0'>
                                                    <Link to="/mark-entries">
                                                        <span className="sub-item">Mark Entries</span>
                                                    </Link>
                                                </li>

                                                <li className='p-0 m-0'>
                                                    <Link to="/grade-range">
                                                        <span className="sub-item">Grade Range</span>
                                                    </Link>
                                                </li>


                                            </ul>
                                        </div>
                                    </li>

                                    <li className='p-0 m-0'>
                                        <Link to="/exam-schedule">
                                            <span className="sub-item">Exam Schedule</span>
                                        </Link>
                                    </li>
                                    <li className='p-0 m-0'>
                                        <Link to="/report-card">
                                            <span className="sub-item">Report Card</span>
                                        </Link>
                                    </li>

                                </ul>

                            </div>

                        </li>

                        <li className={`nav-item ${isAttendanceRoutes ? 'active' : ''}`}>
                            <a data-toggle="collapse" href="#Attendance">
                                <i className="fas fa-check-circle" />
                                <p>Attendance</p>
                                <span className="caret" />
                            </a>
                            <div className={`collapse ${isAttendanceRoutes ? 'show' : ''}`} id="Attendance">
                                <ul className="nav nav-collapse p-0">
                                    <li className='p-0 m-0'>
                                        <Link to="/student-attendance">
                                            <span className="sub-item">Student Attendance</span>
                                        </Link>
                                    </li>
                                    <li className='p-0 m-0'>
                                        <Link to="/employee-attendance">
                                            <span className="sub-item">Employee Attendance</span>
                                        </Link>
                                    </li>
                                    <li className='p-0 m-0'>
                                        <Link to="/exam-attendance">
                                            <span className="sub-item">Exam Attendance</span>
                                        </Link>
                                    </li>

                                </ul>
                            </div>

                        </li>

                        <li className={`nav-item ${isFeesTypeRoutes ? 'active' : ''}`}>
                            <a data-toggle="collapse" href="#studentAccounting">
                                <i className="fas fa-coins" />
                                <p>Student Accounting</p>
                                <span className="caret" />
                            </a>
                            <div className={`collapse ${isFeesTypeRoutes ? 'show' : ''}`} id="studentAccounting">
                                <ul className="nav nav-collapse p-0">
                                    <li className='p-0 m-0'>
                                        <Link to="/fees-type">
                                            <span className="sub-item">Fees Type</span>
                                        </Link>
                                    </li>
                                    <li className='p-0 m-0'>
                                        <Link to="/fees-group">
                                            <span className="sub-item">Fees Group</span>
                                        </Link>
                                    </li>
                                    <li className='p-0 m-0'>
                                        <Link to="/fine-setup">
                                            <span className="sub-item">Fine Setup</span>
                                        </Link>
                                    </li>
                                    <li className='p-0 m-0'>
                                        <Link to="/fees-allocation">
                                            <span className="sub-item">Fees Allocation</span>
                                        </Link>
                                    </li>

                                </ul>
                            </div>

                        </li>

                        {/* <li classNa me={`nav-item ${isAttendanceRoutes ? 'active' : ''}`}>
                            <a data-toggle="collapse" href="#officeAccounting">
                                <i className="fas fa-money-bill-wave" />
                                <p>Office Accounting</p>
                                <span className="caret" />
                            </a>
                            <div className={`collapse ${isAttendanceRoutes ? 'show' : ''}`} id="officeAccounting">
                                <ul className="nav nav-collapse p-0">
                                    <li className='p-0 m-0'>
                                        <Link to="/office-accounting">
                                            <span className="sub-item">Account</span>
                                        </Link>
                                    </li>

                                </ul>
                            </div>

                        </li> */}

                        <li className='nav-item'>
                            <Link to="/add-academic-year">
                                <i className="fas fa-calendar-alt" />
                                <span className="sub-item">Add Academic Year</span>
                            </Link>
                        </li>

                        <li className='nav-item'>
                            <Link onClick={logoutFunction}>
                                <i className="fas fa-sign-out-alt" />
                                <p>Logout</p>
                            </Link>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    )
}



export default AdministratorSidebar;