import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';


// add_student_marks
export const add_student_marks = createAsyncThunk(
    'markEntry/add_student_marks',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/add-student-marks', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// add_grade_range
export const add_grade_range = createAsyncThunk(
    'markEntry/add_grade_range',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/add-grade-range', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_grade_range
export const get_grade_range = createAsyncThunk(
    'markEntry/get_grade_range',
    async (_, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-grade-range`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_single_grade
export const get_single_grade = createAsyncThunk(
    'markEntry/get_single_grade',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-single-grade/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// update_grade_range
export const update_grade_range = createAsyncThunk(
    'markEntry/update_grade_range',
    async ({ editId, obj }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/update-grade-range/${editId}`, obj)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const markEntryReducer = createSlice({
    name: "markEntry",
    initialState: {
        errorMessage: '',
        successMessage: '',
        loader: false,
        gradeRangeList: [],
        singlegradeRange: {}

    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ''
            state.successMessage = ''
        },
    },
    extraReducers: {
        [add_student_marks.pending]: (state, _) => {
            state.loader = true
        },
        [add_student_marks.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [add_student_marks.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [add_grade_range.pending]: (state, _) => {
            state.loader = true
        },
        [add_grade_range.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [add_grade_range.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [get_grade_range.pending]: (state, _) => {
            state.loader = true
        },
        [get_grade_range.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.gradeRangeList = payload.gradeRangeList
        },
        [get_single_grade.fulfilled]: (state, { payload }) => {
            state.singlegradeRange = payload.singlegradeRange
        },
        [update_grade_range.pending]: (state, _) => {
            state.loader = true
        },
        [update_grade_range.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [update_grade_range.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        }

    }
});



export const { messageClear } = markEntryReducer.actions;
export default markEntryReducer.reducer;