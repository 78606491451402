import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { useOptionsFromData } from '../../../utils/other/returnFunction';
import { bloodGroups } from '../../../utils/other/data';
import { useDispatch, useSelector } from 'react-redux';
import { admission_student, admission_student_update } from '../../../store/Reducers/student/studentReducer';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';



const MedicalDetails = forwardRef(({ onSendMessage }, ref) => {

    const dispatch = useDispatch();
    const { studentId } = useParams();


    const { loader, successMessage, errorMessage, student } = useSelector(state => state.student);
    const { sessionValue } = useSelector(state => state.default);


    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();
    const bloodGroupList = useOptionsFromData(bloodGroups?.groups);
    const [bloodValue, setBloodValue] = useState(null);


    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        }
    }));


    // handleSelect
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
        clearErrors(fieldName);
    }


    // onSubmit
    const onSubmit = (data) => {
        onSendMessage(!true);
        sessionStorage.setItem('medicalDetails', JSON.stringify(data))
        const basicDetails = JSON.parse(sessionStorage.getItem('basicDetails'));
        const addressDetails = JSON.parse(sessionStorage.getItem('addressDetails'));
        const guardianDetails = JSON.parse(sessionStorage.getItem('guardianDetails'));
        const bankDetails = JSON.parse(sessionStorage.getItem('bankDetails'));


        // console.log("addressDetails", addressDetails)
        // return


        if (!sessionValue) {
            toast.error("Session is required !")
        } else {
            const dataa = {
                sessionId: sessionValue,
                basicDetails: {
                    ...basicDetails,
                    className: basicDetails.className.value,
                    section: basicDetails.section.value,
                    roll: basicDetails.roll,
                    gender: basicDetails.gender.value,
                    religion: basicDetails.religion.value,
                    fullName: `${basicDetails.firstName} ${basicDetails.lastName}`
                },
                addressDetails: {
                    ...addressDetails,
                    country: addressDetails.country.value,
                    state: addressDetails.country.value === 'India' ? addressDetails.state.value : addressDetails.state,
                    city: addressDetails.city.value
                },
                guardianDetails: {
                    ...guardianDetails,
                    guardianOccupation: guardianDetails.guardianOccupation.value,
                },
                bankDetails,
                medicalDetails: {
                    ...data,
                    bloodGroup: data.bloodGroup.value
                }
            }

            if (!studentId) {
                dispatch(admission_student(dataa))
            } else {
                dispatch(admission_student_update({ studentId, dataa }))
            }

        }

    };


    // removeDetails
    const removeDetails = () => {
        sessionStorage.removeItem('medicalDetails')
        reset({
            weight: '',
            height: '',
            allergies: '',
            bloodGroup: '',
        });
        setBloodValue()
    }


    useEffect(() => {

        if (Object.keys(student).length > 0) {

            setValue('weight', student.medicalDetails.weight);
            setValue('height', student.medicalDetails.height);
            setValue('allergies', student.medicalDetails.allergies);

            setBloodValue({
                label: student.medicalDetails?.bloodGroup, value: student.medicalDetails?.bloodGroup
            })
            setValue("bloodGroup", {
                label: student.medicalDetails?.bloodGroup, value: student.medicalDetails?.bloodGroup
            })

        }

    }, [student])


    useEffect(() => {

        const storeMedicalDetails = sessionStorage.getItem("medicalDetails");
        if (storeMedicalDetails) {
            const medicalDetails = JSON.parse(storeMedicalDetails);
            setValue("weight", medicalDetails.weight)
            setValue("height", medicalDetails.height)
            setValue("allergies", medicalDetails.allergies)

            setBloodValue({
                label: medicalDetails?.bloodGroup.label, value: medicalDetails?.bloodGroup.value
            })
            setValue("bloodGroup", {
                label: medicalDetails?.bloodGroup.label, value: medicalDetails?.bloodGroup.value
            })
        }

    }, [])


    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card">

                    <div className="card-header d-flex justify-content-between">
                        <h4 className="card-title">Medical Details</h4>
                        <button onClick={removeDetails} className='btn btn-danger px-3 py-2 fw-bold'> Reset </button>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="weight">Weight (IN K.G) <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="number"
                                            className={`form-control ${errors.weight ? 'is-invalid' : ''}`}
                                            id="weight"
                                            {...register('weight', { required: 'Weight is required' })}
                                        />
                                        {errors.weight && <div className="invalid-feedback">{errors.weight.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="height"> Height (IN CM) <span className="text-danger">*</span></label>
                                        <input
                                            type="number"
                                            className={`form-control ${errors.height ? 'is-invalid' : ''}`}
                                            id="height"
                                            {...register('height', { required: 'Height is required' })}
                                        />
                                        {errors.height && <div className="invalid-feedback">{errors.height.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="allergies"> Allergies <span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.allergies ? 'is-invalid' : ''}`}
                                            id="allergies"
                                            {...register('allergies', { required: 'Allergies information is required' })}
                                        />
                                        {errors.allergies && <div className="invalid-feedback">{errors.allergies.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="bloodGroup">Blood Group <span className='text-danger'>*</span></label>
                                        <Select
                                            {...register('bloodGroup', { required: 'Blood Group is required' })}
                                            value={bloodValue}
                                            onChange={(selectOption) => {
                                                setBloodValue(selectOption);
                                                handleSelect('bloodGroup', selectOption);
                                            }}
                                            options={bloodGroupList}
                                            className={errors.bloodGroup ? 'is-invalid' : ''}
                                        />
                                        {errors.bloodGroup && <div className="invalid-feedback">{errors.bloodGroup.message}</div>}
                                    </div>
                                </div>

                            </div>
                            <button type="submit" style={{ display: 'none' }}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
});


export default MedicalDetails;