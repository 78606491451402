import React from 'react';

const Empty = ({ data }) => {

    // console.log("data", data)

    return (
        <div className="d-flex align-items-center justify-content-center py-5">
            <h1 className="text-center">
                {
                    data ? data : 'Data not found !'
                }
            </h1>
        </div>
    )
}

export default Empty;
