// import axios from 'axios';


// export const server_url = process.env.REACT_APP_API_HOST




// console.log(server_url)

// const api = axios.create({
//     baseURL: `${server_url}/api/v1`,
//     withCredentials: true
// });


// export default api;


import axios from 'axios';


const mode = 'pro'

export const server_url = mode === 'dev' ? 'http://localhost:5000' : process.env.REACT_APP_API_HOST

export const image_url = mode === 'dev' ? 'http://localhost:3000' : 'https://school.codingsamrat.com'


const api = axios.create({
    baseURL: `${server_url}/api/v1`,
    withCredentials: true
});
 

export default api;