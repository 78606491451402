import React, { useEffect, useState } from 'react';
import StudentLayout from '../../Layout/StudentLayout/StudentLayout';
import { useDispatch, useSelector } from 'react-redux';
import { get_single_student_attendance } from '../../../store/Reducers/attendance/studentAttendance';
import Empty from '../../../utils/page/Empty';
import { getClassName, getStudentDetails } from '../../../utils/other/returnFunction';
import moment from 'moment';

const SingleStudentAttendance = () => {
    const dispatch = useDispatch();
    const [selectedMonth, setSelectedMonth] = useState('');

    const { studentInfo: { studentId } } = useSelector(state => state.personalStudent);
    const { attendanceForSingleStudent } = useSelector(state => state.studentAttendance);
    const { classList } = useSelector(state => state.administrator);
    const { sessionValue } = useSelector(state => state.default);


    // handleMonthChange
    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
        const obj = {
            sessionId: sessionValue,
            studentId,
            date: e.target.value
        };
        dispatch(get_single_student_attendance(obj));
    };


    useEffect(() => {
        const getCurrentMonth = () => {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');

            return `${year}-${month}`;
        };

        const obj = {
            sessionId: sessionValue,
            studentId,
            date: getCurrentMonth()
        };

        dispatch(get_single_student_attendance(obj));

    }, [dispatch, sessionValue])


    return (
        <StudentLayout>

            <div className="card mb-3">
                <div className="card-body">
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-lg-6 d-flex justify-content-center">
                            <div style={{ width: '100%' }}>

                                <div className="form-group pt-0">
                                    <label htmlFor="date">Date<span className='text-danger'>*</span></label>
                                    <input
                                        type="month"
                                        className={`form-control`}
                                        id="date"
                                        value={selectedMonth}
                                        onChange={handleMonthChange}
                                    />
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>



            <div className="card">
                <div className="card-body" style={{ padding: "20px", overflowX: "auto" }}>
                    {Object.keys(attendanceForSingleStudent).length > 0 ? (
                        <div style={{ overflowX: "auto" }}>
                            <h4 style={{ fontSize: "19px", fontWeight: "600" }} className='py-2'>
                                Attendance Sheet of Class {getClassName(classList, attendanceForSingleStudent?.classId)}: Section {attendanceForSingleStudent?.section}, {moment(attendanceForSingleStudent?.date, 'YYYY-MM').format('MMMM YYYY')}
                            </h4>

                            <table
                                style={{
                                    width: "100%",
                                    borderCollapse: "collapse",
                                    textAlign: "center",
                                    fontSize: "14px",
                                    border: "1px solid #ddd",
                                    minWidth: "1500px"
                                }}
                            >
                                <thead>
                                    <tr style={{ backgroundColor: "#f8f9fa" }}>
                                        {attendanceForSingleStudent?.attendance?.map((_, i) => (
                                            <th key={i} style={{ padding: "10px", border: "1px solid #ddd" }} rowSpan="2">{i + 1}</th>
                                        ))}
                                        <th style={{ padding: "10px", border: "1px solid #ddd" }} colSpan="2">Total</th>
                                    </tr>
                                    <tr style={{ backgroundColor: "#f8f9fa" }}>
                                        <th style={{ padding: "10px", border: "1px solid #ddd" }}>Present</th>
                                        <th style={{ padding: "10px", border: "1px solid #ddd" }}>Absent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ backgroundColor: "#f2f2f2" }}>
                                        {attendanceForSingleStudent.attendance?.map((dayAttendance, j) => (
                                            <td key={j} style={{ padding: "10px", border: "1px solid #ddd" }}>
                                                {dayAttendance.attendance === "Present" ? (
                                                    <i className='fas fa-check text-success fa-lg' />
                                                ) : dayAttendance.attendance === "Absent" ? (
                                                    <i className='fas fa-times text-danger fa-lg' />
                                                ) : (
                                                    '-'
                                                )}
                                            </td>
                                        ))}
                                        <td style={{ padding: "10px", border: "1px solid #ddd", fontWeight: "bold" }}>
                                            {attendanceForSingleStudent?.presentCount}
                                        </td>
                                        <td style={{ padding: "10px", border: "1px solid #ddd", fontWeight: "bold" }}>
                                            {attendanceForSingleStudent?.absentCount}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <Empty data="Attendance List Not Found!" />
                    )}
                </div>
            </div>
        </StudentLayout>
    );
};



export default SingleStudentAttendance;
