import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectOrganization = () => {
    const { organisationInfo: { role }, organisationToken } = useSelector(state => state.organization);

    // console.log("role", role)
    // console.log("organisationToken", organisationToken)


    if (role === 'Organization' && organisationToken) {
        return <Outlet />
    } else {
        return <Navigate to="/" />;
    }

}

export default ProtectOrganization;