import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { useOptionsFromData } from '../../../utils/other/returnFunction';
import { countries, fatherOccupations, genders, indiaStates } from '../../../utils/other/data';
import axios from 'axios';
import { useSelector } from 'react-redux';


const GuardianDetails = forwardRef(({ onSendMessage }, ref) => {


    const { student } = useSelector(state => state.student);

    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();
    const fatherOccupationList = useOptionsFromData(fatherOccupations?.occupations);
    const [guardianOccupationValue, setGuardianOccupationValue] = useState(null);



    // handleSelect
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
        clearErrors(fieldName);

    }


    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        }
    }));

    // onSubmit
    const onSubmit = (data) => {
        onSendMessage(true);
        sessionStorage.setItem('guardianDetails', JSON.stringify(data))
    };



    // removeDetails
    const removeDetails = () => {
        sessionStorage.removeItem('guardianDetails')
        reset({
            fatherName: '',
            motherName: '',
            guardianPhoneNumber: '',
            guardianOccupation: ''
        });
        setGuardianOccupationValue()
    }



    useEffect(() => {

        if (Object.keys(student).length > 0) {

            setValue('fatherName', student.guardianDetails.fatherName);
            setValue('motherName', student.guardianDetails.motherName);
            setValue('guardianPhoneNumber', student.guardianDetails.guardianPhoneNumber);

            setGuardianOccupationValue({
                label: student.guardianDetails?.guardianOccupation, value: student.guardianDetails?.guardianOccupation
            })
            setValue("guardianOccupation", {
                label: student.guardianDetails?.guardianOccupation, value: student.guardianDetails?.guardianOccupation
            })

        }

    }, [student])



    useEffect(() => {

        const storeGuardianDetails = sessionStorage.getItem("guardianDetails");
        if (storeGuardianDetails) {
            const guardianDetails = JSON.parse(storeGuardianDetails);
            setValue("fatherName", guardianDetails.fatherName)
            setValue("motherName", guardianDetails.motherName)
            setValue("guardianPhoneNumber", guardianDetails.guardianPhoneNumber)

            setGuardianOccupationValue({
                label: guardianDetails?.guardianOccupation.label, value: guardianDetails?.guardianOccupation.value
            })
            setValue("guardianOccupation", {
                label: guardianDetails?.guardianOccupation.label, value: guardianDetails?.guardianOccupation.value
            })
        }

    }, [])


    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card">

                    <div className="card-header d-flex justify-content-between">
                        <h4 className="card-title">Guardian Details</h4>
                        <button onClick={removeDetails} className='btn btn-danger px-3 py-2 fw-bold'> Reset </button>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="fatherName">Father's Name <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.fatherName ? 'is-invalid' : ''}`}
                                            id="fatherName"
                                            {...register('fatherName', { required: 'Father\'s Name is required' })}
                                        />
                                        {errors.fatherName && <div className="invalid-feedback">{errors.fatherName.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="motherName">Mother's Name <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.motherName ? 'is-invalid' : ''}`}
                                            id="motherName"
                                            {...register('motherName', { required: 'Mother\'s Name is required' })}
                                        />
                                        {errors.motherName && <div className="invalid-feedback">{errors.motherName.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="guardianPhoneNumber">Guardian's Phone Number <span className='text-danger'>*</span></label>
                                        <input
                                            type="tel"
                                            className={`form-control ${errors.guardianPhoneNumber ? 'is-invalid' : ''}`}
                                            id="guardianPhoneNumber"
                                            {...register('guardianPhoneNumber', {
                                                required: 'Guardian\'s Phone Number is required',
                                                pattern: {
                                                    value: /^\+?\d{10,15}$/,
                                                    message: 'Invalid phone number format'
                                                }
                                            })}
                                        />
                                        {errors.guardianPhoneNumber && <div className="invalid-feedback">{errors.guardianPhoneNumber.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="guardianOccupation">Guardian's Occupation <span className='text-danger'>*</span></label>
                                        <Select
                                            {...register('guardianOccupation', { required: "Guardian's Occupation is required" })}
                                            value={guardianOccupationValue}
                                            onChange={(selectOption) => {
                                                setGuardianOccupationValue(selectOption);
                                                handleSelect('guardianOccupation', selectOption);
                                            }}
                                            options={fatherOccupationList}
                                            className={errors.guardianOccupation ? 'is-invalid' : ''}
                                        />
                                        {errors.guardianOccupation && <div className="invalid-feedback">{errors.guardianOccupation.message}</div>}
                                    </div>
                                </div>


                            </div>
                            <button type="submit" style={{ display: 'none' }}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
});


export default GuardianDetails;