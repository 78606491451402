import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { MdSpaceDashboard } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { MdLogout } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import HomeIcon from '@mui/icons-material/Home';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { messageClear, student_logout, tokenClear } from '../../../store/Reducers/student/personalStudentReducer';
import toast from 'react-hot-toast';
import { FaBookReader } from "react-icons/fa";
import { GrSchedule } from "react-icons/gr";
import { confirmMessagge } from '../../../utils/other/aleartFunc';
import { FaCheck } from "react-icons/fa";


const drawerWidth = 240;

const StudentSidebar = ({ open, handleDrawerClose }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const location = useLocation();
    const { logoutMessage } = useSelector(state => state.personalStudent);

    const [academicOpen, setAcademicOpen] = useState(false);
    const [examOpen, setExamOpen] = useState(false);

    const handleAcademicClick = () => {
        setAcademicOpen(!academicOpen);
    };

    const handleExamClick = () => {
        setExamOpen(!examOpen);
    };


    useEffect(() => {
        if (location.pathname === '/student-all-subject' || location.pathname === '/student-class-schedule') {
            setAcademicOpen(true);
        } else {
            setAcademicOpen(false);
        }
    }, [location.pathname]);


    useEffect(() => {
        if (location.pathname === '/student-exam-schedule' || location.pathname === '/student-exam-result') {
            setExamOpen(true);
        } else {
            setExamOpen(false);
        }
    }, [location.pathname]);



    // logoutFunction
    const logoutFunction = async () => {
        const returnValue = await confirmMessagge('Logout');
        if (returnValue) {
            dispatch(student_logout())
        }
    }

    useEffect(() => {
        if (logoutMessage) {
            toast.success(logoutMessage)
            dispatch(messageClear())
            dispatch(tokenClear())
        }
    }, [logoutMessage]);

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    background: "#80808014"
                },
            }}
            variant="persistent"
            anchor="left"
            open={open}
        >
            <div className='' style={{ padding: "6px 0" }}>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon sx={{ fontSize: 34 }} /> : <ChevronRightIcon sx={{ fontSize: 34 }} />}
                </IconButton>
            </div>
            <hr className='my-0' />

            <p></p>

            <List component="div" disablePadding>
                <ListItem button component={Link} to="/student-dashboard" className="list-item">
                    <ListItemIcon style={{ minWidth: '40px' }}>
                        <MdSpaceDashboard size={22} />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
            </List>

            <List>
                <ListItem button onClick={handleAcademicClick}>
                    <ListItemIcon style={{ minWidth: '40px' }}>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Academic" />
                    {academicOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={academicOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem
                            button
                            component={Link}
                            to="/student-all-subject"
                            style={{ backgroundColor: '#e0e0e0' }}
                        >
                            <ListItemIcon style={{ minWidth: '40px' }}>
                                <FaBookReader />
                            </ListItemIcon>
                            <ListItemText primary="Subject" />
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/student-class-schedule"
                            style={{ backgroundColor: '#e0e0e0' }}
                        >
                            <ListItemIcon style={{ minWidth: '40px' }}>
                                <GrSchedule />
                            </ListItemIcon>
                            <ListItemText primary="Class Schedule" />
                        </ListItem>
                    </List>
                </Collapse>
            </List>


            <List>
                <ListItem button onClick={handleExamClick}>
                    <ListItemIcon style={{ minWidth: '40px' }}>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Exam Master" />
                    {examOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={examOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem
                            button
                            component={Link}
                            to="/student-exam-schedule"
                            style={{ backgroundColor: '#e0e0e0' }}
                        >
                            <ListItemIcon style={{ minWidth: '40px' }}>
                                <FaBookReader />
                            </ListItemIcon>
                            <ListItemText primary="Exam Schedule" />
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/student-exam-result"
                            style={{ backgroundColor: '#e0e0e0' }}
                        >
                            <ListItemIcon style={{ minWidth: '40px' }}>
                                <GrSchedule />
                            </ListItemIcon>
                            <ListItemText primary="Exam Result" />
                        </ListItem>
                    </List>
                </Collapse>
            </List>


            <List component="div" disablePadding>
                <ListItem button component={Link} to="/student-profile" className="list-item">
                    <ListItemIcon style={{ minWidth: '40px' }}>
                        <CgProfile size={22} />
                    </ListItemIcon>
                    <ListItemText primary="Profile" />
                </ListItem>
            </List>
            <List component="div" disablePadding>
                <ListItem button component={Link} to="/single-student-attendance" className="list-item">
                    <ListItemIcon style={{ minWidth: '40px' }}>
                        <FaCheck size={22} />
                    </ListItemIcon>
                    <ListItemText primary="Attendance" />
                </ListItem>
            </List>
            <List component="div" disablePadding>
                <ListItem button onClick={logoutFunction} className="list-item">
                    <ListItemIcon style={{ minWidth: '40px' }}>
                        <MdLogout size={22} />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItem>
            </List>
        </Drawer>
    );
}

export default StudentSidebar;
