import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { generatePassword, useOptionsFromData } from '../../../../utils/other/returnFunction';
import { teacherDepartment } from '../../../../utils/other/data';
import { useForm } from 'react-hook-form';
import Pagination from '../../../Layout/Pagination';
import Empty from '../../../../utils/page/Empty';
import { MdOutlineViewQuilt } from "react-icons/md";
import { ButtonLoader, TableLoader } from '../../../../utils/Loader/LoaderComponent';
import { RiPhoneFindLine } from "react-icons/ri";
import TeacherDetails from '../TeacherDetails';
import { authenticate_employee, get_all_teacher, messageClear, teacher_filter } from '../../../../store/Reducers/administrator/teacherReducer';
import { confirmMessagge } from '../../../../utils/other/aleartFunc';
import toast from 'react-hot-toast';
import { image_url, server_url } from '../../../../api/api';



const AddAuthenticate = () => {
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();

    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();

    const { searchValue } = useSelector(state => state.default);
    const { employeeCount, allEmmployee, loader, successMessage, errorMessage } = useSelector(state => state.teacher);
    const departmentList = useOptionsFromData(teacherDepartment?.departments);


    const [currentPage, setCurrentPage] = useState(1)
    const [parPage, setParPage] = useState(20)


    const [departmentValue, setDepartmentValue] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState([]);

    const [teacherDetails, setTeacherDetails] = useState({});



    // handleItemPerPageChange
    const handleItemPerPageChange = (e) => {
        setParPage(e.target.value)
    }


    // handleTeacherDetails
    const handleTeacherDetails = (data) => {
        setOpen(true)
        setTeacherDetails(data)
    }



    // handleEmployeeSelect
    const handleEmployeeSelect = (e, student) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedEmployee(prevEmployee => [...prevEmployee, student]);
        } else {
            setSelectedEmployee(prevEmployee => prevEmployee.filter(s => s._id !== student._id));
        }
    };



    // handleSelectAll
    const handleSelectAll = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedEmployee([...allEmmployee]);
        } else {
            setSelectedEmployee([]);
        }
    };


    // handleSelect
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
    }



    // handleGenerate
    const handleGenerate = async (e) => {
        e.preventDefault();

        const returnValue = await confirmMessagge('Generate');

        if (returnValue) {
            const evaluationData = selectedEmployee?.filter(emp => emp.isAuthenticate === false)?.map(employee => ({
                employeeId: employee._id,
                authEmail: employee?.teacherBasicDetails?.email,
                authPhoneNumber: employee?.teacherBasicDetails?.phoneNumber,
                password: generatePassword()
            }));

            dispatch(authenticate_employee(evaluationData))

        }

    }


    useEffect(() => {
        if (departmentValue) {
            const obj = {
                department: departmentValue ? departmentValue.value : '',
            }
            dispatch(teacher_filter(obj))
        }

    }, [departmentValue])


    useEffect(() => {
        const obj = {
            parPage: parseInt(parPage),
            page: parseInt(currentPage),
            searchValue
        }
        dispatch(get_all_teacher(obj))
    }, [searchValue, currentPage, parPage, successMessage])


    useEffect(() => {

        if (errorMessage) {
            toast.error(errorMessage)
            dispatch(messageClear())
        }
        if (successMessage) {
            toast.success(successMessage)
            dispatch(messageClear())
        }

    }, [errorMessage, successMessage])


    // console.log("allEmmployee", allEmmployee)
    // console.log("departmentValue", departmentValue)

    return (
        <>

            <div className="card mb-3">
                <div className="card-body">
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-lg-6 d-flex justify-content-center">
                            <div style={{ width: '100%' }}>
                                <div className="form-group pt-0">
                                    <label htmlFor="teacher">Department</label>
                                    <Select
                                        placeholder="Select Department"
                                        {...register('department')}
                                        value={departmentValue}
                                        onChange={(selectOption) => {
                                            setDepartmentValue(selectOption);
                                            handleSelect('department', selectOption);
                                        }}
                                        options={departmentList}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">

                            <div className='d-flex justify-content-between m-0 p-0'>
                                <div className="page-header mb-0">
                                    <RiPhoneFindLine style={{ fontSize: '23px', marginBottom: "6px", marginRight: "8px" }} />
                                    <h4 style={{ fontSize: "19px" }}>Generate Employee Authenticate</h4>
                                </div>

                                <>
                                    {
                                        selectedEmployee?.length > 0 && allEmmployee?.length > 0 &&
                                        <div style={{ textAlign: "right" }} className=''>
                                            <button onClick={handleGenerate} type="submit" className='btn btn-primary px-3 py-2 fw-bold'
                                                style={{ width: "110px" }}
                                                disabled={loader ? true : false}>
                                                {
                                                    loader ? <ButtonLoader /> : 'Generate'
                                                }
                                            </button>
                                        </div>
                                    }
                                </>
                            </div>

                            <hr className='my-3' />

                            {loader ? (
                                // Display loader while data is being fetched
                                <TableLoader />
                            ) : allEmmployee?.length > 0 ? (
                                // Render table if data exists
                                <table className="tableStyle">
                                    <thead className=''>
                                        <tr>
                                            <th>
                                                <input
                                                    checked={selectedEmployee?.length === allEmmployee?.length}
                                                    onChange={handleSelectAll}
                                                    type="checkbox"
                                                    style={{ transform: "scale(1.2)" }}
                                                />
                                            </th>
                                            <th>Photo</th>
                                            <th>Name</th>
                                            <th>Department</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Is Authenticate</th>
                                            <th className='no-print'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className=''>
                                        {
                                            allEmmployee?.map((d, i) => (
                                                <tr key={i}>
                                                    <td data-label="Select">
                                                        <input
                                                            value={d._id}
                                                            checked={selectedEmployee.some(s => s._id === d._id)}
                                                            onChange={(e) => handleEmployeeSelect(e, d)}
                                                            type="checkbox"
                                                            style={{ transform: "scale(1.2)" }}
                                                        />
                                                    </td>
                                                    <td data-label="Photo">
                                                        <img style={{ width: "35px", height: "35px", borderRadius: "50%" }} src={d?.teacherImage ? `${server_url}/${d?.teacherImage}` : `${image_url}/assets/img/student/male.png`} alt="Img" />
                                                    </td>
                                                    <td data-lable="Name">{d.teacherBasicDetails.fullName}</td>
                                                    <td data-lable="Department">{d.teacherBasicDetails.department}</td>
                                                    <td data-lable="Email">{d.teacherBasicDetails.email}</td>
                                                    <td data-lable="Phone">{d.teacherBasicDetails.phoneNumber}</td>
                                                    <td data-label="isAuthenticate">
                                                        {
                                                            d.isAuthenticate === true ? <i className='fas fa-check text-success fa-lg' /> : <i className='fas fa-times text-danger fa-lg' />
                                                        }
                                                    </td>
                                                    <td data-lable="Action" className='no-print'>
                                                        <span title='Details' className='mr-1' onClick={() => handleTeacherDetails(d)}>
                                                            <MdOutlineViewQuilt style={{ fontSize: '22px', color: 'blue', marginTop: "3px" }} />
                                                        </span>

                                                    </td>

                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                </table>
                            ) : (
                                // Display empty state when classList is empty
                                <Empty data={`Employee Not Found !`} />
                            )}

                        </div>

                        {
                            employeeCount > 20 && <div class="d-flex justify-content-between">
                                <div className="col-md-4 d-flex justify-content-start align-items-center itemParPageStyle">
                                    <div class="form-group  d-flex">
                                        <label for="itemPerPage" class="col-sm-auto col-form-label">Select items per page :</label>
                                        <div class="col-sm-auto">
                                            <select
                                                id='itemPerPage'
                                                style={{ width: "65px", marginLeft: "-23px", marginTop: "7px", padding: "3px" }}
                                                onChange={handleItemPerPageChange}
                                                value={parPage}
                                            >
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="5000">All</option>

                                            </select>
                                        </div>
                                    </div>

                                </div>

                                <div className='px-4 pt-2'>
                                    {
                                        employeeCount >= parPage ?
                                            <Pagination
                                                pageNumber={currentPage}
                                                setPageNumber={setCurrentPage}
                                                totalItem={employeeCount}
                                                parPage={parPage}
                                                showItem={Math.floor(employeeCount / parPage)}
                                            /> : ''
                                    }
                                </div>

                            </div>
                        }

                    </div>

                </div>

            </div>

            <TeacherDetails open={open} setOpen={setOpen} teacherDetails={teacherDetails} />

        </>
    )
}


export default AddAuthenticate;