import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectAdministrator = () => {
    const { administratorInfo: { role }, administratorToken } = useSelector(state => state.administrator);

    // console.log("role", role)
    // console.log("administratorToken", administratorToken)


    if (role === 'Administrator' && administratorToken) {
        return <Outlet />
    } else {
        return <Navigate to="/" />;
    }

}


export default ProtectAdministrator;