import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import Empty from '../../../../utils/page/Empty';
import { ButtonLoader } from '../../../../utils/Loader/LoaderComponent';
import { add_fees_group, get_single_fees_group, messageClear, update_fees_group } from '../../../../store/Reducers/accounting/feesReducer';
import toast from 'react-hot-toast';
import moment from 'moment';



const AddFeesGroup = ({ groupId, onSendData }) => {

    const dispatch = useDispatch();

    const { loader, successMessage, errorMessage, feesList, feesCount, singleFeesGroup } = useSelector(state => state.fees);


    const { handleSubmit, control, setValue, register, formState: { errors }, reset } = useForm();
    const [selectedFeesType, setSelectedFeesType] = useState(
        JSON.parse(sessionStorage.getItem('selectedFeesType')) || []
    );
    const [displayedFeesList, setDisplayedFeesList] = useState(
        JSON.parse(sessionStorage.getItem('displayedFeesList')) || feesList.slice(0, 10)
    );
    const [hasMore, setHasMore] = useState(feesList.length > 10);

    useEffect(() => {
        sessionStorage.setItem('selectedFeesType', JSON.stringify(selectedFeesType));
    }, [selectedFeesType]);

    useEffect(() => {
        sessionStorage.setItem('displayedFeesList', JSON.stringify(displayedFeesList));
    }, [displayedFeesList]);

    // handleFeesTypeSelect
    const handleFeesTypeSelect = (e, feesType) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedFeesType(prevFeesType => [...prevFeesType, feesType]);
        } else {
            setSelectedFeesType(prevFeesType => prevFeesType.filter(s => s._id !== feesType._id));
        }

    };

    // handleSelectAll
    const handleSelectAll = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedFeesType([...feesList]);
        } else {
            setSelectedFeesType([]);
        }
    };

    // fetchMoreData
    const fetchMoreData = () => {
        if (displayedFeesList.length >= feesList.length) {
            setHasMore(false);
            return;
        }
        // Load more data
        setTimeout(() => {
            setDisplayedFeesList(feesList.slice(0, displayedFeesList.length + 10));
        }, 1000);
    };

    // handleGroupSubmit
    const handleGroupSubmit = (data) => {

        // Collect form data
        const formData = data;

        // Collect selected fees types
        const selectedFeesData = selectedFeesType.map(fees => {
            return {
                feesId: fees._id,
                // feesName: fees.feesName,
                dueDate: formData[`dueDate_${fees._id}`],
                amount: formData[`amount_${fees._id}`]
            };
        });

        // Final data object
        const finalData = {
            groupName: formData.groupName,
            description: formData.feesGroupDescription,
            feesList: selectedFeesData
        };

        if (groupId) {
            dispatch(update_fees_group({ groupId, finalData }))
        } else {
            dispatch(add_fees_group(finalData))
        }
    };



    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage);
            dispatch(messageClear());
        }
        if (successMessage) {
            toast.success(successMessage);
            dispatch(messageClear());
            setSelectedFeesType([])
            sessionStorage.removeItem('selectedFeesType')

            setValue('groupName', '')
            setValue('feesGroupDescription', '')
            onSendData(0)
        }
    }, [errorMessage, successMessage]);


    useEffect(() => {
        if (groupId) {
            dispatch(get_single_fees_group(groupId))
        }
    }, [groupId])



    useEffect(() => {
        if (Object.keys(singleFeesGroup).length > 0) {

            // Set groupName and description
            setValue('groupName', singleFeesGroup.groupName || '');
            setValue('feesGroupDescription', singleFeesGroup.description || '');

            // Prepare feesList data for form initialization
            if (singleFeesGroup.feesList && singleFeesGroup.feesList.length > 0) {
                const selectedFees = [];
                singleFeesGroup.feesList.forEach(fees => {
                    // Check if feesId exists and has _id
                    if (fees.feesId && fees.feesId._id) {
                        // Initialize form fields with existing data
                        setValue(`dueDate_${fees.feesId._id}`, moment(fees.dueDate).format('YYYY-MM-DD') || '');
                        setValue(`amount_${fees.feesId._id}`, fees.amount || '');

                        // Add to selectedFees array
                        selectedFees.push({ _id: fees.feesId._id });
                    }
                });

                // Set the selectedFeesType state to reflect the selected fees types
                setSelectedFeesType(selectedFees);
            }
        }

        if (!groupId) {
            setSelectedFeesType([]);
            sessionStorage.removeItem('selectedFeesType');

            setValue('groupName', '');
            setValue('feesGroupDescription', '');
        }

    }, [singleFeesGroup, setValue, groupId]);


    return (
        <div className="container-fluid">
            {/* Fixed form section */}
            <div className="card mb-3">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="groupName">Group Name <span className='text-danger'>*</span></label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.groupName ? 'is-invalid' : ''}`}
                                    id="groupName"
                                    {...register('groupName', { required: 'Group name is required' })}
                                />
                                {errors.groupName && <div className="invalid-feedback">{errors.groupName.message}</div>}
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-8">
                            <div className="form-group">
                                <label htmlFor="feesGroupDescription">Description</label>
                                <textarea
                                    className="form-control"
                                    id="feesGroupDescription"
                                    {...register('feesGroupDescription')}
                                    rows='1'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Scrollable table section */}
            <div className="card">
                <div className="card-body">
                    {feesList?.length > 0 ? (
                        <form onSubmit={handleSubmit(handleGroupSubmit)}>
                            <InfiniteScroll
                                dataLength={displayedFeesList.length}
                                next={fetchMoreData}
                                hasMore={hasMore}
                                loader={<h4>Loading...</h4>}
                                endMessage={<p style={{ textAlign: 'center', marginTop: "10px", fontSize: "14px" }}><b>Yay! You have seen it all</b></p>}
                                height={300}
                            >
                                <table className="tableStyle">
                                    <thead>
                                        <tr>
                                            <th>
                                                <input
                                                    checked={selectedFeesType?.length === feesList?.length}
                                                    onChange={handleSelectAll}
                                                    type="checkbox"
                                                    style={{ transform: "scale(1.2)" }}
                                                />
                                            </th>
                                            <th>Fees Type</th>
                                            <th>Due Date <span className='text-danger'>*</span></th>
                                            <th>Amount <span className='text-danger'>*</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayedFeesList?.map((d, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <input
                                                        value={d._id}
                                                        checked={selectedFeesType.some(s => s._id === d._id)}
                                                        onChange={(e) => handleFeesTypeSelect(e, d)}
                                                        type="checkbox"
                                                        style={{ transform: "scale(1.2)" }}
                                                    />
                                                </td>
                                                <td>{d.feesName}</td>
                                                <td>
                                                    <input
                                                        style={{ width: "100%" }}
                                                        type="date"
                                                        name={`dueDate_${d._id}`}
                                                        className={`form-control p-2 ${errors[`dueDate_${d._id}`] ? 'is-invalid' : ''}`}
                                                        {...register(`dueDate_${d._id}`, {
                                                            required: selectedFeesType.some(s => s._id === d._id) ? 'Due date is required' : false,
                                                        })}
                                                    />
                                                    {errors[`dueDate_${d._id}`] && (
                                                        <div className="invalid-feedback">
                                                            {errors[`dueDate_${d._id}`].message}
                                                        </div>
                                                    )}
                                                </td>
                                                <td>
                                                    <input
                                                        style={{ width: "100%" }}
                                                        type="number"
                                                        name={`amount_${d._id}`}
                                                        className={`form-control p-2 ${errors[`amount_${d._id}`] ? 'is-invalid' : ''}`}
                                                        {...register(`amount_${d._id}`, {
                                                            required: selectedFeesType.some(s => s._id === d._id) ? 'Amount is required' : false,
                                                        })}
                                                    />
                                                    {errors[`amount_${d._id}`] && (
                                                        <div className="invalid-feedback">
                                                            {errors[`amount_${d._id}`].message}
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </InfiniteScroll>

                            {selectedFeesType?.length > 0 && feesList?.length > 0 && (
                                <div style={{ textAlign: "right" }} className='mt-3 mx-1'>
                                    <button type="submit" className='btn btn-primary px-3 py-2 fw-bold'
                                        style={{ width: "90px" }}
                                        disabled={loader ? true : false}>
                                        {loader ? <ButtonLoader /> : groupId ? 'Update' : 'Save'}
                                    </button>
                                </div>
                            )}

                        </form>
                    ) : (
                        <Empty data={`Fees Type Not Found!`} />
                    )}
                </div>
            </div>
        </div>
    )
}



export default AddFeesGroup;