import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import toast from 'react-hot-toast';
import { add_exam_attendance, messageClear } from '../../../../store/Reducers/attendance/studentAttendance';
import { image_url, server_url } from '../../../../api/api';
import { ButtonLoader } from '../../../../utils/Loader/LoaderComponent';
import Empty from '../../../../utils/page/Empty';
import { student_filter, student_filter_by_subject } from '../../../../store/Reducers/student/studentReducer';


const AddExamAttendance = ({ sendDataToParent }) => {

    const dispatch = useDispatch();
    const { handleSubmit, getValues, setValue, register, clearErrors, formState: { errors }, reset } = useForm();
    const { classList, subjectLists } = useSelector(state => state.administrator);
    const { allStudents, studentExamAttendanceList } = useSelector(state => state.student);
    const { sessionValue } = useSelector(state => state.default);
    const { examSetupList, examScheduleList } = useSelector(state => state.exam);

    const { loader, successMessage, errorMessage } = useSelector(state => state.studentAttendance);


    const [subjectOption, setSubjectOption] = useState('');
    const [examSetupOption, setExamSetupOption] = useState("")
    const [classListOption, setClassListOption] = useState("");
    const [sectionOption, setSectionOption] = useState("");

    const [selectedExamId, setSelectedExamId] = useState('');
    const [selecteeClassId, setSelecteeClassId] = useState('');
    const [selectedSectionId, setSelectedSectionId] = useState('');


    const [examSetupValue, setExamSetupValue] = useState(null);
    const [classValue, setClassValue] = useState(null);
    const [sectionValue, setSectionValue] = useState(null);
    const [subjectValue, setSubjectValue] = useState(null);


    // handleSelect function
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
        clearErrors(fieldName);

        if (fieldName === 'examName') {
            setSelectedExamId(selectOption.value)
            setValue("section", { label: '', value: '' });
            setSubjectValue(null)
        }

        if (fieldName === 'className') {
            setSelecteeClassId(selectOption.value);
            setSectionValue({ value: "", label: "Select Section" });
            setValue("section", { label: '', value: '' });
            setSubjectValue(null)


            const secOption = classList?.filter((item) => item._id === selectOption.value);
            const array = secOption[0]?.sectionNames;
            const convertedArray = array?.map(item => ({
                value: item,
                label: item
            }));
            setSectionOption(convertedArray);

            const filterSubject = subjectLists?.filter(d => d.classDetails.classId === selectOption.value).flatMap(d => d.subjects);
            if (filterSubject) {
                const options = filterSubject?.map(item => ({
                    value: item._id,
                    label: item.subjectName
                }));
                setSubjectOption(options);
            }
        }

        if (fieldName === 'section') {
            setSubjectValue(null)
            setSelectedSectionId(selectOption.value)
            const obj = {
                sessionValue,
                className: selecteeClassId,
                section: selectOption.value
            }
            dispatch(student_filter(obj));
        }


        if (fieldName === 'subject' && selectOption?.value) {

            const isData = examScheduleList?.find((d) => d.examName._id == selectedExamId && d.classId._id == selecteeClassId && d.section == selectedSectionId);

            if (isData && Object.keys(isData)?.length > 0) {

                // Filter students by the current selectOption value
                const filterStudent = allStudents?.filter((student) =>
                    student.sessions.some((session) =>
                        session.sessionIsActive === true && session.assignedSubjects.includes(selectOption.value)
                    )
                );
                // Dispatch the filtered students to the store
                dispatch(student_filter_by_subject(filterStudent));

            } else {
                dispatch(student_filter_by_subject([]));
            }
        }

    }


    // handleSelectForEveryone
    const handleSelectForEveryone = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue) {
            studentExamAttendanceList?.forEach((student, index) => {
                setValue(`students[${index}].attendance`, selectedValue);
            });
        }
    };


    // Function to handle form submission
    const onSubmit = (data) => {
        // Collect student IDs, attendance, and remarks
        const studentData = studentExamAttendanceList.map((student, index) => ({
            studentId: student._id,
            attendance: data.students[index]?.attendance,
            remark: data.students[index]?.remark || '',
        }));

        const obj = {
            sessionId: sessionValue,
            examId: data?.examName?.value,
            classId: selecteeClassId,
            section: data?.section?.value,
            subjectId: data?.subject?.value,
            attendanceRecords: studentData
        }

        dispatch(add_exam_attendance(obj))

    };


    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage);
            dispatch(messageClear());
        }
        if (successMessage) {
            toast.success(successMessage);
            dispatch(messageClear());
            sendDataToParent(0)
        }
    }, [errorMessage, successMessage]);


    useEffect(() => {
        if (studentExamAttendanceList?.length > 0) {
            allStudents.forEach((student, index) => {
                setValue(`students[${index}].attendance`, 'Present');
            });
        }
    }, [studentExamAttendanceList, setValue]);


    useEffect(() => {
        const options = classList?.map(item => ({
            value: item._id,
            label: item.className
        }));


        const options2 = examSetupList?.filter(d => examScheduleList?.some(c => c.examName._id === d._id))?.map(item => ({
            value: item._id,
            label: `${item.examName} (${item.examTypeName || 'N/A'})`,
        }));

        setClassListOption(options)
        setExamSetupOption(options2)
    }, [classList, examSetupList, examScheduleList])


    // console.log("examScheduleList", examScheduleList)


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card mb-3">
                    <div className="card-body py-">
                        <div className="row">

                            <div className="col-md-6 col-lg-3">
                                <div className="form-group pt-0">
                                    <label htmlFor="examName">Exam <span className='text-danger'>*</span></label>
                                    <Select
                                        placeholder="Select Class"
                                        value={examSetupValue}
                                        {...register('examName', { required: 'Exam is required' })}
                                        onChange={(selectOption) => {
                                            setExamSetupValue(selectOption);
                                            handleSelect('examName', selectOption);
                                        }}
                                        options={examSetupOption}
                                        className={errors.examName ? 'is-invalid' : ''}
                                    />
                                    {errors.examName && <div className="invalid-feedback">{errors.examName.message}</div>}
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3">
                                <div className="form-group pt-0">
                                    <label htmlFor="className">Class <span className='text-danger'>*</span></label>
                                    <Select
                                        placeholder="Select Class"
                                        value={classValue}
                                        {...register('className')}
                                        onChange={(selectOption) => {
                                            setClassValue(selectOption);
                                            handleSelect('className', selectOption);
                                        }}
                                        options={classListOption}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3">
                                <div className="form-group pt-0">
                                    <label htmlFor="section">Section <span className='text-danger'>*</span></label>
                                    <Select
                                        {...register('section')}
                                        placeholder="Select Section"
                                        value={sectionValue}
                                        onChange={(selectOption) => {
                                            setSectionValue(selectOption);
                                            handleSelect('section', selectOption);
                                        }}
                                        options={sectionOption}
                                        isDisabled={sectionOption?.length > 0 ? false : true}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3">
                                <div className="form-group pt-0">
                                    <label htmlFor="subject">Subject <span className='text-danger'>*</span></label>
                                    <Select
                                        placeholder="Select Subject"
                                        value={subjectValue}
                                        {...register('subject', { required: 'Subject is required' })}
                                        onChange={(selectOption) => {
                                            setSubjectValue(selectOption);
                                            handleSelect('subject', selectOption);
                                        }}
                                        options={subjectOption}
                                        className={errors.subject ? 'is-invalid' : ''}
                                        isDisabled={subjectOption?.length > 0 ? false : true}
                                    />
                                    {errors.subject && <div className="invalid-feedback">{errors.subject.message}</div>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">

                        <div className='mb-3' style={{ width: "250px" }}>
                            <label htmlFor="" className='mb-1'>Select For Everyone <span className='text-danger'>*</span></label>
                            <select className='form-select p-1 px-2 text' onChange={handleSelectForEveryone}>
                                <option value="" selected>Not Selected</option>
                                <option value="Present">Present</option>
                                <option value="Absent">Absent</option>
                                {/* <option value="Late">Late</option>
                                <option value="Half-day">Half-day</option> */}
                            </select>
                        </div>

                        {studentExamAttendanceList?.length > 0 && sectionValue?.value && subjectValue?.value ? (
                            <table className="tableStyle">
                                <thead>
                                    <tr>
                                        <th>Sl.</th>
                                        <th>Photo</th>
                                        <th>Name</th>
                                        <th>Register No</th>
                                        <th>Attendance</th>
                                        <th>Remarks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {studentExamAttendanceList?.map((d, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td data-label="Photo">
                                                <img style={{ width: "35px", height: "35px", borderRadius: "50%" }} src={d?.studentImage ? `${server_url}/${d?.studentImage}` : `${image_url}/assets/img/student/male.png`} alt="Img" />
                                            </td>
                                            <td data-label="Name">{d.basicDetails.fullName}</td>
                                            <td data-label="Register No">{d.basicDetails.enrollmentNumber}</td>

                                            <td data-label="Attendance">
                                                <div className="form-check form-check-inline p-0 m-0">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={`students[${i}].attendance`}
                                                        id={`present_${i}`}
                                                        value="Present"
                                                        {...register(`students[${i}].attendance`, { required: 'Attendance is required' })}
                                                        style={{ marginBottom: "9px", fontSize: "16px" }}
                                                    />
                                                    <label className="form-check-label" htmlFor={`present_${i}`}>
                                                        Present
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline p-0 m-0">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={`students[${i}].attendance`}
                                                        id={`absent_${i}`}
                                                        value="Absent"
                                                        {...register(`students[${i}].attendance`)}
                                                        style={{ marginBottom: "9px", fontSize: "16px" }}
                                                    />
                                                    <label className="form-check-label" htmlFor={`absent_${i}`}>
                                                        Absent
                                                    </label>
                                                </div>

                                            </td>

                                            <td data-label="Remarks">
                                                <input
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    {...register(`students[${i}].remark`)}
                                                    className='form-control p-2'
                                                    placeholder='Remarks'
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <Empty data={`Student Not Found !`} />
                        )}

                        {
                            allStudents?.length > 0 && sectionValue?.value && <div className="mt-4 text-right">
                                <button
                                    type='submit'
                                    disabled={loader}
                                    style={{ width: "90px" }}
                                    className="btn btn-secondary py-2 font-weight-bold"
                                >
                                    {loader ? <ButtonLoader /> : 'Submit'}
                                </button>
                            </div>
                        }

                    </div>
                </div>

            </form>
        </>
    );
};


export default AddExamAttendance;