import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import StudentSidebar from './StudentSidebar';
import { get_all_class, get_all_subject } from '../../../store/Reducers/administrator/administratorReducer';
import { useDispatch, useSelector } from 'react-redux';
import { sessions } from '../../../utils/other/data';
import { get_academic_year } from '../../../store/Reducers/academic/academicReducer';
import { get_all_teacher } from '../../../store/Reducers/administrator/teacherReducer';
import { searchFunction, sessionFunction } from '../../../store/Reducers/defaultReducer';
import { get_single_student } from '../../../store/Reducers/student/studentReducer';
import { useLocation } from 'react-router-dom';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const StudentLayout = ({ children }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [open, setOpen] = useState(true);

    const { sessionValue } = useSelector(state => state.default);
    const { studentInfo: { studentId, role, organisationId } } = useSelector(state => state.personalStudent);
    const { academicYearApprovedList } = useSelector(state => state.academic);


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    // Detect screen size and set initial open state
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        setOpen(!mediaQuery.matches);

        const handleResize = (e) => {
            setOpen(!e.matches);
        };

        mediaQuery.addEventListener('change', handleResize);
        return () => {
            mediaQuery.removeEventListener('change', handleResize);
        };

    }, []);


    //  handleSearch
    const handleSearch = (e) => {
        dispatch(searchFunction(e.target.value))
    }

    // handlesessionValue
    const handlesessionValue = (e) => {
        localStorage.setItem("sessionValue", e.target.value)
        dispatch(sessionFunction(e.target.value))
    }


    useEffect(() => {
        const obj = {
            parPage: 1000000000,
            page: 1,
            searchValue: ""
        }
        const data = {
            searchValue: '',
            organisationId: organisationId
        }
        dispatch(get_academic_year(obj));
        dispatch(get_all_class(data));
        dispatch(get_all_teacher(obj));
        dispatch(get_all_subject(obj))
        dispatch(get_single_student({ studentId }))

    }, [dispatch]);


    // console.log("academicYearApprovedList", academicYearApprovedList)


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon sx={{ fontSize: 34 }} />
                    </IconButton>
                    <div className="container-fluid d-flex">
                        <div className="collapse" id="search-nav">
                            <form className="navbar-left navbar-form nav-search mr-md-3">
                                <div className="input-group mx-5">
                                    <div className="input-group-prepend">
                                        <button type="submit" className="btn btn-search pr-1">
                                            <i className="fa fa-search search-icon text-dark" />
                                        </button>
                                    </div>
                                    <input onChange={handleSearch} type="text" placeholder="Search ..." className="form-control p-1" />
                                </div>
                            </form>
                        </div>
                        {
                            true && <div className='ml-auto'>
                                <select onChange={handlesessionValue} className='form-select p-1 px-2 text' style={{ width: "140px", fontWeight: "600", fontSize: "17px" }}>
                                    {
                                        academicYearApprovedList?.map((d) => (
                                            <option selected={d._id === sessionValue} value={d._id}>{d.sectionName}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        }
                    </div>
                </Toolbar>
            </AppBar>
            <StudentSidebar open={open} handleDrawerClose={handleDrawerClose} />
            <Main open={open}>
                <DrawerHeader />
                <Typography paragraph>
                    {children}
                </Typography>
            </Main>
        </Box>
    );
};

export default StudentLayout;
