import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { add_teacher, teacher_update } from '../../../../store/Reducers/administrator/teacherReducer';
import { useParams } from 'react-router-dom';


const TeacherPreviousExpriance = forwardRef(({ onSendMessage }, ref) => {

    const dispatch = useDispatch();

    const { teacherId } = useParams();


    const { teacher } = useSelector(state => state.teacher);


    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();

    const [isInstituteNameFilled, setIsInstituteNameFilled] = useState(false);
    const [instituteNameValue, setTnstituteNameValue] = useState('');


    useEffect(() => {
        setIsInstituteNameFilled(!!getValues('instituteName'));
    }, [getValues]);


    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        }
    }));


    // handleInstituteNameChange
    const handleInstituteNameChange = (e) => {
        const { value } = e.target;
        setTnstituteNameValue(value)
        setIsInstituteNameFilled(!!value);
    };


    // onSubmit
    const onSubmit = (data) => {
        onSendMessage(!true);
        // sessionStorage.setItem('teacherPreviousDetails', JSON.stringify(data))

        // console.log("datadata", data)
        // return

        const teacherBasicDetails = JSON.parse(sessionStorage.getItem('teacherBasicDetails'));
        const teacherAddress = JSON.parse(sessionStorage.getItem('teacherAddress'));
        const employmentDetails = JSON.parse(sessionStorage.getItem('employmentDetails'));
        const teacherBankDetails = JSON.parse(sessionStorage.getItem('teacherBankDetails'));
        const teacherAdditionalDetails = JSON.parse(sessionStorage.getItem('teacherAdditionalDetails'));
        // const teacherPreviousDetails = JSON.parse(sessionStorage.getItem('teacherPreviousDetails'));

        const dataaa = {
            teacherBasicDetails: {
                ...teacherBasicDetails,
                department: teacherBasicDetails?.department?.value,
                skills: teacherBasicDetails?.skills?.value,
                gender: teacherBasicDetails?.gender?.value,
                bloodGroup: teacherBasicDetails?.bloodGroup?.value

            },
            teacherAddress: {
                ...teacherAddress,
                country: teacherAddress?.country?.value,
                city: teacherAddress?.city?.value,
                state: teacherAddress?.country?.value === 'India' ? teacherAddress?.state?.value : teacherAddress?.state,
            },
            employmentDetails: {
                ...employmentDetails,
                employment: employmentDetails?.employment?.value,
                qualification: employmentDetails?.qualification?.value,
                workExperience: employmentDetails?.workExperience?.value
            },
            teacherBankDetails,
            teacherAdditionalDetails: {
                ...teacherAdditionalDetails,
                religion: teacherAdditionalDetails?.religion?.value,
                religionCategory: teacherAdditionalDetails?.religionCategory?.value
            },
            teacherPreviousDetails: {
                ...data,
                instituteName: instituteNameValue
            }
        }

        if (!teacherId) {
            dispatch(add_teacher(dataaa))
        } else {
            dispatch(teacher_update({ teacherId, dataaa }))
        }

    };


    // removeDetails
    const removeDetails = () => {
        // sessionStorage.removeItem("teacherPreviousDetails")
        reset({
            instituteName: '',
            location: '',
            joiningDate: '',
            relievingDate: '',
            referencePhoneNumber: '',
        });
        setIsInstituteNameFilled(false)

    }


    useEffect(() => {

        if (Object.keys(teacher).length > 0) {

            setValue('instituteName', teacher?.teacherPreviousDetails?.instituteName);
            setTnstituteNameValue(teacher?.teacherPreviousDetails?.instituteName)
            setValue('location', teacher?.teacherPreviousDetails?.location);

            setValue('joiningDate', moment(teacher.teacherPreviousDetails.joiningDate).format('YYYY-MM-DD'));
            setValue('relievingDate', moment(teacher.teacherPreviousDetails.relievingDate).format('YYYY-MM-DD'));
            setValue('referencePhoneNumber', teacher?.teacherPreviousDetails?.referencePhoneNumber);

        }

    }, [teacher])




    // useEffect(() => {
    //     const storedBasicDetails = sessionStorage.getItem("teacherPreviousDetails");

    //     if (storedBasicDetails) {
    //         const teacherPrevious = JSON.parse(storedBasicDetails);
    //         setValue('instituteName', teacherPrevious?.instituteName);
    //         setValue('location', teacherPrevious?.location);
    //         setValue('joiningDate', moment(teacherPrevious?.joiningDate).format('YYYY-MM-DD'));
    //         setValue('relievingDate', moment(teacherPrevious?.relievingDate).format('YYYY-MM-DD'));
    //         setValue('referencePhoneNumber', teacherPrevious?.referencePhoneNumber);
    //     }

    // }, [])



    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card">

                    <div className="card-header d-flex justify-content-between">
                        <h4 className="card-title">Previous Exprience</h4>
                        <button onClick={removeDetails} className='btn btn-danger px-3 py-2 fw-bold'> Reset </button>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="instituteName">Institute Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="instituteName"
                                            {...register('instituteName')}
                                            onChange={handleInstituteNameChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="location">Location {isInstituteNameFilled ? <span className='text-danger'>*</span> : ''}</label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.location ? 'is-invalid' : ''}`}
                                            id="location"
                                            {...register('location', {
                                                required: isInstituteNameFilled ? "Location is required" : false,
                                                minLength: {
                                                    value: 3,
                                                    message: "Location must be at least 3 characters"
                                                }
                                            })}
                                            disabled={!isInstituteNameFilled}
                                        />
                                        {isInstituteNameFilled && errors.location && (
                                            <div className="invalid-feedback">{errors.location.message}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="joiningDate">Joining Date {isInstituteNameFilled ? <span className='text-danger'>*</span> : ''}</label>
                                        <input
                                            type="date"
                                            className={`form-control ${errors.joiningDate ? 'is-invalid' : ''}`}
                                            id="joiningDate"
                                            {...register('joiningDate', {
                                                required: isInstituteNameFilled ? "Joining Date is required" : false,
                                            })}
                                            disabled={!isInstituteNameFilled}
                                        />
                                        {isInstituteNameFilled && errors.joiningDate && (
                                            <div className="invalid-feedback">{errors.joiningDate.message}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="relievingDate">Relieving Date {isInstituteNameFilled ? <span className='text-danger'>*</span> : ''}</label>
                                        <input
                                            type="date"
                                            className={`form-control ${errors.relievingDate ? 'is-invalid' : ''}`}
                                            id="relievingDate"
                                            {...register('relievingDate', {
                                                required: isInstituteNameFilled ? "Relieving Date is required" : false,
                                            })}
                                            disabled={!isInstituteNameFilled}
                                        />
                                        {isInstituteNameFilled && errors.relievingDate && (
                                            <div className="invalid-feedback">{errors.relievingDate.message}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="referencePhoneNumber">
                                            Reference Phone Number {isInstituteNameFilled ? <span className='text-danger'>*</span> : ''}
                                        </label>
                                        <input
                                            type="tel"
                                            className={`form-control ${errors.referencePhoneNumber ? 'is-invalid' : ''}`}
                                            id="referencePhoneNumber"
                                            {...register('referencePhoneNumber', {
                                                required: isInstituteNameFilled ? 'Reference Phone Number is required' : false,
                                            })}
                                            disabled={!isInstituteNameFilled}
                                        />
                                        {isInstituteNameFilled && errors.referencePhoneNumber && (
                                            <div className="invalid-feedback">{errors.referencePhoneNumber.message}</div>
                                        )}
                                    </div>
                                </div>

                            </div>
                            <button type="submit" style={{ display: 'none' }}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
});


export default TeacherPreviousExpriance;
