import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';



// add_class_schedule
export const add_class_schedule = createAsyncThunk(
    'class/add_class_schedule',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/add-class-schedule', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


// get_class_schedule
export const get_class_schedule = createAsyncThunk(
    'class/get_class_schedule',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/get-class-schedule', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


// get_teacher_schedule
export const get_teacher_schedule = createAsyncThunk(
    'class/get_teacher_schedule',
    async ({ teacherId }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-teacher-schedule/${teacherId}`);

            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const classReducer = createSlice({
    name: "class",
    initialState: {
        errorMessage: '',
        successMessage: '',
        loader: false,
        classSchedule: [],
        teacherSchedule: []
    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ''
            state.successMessage = ''
        }
    },
    extraReducers: {
        [add_class_schedule.pending]: (state, _) => {
            state.loader = true
        },
        [add_class_schedule.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [add_class_schedule.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [get_class_schedule.fulfilled]: (state, { payload }) => {
            state.classSchedule = payload.data
        },
        [get_teacher_schedule.fulfilled]: (state, { payload }) => {
            state.teacherSchedule = payload.data
        }

    }
});


export const { messageClear, studentClear } = classReducer.actions;
export default classReducer.reducer;