

// validateName
export const validateName = (message) => {
    return (value) => {
        if (!value) {
            return message;
        }
        return true;
    };
};


// Define the validateEmail function
export const validateEmail = (message) => {
    return (value) => {
        if (!value) {
            return message;
        }
        // Basic email pattern check
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(value)) {
            return 'Invalid email format';
        }
        return true;
    };
};


// Define the validatePhoneNumber function
export const validatePhoneNumber = (message) => {
    return (value) => {
        if (!value) {
            return message;
        }
        // Basic phone number pattern check (10 to 13 digits)
        const phonePattern = /^\+?\d{10,15}$/;
        if (!phonePattern.test(value)) {
            return 'Invalid phone number format';
        }
        return true;
    };
};
