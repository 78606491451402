import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { BsImage } from 'react-icons/bs';
import { server_url } from '../../../api/api';
import { ButtonLoader } from '../../../utils/Loader/LoaderComponent';
import { useDispatch, useSelector } from 'react-redux';
import { update_teacher_profile } from '../../../store/Reducers/administrator/teacherReducer';


const TeacherDetails = ({ open, setOpen, teacherDetails }) => {

    const dispatch = useDispatch();

    const { loader, successMessage, errorMessage } = useSelector(state => state.teacher);
    const { _id } = teacherDetails;

    const [imageShow, setImage] = useState('');
    const [teacherImage, setTeacherImage] = useState('');


    // imageHandle
    const imageHandle = (e) => {
        let files = e.target.files;
        if (files?.length > 0) {
            setImage(URL.createObjectURL(files[0]))
            setTeacherImage(e.target.files[0])
        }

    }


    // uploadImage
    const uploadImage = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('teacherImage', teacherImage);
        formData.append('oldImage', teacherDetails?.teacherImage ? teacherDetails?.teacherImage : '')
        dispatch(update_teacher_profile({ teacherId: _id, formData }));
    }

    useEffect(() => {
        if (errorMessage) {
            setImage('')
        }
        if (successMessage) {
            setImage('')
        }
    }, [errorMessage, successMessage])


    useEffect(() => {
        setImage('')
    }, [open])


    return (
        <>
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <div style={{ width: '720px' }}>
                    <DialogTitle style={{ fontSize: '20px' }}>Employee Details</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <div className="card mb-0">
                        <div className="card-body">
                            <ul className="nav nav-pills nav-secondary" id="pills-tab" role="tablist">
                                <li className="nav-item submenu">
                                    <a className="nav-link active show" id="pills-home-tab" data-toggle="pill" href="#Basic" role="tab" aria-selected="true">Basic</a>
                                </li>
                                <li className="nav-item submenu">
                                    <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#Address" role="tab" aria-selected="false">Address</a>
                                </li>
                                <li className="nav-item submenu">
                                    <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#Employment" role="tab" aria-selected="false">Employment</a>
                                </li>
                                <li className="nav-item submenu">
                                    <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#Bank" role="tab" aria-selected="false">Bank</a>
                                </li>
                                <li className="nav-item submenu">
                                    <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#Additional" role="tab" aria-selected="false">Additional</a>
                                </li>
                                <li className="nav-item submenu">
                                    <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#Previous" role="tab" aria-selected="false">Previous</a>
                                </li>
                                <li className="nav-item submenu">
                                    <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#Documents" role="tab" aria-selected="false">Documents</a>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div className='card mt-3'>
                        <div className='card-body'>
                            <div className="tab-content mt-2 mb-3" id="pills-tabContent">

                                <div className="tab-pane fade active show" id="Basic" role="tabpanel">

                                    <div className="d-flex flex-row">
                                        {/* <div>
                                            <img style={{ width: "230px", height: "230px", borderRadius: "4px", objectFit: "cover" }} src="https://media.istockphoto.com/id/1330255708/photo/portrait-of-smiling-teacher-in-school-corridor-with-books-in-hand.jpg?s=1024x1024&w=is&k=20&c=OnRShZVO9nnsF8OlrijZiLh-WopN0e6lU9PdcDoA9mw=" alt="Img" />
                                        </div> */}

                                        <div className="col-lg-4">

                                            <div className="card p-1">

                                                <label style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '230px', cursor: 'pointer', border: '1px dashed #d0d2d6', ':hover': { border: '1px solid #6610f2' }, width: '100%', borderColor: '#d0d2d6' }} htmlFor="image">
                                                    {
                                                        teacherDetails?.teacherImage && !imageShow ? <div className="studentProfile">
                                                            <img style={{ width: "100%" }} src={`${server_url}/${teacherDetails?.teacherImage}`} alt="Teacher" />
                                                        </div> : <>
                                                            {
                                                                imageShow ? <img style={{ width: '100%', height: '100%' }} src={imageShow} alt="Selected Image" />
                                                                    : <>
                                                                        <span style={{ fontSize: '24px' }}><BsImage /></span>
                                                                        <span style={{ fontSize: '16px' }}>Select Image</span>
                                                                    </>
                                                            }
                                                        </>
                                                    }

                                                </label>

                                                <input onChange={imageHandle} style={{ display: 'none' }} type="file" accept="image/*" name="image" id="image" />

                                            </div>

                                            {
                                                imageShow && <div className='text-right'>
                                                    <button disabled={loader ? true : false} onClick={uploadImage} style={{ width: "90px", fontWeight: 600 }} className='btn btn-danger px-2 py-1'>
                                                        {loader ? <ButtonLoader /> : 'Upload'}
                                                    </button>
                                                </div>
                                            }

                                        </div>
                                        <div className="col-lg-8">

                                            <div className='pl-4' style={{ width: "100%" }}>
                                                {/* <div className='d-flex justify-content-between'>
                                                <div>
                                                    <h3 style={{ fontSize: "22px" }}>{teacherDetails?.teacherBasicDetails?.fullName}</h3>
                                                </div>
                                            </div> */}
                                                {/* <div>
                                                <p style={{ textAlign: "justify", fontSize: "14px" }}>This is a sample bio for John Doe. He is a dedicated teacher with a passion for education and helping students succeed.</p>
                                            </div> */}

                                                <div className='d-flex border-bottom pb-1 mb-1'>
                                                    <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Full Name :</p>
                                                    <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherBasicDetails?.fullName}</p>
                                                </div>
                                                <div className='d-flex border-bottom pb-1 mb-1'>
                                                    <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Email :</p>
                                                    <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherBasicDetails?.email}</p>
                                                </div>
                                                <div className='d-flex border-bottom pb-1 mb-1'>
                                                    <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Phone Number :</p>
                                                    <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherBasicDetails?.phoneNumber}</p>
                                                </div>
                                                <div className='d-flex border-bottom pb-1 mb-1'>
                                                    <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Department  :</p>
                                                    <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherBasicDetails?.department}</p>
                                                </div>
                                                <div className='d-flex border-bottom pb-1 mb-1'>
                                                    <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Enrollment Number :</p>
                                                    <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherBasicDetails?.enrollmentNumber}</p>
                                                </div>
                                                <div className='d-flex border-bottom pb-1 mb-1'>
                                                    <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Skills :</p>
                                                    <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherBasicDetails?.skills}</p>
                                                </div>
                                                <div className='d-flex border-bottom pb-1 mb-1'>
                                                    <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Gender :</p>
                                                    <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherBasicDetails?.gender}</p>
                                                </div>
                                                <div className='d-flex border-bottom pb-1 mb-1'>
                                                    <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Blood Group :</p>
                                                    <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherBasicDetails?.bloodGroup}</p>
                                                </div>
                                                <div className='d-flex border-bottom pb-1 mb-1'>
                                                    <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Date of Joining :</p>
                                                    <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{moment(teacherDetails?.teacherBasicDetails?.dateOfJoining).format('DD, MMM, YYYY')}</p>
                                                </div>
                                                <div className='d-flex border-bottom pb-1 mb-1'>
                                                    <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Date of Birth :</p>
                                                    <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{moment(teacherDetails?.teacherBasicDetails?.dateOfBirth).format('DD, MMM, YYYY')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="tab-pane fade" id="Address" role="tabpanel">
                                    <div className="d-flex flex-row">

                                        <div className='pl-4' style={{ width: "100%" }}>

                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Country :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherAddress?.country}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>State :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherAddress?.state}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Pincode :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherAddress?.pincode}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>City :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherAddress?.city}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Full Address :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherAddress?.fullAddress}</p>
                                            </div>


                                        </div>
                                    </div>

                                </div>

                                <div className="tab-pane fade" id="Employment" role="tabpanel">
                                    <div className="d-flex flex-row">
                                        <div className='pl-4' style={{ width: "100%" }}>

                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Job Title :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.employmentDetails?.jobTitle}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Designation :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.employmentDetails?.designation}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Department :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.employmentDetails?.department}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Employment :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.employmentDetails?.employment}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Date of Appointment :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{moment(teacherDetails?.employmentDetails?.dateOfAppointment).format('DD, MMM, YYYY')}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Highest Qualification :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.employmentDetails?.qualification}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Work Experience :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.employmentDetails?.workExperience}</p>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="Bank" role="tabpanel">
                                    <div className="d-flex flex-row">

                                        <div className='pl-4' style={{ width: "100%" }}>

                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Bank Name :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherBankDetails?.bankName}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Branch Name :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherBankDetails?.branchName}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Account Holder Name :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherBankDetails?.accountHolderName}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Account Number :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherBankDetails?.accountNumber}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>IFSC Code :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherBankDetails?.ifscCode}</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="Additional" role="tabpanel">
                                    <div className="d-flex flex-row">
                                        <div className='pl-4' style={{ width: "100%" }}>

                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Religion :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherAdditionalDetails?.religion}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Religion Category :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherAdditionalDetails?.religionCategory}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Marital Status :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherAdditionalDetails?.maritalStatus}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Aadhar Number :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherAdditionalDetails?.aadharNumber}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>PAN Number :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherAdditionalDetails?.panNumber}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Emergency Contact Number :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherAdditionalDetails?.emergencyNumber}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Father's Name :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherAdditionalDetails?.fatherName}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Mother's Name :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherAdditionalDetails?.motherName}</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="Previous" role="tabpanel">
                                    <div className="d-flex flex-row">

                                        <div className='pl-4' style={{ width: "100%" }}>

                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Institute Name :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherPreviousDetails?.instituteName}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Location :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherPreviousDetails?.location}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Joining Date :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{moment(teacherDetails?.teacherPreviousDetails?.joiningDate).format('DD, MMM, YYYY')}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Relieving Date :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{moment(teacherDetails?.teacherPreviousDetails?.relievingDate).format('DD, MMM, YYYY')}</p>
                                            </div>
                                            <div className='d-flex border-bottom pb-1 mb-1'>
                                                <p style={{ fontSize: "16px", width: "40%" }} className='mb-1'>Reference Phone Number :</p>
                                                <p style={{ fontSize: "16px", fontWeight: "600", width: "60%" }} className='mb-1'>{teacherDetails?.teacherPreviousDetails?.referencePhoneNumber}</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="Documents" role="tabpanel">
                                    <h1>Comming......</h1>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            </Drawer>
        </>
    )
}

export default TeacherDetails;