
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectStudent = () => {
    const { studentInfo: { role }, studentToken } = useSelector(state => state.personalStudent);

    // console.log("role", role)
    // console.log("studentToken", studentToken)


    if (role === 'Student' && studentToken) {
        return <Outlet />
    } else {
        return <Navigate to="/" />;
    }

}


export default ProtectStudent;