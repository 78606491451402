import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';



// add_employee_attendance
export const add_employee_attendance = createAsyncThunk(
    'employeeAttendance/add_employee_attendance',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/add-employee-attendance', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


// get_employee_attendance
export const get_employee_attendance = createAsyncThunk(
    'employeeAttendance/get_employee_attendance',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/get-employee-attendance', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)



export const employeeAttendance = createSlice({
    name: "employeeAttendance",
    initialState: {
        errorMessage: '',
        successMessage: '',
        loader: false,
        emploueeAttendanceForMonth: [],
        attendanceForSingleEmployee: {}
    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ''
            state.successMessage = ''
        }
    },
    extraReducers: {
        [add_employee_attendance.pending]: (state, _) => {
            state.loader = true
        },
        [add_employee_attendance.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [add_employee_attendance.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [get_employee_attendance.pending]: (state, _) => {
            state.loader = true
        },
        [get_employee_attendance.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [get_employee_attendance.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.emploueeAttendanceForMonth = payload.data
        },

    }
});


export const { messageClear, studentClear } = employeeAttendance.actions;
export default employeeAttendance.reducer;