import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';


// employee_login
export const employee_login = createAsyncThunk(
    'employee/employee_login',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/employee-login', info)
            localStorage.setItem('authToken', data.authToken)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)



// teacher_logout
export const employee_logout = createAsyncThunk(
    'employee/employee_logout',
    async (_, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get('/employee-logout')
            localStorage.removeItem('authToken');
            localStorage.removeItem('sessionValue');
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)




// returnRole
const returnRole = (token) => {
    if (token) {
        const payload = token.split('.')[1];
        const decodedPayload = JSON.parse(atob(payload));
        const expireTime = new Date(decodedPayload.exp * 1000);
        if (new Date() > expireTime) {
            localStorage.removeItem('authToken');
            return '';
        } else {
            return decodedPayload;
        }
    } else {
        return '';
    }
};



export const employeeAuthReducer = createSlice({
    name: "employee",
    initialState: {
        errorMessage: '',
        successMessage: '',
        logoutMessage: '',
        loader: false,
        employeeInfo: returnRole(localStorage.getItem("authToken")),
        employeeToken: localStorage.getItem("authToken"),

    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ''
            state.successMessage = ''
            state.logoutMessage = ''
        },
        tokenClear: (state, _) => {
            state.employeeInfo = ''
            state.employeeToken = ''
        },
    },
    extraReducers: {
        [employee_login.pending]: (state, _) => {
            state.loader = true
        },
        [employee_login.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [employee_login.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
            state.employeeInfo = returnRole(payload.authToken)
            state.employeeToken = payload.authToken
        },
        [employee_logout.fulfilled]: (state, { payload }) => {
            state.logoutMessage = payload.message
        },


    }
});



export const { messageClear, tokenClear } = employeeAuthReducer.actions;
export default employeeAuthReducer.reducer;