import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail, validateName, validatePhoneNumber } from '../../../../utils/validators/commonErrorHandle';
import { useOptionsFromData } from '../../../../utils/other/returnFunction';
import { employmentType, indianQualifications, workExperienceList } from '../../../../utils/other/data';


const EmploymentDetails = forwardRef(({ onSendMessage }, ref) => {

    const dispatch = useDispatch();

    const { teacher } = useSelector(state => state.teacher);

    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();


    const employmentList = useOptionsFromData(employmentType?.employment);
    const qualificationList = useOptionsFromData(indianQualifications?.qualifications);
    const experienceList = useOptionsFromData(workExperienceList?.experienceLevels);

    const [employmentValue, setEmploymentValue] = useState(null);
    const [qualificationValue, setQualificationValue] = useState(null);
    const [experienceValue, setExperienceValue] = useState(null);



    // handleSelect
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
        clearErrors(fieldName);
    }


    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        }
    }));



    // onSubmit
    const onSubmit = (data) => {
        onSendMessage(true);
        sessionStorage.setItem('employmentDetails', JSON.stringify(data))
    };


    // removeDetails
    const removeDetails = () => {
        sessionStorage.removeItem("employmentDetails")

        reset({
            jobTitle: '',
            designation: '',
            department: '',
            employment: '',
            dateOfAppointment: '',
            qualification: '',
            workExperience: '',

        });
        setEmploymentValue();
        setQualificationValue();
        setQualificationValue();
        setExperienceValue();

    }


    useEffect(() => {
        if (Object.keys(teacher).length > 0) {

            setValue('jobTitle', teacher?.employmentDetails.jobTitle);
            setValue('designation', teacher?.employmentDetails.designation);
            setValue('department', teacher?.employmentDetails.department);

            setEmploymentValue({
                label: teacher.employmentDetails?.employment,
                value: teacher.employmentDetails?.employment
            });
            setValue("employment", {
                label: teacher.employmentDetails?.employment,
                value: teacher.employmentDetails?.employment
            });

            setValue('dateOfAppointment', moment(teacher.employmentDetails.dateOfAppointment).format('YYYY-MM-DD'));

            setQualificationValue({
                label: teacher.employmentDetails?.qualification,
                value: teacher.employmentDetails?.qualification
            });
            setValue("qualification", {
                label: teacher.employmentDetails?.qualification,
                value: teacher.employmentDetails?.qualification
            });

            setExperienceValue({
                label: teacher.employmentDetails?.workExperience,
                value: teacher.employmentDetails?.workExperience
            });
            setValue("workExperience", {
                label: teacher.employmentDetails?.workExperience,
                value: teacher.employmentDetails?.workExperience
            });

        }

    }, [teacher])



    useEffect(() => {

        const storedBasicDetails = sessionStorage.getItem("employmentDetails");
        if (storedBasicDetails) {
            const employment = JSON.parse(storedBasicDetails);

            setValue("jobTitle", employment.jobTitle)
            setValue("designation", employment.designation)
            setValue("department", employment.department)

            setEmploymentValue({
                label: employment?.employment.label, value: employment?.employment.value
            })
            setValue("employment", {
                label: employment?.employment.label, value: employment?.employment.value
            })
            setValue('dateOfAppointment', moment(employment.dateOfAppointment).format('YYYY-MM-DD'));

            setQualificationValue({
                label: employment?.qualification.label, value: employment?.qualification.value
            })
            setValue("qualification", {
                label: employment?.qualification.label, value: employment?.qualification.value
            })

            setExperienceValue({
                label: employment?.workExperience.label, value: employment?.workExperience.value
            })
            setValue("workExperience", {
                label: employment?.workExperience.label, value: employment?.workExperience.value
            })
        }

    }, [])


    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header d-flex justify-content-between">
                        <h4 className="card-title">Employment Details</h4>
                        <button onClick={removeDetails} className='btn btn-danger px-3 py-2 fw-bold'> Reset </button>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="jobTitle">Job Title <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.jobTitle ? 'is-invalid' : ''}`}
                                            id="jobTitle"
                                            {...register('jobTitle', {
                                                validate: validateName('Job title is required')
                                            })}
                                        />
                                        {errors.jobTitle && <div className="invalid-feedback">{errors.jobTitle.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="designation">Designation <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.designation ? 'is-invalid' : ''}`}
                                            id="designation"
                                            {...register('designation', {
                                                validate: value => value ? true : 'Designation is required'
                                            })}
                                        />
                                        {errors.designation && <div className="invalid-feedback">{errors.designation.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="department">Department <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.department ? 'is-invalid' : ''}`}
                                            id="department"
                                            {...register('department', {
                                                validate: value => value ? true : 'Department is required'
                                            })}
                                        />
                                        {errors.department && <div className="invalid-feedback">{errors.department.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="employment">Employment <span className='text-danger'>*</span></label>
                                        <Select
                                            {...register('employment', { required: 'Employment is required' })}
                                            value={employmentValue}
                                            onChange={(selectOption) => {
                                                setEmploymentValue(selectOption);
                                                handleSelect('employment', selectOption);
                                            }}
                                            options={employmentList}
                                            className={errors.employment ? 'is-invalid' : ''}
                                        />
                                        {errors.employment && <div className="invalid-feedback">{errors.employment.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="dateOfAppointment">Date of Appointment <span className='text-danger'>*</span></label>
                                        <input
                                            type="date"
                                            className={`form-control ${errors.dateOfAppointment ? 'is-invalid' : ''}`}
                                            id="dateOfAppointment"
                                            {...register('dateOfAppointment', {
                                                validate: validateName('Date of Appointment is required')
                                            })}
                                        />
                                        {errors.dateOfAppointment && <div className="invalid-feedback">{errors.dateOfAppointment.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="qualification">Highest Qualification <span className='text-danger'>*</span></label>
                                        <Select
                                            {...register('qualification', { required: 'Qualification is required' })}
                                            value={qualificationValue}
                                            onChange={(selectOption) => {
                                                setQualificationValue(selectOption);
                                                handleSelect('qualification', selectOption);
                                            }}
                                            options={qualificationList}
                                            className={errors.qualification ? 'is-invalid' : ''}
                                        />
                                        {errors.qualification && <div className="invalid-feedback">{errors.qualification.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="workExperience">Work Experience <span className='text-danger'>*</span></label>
                                        <Select
                                            {...register('workExperience', { required: 'Experience is required' })}
                                            value={experienceValue}
                                            onChange={(selectOption) => {
                                                setExperienceValue(selectOption);
                                                handleSelect('workExperience', selectOption);
                                            }}
                                            options={experienceList}
                                            className={errors.workExperience ? 'is-invalid' : ''}
                                        />
                                        {errors.workExperience && <div className="invalid-feedback">{errors.workExperience.message}</div>}
                                    </div>
                                </div>

                            </div>
                            <button type="submit" style={{ display: 'none' }}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
});


export default EmploymentDetails;