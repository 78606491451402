import React, { useEffect, useState } from 'react';
import Layout from '../../Layout/Layout';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ButtonLoader } from '../../../utils/Loader/LoaderComponent';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { generatePassword } from '../../../utils/other/returnFunction';
import { add_administrator, messageClear } from '../../../store/Reducers/organisation/organizationReducer';


const AddAdministrator = () => {

    const { successMessage, loader, errorMessage } = useSelector(state => state.organization);


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors } = useForm();

    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');


    // onSubmit
    const onSubmit = (data) => {
        const { firstName, lastName, email, address, phoneNumber } = data;
        const obj = {
            firstName,
            lastName,
            email,
            address,
            phoneNumber,
            password
        }
        dispatch(add_administrator(obj))
    }


    // Listen for changes in the phone number input field
    useEffect(() => {
        if (phoneNumber?.length === 10) {
            const newPassword = generatePassword();
            setPassword(newPassword);
        } else {
            setPassword('')
        }
    }, [phoneNumber]);

    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage)
            dispatch(messageClear())
        }
        if (successMessage) {
            toast.success(successMessage)
            dispatch(messageClear())
            navigate('/all-administrator', { replace: true })
        }
    }, [errorMessage, successMessage])



    return (
        <Layout>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">

                        <div className="card-body">

                            <div className="page-header mb-0">
                                <span style={{ fontSize: "18px", padding: "0px 8px 8px 0px" }}>
                                    <i className="fas fa-edit" />
                                </span>
                                <h4 style={{ fontSize: "19px" }}>Add Administrator</h4>

                                <Link to='/all-administrator' className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                    <span><i className="far fa-list-alt pr-2" />All</span>
                                </Link>
                            </div>

                            <hr className='my-3' />

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">

                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="firstName">First Name <span className='text-danger'>*</span></label>
                                            <input type="text" className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} id="firstName" {...register('firstName', { required: 'First Name is required' })} />
                                            {errors.firstName && <div className="invalid-feedback">{errors.firstName.message}</div>}
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="lastName">Last Name <span className='text-danger'>*</span></label>
                                            <input type="text" className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} id="lastName" {...register('lastName', { required: 'Last Name is required' })} />
                                            {errors.lastName && <div className="invalid-feedback">{errors.lastName.message}</div>}
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="email">Email <span className='text-danger'>*</span></label>
                                            <input type="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} id="email" {...register('email', { required: 'Email is required', pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i, message: 'Invalid email address' } })} />
                                            {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="address">Address <span className='text-danger'>*</span></label>
                                            <input type="text" className={`form-control ${errors.address ? 'is-invalid' : ''}`} id="address" {...register('address', { required: 'Address is required' })} />
                                            {errors.address && <div className="invalid-feedback">{errors.address.message}</div>}
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="phoneNumber">Phone Number <span className='text-danger'>*</span></label>
                                            <input type="tel" className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`} id="phoneNumber" {...register('phoneNumber', {
                                                required: 'Phone Number is required',
                                                pattern: {
                                                    value: /^\d{10}$/,
                                                    message: 'Invalid phone number format'
                                                }
                                            })} onChange={(e) => setPhoneNumber(e.target.value)} />
                                            {errors.phoneNumber && <div className="invalid-feedback">{errors.phoneNumber.message}</div>}
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="password">Password <span className='text-danger'>*</span></label>
                                            <input type="text" className={`form-control ${errors.password ? 'is-invalid' : ''}`} id="password" value={password} disabled />
                                            {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
                                        </div>

                                    </div>

                                </div>

                                <div className="m-3" style={{ textAlign: "right" }}>
                                    <button disabled={loader ? true : false} type="submit" style={{ width: "100px" }} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                        {
                                            loader ? <ButtonLoader /> : 'Register'
                                        }
                                    </button>

                                </div>
                            </form>


                        </div>
                    </div>
                </div>

            </div>

        </Layout>
    )
}


export default AddAdministrator;