import React, { useEffect, useState } from 'react';
import Layout from '../../../Layout/Layout';
import './studentDetails.css';
import { FaBirthdayCake } from "react-icons/fa";
import { GiHeavyTimer } from "react-icons/gi";
import { MdOutlineEmail } from "react-icons/md";
import { MdOutlinePermPhoneMsg } from "react-icons/md";
import { AiOutlineHome } from "react-icons/ai";
import StudentBasicDetails from './StudentBasicDetails';
import FeesDetails from './FeesDetails';
import PromotionHistory from './PromotionHistory';
import BookIssue from './BookIssue';
import ExamResult from './ExamResult';
import Documents from './Documents';
import { useParams } from 'react-router-dom';
import { get_single_student, studentClear, update_student_profile, messageClear } from '../../../../store/Reducers/student/studentReducer';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import { getActiveClassName, getActiveSession } from '../../../../utils/other/returnFunction';
import { server_url } from '../../../../api/api';
import { BsImage } from 'react-icons/bs';
import { ButtonLoader } from '../../../../utils/Loader/LoaderComponent';
import toast from 'react-hot-toast';


const StudentDetails = () => {

    const { studentId } = useParams();
    const dispatch = useDispatch();

    const { classList } = useSelector(state => state.administrator);
    const { loader, successMessage, errorMessage, student } = useSelector(state => state.student);

    const [imageShow, setImage] = useState('');
    const [studentImage, setStudentImage] = useState('');


    // imageHandle
    const imageHandle = (e) => {
        let files = e.target.files;
        if (files?.length > 0) {
            setImage(URL.createObjectURL(files[0]))
            setStudentImage(e.target.files[0])
        }

    }


    // uploadImage
    const uploadImage = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('studentImage', studentImage);
        formData.append('oldImage', student?.studentImage ? student?.studentImage : '')
        dispatch(update_student_profile({ studentId, formData }));
    }


    useEffect(() => {
        if (studentId) {
            dispatch(get_single_student({ studentId }))
        } else {
            dispatch(studentClear())
        }

    }, [studentId, successMessage])


    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage)
            dispatch(messageClear())
            setImage('')
        }
        if (successMessage) {
            toast.success(successMessage)
            dispatch(messageClear())
            setImage('')
        }

    }, [errorMessage, successMessage])


    return (
        <Layout>
            <div className="card">
                <div className="studentBgProfile">
                    <div className="studentBgColor">
                        <div className="container p-3">
                            <div className="row">
                                <div className="col-lg-3">

                                    <div className="card p-2 mt-4">

                                        <label style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '230px', cursor: 'pointer', border: '1px dashed #d0d2d6', ':hover': { border: '1px solid #6610f2' }, width: '100%', borderColor: '#d0d2d6' }} htmlFor="image">
                                            {
                                                student?.studentImage && !imageShow ? <div className="studentProfile">
                                                    <img style={{ width: "100%" }} src={`${server_url}/${student?.studentImage}`} alt="Student" />
                                                </div> : <>
                                                    {
                                                        imageShow ? <img style={{ width: '100%', height: '100%' }} src={imageShow} alt="Selected Image" />
                                                            : <>
                                                                <span style={{ fontSize: '24px' }}><BsImage /></span>
                                                                <span style={{ fontSize: '16px' }}>Select Image</span>
                                                            </>
                                                    }
                                                </>
                                            }

                                        </label>

                                        <input onChange={imageHandle} style={{ display: 'none' }} type="file" accept="image/*" name="image" id="image" />

                                    </div>


                                </div>
                                <div className="col-lg-6">
                                    <div className='p-2 mt-3 studentInfo'>
                                        <h5>{student?.basicDetails?.fullName}</h5>
                                        <p>Student / General</p>
                                        <div className='d-flex'>
                                            <FaBirthdayCake style={{ color: "yellow", fontSize: "18px" }} />
                                            <p className='pt-1 px-2' style={{ fontSize: "13px", color: "#fff", fontWeight: "800" }}>{moment(student?.basicDetails?.dateOfBirth).format('DD, MMM, YYYY')}</p>
                                        </div>
                                        <div className='d-flex' style={{ marginTop: "-10px" }}>
                                            <GiHeavyTimer style={{ color: "yellow", fontSize: "22px" }} />
                                            <p className='pt-1 px-2' style={{ fontSize: "13px", color: "#fff", fontWeight: "800" }}>{getActiveClassName(classList, student?.sessions)} ({getActiveSession(student?.sessions)})</p>
                                        </div>
                                        <div className='d-flex' style={{ marginTop: "-10px" }}>
                                            <MdOutlinePermPhoneMsg style={{ color: "yellow", fontSize: "20px" }} />
                                            <p className='pt-1 px-2' style={{ fontSize: "13px", color: "#fff", fontWeight: "800" }}>{student?.basicDetails?.phoneNumber}</p>
                                        </div>
                                        <div className='d-flex' style={{ marginTop: "-10px" }}>
                                            <MdOutlineEmail style={{ color: "yellow", fontSize: "20px" }} />
                                            <p className='pt-1 px-2' style={{ fontSize: "13px", color: "#fff", fontWeight: "800" }}>{student?.basicDetails?.email}</p>
                                        </div>
                                        <div className='d-flex' style={{ marginTop: "-10px" }}>
                                            <AiOutlineHome style={{ color: "yellow", fontSize: "20px" }} />
                                            <p className='pt-1 px-2' style={{ fontSize: "13px", color: "#fff", fontWeight: "800" }}>{student?.addressDetails?.fullAddress}</p>
                                        </div>

                                        {
                                            imageShow && <div>
                                                <button disabled={loader ? true : false} onClick={uploadImage} style={{ width: "90px", fontWeight: 600 }} className='btn btn-danger px-2 py-1'>
                                                    {loader ? <ButtonLoader /> : 'Upload'}
                                                </button>
                                            </div>
                                        }

                                    </div>
                                </div>

                                <div className="col-lg-3">

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <StudentBasicDetails student={student} classList={classList} />
                <FeesDetails />
                <PromotionHistory student={student} />
                <BookIssue />
                <ExamResult />
                <Documents />

            </div>
        </Layout>
    )
}

export default StudentDetails;
