import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import toast from 'react-hot-toast';
import { student_filter } from '../../../../store/Reducers/student/studentReducer';
import { add_student_attendance, messageClear } from '../../../../store/Reducers/attendance/studentAttendance';
import { image_url, server_url } from '../../../../api/api';
import { ButtonLoader } from '../../../../utils/Loader/LoaderComponent';
import Empty from '../../../../utils/page/Empty';


const AddStudentAttendance = () => {

    const dispatch = useDispatch();
    const { handleSubmit, getValues, setValue, register, formState: { errors }, reset } = useForm();
    const { classList } = useSelector(state => state.administrator);
    const { allStudents } = useSelector(state => state.student);
    const { sessionValue } = useSelector(state => state.default);

    const { loader, successMessage, errorMessage } = useSelector(state => state.studentAttendance);


    const [classListOption, setClassListOption] = useState("");
    const [sectionOption, setSectionOption] = useState("");
    const [selecteeClassId, setSelecteeClassId] = useState('');
    const [selecteeSectionId, setSelecteeSectionId] = useState('');

    const [classValue, setClassValue] = useState(null);
    const [sectionValue, setSectionValue] = useState(null);

    // handleSelect function
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);

        if (fieldName === 'className') {
            setSelecteeClassId(selectOption.value);
            setSectionValue({ value: "", label: "Select Section" });
            setValue("section", { label: '', value: '' });
            const secOption = classList?.filter((item) => item._id === selectOption.value);
            const array = secOption[0]?.sectionNames;
            const convertedArray = array?.map(item => ({
                value: item,
                label: item
            }));
            setSectionOption(convertedArray);
        }

        if (fieldName === 'section') {
            setSelecteeSectionId(selectOption.value)
            const obj = {
                sessionValue,
                className: selecteeClassId,
                section: selectOption.value
            }
            dispatch(student_filter(obj));
        }
    }


    // handleSelectForEveryone
    const handleSelectForEveryone = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue) {
            allStudents?.forEach((student, index) => {
                setValue(`students[${index}].attendance`, selectedValue);
            });
        }
    };


    // Function to handle form submission
    const onSubmit = (data) => {
        // Collect student IDs, attendance, and remarks
        const studentData = allStudents.map((student, index) => ({
            studentId: student._id,
            attendance: data.students[index]?.attendance,
            remark: data.students[index]?.remark || '',
        }));

        const obj = {
            sessionId: sessionValue,
            classId: selecteeClassId,
            section: selecteeSectionId,
            date: data.date,
            attendanceRecords: studentData
        }

        // console.log("obj", obj)

        dispatch(add_student_attendance(obj))

    };


    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage);
            dispatch(messageClear());
        }
        if (successMessage) {
            toast.success(successMessage);
            dispatch(messageClear());
        }
    }, [errorMessage, successMessage]);


    useEffect(() => {
        if (allStudents?.length > 0) {
            allStudents.forEach((student, index) => {
                setValue(`students[${index}].attendance`, 'Present');
            });
        }
    }, [allStudents, setValue]);


    useEffect(() => {
        const options = classList?.map(item => ({
            value: item._id,
            label: item.className
        }));
        setClassListOption(options);
    }, [classList]);


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card mb-3">
                    <div className="card-body py-">
                        <div className="row">
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group pt-0">
                                    <label htmlFor="className">Class <span className='text-danger'>*</span></label>
                                    <Select
                                        placeholder="Select Class"
                                        value={classValue}
                                        {...register('className')}
                                        onChange={(selectOption) => {
                                            setClassValue(selectOption);
                                            handleSelect('className', selectOption);
                                        }}
                                        options={classListOption}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <div className="form-group pt-0">
                                    <label htmlFor="section">Section <span className='text-danger'>*</span></label>
                                    <Select
                                        {...register('section')}
                                        placeholder="Select Section"
                                        value={sectionValue}
                                        onChange={(selectOption) => {
                                            setSectionValue(selectOption);
                                            handleSelect('section', selectOption);
                                        }}
                                        options={sectionOption}
                                        isDisabled={sectionOption?.length > 0 ? false : true}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <div className="form-group pt-0">
                                    <label htmlFor="date">Date<span className='text-danger'>*</span></label>
                                    <input
                                        type="date"
                                        className={`form-control ${errors.date ? 'is-invalid' : ''}`}
                                        id="date"
                                        {...register('date', { required: 'Date is required' })}
                                    />
                                    {errors.date && <div className="invalid-feedback">{errors.date.message}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">

                        <div className='mb-3' style={{ width: "250px" }}>
                            <label htmlFor="" className='mb-1'>Select For Everyone <span className='text-danger'>*</span></label>
                            <select className='form-select p-1 px-2 text' onChange={handleSelectForEveryone}>
                                <option value="" selected>Not Selected</option>
                                <option value="Present">Present</option>
                                <option value="Absent">Absent</option>
                                {/* <option value="Late">Late</option>
                                <option value="Half-day">Half-day</option> */}
                            </select>
                        </div>

                        {allStudents?.length > 0 && sectionValue?.value ? (
                            <table className="tableStyle">
                                <thead>
                                    <tr>
                                        <th>Sl.</th>
                                        <th>Photo</th>
                                        <th>Name</th>
                                        <th>Register No</th>
                                        <th>Attendance</th>
                                        <th>Remarks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allStudents?.map((d, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td data-label="Photo">
                                                <img style={{ width: "35px", height: "35px", borderRadius: "50%" }} src={d?.studentImage ? `${server_url}/${d?.studentImage}` : `${image_url}/assets/img/student/male.png`} alt="Img" />
                                            </td>
                                            <td data-label="Name">{d.basicDetails.fullName}</td>
                                            <td data-label="Register No">{d.basicDetails.enrollmentNumber}</td>

                                            <td data-label="Attendance">
                                                <div className="form-check form-check-inline p-0 m-0">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={`students[${i}].attendance`}
                                                        id={`present_${i}`}
                                                        value="Present"
                                                        {...register(`students[${i}].attendance`, { required: 'Attendance is required' })}
                                                        style={{ marginBottom: "9px", fontSize: "16px" }}
                                                    />
                                                    <label className="form-check-label" htmlFor={`present_${i}`}>
                                                        Present
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline p-0 m-0">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={`students[${i}].attendance`}
                                                        id={`absent_${i}`}
                                                        value="Absent"
                                                        {...register(`students[${i}].attendance`)}
                                                        style={{ marginBottom: "9px", fontSize: "16px" }}
                                                    />
                                                    <label className="form-check-label" htmlFor={`absent_${i}`}>
                                                        Absent
                                                    </label>
                                                </div>

                                                {/* <div className="form-check form-check-inline p-0 m-0">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={`students[${i}].attendance`}
                                                        id={`late_${i}`}
                                                        value="Late"
                                                        {...register(`students[${i}].attendance`)}
                                                        style={{ marginBottom: "9px", fontSize: "16px" }}
                                                    />
                                                    <label className="form-check-label" htmlFor={`late_${i}`}>
                                                        Late
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline p-0 m-0">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={`students[${i}].attendance`}
                                                        id={`halfday_${i}`}
                                                        value="Half-day"
                                                        {...register(`students[${i}].attendance`)}
                                                        style={{ marginBottom: "9px", fontSize: "16px" }}
                                                    />
                                                    <label className="form-check-label" htmlFor={`halfday_${i}`}>
                                                        Half-day
                                                    </label>
                                                </div> */}

                                            </td>

                                            <td data-label="Remarks">
                                                <input
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    {...register(`students[${i}].remark`)}
                                                    className='form-control p-2'
                                                    placeholder='Remarks'
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <Empty data={`Student Not Found !`} />
                        )}

                        {
                            allStudents?.length > 0 && sectionValue?.value && <div className="mt-4 text-right">
                                <button
                                    type='submit'
                                    disabled={loader}
                                    style={{ width: "90px" }}
                                    className="btn btn-secondary py-2 font-weight-bold"
                                >
                                    {loader ? <ButtonLoader /> : 'Submit'}
                                </button>
                            </div>
                        }

                    </div>
                </div>

            </form>
        </>
    );
};


export default AddStudentAttendance;