import React from 'react';
import AdministratorLogin from './AdministratorLogin';
import OrWonerLogin from './OrWonerLogin';
import TeacherLogin from './TeacherLogin';
import StudentLogin from './StudentLogin';
import { GiTeacher } from "react-icons/gi";
import { PiStudent } from "react-icons/pi";
import { FaHome } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import { GrOrganization } from "react-icons/gr";
import OrganizationLogin from './OrganizationLogin';


const AllLogin = () => {
    return (
        <div className='bg-secondary' style={{ height: "100vh", display: "flex", alignItems: "center" }}>

            <div className="container py-5 px-5">
                <div className="row justify-content-center">
                    <div className="col-lg-12" style={{ maxWidth: "750px" }}>
                        <div className="card p-3">

                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-lg-4">
                                        <div className="nav flex-column nav-pills nav-secondary nav-pills-no-bd nav-pills-icons" id="v-pills-tab-with-icon" role="tablist" aria-orientation="vertical">

                                            <a className="nav-link py-2 active" id="v-pills-profile-tab-icons" data-toggle="pill" href="#v-pills-Student-icons" role="tab" aria-controls="v-pills-profile-icons" aria-selected="false">
                                                <div style={{ fontSize: "25px" }}>
                                                    <PiStudent />
                                                </div>
                                                <span style={{ fontSize: "14px", fontWeight: "600" }}>Student Login</span>
                                            </a>

                                            <a className="nav-link py-2" id="v-pills-profile-tab-icons" data-toggle="pill" href="#v-pills-Teacher-icons" role="tab" aria-controls="v-pills-profile-icons" aria-selected="false">
                                                <div style={{ fontSize: "25px" }}>
                                                    <GiTeacher />
                                                </div>
                                                <span style={{ fontSize: "14px", fontWeight: "600" }}>Employee Login</span>
                                            </a>

                                            <a className="nav-link py-2" id="v-pills-profile-tab-icons" data-toggle="pill" href="#v-pills-Administrator-icons" role="tab" aria-controls="v-pills-profile-icons" aria-selected="false">
                                                <div style={{ fontSize: "25px" }}>
                                                    <FaRegUser />
                                                </div>
                                                <span style={{ fontSize: "14px", fontWeight: "600" }}>Administrator Login</span>
                                            </a>

                                            <a className="nav-link py-2" id="v-pills-home-tab-icons" data-toggle="pill" href="#OrganizationLogin" role="tab" aria-controls="v-pills-home-icons" aria-selected="true">
                                                <div style={{ fontSize: "25px" }}>
                                                    <GrOrganization />
                                                </div>
                                                <span style={{ fontSize: "14px", fontWeight: "600" }}>Organisation Login</span>
                                            </a>
                                            {/* <a className="nav-link py-2" id="v-pills-home-tab-icons" data-toggle="pill" href="#OrWonerLogin" role="tab" aria-controls="v-pills-home-icons" aria-selected="true">
                                                <div style={{ fontSize: "25px" }}>
                                                    <FaHome />
                                                </div>
                                                <span style={{ fontSize: "14px", fontWeight: "600" }}>Organisation Woner Login</span>
                                            </a> */}

                                        </div>
                                    </div>
                                    <div className=" col-12 col-lg-8">
                                        <div className="tab-content" id="v-pills-with-icon-tabContent">

                                            <div className="tab-pane fade show active" id="v-pills-Student-icons" role="tabpanel" aria-labelledby="v-pills-profile-tab-icons">
                                                <StudentLogin />
                                            </div>

                                            <div className="tab-pane fade" id="v-pills-Teacher-icons" role="tabpanel" aria-labelledby="v-pills-profile-tab-icons">
                                                <TeacherLogin />
                                            </div>

                                            <div className="tab-pane fade" id="v-pills-Administrator-icons" role="tabpanel" aria-labelledby="v-pills-profile-tab-icons">
                                                <AdministratorLogin />
                                            </div>

                                            <div className="tab-pane fade" id="OrganizationLogin" role="tabpanel" aria-labelledby="v-pills-home-tab-icons">
                                                <OrganizationLogin />
                                            </div>
                                            {/* <div className="tab-pane fade" id="OrWonerLogin" role="tabpanel" aria-labelledby="v-pills-home-tab-icons">
                                                <OrWonerLogin />
                                            </div> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default AllLogin;