import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';


// add_exam_term
export const add_exam_term = createAsyncThunk(
    'exam/add_exam_term',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/add-exam-term', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const get_exam_term = createAsyncThunk(
    'exam/get_exam_term',
    async ({ searchValue, page, parPage }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-exam-term?searchValue=${searchValue}&&page=${page}&&parPage=${parPage}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_single_exam_term
export const get_single_exam_term = createAsyncThunk(
    'exam/get_single_exam_term',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-single-exam-term/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// update_exam_term
export const update_exam_term = createAsyncThunk(
    'exam/update_exam_term',
    async ({ editId, obj }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/update-exam-term/${editId}`, obj)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// delete_exam_term
export const delete_exam_term = createAsyncThunk(
    'exam/delete_exam_term',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.delete(`/delete-exam-term/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)



// add_exam_hall
export const add_exam_hall = createAsyncThunk(
    'exam/add_exam_hall',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/add-exam-hall', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_exam_hall
export const get_exam_hall = createAsyncThunk(
    'exam/get_exam_hall',
    async ({ searchValue, page, parPage }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-exam-hall?searchValue=${searchValue}&&page=${page}&&parPage=${parPage}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_single_exam_hall
export const get_single_exam_hall = createAsyncThunk(
    'exam/get_single_exam_hall',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-single-exam-hall/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// update_exam_hall
export const update_exam_hall = createAsyncThunk(
    'exam/update_exam_hall',
    async ({ editId, obj }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/update-exam-hall/${editId}`, obj)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// delete_exam_hall
export const delete_exam_hall = createAsyncThunk(
    'exam/delete_exam_hall',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.delete(`/delete-exam-hall/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)



// add_mark_distribution
export const add_mark_distribution = createAsyncThunk(
    'exam/add_mark_distribution',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/add-mark-distribution', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_mark_distribution
export const get_mark_distribution = createAsyncThunk(
    'exam/get_mark_distribution',
    async ({ searchValue, page, parPage }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-mark-distribution?searchValue=${searchValue}&&page=${page}&&parPage=${parPage}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_single_distribution
export const get_single_distribution = createAsyncThunk(
    'exam/get_single_distribution',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-single-distribution/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// update_mark_distribution
export const update_mark_distribution = createAsyncThunk(
    'exam/update_mark_distribution',
    async ({ editId, obj }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/update-mark-distribution/${editId}`, obj)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// delete_mark_distribution
export const delete_mark_distribution = createAsyncThunk(
    'exam/delete_mark_distribution',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.delete(`/delete-mark-distribution/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)



// add_exam_setup
export const add_exam_setup = createAsyncThunk(
    'exam/add_exam_setup',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/add-exam-setup', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_exam_setup
export const get_exam_setup = createAsyncThunk(
    'exam/get_exam_setup',
    async ({ searchValue, page, parPage, sessionValue }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-exam-setup?searchValue=${searchValue}&&page=${page}&&parPage=${parPage}&&sessionValue=${sessionValue}`);

            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// update_exam_publish_stauts
export const update_exam_publish_stauts = createAsyncThunk(
    'exam/update_exam_publish_stauts',
    async ({ examId, value }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/update-exam-publish-stauts/${examId}`, { value });
            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// get_single_exam_hall
export const get_single_exam_setup = createAsyncThunk(
    'exam/get_single_exam_setup',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-single-exam-setup/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// update_exam_setup
export const update_exam_setup = createAsyncThunk(
    'exam/update_exam_setup',
    async ({ editId, obj }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/update-exam-setup/${editId}`, obj)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


// delete_exam_setup
export const delete_exam_setup = createAsyncThunk(
    'exam/delete_exam_setup',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.delete(`/delete-exam-setup/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)



// add_exam_schedule
export const add_exam_schedule = createAsyncThunk(
    'exam/add_exam_schedule',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/add-exam-schedule', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_exam_schedule
export const get_exam_schedule = createAsyncThunk(
    'exam/get_exam_schedule',
    async ({ searchValue, page, parPage, sessionValue, classId, section }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-exam-schedule?searchValue=${searchValue}&&page=${page}&&parPage=${parPage}&&sessionValue=${sessionValue}&&classId=${classId}&&section=${section}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// exam_schedule_filter
export const exam_schedule_filter = createAsyncThunk(
    'exam/exam_schedule_filter',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post(`/exam-schedule-filter`, info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// delete_exam_schedule
export const delete_exam_schedule = createAsyncThunk(
    'exam/delete_exam_schedule',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.delete(`/delete-exam-schedule/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)




export const examReducer = createSlice({
    name: "exam",
    initialState: {
        errorMessage: '',
        successMessage: '',
        loader: false,
        examTermList: [],
        examTermCount: 0,
        singleExamTerm: {},
        hallList: [],
        hallCount: 0,
        singleExamHall: {},
        distributionList: [],
        distributionCount: 0,
        singleDistribution: {},
        examSetupList: [],
        singleExamSetup: {},
        examSetupCount: 0,
        examScheduleList: [],
        examScheduleCount: 0,
        studentExamScheduleList: [],
    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ''
            state.successMessage = ''
        },
        setExamSetupList: (state, { payload }) => {
            state.examSetupList = payload
        }
    },
    extraReducers: {
        [add_exam_term.pending]: (state, _) => {
            state.loader = true
        },
        [add_exam_term.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [add_exam_term.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [get_exam_term.pending]: (state, _) => {
            state.loader = true
        },
        [get_exam_term.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.examTermList = payload.examTermList
            state.examTermCount = payload.examTermCount
        },
        [get_single_exam_term.fulfilled]: (state, { payload }) => {
            state.singleExamTerm = payload.singleExamTerm
        },
        [update_exam_term.pending]: (state, _) => {
            state.loader = true
        },
        [update_exam_term.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [update_exam_term.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [delete_exam_term.pending]: (state, _) => {
            state.loader = true
        },
        [delete_exam_term.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [delete_exam_term.fulfilled]: (state, { payload }) => {
            state.successMessage = payload.message
            state.loader = false
        },
        [add_exam_hall.pending]: (state, _) => {
            state.loader = true
        },
        [add_exam_hall.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [add_exam_hall.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [get_exam_hall.pending]: (state, _) => {
            state.loader = true
        },
        [get_exam_hall.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.hallList = payload.hallList
            state.hallCount = payload.hallCount
        },
        [get_single_exam_hall.fulfilled]: (state, { payload }) => {
            state.singleExamHall = payload.singleExamHall
        },
        [update_exam_hall.pending]: (state, _) => {
            state.loader = true
        },
        [update_exam_hall.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [update_exam_hall.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [delete_exam_hall.fulfilled]: (state, { payload }) => {
            state.successMessage = payload.message
            state.loader = false
        },
        [add_mark_distribution.pending]: (state, _) => {
            state.loader = true
        },
        [add_mark_distribution.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [add_mark_distribution.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [get_mark_distribution.pending]: (state, _) => {
            state.loader = true
        },
        [get_mark_distribution.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.distributionList = payload.distributionList
            state.distributionCount = payload.distributionCount
        },
        [get_single_distribution.fulfilled]: (state, { payload }) => {
            state.singleDistribution = payload.singleDistribution
        },
        [update_mark_distribution.pending]: (state, _) => {
            state.loader = true
        },
        [update_mark_distribution.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [update_mark_distribution.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [delete_mark_distribution.pending]: (state, _) => {
            state.loader = true
        },
        [delete_mark_distribution.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [delete_mark_distribution.fulfilled]: (state, { payload }) => {
            state.successMessage = payload.message
            state.loader = false
        },
        [add_exam_setup.pending]: (state, _) => {
            state.loader = true
        },
        [add_exam_setup.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [add_exam_setup.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [get_exam_setup.pending]: (state, _) => {
            state.loader = true
        },
        [get_exam_setup.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.examSetupList = payload.examSetupList
            state.examSetupCount = payload.examSetupCount
        },
        [update_exam_publish_stauts.fulfilled]: (state, { payload }) => {
            state.successMessage = payload.message
        },
        [get_single_exam_setup.fulfilled]: (state, { payload }) => {
            state.singleExamSetup = payload.singleExamSetup
        },
        [update_exam_setup.pending]: (state, _) => {
            state.loader = true
        },
        [update_exam_setup.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [update_exam_setup.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [delete_exam_setup.pending]: (state, _) => {
            state.loader = true
        },
        [delete_exam_setup.rejected]: (state, { payload }) => {
            state.errorMessage = payload.error
            state.loader = false
        },
        [delete_exam_setup.fulfilled]: (state, { payload }) => {
            state.successMessage = payload.message
            state.loader = false
        },
        [add_exam_schedule.pending]: (state, _) => {
            state.loader = true
        },
        [add_exam_schedule.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [add_exam_schedule.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [get_exam_schedule.pending]: (state, _) => {
            state.loader = true
        },
        [get_exam_schedule.fulfilled]: (state, { payload }) => {
            const filterStudentExam = payload?.examScheduleList?.filter((d) => d.examName.isPublish === true)
            state.loader = false
            state.examScheduleList = payload.examScheduleList
            state.examScheduleCount = payload.examScheduleCount
            state.studentExamScheduleList = filterStudentExam
        },
        [exam_schedule_filter.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.examScheduleList = payload.examScheduleList
            state.examScheduleCount = payload.examScheduleCount
        },
        [delete_exam_schedule.pending]: (state, _) => {
            state.loader = true
        },
        [delete_exam_schedule.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [delete_exam_schedule.fulfilled]: (state, { payload }) => {
            state.successMessage = payload.message
            state.loader = false
        }

    }
});



export const { messageClear, setExamSetupList } = examReducer.actions;
export default examReducer.reducer;