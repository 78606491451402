import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { import_students } from '../../../store/Reducers/student/studentReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Slide from '@mui/material/Slide';
import Select from 'react-select';
import { ButtonLoader } from '../../../utils/Loader/LoaderComponent';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const ImportStudents = ({ openModel, setOpenModel }) => {

    const [file, setFile] = useState(null);

    const dispatch = useDispatch();

    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();

    const { loader, successMessage, errorMessage } = useSelector(state => state.student);
    const { searchValue } = useSelector(state => state.default);
    const { classList } = useSelector(state => state.administrator);
    const { sessionValue } = useSelector(state => state.default);

    const [classListOption, setClassListOption] = useState("")
    const [sectionOption, setSectionOption] = useState("")

    const [classValue, setClassValue] = useState(null);
    const [sectionValue, setSectionValue] = useState(null);

    //  handleClassChange
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
        clearErrors(fieldName);

        if (fieldName === 'className') {
            setSectionValue({ value: "", label: "Select Section" })
            setValue("section", { label: '', value: '' })
            const secOption = classList?.filter((item) => item._id === selectOption.value);
            const array = secOption[0]?.sectionNames;
            const convertedArray = array?.map(item => ({
                value: item,
                label: item
            }));
            setSectionOption(convertedArray)
        }
    }

    // handleFileChange
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // handleUpload
    const handleUpload = async (data) => {
        const formData = new FormData();
        formData.append('sessionId', sessionValue);
        formData.append('className', data?.className?.value);
        formData.append('section', data?.section?.value);
        formData.append('studentData', file);
        const res = await dispatch(import_students(formData))
        console.log(res)
    }

    useEffect(() => {
        const options = classList?.map(item => ({
            value: item._id,
            label: item.className
        }));
        setClassListOption(options)
    }, [classList])


    // Clear file input when successMessage is set
    useEffect(() => {
        if (successMessage) {
            reset({
                className: null,
                section: null,
                studentFile: null
            });

            setFile(null);
            setClassValue(null);
            setSectionValue(null);
        }
    }, [successMessage, reset]);


    return (
        <>
            <Dialog
                open={openModel}
                onClose={() => setOpenModel(false)}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth="md"
                PaperProps={{
                    style: {
                        width: '600px',
                    }
                }}
            >
                <DialogTitle style={{ fontSize: '18px' }}>Import Students</DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={() => setOpenModel(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <div className='px-4 pb-4'>

                    <form onSubmit={handleSubmit(handleUpload)}>

                        {/* Class Field */}
                        <div className="form-group">
                            <label htmlFor="className">Class <span className='text-danger'>*</span></label>
                            <Select
                                {...register('className', { required: 'Class is required' })}
                                value={classValue}
                                onChange={(selectOption) => {
                                    setClassValue(selectOption);
                                    handleSelect('className', selectOption);
                                }}
                                options={classListOption}
                                className={errors.className ? 'is-invalid' : ''}
                            />
                            {errors.className && <div className="invalid-feedback">{errors.className.message}</div>}
                        </div>

                        {/* Section Field */}
                        <div className="form-group">
                            <label htmlFor="section">Section <span className='text-danger'>*</span></label>
                            <Select
                                {...register('section', { required: 'Section is required' })}
                                placeholder="Select Section"
                                value={sectionValue}
                                onChange={(selectOption) => {
                                    setSectionValue(selectOption);
                                    handleSelect('section', selectOption);
                                }}
                                options={sectionOption}
                                isDisabled={sectionOption?.length > 0 ? false : true}
                                className={errors.section ? 'is-invalid' : ''}
                            />
                            {errors.section && <div className="invalid-feedback">{errors.section.message}</div>}
                        </div>

                        {/* File Upload Field */}
                        <div className="form-group">
                            <label htmlFor="studentFile">Upload File <span className='text-danger'>*</span></label>
                            <input
                                type="file"
                                className={`form-control ${errors.studentFile ? 'is-invalid' : ''}`}
                                {...register('studentFile', { required: 'File is required' })}
                                onChange={handleFileChange}
                                id="studentFile"
                                accept=".xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            />
                            {errors.studentFile && <div className="invalid-feedback">{errors.studentFile.message}</div>}
                        </div>

                        {/* Submit Button */}
                        <div className="mr-2 mt-3 text-right">
                            <button type='submit' style={{ width: "100px" }} disabled={loader ? true : false} className="btn btn-secondary px-4 py-2 font-weight-bold">
                                {loader ? <ButtonLoader /> : 'Submit'}
                            </button>
                        </div>

                    </form>

                </div>
            </Dialog>
        </>
    )
}

export default ImportStudents;
