import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';


// org_woner_login
export const organization_login = createAsyncThunk(
    'organization/organization_login',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/organization-login', info)
            localStorage.setItem('authToken', data.authToken)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// organization_logout
export const organization_logout = createAsyncThunk(
    'org_woner/organization_logout',
    async (_, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get('/organization-logout')
            localStorage.removeItem('authToken');
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


// add_administrator
export const add_administrator = createAsyncThunk(
    'organization/add_administrator',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/add-administrator', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


// get_all_administrator
export const get_all_administrator = createAsyncThunk(
    'organization/get_all_administrator',
    async ({ searchValue, page, parPage }, { rejectWithValue, fulfillWithValue }) => {

        try {
            const { data } = await api.get(`/get-all-administrator?searchValue=${searchValue}&&page=${page}&&parPage=${parPage}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


// update_administrator_status
export const update_administrator_status = createAsyncThunk(
    'organization/update_administrator_status',
    async ({ statusId, value }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/update-administrator-status/${statusId}`, { value })
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


// delete_administrator
export const delete_administrator = createAsyncThunk(
    'organization/delete_administrator',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.delete(`/delete-administrator/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)



// returnRole
const returnRole = (token) => {
    if (token) {
        const payload = token.split('.')[1];
        const decodedPayload = JSON.parse(atob(payload));
        const expireTime = new Date(decodedPayload.exp * 1000);
        if (new Date() > expireTime) {
            localStorage.removeItem('authToken');
            return '';
        } else {
            return decodedPayload;
        }
    } else {
        return '';
    }
};


export const organizationReducer = createSlice({
    name: "organization",
    initialState: {
        errorMessage: '',
        successMessage: '',
        logoutMessage: '',
        loader: false,
        organisationInfo: returnRole(localStorage.getItem("authToken")),
        organisationToken: localStorage.getItem("authToken"),
        allAdministrator: [],
        administratorCount: 0
    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ''
            state.successMessage = ''
            state.logoutMessage = ''
        },
        messageClear2: (state, _) => {
            state.errorMessage = ''
            state.successMessage = ''
        },
        tokenClear: (state, _) => {
            state.organisationInfo = ''
            state.organisationToken = ''
        }
    },
    extraReducers: {
        [organization_login.pending]: (state, _) => {
            state.loader = true
        },
        [organization_login.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [organization_login.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
            state.organisationInfo = returnRole(payload.authToken)
            state.organisationToken = payload.authToken
        },
        [organization_logout.fulfilled]: (state, { payload }) => {
            state.logoutMessage = payload.message
        },
        [add_administrator.pending]: (state, _) => {
            state.loader = true
        },
        [add_administrator.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [add_administrator.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        // [get_all_organization.pending]: (state, { payload }) => {
        //     state.loader = true
        // },
        [get_all_administrator.pending]: (state, _) => {
            state.loader = true
        },
        [get_all_administrator.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.allAdministrator = payload.allAdministrator
            state.administratorCount = payload.administratorCount
        },
        [update_administrator_status.pending]: (state, _) => {
            state.loader = true
        },
        [update_administrator_status.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [update_administrator_status.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [delete_administrator.fulfilled]: (state, { payload }) => {
            state.successMessage = payload.message
        },
    }
});


export const { messageClear, messageClear2, tokenClear } = organizationReducer.actions;
export default organizationReducer.reducer;