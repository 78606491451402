import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { sidebarFunction } from '../../store/Reducers/defaultReducer';

const LogoBar = () => {

  const dispatch = useDispatch();

  const [sideBar, setSideBar] = useState(false)

  // handleSideBar 
  const handleSideBar = () => {
    const header = document.getElementById('changeHeader');

    if (header) {
      if (sideBar) {
        header.classList.add('nav_open');
      } else {
        header.classList.remove('nav_open');
      }
    }

    setSideBar(!sideBar)
    dispatch(sidebarFunction(!sideBar))
  }

  return (
    <div className="logo-header " data-background-color="purple2">
      <a className="h5 text-white fw-bold my-auto text-decoration-none btn btn-secondary mx-1">
        SANTO
      </a>
      <button onClick={handleSideBar} className="navbar-toggler sidenav-toggler ml-auto" type="button" data-toggle="collapse" data-target="collapse" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon">
          <i className="icon-menu" />
        </span>
      </button>
      <button className="topbar-toggler more"><i className="icon-options-vertical" /></button>
      <div className="nav-toggle ml-5" style={{ zIndex: -1 }}>
        <button onClick={handleSideBar} className="btn btn-toggle toggle-sidebar mx-4 ">
          <i className="icon-menu" />
        </button>
      </div>
    </div>
  )
}

export default LogoBar;
