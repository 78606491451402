import React, { useEffect, useRef, useState } from 'react';
import Empty from '../../../utils/page/Empty';
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { ButtonLoader, TableLoader } from '../../../utils/Loader/LoaderComponent';
import { teacher_assign_class, messageClear } from '../../../store/Reducers/administrator/teacherReducer';
import toast from 'react-hot-toast';
import { get_all_class } from '../../../store/Reducers/administrator/administratorReducer';
import { FaGraduationCap } from "react-icons/fa";
import { useReactToPrint } from "react-to-print";
import { FaRegCopy } from "react-icons/fa6";
import { RiFileExcelLine } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa";
import { exportToExcel, exportToPDF } from '../../../utils/other/fileGenerate';
import { Modal, Box, Typography, Button } from '@mui/material';
import { FiPrinter } from "react-icons/fi";



const TeacherAssignClass = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const { classList, administratorInfo: { organisationId } } = useSelector(state => state.administrator);
    const { allTeachers, loader, errorMessage, successMessage } = useSelector(state => state.teacher);


    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors } = useForm();

    const [selectedTeachers, setSelectedTeachers] = useState([]);
    const [classListOption, setClassListOption] = useState("")

    const [classValue, setClassValue] = useState(null)


    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);

    const handleOpenModal = (classItem) => {
        setModalData(classItem);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalData(null);
    };


    // handleOpenDrawer
    const handleOpenDrawer = () => {
        setOpen(true);
        setIsEditing(false);
    }


    // handleCheckboxChange
    const handleCheckboxChange = (teacherId) => {
        setSelectedTeachers(prevSelected => {
            if (prevSelected.includes(teacherId)) {
                return prevSelected.filter(id => id !== teacherId)
            } else {
                return [...prevSelected, teacherId]
            }
        })
    };


    // handleSelect
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
        clearErrors(fieldName);

        const data2 = classList?.filter((d) => d._id === selectOption.value)[0].assignTeacher?.map(item => item._id)
        setSelectedTeachers(data2)
    }

    // handleAssignEdit
    // const handleAssignEdit = (id) => {
    //     console.log("Id", id)
    // }


    // onSubmit
    const onSubmit = (data) => {
        const { className } = data;
        const payload = {
            classId: className.value,
            selectedTeachers,
        };

        dispatch(teacher_assign_class(payload))
    }



    //  createExceclFile
    const createExceclFile = () => {
        const data = classList?.map((d, i) => ({
            Sl: i + 1,
            'Class Name': d.className,
            'Assign Teachers Name': d.assignTeacher.map((t) => t.teacherBasicDetails.fullName).join(', '),
        }));
        exportToExcel(data, 'assign_teacher_data.xlsx');
    }

    // createPdfFile
    const createPdfFile = () => {
        const data = classList?.map((d, i) => ({
            Sl: i + 1,
            'Class Name': d.className,
            'Assign Teachers Name': d.assignTeacher.map((t) => t.teacherBasicDetails.fullName).join(', '),
        }));

        exportToPDF(data, 'assign_teacher_data.pdf');
    };


    const componentRef = useRef();


    // createPrint
    const createPrint = useReactToPrint({
        content: () => componentRef.current,
    });





    useEffect(() => {
        const options = classList?.map(item => ({
            value: item._id,
            label: item.className
        }));
        setClassListOption(options)
    }, [classList])



    useEffect(() => {
        const obj = {
            searchValue: '',
            organisationId: organisationId
        };
        if (errorMessage) {
            toast.error(errorMessage)
            dispatch(messageClear())
        }
        if (successMessage) {
            toast.success(successMessage)
            dispatch(messageClear())
            setSelectedTeachers('')
            setOpen(false);
            setClassValue();
            dispatch(get_all_class(obj))
        }
    }, [errorMessage, successMessage])


    // console.log("classList", classList)


    return (
        <>
            <div className="row">
                <div className="col-md-12">

                    <div className="card">
                        <div className="card-body">

                            <div className="page-header mb-0">
                                <span style={{ fontSize: "18px", padding: "0px 8px 8px 0px" }}>
                                    <i className="fas fa-list" />
                                </span>
                                <h4 style={{ fontSize: "20px", marginBottom: "9px" }}>Assign Teacher List</h4>

                                <span className='px-4'>
                                    <FaRegCopy title='Copy' style={{ fontSize: '20px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <RiFileExcelLine onClick={createExceclFile} title='Excel' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FaRegFilePdf onClick={createPdfFile} title='PDF' style={{ fontSize: '18px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FiPrinter onClick={createPrint} title='Print' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                </span>

                                <button onClick={handleOpenDrawer} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                    <span><i className="fa fa-plus pr-2" />Add</span>
                                </button>
                            </div>

                            <hr className='my-3' />

                            {loader ? (
                                <TableLoader />
                            ) : classList?.length > 0 ? (
                                <table className="tableStyle">
                                    <thead>
                                        <tr>
                                            <th>Sl.</th>
                                            <th>Class Name</th>
                                            <th>Assign Teachers Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {classList?.map((classItem, index) => (
                                            <tr key={classItem._id} style={{ cursor: 'pointer' }}>
                                                <td>{index + 1}</td>
                                                <td data-label="Class Name">{classItem.className}</td>
                                                <td data-label="Teacher Name">
                                                    {classItem.assignTeacher?.slice(0, 2).map((teacher, idx) => (
                                                        <span
                                                            key={idx}
                                                            style={{ fontSize: '14px' }}
                                                            className='bg-danger text-light px-2 py-1 mr-1 rounded'
                                                        >
                                                            {teacher?.teacherBasicDetails?.fullName}
                                                        </span>
                                                    ))}
                                                    {classItem.assignTeacher?.length > 2 && (
                                                        <span
                                                            style={{ fontWeight: '600', background: '#444', cursor: 'pointer' }}
                                                            className='text-light px-2 py-1 mr-1 rounded'
                                                            onClick={() => handleOpenModal(classItem)}
                                                        >
                                                            Show All
                                                        </span>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                    <Modal
                                        open={showModal}
                                        onClose={handleCloseModal}
                                        aria-labelledby="modal-title"
                                        aria-describedby="modal-description"
                                    >
                                        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', borderRadius: '5px', boxShadow: 24, p: 2 }}>
                                            <Typography id="modal-title" variant="h6" component="h2">
                                                Assign Teachers
                                            </Typography>
                                            <IconButton
                                                aria-label="close"
                                                onClick={handleCloseModal}
                                                sx={{
                                                    position: 'absolute',
                                                    right: 8,
                                                    top: 8,
                                                    color: (theme) => theme.palette.grey[500],
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>

                                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                <>
                                                    {modalData?.assignTeacher?.map((teacher, idx) => (
                                                        <span style={{ fontSize: "17px", display: 'block', marginBottom: '8px' }}
                                                            key={idx}
                                                            className='bg-danger text-light px-2 py-1 mr-1 rounded'>
                                                            {teacher?.teacherBasicDetails?.fullName}
                                                        </span>
                                                    ))}
                                                </>
                                            </Typography>

                                        </Box>
                                    </Modal>

                                </table>
                            ) : (
                                <Empty data="Class Not Found!" />
                            )}


                        </div>
                    </div>
                </div>
            </div>

            <Drawer anchor="right" open={open} onClose={() => setOpen(false)} style={{ width: '300px' }}>
                <div style={{ width: '600px' }}>
                    <DialogTitle style={{ fontSize: '16px' }}>Assign Teacher Class</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <div className='px-2'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group d-flex">
                                <label className='pt-1' style={{ width: "15%" }} htmlFor="className">Select Class <span className='text-danger'>*</span></label>
                                <div style={{ width: "65%" }}>
                                    <Select
                                        value={classValue}
                                        {...register('className', { required: 'Class is required' })}
                                        onChange={(selectOption) => {
                                            setClassValue(selectOption)
                                            handleSelect('className', selectOption);
                                        }}
                                        options={classListOption}
                                        className={errors.className ? 'is-invalid' : ''}
                                    />
                                    {errors.className && <div className="invalid-feedback">{errors.className.message}</div>}
                                </div>

                                <div className="my- mx-2" style={{ width: "15%" }}>
                                    <button disabled={loader} type="submit" style={{ width: "100px" }} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                        {loader ? <ButtonLoader /> : (isEditing ? 'Edit' : 'Assign')}
                                    </button>
                                </div>

                            </div>

                            <div className="container mt-3">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                {allTeachers?.length > 0 ? (
                                                    <table className="tableStyle">
                                                        <thead className=''>
                                                            <tr>
                                                                <th>Select</th>
                                                                <th>Teacher Name</th>
                                                                <th>Teacher Skill</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className=''>
                                                            {
                                                                allTeachers?.map((d) => (
                                                                    <tr key={d._id}>
                                                                        <td>
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={selectedTeachers.includes(d._id)}
                                                                                onChange={() => handleCheckboxChange(d._id)}
                                                                            />
                                                                        </td>
                                                                        <td data-lable="Teacher Name">{d.teacherBasicDetails?.fullName}</td>
                                                                        <td data-lable="Email">{d.teacherBasicDetails?.skills}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>

                                                    </table>
                                                ) : (
                                                    <Empty />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </Drawer>

        </>
    )
}

export default TeacherAssignClass;