import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useOptionsFromData } from '../../../../utils/other/returnFunction';
import { teacherDepartment } from '../../../../utils/other/data';
import { teacher_filter } from '../../../../store/Reducers/administrator/teacherReducer';
import { ButtonLoader } from '../../../../utils/Loader/LoaderComponent';
import Empty from '../../../../utils/page/Empty';
import { add_employee_attendance, messageClear } from '../../../../store/Reducers/attendance/employeeAttendance';
import toast from 'react-hot-toast';
import { image_url, server_url } from '../../../../api/api';



const AddEmployeeAttendance = () => {
  const dispatch = useDispatch();
  const { handleSubmit, register, setValue, formState: { errors } } = useForm();
  const { employeeCount, allEmmployee } = useSelector(state => state.teacher);
  const { loader, successMessage, errorMessage } = useSelector(state => state.employeeAttendance);

  const departmentList = useOptionsFromData(teacherDepartment?.departments);

  const [departmentValue, setDepartmentValue] = useState(null);

  // Handle department selection and filter employees
  const handleSelect = (data) => {
    const obj = {
      department: data ? data.value : '',
    };
    dispatch(teacher_filter(obj));
  };

  // Handle "Select For Everyone" dropdown change
  const handleSelectForEveryone = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue) {
      allEmmployee?.forEach((student, index) => {
        setValue(`employees[${index}].attendance`, selectedValue);
      });
    }

  };


  // On form submit, collect employee attendance data
  const onSubmit = (data) => {
    const employeeData = allEmmployee?.map((employee, index) => ({
      employeeId: employee._id,
      attendance: data.employees[index]?.attendance,
      remark: data.employees[index]?.remark || '',
    }));

    const obj = {
      department: departmentValue?.value,
      date: data.date,
      attendanceRecords: employeeData,
    };

    dispatch(add_employee_attendance(obj));
  };


  useEffect(() => {
    if (allEmmployee?.length > 0) {
      allEmmployee.forEach((student, index) => {
        setValue(`employees[${index}].attendance`, 'Present');
      });
    }
  }, [allEmmployee, setValue]);


  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
  }, [errorMessage, successMessage]);



  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="department">Department <span className="text-danger">*</span></label>
                  <Select
                    placeholder="Select Department"
                    value={departmentValue}
                    onChange={(selectOption) => {
                      setDepartmentValue(selectOption);
                      handleSelect(selectOption);
                    }}
                    options={departmentList}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="date">Date <span className="text-danger">*</span></label>
                  <input
                    type="date"
                    className={`form-control ${errors.date ? 'is-invalid' : ''}`}
                    id="date"
                    {...register('date', { required: 'Date is required' })}
                  />
                  {errors.date && <div className="invalid-feedback">{errors.date.message}</div>}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className='mb-3' style={{ width: "250px" }}>
              <label htmlFor="" className='mb-1'>Select For Everyone <span className='text-danger'>*</span></label>
              <select className='form-select p-1 px-2' onChange={handleSelectForEveryone}>
                <option value="">Not Selected</option>
                <option value="Present">Present</option>
                <option value="Absent">Absent</option>
              </select>
            </div>

            {allEmmployee?.length > 0 && departmentValue?.value ? (
              <table className="tableStyle">
                <thead>
                  <tr>
                    <th>Sl.</th>
                    <th>Photo</th>
                    <th>Name</th>
                    <th>Attendance</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {allEmmployee?.map((employee, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>

                      <td data-label="Photo">
                        <img style={{ width: "35px", height: "35px", borderRadius: "50%" }} src={employee?.teacherImage ? `${server_url}/${employee?.teacherImage}` : `${image_url}/assets/img/student/male.png`} alt="Img" />
                      </td>

                      <td data-label="Name">{employee.teacherBasicDetails.fullName}</td>

                      <td data-label="Attendance">
                        <div className="form-check form-check-inline p-0 m-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            name={`employees[${i}].attendance`}
                            id={`present_${i}`}
                            value="Present"
                            {...register(`employees[${i}].attendance`, { required: 'Attendance is required' })}
                            style={{ marginBottom: "9px", fontSize: "16px" }}
                          />
                          <label className="form-check-label" htmlFor={`present_${i}`}>
                            Present
                          </label>
                        </div>
                        <div className="form-check form-check-inline p-0 m-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            name={`employees[${i}].attendance`}
                            id={`absent_${i}`}
                            value="Absent"
                            {...register(`employees[${i}].attendance`)}
                            style={{ marginBottom: "9px", fontSize: "16px" }}
                          />
                          <label className="form-check-label" htmlFor={`absent_${i}`}>
                            Absent
                          </label>
                        </div>

                      </td>

                      <td data-label="Remarks">
                        <input
                          type="text"
                          {...register(`employees[${i}].remark`)}
                          className="form-control"
                          placeholder="Remarks"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <Empty data={`Employee Not Found !`} />
            )}

            {allEmmployee?.length > 0 && departmentValue?.value && (
              <div className="mt-4 text-right">
                <button type="submit" disabled={loader} className="btn btn-secondary py-2">
                  {loader ? <ButtonLoader /> : 'Submit'}
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEmployeeAttendance;
