
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectTeacher = () => {
    const { employeeInfo: { role }, employeeToken } = useSelector(state => state.employee);

    if (role === 'Teacher' && employeeToken) {
        return <Outlet />
    } else {
        return <Navigate to="/" />;
    }

}


export default ProtectTeacher;