import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';



// add_student_attendance
export const add_student_attendance = createAsyncThunk(
    'studentAttendance/add_student_attendance',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/add-student-attendance', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_student_attendance
export const get_student_attendance = createAsyncThunk(
    'studentAttendance/get_student_attendance',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/get-student-attendance', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_single_student_attendance
export const get_single_student_attendance = createAsyncThunk(
    'studentAttendance/get_single_student_attendance',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/get-single-student-attendance', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


// add_exam_attendance
export const add_exam_attendance = createAsyncThunk(
    'studentAttendance/add_exam_attendance',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/add-exam-attendance', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


// get_exam_attendance
export const get_exam_attendance = createAsyncThunk(
    'studentAttendance/get_exam_attendance',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/get-exam-attendance', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const studentAttendance = createSlice({
    name: "studentAttendance",
    initialState: {
        errorMessage: '',
        successMessage: '',
        loader: false,
        attendanceForMonth: [],
        attendanceForSingleStudent:{},
        examAttendance: []
    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ''
            state.successMessage = ''
        }
    },
    extraReducers: {
        [add_student_attendance.pending]: (state, _) => {
            state.loader = true
        },
        [add_student_attendance.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [add_student_attendance.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [get_student_attendance.pending]: (state, _) => {
            state.loader = true
        },
        [get_student_attendance.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [get_student_attendance.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.attendanceForMonth = payload.data
        },
        [get_single_student_attendance.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.attendanceForSingleStudent = payload.data
        },
        [add_exam_attendance.pending]: (state, _) => {
            state.loader = true
        },
        [add_exam_attendance.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [add_exam_attendance.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [get_exam_attendance.pending]: (state, _) => {
            state.loader = true
        },
        [get_exam_attendance.rejected]: (state, { payload }) => {
            state.loader = false
            // state.errorMessage = payload.error
            state.examAttendance =[]
        },
        [get_exam_attendance.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.examAttendance = payload.data
        },

    }
});


export const { messageClear, studentClear } = studentAttendance.actions;
export default studentAttendance.reducer;