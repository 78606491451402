import React from 'react';
import StudentLayout from '../../Layout/StudentLayout/StudentLayout';
import Empty from '../../../utils/page/Empty';
import { FaListUl, FaRegCopy, FaRegFilePdf } from 'react-icons/fa';
import { FiPrinter } from "react-icons/fi";
import { FaUserClock } from "react-icons/fa";
import { RiFileExcelLine } from "react-icons/ri";
import { useSelector } from 'react-redux';
import { getClassBySection, getSectionBySession, getSubjectDetails } from '../../../utils/other/returnFunction';



const StudentAllSubject = () => {
    const { student } = useSelector(state => state.student);
    const { sessionValue } = useSelector(state => state.default);
    const { classList, subjectLists } = useSelector(state => state.administrator);



    // console.log("subjectLists", subjectLists)
    // console.log("sessionValue", sessionValue)


    return (
        <StudentLayout>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">

                            <div className="page-header mb-0 d-flex">
                                <FaListUl style={{ fontSize: '20px', marginRight: "8px", marginTop: "4px" }} />
                                <h4 style={{ fontSize: "20px" }}>Subject List</h4>
                            </div>

                            <hr className='my-3' />

                            <div >
                                {
                                    Object.keys(student).length ? (
                                        <table className="tableStyle print-content">
                                            <thead className=''>
                                                <tr>
                                                    <th>Sl.</th>
                                                    <th>Class Name</th>
                                                    <th>Section</th>
                                                    <th>Subject Name</th>
                                                    <th>Subject Code</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    student?.sessions?.find((d) => d.sessionId === sessionValue)?.assignedSubjects?.map((item, i) => {
                                                        const subjectDetails = getSubjectDetails(subjectLists, item);
                                                        const subjectName = subjectDetails ? subjectDetails?.subjectName : '';
                                                        const subjectCode = subjectDetails ? subjectDetails?.subjectCode : '';
                                                        return (
                                                            <tr>
                                                                <td>{i + 1}</td>
                                                                <td>{getClassBySection(classList, student?.sessions, sessionValue)}</td>
                                                                <td>{getSectionBySession(student?.sessions, sessionValue)}</td>
                                                                <td>{subjectName}</td>
                                                                <td>{subjectCode}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    ) : (
                                        <Empty data={`Subject Not Found!`} />
                                    )
                                }
                            </div>

                        </div>


                    </div>

                </div>

            </div>
        </StudentLayout>
    )
}

export default StudentAllSubject;