import administratorReducer from "./Reducers/administrator/administratorReducer";
import studentReducer from "./Reducers/student/studentReducer";
import teacherReducer from "./Reducers/administrator/teacherReducer";
import defaultReducer from "./Reducers/defaultReducer";
import orgWonerReducer from "./Reducers/organisation/orgWonerReducer";
import organizationReducer from "./Reducers/organisation/organizationReducer";
import classReducer from "./Reducers/class/classReducer";
import personalStudentReducer from "./Reducers/student/personalStudentReducer";
import academicReducer from "./Reducers/academic/academicReducer";
import examReducer from "./Reducers/examMaster/examReducer";
import employeeAuthReducer from "./Reducers/employee/employeeAuthReducer";
import studentAttendance from "./Reducers/attendance/studentAttendance";
import feesReducer from "./Reducers/accounting/feesReducer";
import employeeAttendance from "./Reducers/attendance/employeeAttendance";
import markEntryReducer from "./Reducers/examMaster/markEntryReducer";


const rootReducer = {
    default: defaultReducer,
    org_woner: orgWonerReducer,
    organization: organizationReducer,
    administrator: administratorReducer,
    student: studentReducer,
    teacher: teacherReducer,
    class: classReducer,
    personalStudent: personalStudentReducer,
    academic: academicReducer,
    exam: examReducer,
    employee: employeeAuthReducer,
    studentAttendance: studentAttendance,
    employeeAttendance: employeeAttendance,
    fees: feesReducer,
    markEntry: markEntryReducer,
}

export default rootReducer;