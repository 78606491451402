import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import toast from 'react-hot-toast';
import { useReactToPrint } from "react-to-print";
import { FaUserClock } from "react-icons/fa";
import moment from 'moment';
import { RiFileExcelLine } from "react-icons/ri";
import { GiTeacher } from "react-icons/gi";
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { MdOutlineViewQuilt } from "react-icons/md";
import { IoIosEye } from "react-icons/io";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FaListUl, FaRegCopy, FaRegFilePdf } from 'react-icons/fa';
import { FiPrinter } from "react-icons/fi";
import { confirmMessagge } from '../../../../utils/other/aleartFunc';
import { exportToExcel, exportToPDF } from '../../../../utils/other/fileGenerate';
import { delete_exam_schedule, exam_schedule_filter, get_exam_schedule, messageClear } from '../../../../store/Reducers/examMaster/examReducer';
import { getSubjectDetails, getTeacehrName } from '../../../../utils/other/returnFunction';
import Pagination from '../../../Layout/Pagination';
import { ButtonLoader } from '../../../../utils/Loader/LoaderComponent';
import Empty from '../../../../utils/page/Empty';



const ShowExamSchedule = () => {

    const dispatch = useDispatch();

    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();

    const { classList, subjectLists } = useSelector(state => state.administrator);
    const { examSetupList, loader, successMessage, errorMessage, examScheduleList, examScheduleCount } = useSelector(state => state.exam);
    const { sessionValue } = useSelector(state => state.default);
    const { searchValue } = useSelector(state => state.default);


    const [currentPage, setCurrentPage] = useState(1)
    const [parPage, setParPage] = useState(10)

    const [classListOption, setClassListOption] = useState([]);
    const [sectionOption, setSectionOption] = useState([]);
    const [examSetupOption, setExamSetupOption] = useState([]);
    const [selecteeClassId, setSelecteeClassId] = useState('');

    const [classValue, setClassValue] = useState(null);
    const [sectionValue, setSectionValue] = useState(null);


    const [openDialog, setOpenDialog] = useState(false);
    const [scheduleDetails, setScheduleDetails] = useState([]);

    // handleDialogClose
    const handleDialogClose = () => {
        setOpenDialog(false)
    }


    // handleSelect
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
        clearErrors(fieldName);

        if (fieldName === 'className') {
            setSelecteeClassId(selectOption.value);
            setSectionValue({ value: "", label: "Select Section" })
            setValue("section", { label: '', value: '' })
            const secOption = classList?.find((item) => item._id === selectOption.value)?.sectionNames || [];
            const convertedArray = secOption.map(item => ({
                value: item,
                label: item
            }));
            setSectionOption(convertedArray);
        }
    }


    // filterExamSchedule
    const filterExamSchedule = () => {
        const obj = {
            sessionId: sessionValue,
            classId: selecteeClassId ? selecteeClassId : '',
            section: sectionValue?.value || ''
        }

        dispatch(exam_schedule_filter(obj))
    }


    // handleReset
    const handleReset = () => {
        setClassValue({ value: "", label: "Select Class" })
        setValue("className", { label: '', value: '' })

        setSectionValue({ value: "", label: "Select Section" })
        setValue("section", { label: '', value: '' })

        const obj = {
            sessionId: sessionValue,
            classId: '',
            section: ''
        }

        dispatch(exam_schedule_filter(obj))

    }


    //  createExceclFile
    const createExceclFile = () => {
        const data = examScheduleList?.map((d, i) => ({
            'Sl.': i + 1 + (currentPage - 1) * parPage,
            'Class': d.classId.className,
            'Section': d.section,
            'Exam Name': `${d.examName.examName} (${d.examName.examTypeName || 'N/A'})`
        }));
        exportToExcel(data, 'exam_schedule_data.xlsx');
    }

    // createPdfFile
    const createPdfFile = () => {
        const data = examScheduleList?.map((d, i) => ({
            'Sl.': i + 1 + (currentPage - 1) * parPage,
            'Class': d.classId.className,
            'Section': d.section,
            'Exam Name': `${d.examName.examName} (${d.examName.examTypeName || 'N/A'})`
        }));
        exportToPDF(data, 'exam_schedule_data.pdf');
    }


    // deleteExamSchedule
    const deleteExamSchedule = async (deleteId) => {
        const returnValue = await confirmMessagge('Delete');
        if (returnValue) {
            dispatch(delete_exam_schedule(deleteId))
        }
    }



    // handleItemPerPageChange
    const handleItemPerPageChange = (e) => {
        setParPage(e.target.value)
    }


    const componentRef = useRef();
    const componentRef2 = useRef();

    // createPrint
    const createPrint = useReactToPrint({
        content: () => componentRef.current,
    });

    // createSchedulePrint
    const createSchedulePrint = useReactToPrint({
        content: () => componentRef2.current
    })


    useEffect(() => {
        const obj = {
            parPage: parseInt(parPage),
            page: parseInt(currentPage),
            searchValue,
            sessionValue,
            classId: "",
            section: ""
        }
        dispatch(get_exam_schedule(obj))
    }, [searchValue, currentPage, parPage, sessionValue, successMessage])

    useEffect(() => {
        const options = classList?.map(item => ({
            value: item._id,
            label: item.className
        }));

        const options2 = examSetupList?.map(item => ({
            value: item._id,
            label: `${item.examName} (${item.examTypeName || 'N/A'})`
        }));

        setClassListOption(options);
        setExamSetupOption(options2);
    }, [classList, examSetupList]);



    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage);
            dispatch(messageClear());
        }
        if (successMessage) {
            toast.success(successMessage);
            dispatch(messageClear());
        }
    }, [successMessage, errorMessage]);


    // console.log("examScheduleList", examScheduleList)


    return (
        <>
            <div className="card mb-3">
                <div className="card-body py-">

                    <div className="row">

                        <div className="col-md-6 col-lg-6">
                            <div className="form-group pt-0">
                                <label htmlFor="className">Class </label>
                                <Select
                                    placeholder="Select Class"
                                    value={classValue}
                                    {...register('className')}
                                    onChange={(selectOption) => {
                                        setClassValue(selectOption);
                                        handleSelect('className', selectOption);
                                    }}
                                    options={classListOption}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6">
                            <div className="form-group pt-0">
                                <label htmlFor="section">Section</label>
                                <Select
                                    {...register('section')}
                                    placeholder="Select Section"
                                    value={sectionValue}
                                    onChange={(selectOption) => {
                                        setSectionValue(selectOption);
                                        handleSelect('section', selectOption);
                                    }}
                                    options={sectionOption}
                                    isDisabled={sectionOption?.length > 0 ? false : true}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="mr-2 text-right">
                        <button type='button' onClick={handleReset} style={{ width: "80px" }} className="btn btn-danger px-4 py-2 mr-2 font-weight-bold">
                            Reset
                        </button>
                        <button type='button' onClick={filterExamSchedule} style={{ width: "80px" }} disabled={loader ? true : false} className="btn btn-secondary px-4 py-2 font-weight-bold">
                            {
                                loader ? <ButtonLoader /> : 'Filter'
                            }
                        </button>
                    </div>

                </div>
            </div>


            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="page-header mb-0">

                                <span style={{ fontSize: "18px", padding: "0px 8px 7px 0px" }}>
                                    <i className="fas fa-list" />
                                </span>
                                <h4 style={{ fontSize: "19px" }}>Schedule List</h4>
                                <span className='px-4'>
                                    <FaRegCopy title='Copy' style={{ fontSize: '20px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <RiFileExcelLine title='Excel' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FaRegFilePdf title='PDF' style={{ fontSize: '18px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FiPrinter title='Print' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                </span>

                            </div>

                            <hr className='my-3' />

                            <div ref={componentRef}>
                                {
                                    examScheduleList?.length > 0 ? (
                                        <table className="tableStyle print-content">
                                            <thead className=''>
                                                <tr>
                                                    <th>Sl.</th>
                                                    <th>Class</th>
                                                    <th>Section</th>
                                                    <th>Exam Name</th>
                                                    <th className='no-print'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    examScheduleList?.map((d, i) => (
                                                        <tr key={i}>
                                                            <td data-lable="Sl">{i + 1 + (currentPage - 1) * parPage}</td>
                                                            <td data-lable="Class">{d.classId.className}</td>
                                                            <td data-lable="Section">{d.section}</td>
                                                            <td data-lable="ExamName">{`${d.examName.examName} (${d.examName.examTypeName || 'N/A'})`}</td>

                                                            <td data-lable="Action" className='no-print'>
                                                                <span title='Details' className='mr-1' onClick={() => { setOpenDialog(true); setScheduleDetails(d); }}>
                                                                    <IoIosEye style={{ fontSize: '24px', color: 'blue', marginTop: "3px", cursor: "pointer" }} />
                                                                </span>

                                                                <span title='Delete' className='ml-1' onClick={() => deleteExamSchedule(d._id)}>
                                                                    <MdDeleteForever style={{ fontSize: '22px', color: 'red' }} />
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    ) : (
                                        <Empty data={`Schedule Not Found!`} />
                                    )
                                }
                            </div>

                        </div>

                        {
                            examScheduleCount > 10 && <div class="d-flex justify-content-between">
                                <div className="col-md-4 d-flex justify-content-start align-items-center itemParPageStyle">
                                    <div class="d-flex mb-2">
                                        <label for="itemPerPage" class="col-sm-auto col-form-label">Select items per page :</label>
                                        <div class="col-sm-auto">
                                            <select
                                                id='itemPerPage'
                                                style={{ width: "65px", marginLeft: "-23px", marginTop: "7px", padding: "3px" }}
                                                onChange={handleItemPerPageChange}
                                                value={parPage}
                                            >
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="50000">All</option>

                                            </select>
                                        </div>
                                    </div>

                                </div>

                                <div className='px-4 pt-2'>
                                    {
                                        examScheduleCount >= parPage ?
                                            <Pagination
                                                pageNumber={currentPage}
                                                setPageNumber={setCurrentPage}
                                                totalItem={examScheduleCount}
                                                parPage={parPage}
                                                showItem={Math.floor(examScheduleCount / parPage)}
                                            /> : ''
                                    }
                                </div>

                            </div>
                        }

                    </div>

                </div>

            </div>


            <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="md" fullWidth>
                <DialogTitle>
                    <div className="page-header mb-0 d-flex">
                        <span style={{ fontSize: "18px", padding: "0px 8px 7px 0px" }}>
                            <i className="fas fa-list" />
                        </span>
                        <h4 style={{ fontSize: "19px" }}>Schedule Details</h4>
                        <span className='px-4'>
                            <FiPrinter onClick={createSchedulePrint} title='Print' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                        </span>
                    </div>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setOpenDialog(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography ref={componentRef2}>
                        <div className='print-content'>

                            <div className='text-center'>
                                <p style={{ fontSize: "17px", fontWeight: "600" }} className='mb-0'>Exam : {scheduleDetails?.examName?.examName} ({scheduleDetails?.examName?.examTypeName || 'N/A'})</p>
                                <p style={{ fontSize: "16px", fontWeight: "600" }}>Class : {scheduleDetails?.classId?.className} ({scheduleDetails?.section})</p>
                            </div>

                            {Object.keys(scheduleDetails).length > 0 ? (
                                <table className="tableStyle">
                                    <thead className=''>
                                        <tr>
                                            <th>Subject</th>
                                            <th>Date</th>
                                            <th>Starting Time</th>
                                            <th>Ending Time</th>
                                            <th>Hall Room</th>
                                        </tr>
                                    </thead>
                                    <tbody className=''>
                                        {scheduleDetails?.schedule?.map((d, i) => {
                                            const subjectDetails = getSubjectDetails(subjectLists, d?.subjectId);
                                            const subjectName = subjectDetails ? subjectDetails?.subjectName : '';

                                            return <tr key={i}>
                                                <td>{subjectName}</td>
                                                <td>{moment(d.date).format('DD, MMM, YYYY')}</td>
                                                <td>{moment(d.startingTime, "HH:mm").format("h:mm A")}</td>
                                                <td>{moment(d.endingTime, "HH:mm").format("h:mm A")}</td>
                                                <td>{d.hallRoom}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            ) : (
                                <Empty data={`Date Not Found !`} />
                            )}
                        </div>
                    </Typography>
                </DialogContent>
            </Dialog>

        </>
    );
}



export default ShowExamSchedule;
