
import moment from 'moment';
import 'moment-duration-format';



// Generate random 6-digit password
export const generatePassword = () => {
    const length = 8; // Length of the password
    const charset = "0123456789abcdefghijk123456789lmnp123456789qrstuv123456789wxyz0123456789ABCDEFGHIJ123456789KLMNP123456789QRSTUVWXYZ0123456789!@#0123456789";
    let password = "";
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
    }
    return password;
};


// useOptionsFromData
export const useOptionsFromData = (data) => {
    return data?.map(item => ({
        value: item.name,
        label: item.name,
    })) || [];
}


// getClassBySection
export const getClassBySection = (classList, sessions, sessionValue) => {
    // Find the active session
    const activeSession = sessions?.find(session => session.sessionId === sessionValue);

    if (activeSession) {
        // Find the class associated with the active session
        const activeClass = classList.find(c => c._id === activeSession.className);

        // Return the className if found, otherwise return an empty string
        return activeClass ? activeClass.className : '';
    }
    // Return an empty string if there is no active session
    return '';
}


// getActiveClassName
export const getActiveClassName = (classList, sessions) => {
    // Find the active session
    const activeSession = sessions?.find(session => session.sessionIsActive);

    if (activeSession) {
        // Find the class associated with the active session
        const activeClass = classList.find(c => c._id === activeSession.className);

        // Return the className if found, otherwise return an empty string
        return activeClass ? activeClass.className : '';
    }
    // Return an empty string if there is no active session
    return '';
}


// getClassName
export const getClassName = (classList, classId) => {
    // Find the class associated with the active session
    const activeClass = classList?.find(c => c._id === classId);

    // Return the className if found, otherwise return an empty string
    return activeClass ? activeClass.className : '';
}


// getSectionBySession
export const getSectionBySession = (sessions, sessionValue) => {
    const activeSection = sessions?.find((d) => d.sessionId === sessionValue)?.section
    return activeSection ? activeSection : ''
}


// getActiveSession
export const getActiveSession = (sessions) => {
    const activeSection = sessions?.find((d) => d.sessionIsActive === true)?.section
    return activeSection ? activeSection : ''
}


// getSessionName
export const getSessionName = (sections, sectionId) => {

    const activeSection = sections?.find(c => c._id === sectionId);

    // Return the className if found, otherwise return an empty string
    return activeSection ? activeSection.sectionName : '';
}


// getActiveRoll
export const getActiveRoll = (sessions) => {
    const activeRoll = sessions?.find((d) => d.sessionIsActive === true)?.roll
    return activeRoll ? activeRoll : ''
}


// getSubjectName
// export const getSubjectName = (subjectLists, subjectId) => {
//     const subjectSNames = subjectLists
//         .flatMap((d) => d.subjects)
//         .filter((s) => s._id === subjectId)
//         .map((s) => s.subjectName);

//     // Join multiple subject names with a comma or handle accordingly
//     return subjectSNames.length > 0 ? subjectSNames.join(', ') : '';
// };


// getSubjectDetails
export const getSubjectDetails = (subjectLists, subjectId) => {

    for (const classItem of subjectLists) {
        for (const subject of classItem?.subjects) {
            if (subject._id === subjectId) {
                return {
                    subjectName: subject.subjectName,
                    subjectCode: subject.subjectCode,
                    className: classItem.classDetails.className,
                };
            }
        }
    }
    return null;
};


// getTeacehrName
export const getTeacehrName = (teachers, teacherId) => {

    const teacherDetails = teachers?.find((d) => d._id === teacherId)

    return teacherDetails ? teacherDetails : ''

}


// getCurrentAcademicYear
export const getCurrentAcademicYear = (sections) => {

    const today = new Date();

    for (const section of sections) {
        const startDate = new Date(section.startDate);
        const endDate = new Date(section.endDate);

        if (today >= startDate && today <= endDate) {
            return section.sectionName;
        }
    }

    return "No current academic year found.";
}


// getDuration
export const getDuration = (startDate, endDate) => {
    let start = moment(startDate);
    const end = moment(endDate);

    // Initialize year, month, and day counters
    let years = 0;
    let months = 0;
    let days = 0;

    // Increment year counter
    while (start.clone().add(1, 'year').isSameOrBefore(end, 'day')) {
        start.add(1, 'year');
        years++;
    }

    // Increment month counter
    while (start.clone().add(1, 'month').isSameOrBefore(end, 'day')) {
        start.add(1, 'month');
        months++;
    }

    // Increment day counter
    while (start.clone().add(1, 'day').isSameOrBefore(end, 'day')) {
        start.add(1, 'day');
        days++;
    }

    // Adding 1 day to the final count if required
    const totalDays = days + (months * 30) + (years * 365);
    if (totalDays % 30 === 29) {
        days++;
    }

    // Format the duration
    let formattedDuration = '';
    if (years > 0) {
        formattedDuration += years === 1 ? '1 year' : `${years} years`;
    }
    if (months > 0) {
        if (formattedDuration) formattedDuration += ', ';
        formattedDuration += months === 1 ? '1 month' : `${months} months`;
    }
    if (days > 0) {
        if (formattedDuration) formattedDuration += ', ';
        formattedDuration += days === 1 ? '1 day' : `${days} days`;
    }

    return formattedDuration || '0 days';
};


// getExamTermName
export const getExamTermName = (examTermList, id) => {

    // Find the term object with the matching id
    const term = examTermList?.find(c => c._id === id);

    // Return the termName if found, otherwise return an empty string
    return term ? term.termName : '';
};


// getExamTermName
export const getDistributionName = (distributionList, ids) => {

    // Filter the distributionList to find all matching IDs
    const terms = distributionList?.filter(c => ids.includes(c._id));

    // Return an array of distribution values or an empty array if no matches are found
    return terms.length > 0 ? terms.map(term => term.distribution) : [];
};


// getSubjectNames
export const getSubjectNames = (sessions, subjectLists) => {

    const activeSession = sessions.find(session => session.sessionIsActive === true);

    if (!activeSession) {
        return [];
    }

    const assignedSubjectIds = activeSession.assignedSubjects || [];

    const allSubjects = subjectLists.flatMap(classData => classData.subjects);

    // Filter subjects based on the assignedSubjectIds and return their names
    const subjectNames = allSubjects
        .filter(subject => assignedSubjectIds.includes(subject._id))
        .map(subject => subject.subjectName);


    return subjectNames;
};


// getStudentDetails
export const getStudentDetails = (allStudents, studentId) => {
    const studentDetails = allStudents.find((d) => d._id === studentId)
    return studentDetails ? studentDetails : {}
}

// getTeacherDetails
export const getTeacherDetails = (allTeacher, teacherId) => {
    const teacherDetails = allTeacher.find((d) => d._id === teacherId)
    return teacherDetails ? teacherDetails : {}
}