import React, { useEffect, useState } from 'react';
import './auth.css';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { ButtonLoader } from '../../../utils/Loader/LoaderComponent';
import { messageClear, verify_email } from '../../../store/Reducers/organisation/orgWonerReducer';


const EmailVerify = () => {

    const verifyEmailToken = localStorage.getItem("verifyEmailToken");
    const { errorMessage, loader, successMessage } = useSelector(state => state.org_woner);

    const { handleSubmit, register, formState: { errors } } = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    // onSubmit
    const onSubmit = (data) => {
        const { otp } = data;
        if (verifyEmailToken) {
            const obj = {
                otp,
                verifyEmailToken
            }
            dispatch(verify_email(obj))
        }

    };


    useEffect(() => {
        if (errorMessage) {
            // toast.error(errorMessage)
            dispatch(messageClear())
        }
        if (successMessage) {
            toast.success(successMessage)
            dispatch(messageClear())
            localStorage.removeItem("verifyEmailToken")
            navigate('/', { replace: true })
        }
    }, [errorMessage, successMessage])


    useEffect(() => {
        if (!verifyEmailToken) {
            navigate('/', { replace: true })
        }
    }, [])


    return (
        <div className='loginStyle'>
            <div className=" wrapper-login wrapperLogin">
                <div className="container container-login animated fadeIn" style={{ display: 'block' }}>
                    <h4 className="text-center pb-3">Check your email and submit OTP</h4>
                    <form onSubmit={handleSubmit(onSubmit)} className="login-form">
                        <div className="form-group">
                            <label htmlFor="otp" className="placeholder"><b>Enter otp</b></label>
                            <input
                                {...register('otp', {
                                    required: 'Otp is required',
                                })}
                                id="otp"
                                name="otp"
                                type="text"
                                className={`form-control ${errors.otp ? 'is-invalid' : ''}`}
                            />
                            {errors.otp && <p className="invalid-feedback">{errors.otp.message}</p>}
                        </div>

                        <div className="form-group form-action-d-flex text-center">
                            <button disabled={loader ? true : false} type="submit" className="btn btn-secondary col-md-12 mt-3 mt-sm-0 fw-bold mx-auto">
                                {
                                    loader ? <ButtonLoader /> : 'Verify'
                                }
                            </button>
                        </div>

                    </form>
                </div>


            </div>

        </div>
    )
}

export default EmailVerify;