import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useOptionsFromData } from '../../../../utils/other/returnFunction';
import { bloodGroups, genders, teacherDepartment, teacherSkill } from '../../../../utils/other/data';
import { validateEmail, validateName, validatePhoneNumber } from '../../../../utils/validators/commonErrorHandle';


const TeacherBalicDetails = forwardRef(({ onSendMessage }, ref) => {

    const dispatch = useDispatch();

    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();

    const { teacher } = useSelector(state => state.teacher);


    const departmentList = useOptionsFromData(teacherDepartment?.departments);
    const genderList = useOptionsFromData(genders?.gender);
    const bloodGroupList = useOptionsFromData(bloodGroups?.groups);
    const skillList = useOptionsFromData(teacherSkill?.skills);


    const [genderValue, setGenderValue] = useState(null);
    const [departmentValue, setDepartmentValue] = useState(null);
    const [bloodValue, setBloodValue] = useState(null);
    const [skillsValue, setSkillsValue] = useState(null);


    // handleSelect
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
        clearErrors(fieldName);

    }


    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        }
    }));



    // onSubmit
    const onSubmit = (data) => {
        onSendMessage(true);
        sessionStorage.setItem('teacherBasicDetails', JSON.stringify(data))
    };


    // removeDetails
    const removeDetails = () => {
        sessionStorage.removeItem("teacherBasicDetails")
        reset({
            fullName: '',
            email: '',
            phoneNumber: '',
            department: '',
            enrollmentNumber: '',
            skills: '',
            gender: '',
            bloodGroup: '',
            dateOfJoining: '',
            dateOfBirth: ''
        });
        setDepartmentValue();
        setSkillsValue();
        setGenderValue();
        setBloodValue();
    }

    useEffect(() => {
        if (Object.keys(teacher).length > 0) {
            setValue('fullName', teacher?.teacherBasicDetails.fullName);
            setValue('email', teacher?.teacherBasicDetails.email);
            setValue('phoneNumber', teacher?.teacherBasicDetails.phoneNumber);

            setDepartmentValue({
                label: teacher.teacherBasicDetails?.department,
                value: teacher.teacherBasicDetails?.department
            });
            setValue("department", {
                label: teacher.teacherBasicDetails?.department,
                value: teacher.teacherBasicDetails?.department
            });
            setValue('enrollmentNumber', teacher?.teacherBasicDetails.enrollmentNumber);

            setSkillsValue({
                label: teacher.teacherBasicDetails?.skills,
                value: teacher.teacherBasicDetails?.skills
            });
            setValue("skills", {
                label: teacher.teacherBasicDetails?.skills,
                value: teacher.teacherBasicDetails?.skills
            });
            setGenderValue({
                label: teacher.teacherBasicDetails?.gender,
                value: teacher.teacherBasicDetails?.gender
            });
            setValue("gender", {
                label: teacher.teacherBasicDetails?.gender,
                value: teacher.teacherBasicDetails?.gender
            });
            setBloodValue({
                label: teacher.teacherBasicDetails?.bloodGroup,
                value: teacher.teacherBasicDetails?.bloodGroup
            });
            setValue("bloodGroup", {
                label: teacher.teacherBasicDetails?.bloodGroup,
                value: teacher.teacherBasicDetails?.bloodGroup
            });

            setValue('dateOfJoining', moment(teacher.teacherBasicDetails.dateOfJoining).format('YYYY-MM-DD'));
            setValue('dateOfBirth', moment(teacher.teacherBasicDetails.dateOfBirth).format('YYYY-MM-DD'));

        }

    }, [teacher])


    useEffect(() => {

        const storedBasicDetails = sessionStorage.getItem("teacherBasicDetails");

        if (storedBasicDetails) {
            const teacherBasicDetails = JSON.parse(storedBasicDetails);
            setValue('fullName', teacherBasicDetails.fullName);
            setValue('email', teacherBasicDetails.email);
            setValue('phoneNumber', teacherBasicDetails?.phoneNumber);
            setDepartmentValue({
                label: teacherBasicDetails?.department.label, value: teacherBasicDetails?.department.value
            })
            setValue("department", {
                label: teacherBasicDetails?.department.label, value: teacherBasicDetails?.department.value
            })
            setValue('enrollmentNumber', teacherBasicDetails?.enrollmentNumber);

            setSkillsValue({
                label: teacherBasicDetails?.skills.label, value: teacherBasicDetails?.skills.value
            })
            setValue("skills", {
                label: teacherBasicDetails?.skills.label, value: teacherBasicDetails?.skills.value
            })
            setGenderValue({
                label: teacherBasicDetails?.gender.label, value: teacherBasicDetails?.gender.value
            })
            setValue("gender", {
                label: teacherBasicDetails?.gender.label, value: teacherBasicDetails?.gender.value
            })
            setBloodValue({
                label: teacherBasicDetails?.bloodGroup.label, value: teacherBasicDetails?.bloodGroup.value
            })
            setValue("bloodGroup", {
                label: teacherBasicDetails?.bloodGroup.label, value: teacherBasicDetails?.bloodGroup.value
            })
            setValue('dateOfJoining', moment(teacherBasicDetails.dateOfJoining).format('YYYY-MM-DD'));
            setValue('dateOfBirth', moment(teacherBasicDetails.dateOfBirth).format('YYYY-MM-DD'));

        }

    }, [])


    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header d-flex justify-content-between">
                        <h4 className="card-title">Basic Details</h4>
                        <button onClick={removeDetails} className='btn btn-danger px-3 py-2 fw-bold'> Reset </button>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">

                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="fullName">Full Name <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.fullName ? 'is-invalid' : ''}`}
                                            id="fullName"
                                            {...register('fullName', {
                                                validate: validateName('Full name is required')
                                            })}
                                        />
                                        {errors.fullName && <div className="invalid-feedback">{errors.fullName.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="email">Email <span className='text-danger'>*</span></label>
                                        <input type="email"
                                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                            id="email"
                                            {...register('email', { validate: validateEmail('Email is required') })}
                                        />
                                        {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="phoneNumber">Phone Number <span className='text-danger'>*</span></label>
                                        <input type="tel"
                                            className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                                            id="phoneNumber"
                                            {...register('phoneNumber', { validate: validatePhoneNumber('Phone Number is required') })}
                                        />
                                        {errors.phoneNumber && <div className="invalid-feedback">{errors.phoneNumber.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="department">Department <span className='text-danger'>*</span></label>
                                        <Select
                                            {...register('department', { required: 'Department is required' })}
                                            value={departmentValue}
                                            onChange={(selectOption) => {
                                                setDepartmentValue(selectOption);
                                                handleSelect('department', selectOption);
                                            }}
                                            options={departmentList}
                                            className={errors.department ? 'is-invalid' : ''}
                                        />
                                        {errors.department && <div className="invalid-feedback">{errors.department.message}</div>}
                                    </div>
                                </div>


                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="enrollmentNumber">Enrollment Number <span className='text-danger'>*</span></label>
                                        <input type="text"
                                            className={`form-control ${errors.enrollmentNumber ? 'is-invalid' : ''}`}
                                            id="enrollmentNumber"
                                            {...register('enrollmentNumber', {
                                                validate: validateName('Enrollment Number is required')
                                            })}

                                        />
                                        {errors.enrollmentNumber && <div className="invalid-feedback">{errors.enrollmentNumber.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="skills">Skills <span className='text-danger'>*</span></label>
                                        <Select
                                            {...register('skills', { required: 'Skill is required' })}
                                            value={skillsValue}
                                            onChange={(selectOption) => {
                                                setSkillsValue(selectOption);
                                                handleSelect('skills', selectOption);
                                            }}
                                            options={skillList}
                                            className={errors.skills ? 'is-invalid' : ''}
                                        />
                                        {errors.skills && <div className="invalid-feedback">{errors.skills.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="gender">Gender <span className='text-danger'>*</span></label>
                                        <Select
                                            {...register('gender', { required: 'Gender is required' })}
                                            value={genderValue}
                                            onChange={(selectOption) => {
                                                setGenderValue(selectOption);
                                                handleSelect('gender', selectOption);
                                            }}
                                            options={genderList}
                                            className={errors.gender ? 'is-invalid' : ''}
                                        />
                                        {errors.gender && <div className="invalid-feedback">{errors.gender.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="bloodGroup">Blood Group <span className='text-danger'>*</span></label>
                                        <Select
                                            {...register('bloodGroup', { required: 'Blood Group is required' })}
                                            value={bloodValue}
                                            onChange={(selectOption) => {
                                                setBloodValue(selectOption);
                                                handleSelect('bloodGroup', selectOption);
                                            }}
                                            options={bloodGroupList}
                                            className={errors.bloodGroup ? 'is-invalid' : ''}
                                        />
                                        {errors.bloodGroup && <div className="invalid-feedback">{errors.bloodGroup.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="dateOfJoining">Date of Joining <span className='text-danger'>*</span></label>
                                        <input
                                            type="date"
                                            className={`form-control ${errors.dateOfJoining ? 'is-invalid' : ''}`}
                                            id="dateOfJoining"
                                            {...register('dateOfJoining', {
                                                validate: validateName('Date of Joining is required')
                                            })}
                                        />
                                        {errors.dateOfJoining && <div className="invalid-feedback">{errors.dateOfJoining.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="dateOfBirth">Date of Birth <span className='text-danger'>*</span></label>
                                        <input
                                            type="date"
                                            className={`form-control ${errors.dateOfBirth ? 'is-invalid' : ''}`}
                                            id="dateOfBirth"
                                            {...register('dateOfBirth', {
                                                validate: validateName('Date of Birth is required')
                                            })}
                                        />
                                        {errors.dateOfBirth && <div className="invalid-feedback">{errors.dateOfBirth.message}</div>}
                                    </div>
                                </div>


                            </div>
                            <button type="submit" style={{ display: 'none' }}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
});



export default TeacherBalicDetails;