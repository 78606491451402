import React from 'react';
import Layout from '../../Layout/Layout';

const ReportCard = () => {
    return (
        <Layout>
            <h1>ReportCard</h1>
        </Layout>
    )
}

export default ReportCard;