import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';


// student_login
export const student_login = createAsyncThunk(
    'personalStudent/student_login',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/student-login', info)
            localStorage.setItem('authToken', data.authToken)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// student_logout
export const student_logout = createAsyncThunk(
    'personalStudent/student_logout',
    async (_, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get('/student-logout')
            localStorage.removeItem('authToken');
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)



// returnRole
const returnRole = (token) => {
    if (token) {
        const payload = token.split('.')[1];
        const decodedPayload = JSON.parse(atob(payload));
        const expireTime = new Date(decodedPayload.exp * 1000);
        if (new Date() > expireTime) {
            localStorage.removeItem('authToken');
            return '';
        } else {
            return decodedPayload;
        }
    } else {
        return '';
    }
};



export const personalStudentReducer = createSlice({
    name: "personalStudent",
    initialState: {
        errorMessage: '',
        successMessage: '',
        logoutMessage: '',
        loader: false,
        studentInfo: returnRole(localStorage.getItem("authToken")),
        studentToken: localStorage.getItem("authToken"),

    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ''
            state.successMessage = ''
            state.logoutMessage = ''
        },
        tokenClear: (state, _) => {
            state.studentInfo = ''
            state.studentToken = ''
        },
    },
    extraReducers: {
        [student_login.pending]: (state, _) => {
            state.loader = true
        },
        [student_login.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [student_login.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
            state.studentInfo = returnRole(payload.authToken)
            state.studentToken = payload.authToken
        },
        [student_logout.fulfilled]: (state, { payload }) => {
            state.logoutMessage = payload.message
        },

    }
});



export const { messageClear, tokenClear } = personalStudentReducer.actions;
export default personalStudentReducer.reducer;