import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../Layout/Layout';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Empty from '../../../utils/page/Empty';
import moment from 'moment';
import { useReactToPrint } from "react-to-print";
import { FaUserClock } from "react-icons/fa";
import { get_teacher_schedule } from '../../../store/Reducers/class/classReducer';
import { getClassName, getSubjectDetails, getTeacehrName } from '../../../utils/other/returnFunction';
import { FiPrinter } from "react-icons/fi";


const TeacherSchedule = () => {

    const dispatch = useDispatch();

    const { allTeachers } = useSelector(state => state.teacher);
    const { teacherSchedule } = useSelector(state => state.class);
    const { classList, subjectLists } = useSelector(state => state.administrator);


    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();


    const [teacherListOption, setTeacherListOption] = useState([]);

    const [teacherValue, setTeacherValue] = useState(null);



    //  handleSelect
    const handleSelect = (selectOption) => {
        const teacherId = selectOption?.value
        dispatch(get_teacher_schedule({ teacherId }))
    }


    const componentRef = useRef();


    // createPrint
    const createPrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {

        console.log("allTeachers", allTeachers)

        const options = allTeachers?.map((item) => ({
            value: item._id,
            label: `${item.teacherBasicDetails.fullName} (${item.teacherBasicDetails.skills})`
        }));

        setTeacherListOption(options)
    }, [allTeachers])


    // console.log("allTeachers", allTeachers)
    // console.log("teacherSchedule", teacherSchedule)



    return (
        <Layout>

            <div className="card mb-3">
                <div className="card-body">
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-lg-6 d-flex justify-content-center">
                            <div style={{ width: '100%' }}>
                                <div className="form-group pt-0">
                                    <label htmlFor="teacher">Teacher <span className='text-danger'>*</span></label>
                                    <Select
                                        placeholder="Select Teacher"
                                        value={teacherValue}
                                        {...register('teacher')}
                                        onChange={(selectOption) => {
                                            setTeacherValue(selectOption);
                                            handleSelect(selectOption);
                                        }}
                                        options={teacherListOption}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className="card">
                <div className="card-body">

                    {
                        teacherSchedule?.length > 0 ?
                            <>
                                <div className="page-header mb-0">
                                    <FaUserClock style={{ fontSize: '25px', marginBottom: "6px", marginRight: "8px" }} />
                                    <h4 style={{ fontSize: "19px" }}>Schedule List</h4>

                                    <span className='px-4'>
                                        <FiPrinter onClick={createPrint} title='Print' style={{ fontSize: '24px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    </span>

                                </div>

                                <hr className='my-3' />
                                <table className="tableStyle" ref={componentRef}>
                                    <tbody>
                                        {
                                            teacherSchedule?.map((d, i) => (
                                                <tr key={i} className='border'>
                                                    <td className='border'>{d.day}</td>
                                                    {d.schedules.map((schedule, j) => (
                                                        schedule.schedule.map((s, k) => {
                                                            const subjectDetails = getSubjectDetails(subjectLists, s.subjectId);
                                                            const subjectName = subjectDetails ? subjectDetails.subjectName : '';
                                                            return (
                                                                <td className='border' key={`${j}-${k}`}>
                                                                    <div>
                                                                        {s.isBreak !== true ? (
                                                                            <>
                                                                                <p className='py-0 my-0' style={{ fontSize: '15px', fontWeight: '700' }}>{subjectName}</p>
                                                                                <p className='py-0 my-0' style={{ fontSize: '12px' }}>({moment(s.startingTime, "HH:mm").format("h:mm A")} - {moment(s.endingTime, "HH:mm").format("h:mm A")})</p>
                                                                                <p className='py-0 my-0' style={{ fontSize: '14px' }}>Class : {getClassName(classList, schedule.classId)} ({schedule.section})</p>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {/* <p className='py-0 my-0' style={{ fontSize: '15px', fontWeight: '700' }}>Break</p>
                                                                                <p className='py-0 my-0' style={{ fontSize: '12px' }}>({moment(s.startingTime, "HH:mm").format("h:mm A")} - {moment(s.endingTime, "HH:mm").format("h:mm A")})</p> */} <p className='px-3'>N / A</p>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            );
                                                        })
                                                    ))}
                                                </tr>
                                            ))
                                        }
                                    </tbody>


                                </table>

                            </>

                            : <Empty data={`Schedule List Not Found!`} />
                    }

                </div>
            </div >


        </Layout>
    )
}

export default TeacherSchedule;