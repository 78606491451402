import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useForm, Controller } from 'react-hook-form';
import { ButtonLoader } from '../../../utils/Loader/LoaderComponent';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { administrator_login, messageClear } from '../../../store/Reducers/administrator/administratorReducer';
import { getCurrentAcademicYear } from '../../../utils/other/returnFunction';
import { sessions } from '../../../utils/other/data';
import { sessionFunction } from '../../../store/Reducers/defaultReducer';
import { FaEye, FaEyeSlash } from 'react-icons/fa';


const AdministratorLogin = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility



  const { errorMessage, loader, successMessage } = useSelector(state => state.administrator);
  const { academicYearApprovedList } = useSelector(state => state.academic);

  const { handleSubmit, setValue, register, formState: { errors } } = useForm();

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  // onSubmit
  const onSubmit = (data) => {
    const { phoneNumber, password } = data;
    const obj = {
      phoneNumber,
      password
    }

    dispatch(administrator_login(obj))

  };


  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage)
      dispatch(messageClear())
    }
    if (successMessage) {
      toast.success(successMessage)
      dispatch(messageClear())

      const sessionsId = academicYearApprovedList?.find((d) => d.sectionName == getCurrentAcademicYear(academicYearApprovedList))
      if (sessionsId) {
        localStorage.setItem("sessionValue", sessionsId._id)
        dispatch(sessionFunction(sessionsId._id))
      }

      navigate('/administrator-dashboard', { replace: true })
    }
  }, [errorMessage, successMessage])


  return (
    <div className="pt-5" style={{ display: "flex", alignItems: 'center', height: '100%' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container">
          <h4 className='pl-2 font-weight-bold'>Login as Administrator</h4>

          <div className="row">

            <div className="col-lg-12">
              <div className="form-group">
                <input
                  {...register('phoneNumber', {
                    required: 'Phone number is required',
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: 'Invalid phone number',
                    },
                  })}
                  id="phoneNumber"
                  name="phoneNumber"
                  type="tel" // Change type to 'tel' for phone number input
                  className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                  placeholder='Enter Phone Number'
                />
                {errors.phoneNumber && <p className="invalid-feedback">{errors.phoneNumber.message}</p>}
              </div>
            </div>


            <div className="col-lg-12">
              <div className="form-group position-relative">
                <input
                  {...register('password', {
                    required: 'Password is required',
                  })}
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
                  className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                  placeholder='Enter Password'
                />
                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: '20px',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer'
                  }}>
                  {showPassword ? <FaEyeSlash size={18} /> : <FaEye size={18} />}
                </span>
                {errors.password && <p className="invalid-feedback">{errors.password.message}</p>}
              </div>
            </div>
          </div>
          <div className="mt-3" style={{ marginLeft: "12px" }}>
            <button disabled={loader ? true : false} type="submit" className="btn btn-secondary col-md-12 mt-3 mt-sm-0 fw-bold mx-auto">
              {
                loader ? <ButtonLoader /> : 'Login'
              }
            </button>
          </div>
        </div>
        <div className="login-account pt-3" style={{ textAlign: "center" }}>
          <span className="msg">Do you want to log in as an owner ?</span>
          <Link to='/login' id="show-login" className="link"> Login</Link>
        </div>

      </form>

    </div>
  )
}


export default AdministratorLogin;