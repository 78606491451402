import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';


// add_fees_type
export const add_fees_type = createAsyncThunk(
    'fees/add_fees_type',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/add-fees-type', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_fees_type
export const get_fees_type = createAsyncThunk(
    'fees/get_fees_type',
    async ({ searchValue, page, parPage }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-fees-type?searchValue=${searchValue}&&page=${page}&&parPage=${parPage}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_single_fees_type
export const get_single_fees_type = createAsyncThunk(
    'fees/get_single_fees_type',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-single-fees-type/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// update_fees_type
export const update_fees_type = createAsyncThunk(
    'fees/update_fees_type',
    async ({ editId, obj }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/update-fees-type/${editId}`, obj)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// delete_fees_type
export const delete_fees_type = createAsyncThunk(
    'fees/delete_fees_type',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.delete(`/delete-fees-type/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)



// add_fees_group
export const add_fees_group = createAsyncThunk(
    'fees/add_fees_group',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/add-fees-group', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_fees_type
export const get_group_type = createAsyncThunk(
    'fees/get_group_type',
    async ({ searchValue, page, parPage }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-group-type?searchValue=${searchValue}&&page=${page}&&parPage=${parPage}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_single_fees_group
export const get_single_fees_group = createAsyncThunk(
    'fees/get_single_fees_group',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-single-fees-group/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// update_fees_group
export const update_fees_group = createAsyncThunk(
    'fees/update_fees_group',
    async ({ groupId, finalData }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/update-fees-group/${groupId}`, finalData)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// delete_group_type
export const delete_group_type = createAsyncThunk(
    'fees/delete_group_type',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.delete(`/delete-group-type/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)



// add_fine
export const add_fine = createAsyncThunk(
    'fees/add_fine',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/add-fine', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_fine
export const get_fine = createAsyncThunk(
    'fees/get_fine',
    async ({ searchValue, page, parPage }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-fine?searchValue=${searchValue}&&page=${page}&&parPage=${parPage}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_single_fine
export const get_single_fine = createAsyncThunk(
    'fees/get_single_fine',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-single-fine/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// update_fine
export const update_fine = createAsyncThunk(
    'fees/update_fine',
    async ({ editId, obj }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/update-fine/${editId}`, obj)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// delete_fine
export const delete_fine = createAsyncThunk(
    'fees/delete_fine',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.delete(`/delete-fine/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const feesReducer = createSlice({
    name: "fees",
    initialState: {
        errorMessage: '',
        successMessage: '',
        loader: false,
        feesList: [],
        feesCount: 0,
        singleFeesType: {},
        feesGroupList: [],
        feesGroupCount: 0,
        singleFeesGroup: {},
        fineList: [],
        fineCount: 0,
        singleFine: {}
    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ''
            state.successMessage = ''
        },
    },
    extraReducers: {
        [add_fees_type.pending]: (state, _) => {
            state.loader = true
        },
        [add_fees_type.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [add_fees_type.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [get_fees_type.pending]: (state, _) => {
            state.loader = true
        },
        [get_fees_type.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.feesList = payload.feesList
            state.feesCount = payload.feesCount
        },
        [get_single_fees_type.fulfilled]: (state, { payload }) => {
            state.singleFeesType = payload.singleFeesType
        },
        [update_fees_type.pending]: (state, _) => {
            state.loader = true
        },
        [update_fees_type.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [update_fees_type.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [delete_fees_type.rejected]: (state, { payload }) => {
            state.errorMessage = payload.error
        },
        [delete_fees_type.fulfilled]: (state, { payload }) => {
            state.successMessage = payload.message
        },
        [add_fees_group.pending]: (state, _) => {
            state.loader = true
        },
        [add_fees_group.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [add_fees_group.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [get_group_type.pending]: (state, _) => {
            state.loader = true
        },
        [get_group_type.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.feesGroupList = payload.feesGroupList
            state.feesGroupCount = payload.feesGroupCount
        },
        [get_single_fees_group.fulfilled]: (state, { payload }) => {
            state.singleFeesGroup = payload.singleFeesGroup
        },
        [update_fees_group.pending]: (state, _) => {
            state.loader = true
        },
        [update_fees_group.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [update_fees_group.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [delete_group_type.rejected]: (state, { payload }) => {
            state.errorMessage = payload.error
        },
        [delete_group_type.fulfilled]: (state, { payload }) => {
            state.successMessage = payload.message
        },
        [add_fine.pending]: (state, _) => {
            state.loader = true
        },
        [add_fine.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [add_fine.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [get_fine.pending]: (state, _) => {
            state.loader = true
        },
        [get_fine.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.fineList = payload.fineList
            state.fineCount = payload.fineCount
        },
        [get_single_fine.fulfilled]: (state, { payload }) => {
            state.singleFine = payload.singleFine
        },
        [update_fine.pending]: (state, _) => {
            state.loader = true
        },
        [update_fine.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [update_fine.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },

    }
});


export const { messageClear } = feesReducer.actions;
export default feesReducer.reducer;