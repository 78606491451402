import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { daysOfWeek } from '../../../utils/other/data';
import { useOptionsFromData } from '../../../utils/other/returnFunction';
import { IoTimeOutline } from "react-icons/io5";
import Empty from '../../../utils/page/Empty';
import { add_class_schedule, get_class_schedule, messageClear } from '../../../store/Reducers/class/classReducer';
import { ButtonLoader } from '../../../utils/Loader/LoaderComponent';
import toast from 'react-hot-toast';


const AddClassSchedule = () => {

    const dispatch = useDispatch();

    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();
    const { classList, subjectLists } = useSelector(state => state.administrator);

    const { errorMessage, successMessage, loader, classSchedule } = useSelector(state => state.class);


    const [classListOption, setClassListOption] = useState([]);
    const [sectionOption, setSectionOption] = useState([]);
    const daysOfWeekList = useOptionsFromData(daysOfWeek?.list);

    const [classValue, setClassValue] = useState(null);
    const [sectionValue, setSectionValue] = useState(null);
    const [dayValue, setDayValue] = useState(null);

    const [selecteeClassId, setSelecteeClassId] = useState('');
    const [selectedSection, setSelectedSection] = useState('');
    const [selectedDay, setSelectedDay] = useState('');

    const [timeTableList, setTimeTableList] = useState([]);
    const [teacherList, setTeacherList] = useState([]);
    const [subjectOptions, setSubjectOptions] = useState([]);

    const [startingTime, setStartingTime] = useState('');
    const [duration, setDuration] = useState('');
    const [interval, setInterval] = useState('');
    const [classRoom, setClassRoom] = useState('');


    // handleSelect
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);

        if (fieldName === 'className') {
            setSelecteeClassId(selectOption.value);

            setSectionValue({ value: "", label: "Select Section" });
            setValue("section", { label: '', value: '' });
            setDayValue({ value: "", label: "Select Day" });
            setValue("days", { label: '', value: '' });
            setSelectedDay('');

            const secOption = classList?.filter((item) => item._id === selectOption.value);
            const array = secOption[0]?.sectionNames;
            const convertedArray = array?.map(item => ({
                value: item,
                label: item
            }));
            setSectionOption(convertedArray);
        }


        if (fieldName === 'section') {
            setSelectedSection(selectOption.value);
        }


        if (fieldName === 'days') {
            setSelectedDay(selectOption.value);

            // Clear the timeTableList before updating it
            setTimeTableList([]);

            const filterData = subjectLists?.find((d) => d.classDetails.classId === selecteeClassId);
            const subjects = filterData?.subjects.map(subject => ({
                value: subject._id,
                label: subject.subjectName
            }));
            setSubjectOptions(subjects);

            // Set timeTableList with a single entry for the selected day
            setTimeTableList(filterData?.subjects.map(subject => ({
                ...subject,
                isBreak: false // Add the isBreak property to each subject
            }))[0] ? [filterData.subjects[0]] : []);

            const filterTeacher = classList?.find((d) => d._id === selecteeClassId);
            setTeacherList(filterTeacher?.assignTeacher);

        }

    };



    useEffect(() => {

        const obj = {
            classId: selecteeClassId,
            section: selectedSection
        }
        dispatch(get_class_schedule(obj))

        const findSchedule = classSchedule?.find((d) => d.day === selectedDay)

        if (findSchedule !== undefined) {
            setTimeTableList(findSchedule?.schedule)
        }


    }, [selectedSection, selectedDay])


    // console.log("timeTableList", timeTableList)


    // applySchedule
    const applySchedule = () => {
        if (startingTime && duration && interval && classRoom) {
            let [hours, minutes] = startingTime.split(':').map(Number);
            let currentStartTime = new Date(1970, 0, 1, hours, minutes);

            const updatedTimeTable = timeTableList?.map((subject, index) => {
                let startTime = new Date(currentStartTime);
                let endTime = new Date(startTime.getTime() + duration * 60000);

                const formattedStartTime = startTime.toTimeString().substr(0, 5);
                const formattedEndTime = endTime.toTimeString().substr(0, 5);

                currentStartTime = new Date(endTime.getTime() + interval * 60000);

                return {
                    ...subject,
                    startingTime: formattedStartTime,
                    endingTime: formattedEndTime,
                    classRoom
                };
            });

            setTimeTableList(updatedTimeTable);
        }
    };


    // handleInputChange
    const handleInputChange = (index, field, value) => {
        setTimeTableList(prevList => {
            const updatedTimeTable = prevList.map((item, i) => {
                if (i === index) {
                    return { ...item, [field]: value };
                }
                return item;
            });
            return updatedTimeTable;
        });
    };

    // handleTeacherChange
    const handleTeacherChange = (index, teacherId) => {
        const updatedTimeTable = timeTableList?.map((item, i) =>
            i === index ? { ...item, teacherId } : item
        );
        setTimeTableList(updatedTimeTable);
    };

    // handleSubjectChange
    const handleSubjectChange = (index, subjectId) => {
        const updatedTimeTable = timeTableList?.map((item, i) =>
            i === index ? { ...item, subjectId } : item
        );
        setTimeTableList(updatedTimeTable);
    };


    // handleAddRow
    const handleAddRow = (e) => {
        e.preventDefault();
        // Use functional update form to ensure the latest state is used
        setTimeTableList(prevList => {
            if (Array.isArray(prevList)) {
                return [
                    ...prevList,
                    { subjectId: '', teacherId: '', startingTime: '', endingTime: '', classRoom: '', isBreak: false }
                ];
            } else {
                return [
                    { subjectId: '', teacherId: '', startingTime: '', endingTime: '', classRoom: '', isBreak: false }
                ];
            }
        });
    };


    // handleRemoveRow
    const handleRemoveRow = (index) => {
        const updatedTimeTable = [...timeTableList];
        updatedTimeTable.splice(index, 1);
        setTimeTableList(updatedTimeTable);

        // console.log("updatedTimeTable", updatedTimeTable)

    };


    // handleSave
    const handleSave = (e) => {
        e.preventDefault();
        // Collect the data from timeTableList
        const updatedSchedule = timeTableList?.map(subject => ({
            ...subject,
            startingTime: subject.startingTime,
            endingTime: subject.endingTime,
            classRoom: subject.classRoom,
            teacherId: subject.teacherId,
            subjectId: subject.subjectId, // Ensure subjectId is included
            isBreak: subject.isBreak // Ensure isBreak is included
        }));


        const obj = {
            classId: selecteeClassId,
            section: selectedSection,
            day: selectedDay,
            schedule: updatedSchedule
        }

        dispatch(add_class_schedule(obj))

    };


    // handleBreakChange
    const handleBreakChange = (index) => {
        const updatedTimeTable = timeTableList?.map((item, i) =>
            i === index ? { ...item, isBreak: !item.isBreak } : item
        );
        setTimeTableList(updatedTimeTable);
    };


    useEffect(() => {
        const options = classList?.map(item => ({
            value: item._id,
            label: item.className
        }));
        setClassListOption(options);
    }, [classList]);


    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage);
            dispatch(messageClear());
        }
        if (successMessage) {
            toast.success(successMessage);
            dispatch(messageClear());
        }
    }, [errorMessage, successMessage]);



    return (
        <>
            <div className="card mb-3">
                <div className="card-body">
                    <div className="row">

                        <div className="col-md-6 col-lg-4">
                            <div className="form-group pt-0">
                                <label htmlFor="className">Class <span className='text-danger'>*</span></label>
                                <Select
                                    placeholder="Select Class"
                                    value={classValue}
                                    {...register('className')}
                                    onChange={(selectOption) => {
                                        setClassValue(selectOption);
                                        handleSelect('className', selectOption);
                                    }}
                                    options={classListOption}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <div className="form-group pt-0">
                                <label htmlFor="section">Section <span className='text-danger'>*</span></label>
                                <Select
                                    {...register('section')}
                                    placeholder="Select Section"
                                    value={sectionValue}
                                    onChange={(selectOption) => {
                                        setSectionValue(selectOption);
                                        handleSelect('section', selectOption);
                                    }}
                                    options={sectionOption}
                                    isDisabled={sectionOption?.length > 0 ? false : true}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <div className="form-group py-0">
                                <label htmlFor="days">Day <span className='text-danger'>*</span></label>
                                <Select
                                    placeholder="Select Day"
                                    value={dayValue}
                                    {...register('days')}
                                    onChange={(selectOption) => {
                                        setDayValue(selectOption);
                                        handleSelect('days', selectOption);
                                    }}
                                    options={daysOfWeekList}
                                    isDisabled={sectionOption?.length > 0 ? false : true}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {selecteeClassId && selectedDay &&
                <div className="card">
                    <div className="card-body">
                        <div className="page-header mb-0">
                            <IoTimeOutline style={{ fontSize: '25px', marginBottom: "6px", marginRight: "8px" }} />
                            <h4 style={{ fontSize: "19px" }}>Add Schedule</h4>
                        </div>

                        <div className="border p-2">
                            <form onSubmit={handleSubmit(applySchedule)}>
                                <div className="row">
                                    <h4 className='px-4' style={{ fontSize: "18px", fontWeight: "600" }}> Set Parameters To Quickly Create Schedule</h4>

                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group pt-0">
                                            <label htmlFor="startingTime">Starting Time <span className='text-danger'>*</span></label>
                                            <input
                                                type="time"
                                                className={`form-control ${errors.startingTime ? 'is-invalid' : ''}`}
                                                id="startingTime"
                                                {...register('startingTime', { required: 'Starting Time is required' })}
                                                value={startingTime}
                                                onChange={(e) => setStartingTime(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group pt-0">
                                            <label htmlFor="duration">Duration (Minutes) <span className='text-danger'>*</span></label>
                                            <input
                                                type="number"
                                                className={`form-control ${errors.duration ? 'is-invalid' : ''}`}
                                                id="duration"
                                                {...register('duration', { required: 'Duration is required' })}
                                                value={duration}
                                                onChange={(e) => setDuration(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group pt-0">
                                            <label htmlFor="interval">Interval (Minutes) <span className='text-danger'>*</span></label>
                                            <input
                                                type="number"
                                                className={`form-control ${errors.interval ? 'is-invalid' : ''}`}
                                                id="interval"
                                                {...register('interval', { required: 'Interval is required' })}
                                                value={interval}
                                                onChange={(e) => setInterval(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group pt-0">
                                            <label htmlFor="classRoom">Class Room <span className='text-danger'>*</span></label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.classRoom ? 'is-invalid' : ''}`}
                                                id="classRoom"
                                                {...register('classRoom', { required: 'Class Room is required' })}
                                                value={classRoom}
                                                onChange={(e) => setClassRoom(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="mr-2 text-right">
                                    <button type="submit" style={{ width: "85px" }} className="btn btn-secondary py-2 font-weight-bold">
                                        Apply
                                    </button>
                                </div>

                            </form>
                        </div>

                        <div className="mt-4">
                            <form>
                                {timeTableList?.length > 0 ?
                                    <>
                                        <table className="tableStyle">
                                            <thead>
                                                <tr>
                                                    <th>Sl.</th>
                                                    <th>Subject <span className='text-danger'>*</span></th>
                                                    <th>Teacher <span className='text-danger'>*</span></th>
                                                    <th>Starting Time <span className='text-danger'>*</span></th>
                                                    <th>Ending Time <span className='text-danger'>*</span></th>
                                                    <th>Class Room</th>
                                                    <th>Break</th> {/* Add header for Break column */}
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {timeTableList?.map((d, i) => (
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>
                                                            <Select
                                                                placeholder="Select Subject"
                                                                value={subjectOptions.find(option => option.value === d.subjectId)}
                                                                onChange={(selectOption) => handleSubjectChange(i, selectOption.value)}
                                                                options={subjectOptions}
                                                            />
                                                        </td>
                                                        <td>
                                                            <select
                                                                className='form-select p-1 px-2 text'
                                                                value={d.teacherId || ''}
                                                                onChange={(e) => handleTeacherChange(i, e.target.value)}
                                                            >
                                                                <option value="">Select</option>
                                                                {teacherList?.map((t) => (
                                                                    <option key={t._id} value={t._id}>
                                                                        {`${t.teacherBasicDetails.fullName} (${t.teacherBasicDetails.skills})`}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input
                                                                style={{ width: "100%" }}
                                                                type="time"
                                                                name='startingTime'
                                                                className='form-control p-2'
                                                                value={d.startingTime || ''}
                                                                onChange={(e) => handleInputChange(i, 'startingTime', e.target.value)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                style={{ width: "100%" }}
                                                                type="time"
                                                                name='endingTime'
                                                                className='form-control p-2'
                                                                value={d.endingTime || ''}
                                                                onChange={(e) => handleInputChange(i, 'endingTime', e.target.value)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                style={{ width: "100%" }}
                                                                type="text"
                                                                name='classRoom'
                                                                className='form-control p-2'
                                                                value={d.classRoom || ''}
                                                                onChange={(e) => handleInputChange(i, 'classRoom', e.target.value)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                checked={d.isBreak}
                                                                onChange={() => handleBreakChange(i)}
                                                                style={{ transform: 'scale(1.2)' }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-danger px-3 py-1" onClick={() => handleRemoveRow(i)}>
                                                                <span style={{ fontSize: "14px" }}>
                                                                    <i className="fa fa-times" />
                                                                </span>
                                                            </button>

                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </> : <Empty data={`Click the "Add Row" button to add a schedule!`} />

                                }

                                <button onClick={handleAddRow} disabled={subjectOptions?.length > 0 ? false : true} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto mt-3">
                                    <span><i className="fa fa-plus pr-2" />Add</span>
                                </button>

                                <hr />

                                <div className="mt-4 text-right">
                                    {
                                        subjectOptions?.length > 0 && <button
                                            disabled={loader}
                                            style={{ width: "90px" }}
                                            className="btn btn-secondary py-2 font-weight-bold"
                                            onClick={handleSave}
                                        >
                                            {loader ? <ButtonLoader /> : 'Save'}

                                        </button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default AddClassSchedule;
