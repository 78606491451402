import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { religionCategorys, religions } from '../../../../utils/other/data';
import { useOptionsFromData } from '../../../../utils/other/returnFunction';
import { validateName, validatePhoneNumber } from '../../../../utils/validators/commonErrorHandle';



const TeacherAdditionalDetails = forwardRef(({ onSendMessage }, ref) => {

    const { teacher } = useSelector(state => state.teacher);

    const { handleSubmit, getValues, control, setValue, register, formState: { errors }, clearErrors, reset } = useForm();


    const religionList = useOptionsFromData(religions?.list);
    const religionCategoryList = useOptionsFromData(religionCategorys?.list);


    const [religionValue, setReligionValue] = useState(null);
    const [religionCategoryValue, setReligionCategoryValue] = useState(null);


    // handleSelect
    const handleSelect = (fieldName, selectOption) => {
        setValue(fieldName, selectOption);
        clearErrors(fieldName);

    }


    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        }
    }));


    // onSubmit
    const onSubmit = (data) => {
        onSendMessage(true);
        sessionStorage.setItem('teacherAdditionalDetails', JSON.stringify(data))

    };

    // removeDetails
    const removeDetails = () => {

        sessionStorage.removeItem("teacherAdditionalDetails")
        reset({
            religion: '',
            religionCategory: '',
            maritalStatus: '',
            aadharNumber: '',
            panNumber: '',
            emergencyNumber: '',
            fatherName: '',
            motherName: ''
        });
        setReligionValue();
        setReligionCategoryValue();
    }




    useEffect(() => {
        if (Object.keys(teacher).length > 0) {
            setReligionValue({
                label: teacher.teacherAdditionalDetails?.religion,
                value: teacher.teacherAdditionalDetails?.religion
            });
            setValue("religion", {
                label: teacher.teacherAdditionalDetails?.religion,
                value: teacher.teacherAdditionalDetails?.religion
            });

            setReligionCategoryValue({
                label: teacher.teacherAdditionalDetails?.religionCategory,
                value: teacher.teacherAdditionalDetails?.religionCategory
            });
            setValue("religionCategory", {
                label: teacher.teacherAdditionalDetails?.religionCategory,
                value: teacher.teacherAdditionalDetails?.religionCategory
            });

            setValue('maritalStatus', teacher?.teacherAdditionalDetails.maritalStatus);

            setValue('aadharNumber', teacher?.teacherAdditionalDetails.aadharNumber);
            setValue('panNumber', teacher?.teacherAdditionalDetails.panNumber);
            setValue('emergencyNumber', teacher?.teacherAdditionalDetails.emergencyNumber);
            setValue('fatherName', teacher?.teacherAdditionalDetails.fatherName);
            setValue('motherName', teacher?.teacherAdditionalDetails.motherName);

        }

    }, [teacher])



    useEffect(() => {

        const storedBasicDetails = sessionStorage.getItem("teacherAdditionalDetails");

        if (storedBasicDetails) {
            const teacherAdditional = JSON.parse(storedBasicDetails);

            setReligionValue({
                label: teacherAdditional?.religion.label, value: teacherAdditional?.religion.value
            })
            setValue("religion", {
                label: teacherAdditional?.religion.label, value: teacherAdditional?.religion.value
            })
            setReligionCategoryValue({
                label: teacherAdditional?.religionCategory.label, value: teacherAdditional?.religionCategory.value
            })
            setValue("religionCategory", {
                label: teacherAdditional?.religionCategory.label, value: teacherAdditional?.religionCategory.value
            })

            setValue('maritalStatus', teacherAdditional?.maritalStatus);
            setValue('aadharNumber', teacherAdditional?.aadharNumber);
            setValue('panNumber', teacherAdditional?.panNumber);
            setValue('emergencyNumber', teacherAdditional?.emergencyNumber);
            setValue('fatherName', teacherAdditional?.fatherName);
            setValue('motherName', teacherAdditional?.motherName);

        }

    }, [])

    /* FVQPB7748C */

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card">

                    <div className="card-header d-flex justify-content-between">
                        <h4 className="card-title">Additional Details</h4>
                        <button onClick={removeDetails} className='btn btn-danger px-3 py-2 fw-bold'> Reset </button>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="religion">Religion <span className='text-danger'>*</span></label>
                                        <Select
                                            {...register('religion', { required: 'Religion is required' })}
                                            value={religionValue}
                                            onChange={(selectOption) => {
                                                setReligionValue(selectOption);
                                                handleSelect('religion', selectOption);
                                            }}
                                            options={religionList}
                                            className={errors.religion ? 'is-invalid' : ''}
                                        />
                                        {errors.religion && <div className="invalid-feedback">{errors.religion.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="religionCategory">Religion Category<span className='text-danger'>*</span></label>
                                        <Select
                                            {...register('religionCategory', { required: 'Religion category is required' })}
                                            value={religionCategoryValue}
                                            onChange={(selectOption) => {
                                                setReligionCategoryValue(selectOption);
                                                handleSelect('religionCategory', selectOption);
                                            }}
                                            options={religionCategoryList}
                                            className={errors.religionCategory ? 'is-invalid' : ''}
                                        />
                                        {errors.religionCategory && <div className="invalid-feedback">{errors.religionCategory.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="maritalStatus">Marital Status <span className='text-danger'>*</span></label>
                                        <select
                                            style={{ fontSize: "18px" }}
                                            className={`form-select p-1 px-2 text ${errors.maritalStatus ? 'is-invalid' : ''}`}
                                            id="maritalStatus"
                                            {...register('maritalStatus', { required: 'Marital Status is required' })}
                                        >
                                            <option value=''>Select...</option>
                                            <option value='Yes'>Yes</option>
                                            <option value='No'>No</option>
                                        </select>
                                        {errors.maritalStatus && <div className="invalid-feedback">{errors.maritalStatus.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="aadharNumber">Aadhar Number <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.aadharNumber ? 'is-invalid' : ''}`}
                                            id="aadharNumber"
                                            {...register('aadharNumber', {
                                                required: 'Aadhar Number is required',
                                                pattern: {
                                                    value: /^\d{12}$/,
                                                    message: 'Aadhar Number must be 12 digits'
                                                }
                                            })}
                                        />
                                        {errors.aadharNumber && <div className="invalid-feedback">{errors.aadharNumber.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="panNumber">PAN Number <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.panNumber ? 'is-invalid' : ''}`}
                                            id="panNumber"
                                            {...register('panNumber', {
                                                required: 'PAN Number is required',
                                                pattern: {
                                                    value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                                                    message: 'PAN Number must be in the format ABCDE1234F'
                                                }
                                            })}
                                        />
                                        {errors.panNumber && <div className="invalid-feedback">{errors.panNumber.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="emergencyNumber">Emergency Contact Number <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.emergencyNumber ? 'is-invalid' : ''}`}
                                            id="emergencyNumber"
                                            {...register('emergencyNumber', { validate: validatePhoneNumber('Emergency Contact Number is required') })}
                                        />
                                        {errors.emergencyNumber && <div className="invalid-feedback">{errors.emergencyNumber.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="fatherName">Father's Name <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.fatherName ? 'is-invalid' : ''}`}
                                            id="fatherName"
                                            {...register('fatherName', {
                                                validate: validateName("Father's Name is required")
                                            })}
                                        />
                                        {errors.fatherName && <div className="invalid-feedback">{errors.fatherName.message}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="motherName">Mother's Name <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.motherName ? 'is-invalid' : ''}`}
                                            id="motherName"
                                            {...register('motherName', {
                                                validate: validateName("Mother's Name is required")
                                            })}
                                        />
                                        {errors.motherName && <div className="invalid-feedback">{errors.motherName.message}</div>}
                                    </div>
                                </div>




                            </div>
                            <button type="submit" style={{ display: 'none' }}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
});


export default TeacherAdditionalDetails;