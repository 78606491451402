import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form'; // Import useForm from react-hook-form
import Layout from '../../Layout/Layout';
import { ButtonLoader, TableLoader } from '../../../utils/Loader/LoaderComponent';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { add_head_organization, delete_head_organization, get_head_organization, messageClear } from '../../../store/Reducers/organisation/orgWonerReducer';
import toast from 'react-hot-toast';
import Empty from '../../../utils/page/Empty';
import Pagination from '../../Layout/Pagination';
import { confirmMessagge } from '../../../utils/other/aleartFunc';

const HeadOrganization = () => {

    const dispatch = useDispatch();
    const { errorMessage, loader, successMessage, headOrgList, headOrgCount } = useSelector(state => state.org_woner);
    const { searchValue } = useSelector(state => state.default);

    const { register, handleSubmit, formState: { errors } } = useForm();


    const [currentPage, setCurrentPage] = useState(1)
    const [parPage, setParPage] = useState(10)


    // handleClassEdit
    const handleClassEdit = (id) => {
        console.log(`Editing term with id: ${id}`);
    };


    // handleClassDelete
    const handleClassDelete = async (deleteId) => {
        const returnValue = await confirmMessagge('Delete');
        if (returnValue) {
            dispatch(delete_head_organization(deleteId))
        }
    };


    // handleItemPerPageChange
    const handleItemPerPageChange = (e) => {
        setParPage(e.target.value)
    };


    // onSubmit
    const onSubmit = (data) => {
        const { headOrg } = data;
        const obj = {
            headOrg
        }
        dispatch(add_head_organization(obj))

    };


    useEffect(() => {
        const obj = {
            parPage: parseInt(parPage),
            page: parseInt(currentPage),
            searchValue
        }
        dispatch(get_head_organization(obj))
    }, [searchValue, currentPage, parPage, successMessage])


    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage)
            dispatch(messageClear())
        }
        if (successMessage) {
            toast.success(successMessage)
            dispatch(messageClear())
        }
    }, [errorMessage, successMessage])

    return (
        <Layout>
            <div className="row">

                <div className="col-md-7">
                    <div className="card">
                        <div className="card-body">
                            <div className="page-header mb-0">
                                <span style={{ fontSize: "18px", padding: "0px 8px 7px 0px" }}>
                                    <i className="fas fa-list" />
                                </span>
                                <h4 style={{ fontSize: "19px" }}>Head Organisation List</h4>
                            </div>

                            <hr className='px-0 my-1 ' />

                            <div className='mt-3'>

                                {loader ? (
                                    // Display loader while data is being fetched
                                    <TableLoader />
                                ) : headOrgList?.length > 0 ? (
                                    // Render table if data exists
                                    <table className="tableStyle">
                                        <thead>
                                            <tr>
                                                <th>Sl.</th>
                                                <th>Name</th>
                                                <th>CreatedAt</th>
                                                <th className='no-print'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {headOrgList?.map((d, i) => (
                                                <tr key={i}>
                                                    <td data-lable="Sl">{i + 1}</td>
                                                    <td data-lable="Name">{d.headOrg}</td>
                                                    <td>{moment(d.createdAt).format('DD/MM/YYYY')}</td>
                                                    <td data-lable="Action" className='no-print'>
                                                        {/* <span title='Edit' className='mr-1' onClick={() => handleClassEdit(d._id)}>
                                                            <FaEdit style={{ fontSize: '20px', color: 'green' }} />
                                                        </span> */}
                                                        <span title='Delete' className='ml-1' onClick={() => handleClassDelete(d._id)}>
                                                            <MdDeleteForever style={{ fontSize: '24px', color: 'red' }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    // Display empty state when classList is empty
                                    <Empty data={`Date Not Found !`} />
                                )}

                            </div>

                            {headOrgCount > 10 && (
                                <div className="d-flex justify-content-between">
                                    <div className="col-md-4 d-flex justify-content-start align-items-center itemParPageStyle">
                                        <div className="d-flex mb-2">
                                            <label htmlFor="itemPerPage" className="col-sm-auto col-form-label">Select items per page :</label>
                                            <div className="col-sm-auto">
                                                <select
                                                    id='itemPerPage'
                                                    style={{ width: "65px", marginLeft: "-23px", marginTop: "7px", padding: "3px" }}
                                                    onChange={handleItemPerPageChange}
                                                    value={parPage}
                                                >
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                    <option value="5000">All</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='px-4 pt-2'>
                                        {
                                            headOrgCount >= parPage ?
                                                <Pagination
                                                    pageNumber={currentPage}
                                                    setPageNumber={setCurrentPage}
                                                    totalItem={headOrgCount}
                                                    parPage={parPage}
                                                    showItem={Math.floor(headOrgCount / parPage)}
                                                /> : ''
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-md-5">
                    <div className="card">
                        <div className="card-body">
                            <div className="page-header mb-0">
                                <span style={{ fontSize: "18px", padding: "0px 8px 8px 0px" }}>
                                    <i className="fas fa-edit" />
                                </span>
                                <h4 style={{ fontSize: "19px" }}>Add Head Organisation</h4>
                            </div>
                            <hr className='px-0 my-1 ' />
                            <div>
                                <form onSubmit={handleSubmit(onSubmit)}> {/* handleSubmit comes from useForm */}
                                    <div className="form-group">
                                        <label htmlFor="headOrg">Head Name <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.headOrg ? 'is-invalid' : ''}`} // Display error if validation fails
                                            id="headOrg"
                                            {...register('headOrg', { required: 'Head name is required' })} // Register field with validation
                                        />
                                        {errors.headOrg && <div className="invalid-feedback">{errors.headOrg.message}</div>} {/* Display validation message */}
                                    </div>

                                    <div className="my-3 mx-2" style={{ textAlign: "right" }}>
                                        <button disabled={loader} type="submit" style={{ width: "90px" }} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                            {loader ? <ButtonLoader /> : 'Add'} {/* Use loader conditionally */}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Layout>
    );
};

export default HeadOrganization;
