import React from 'react';
import Layout from '../../Layout/Layout';


const OrganisationDashbaord = () => {
    return (
        <Layout>
            <h1>OrganisationDashbaord</h1>
        </Layout>
    )
}

export default OrganisationDashbaord;