import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { ButtonLoader } from '../../../utils/Loader/LoaderComponent';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { employee_login, messageClear } from '../../../store/Reducers/employee/employeeAuthReducer';
import { getCurrentAcademicYear } from '../../../utils/other/returnFunction';
import { sessionFunction } from '../../../store/Reducers/defaultReducer';



const TeacherLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false); // State to manage password visibility

    const { errorMessage, loader, successMessage, employeeInfo: { role } } = useSelector(state => state.employee);
    const { academicYearApprovedList } = useSelector(state => state.academic);




    const { handleSubmit, register, formState: { errors } } = useForm();

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };
    // onSubmit
    const onSubmit = (data) => {
        const { employeeId, password } = data;
        const obj = { employeeId, password };

        dispatch(employee_login(obj));
    };

    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage);
            dispatch(messageClear());
        }
        if (successMessage) {
            toast.success(successMessage);
            dispatch(messageClear());

            const sessionsId = academicYearApprovedList?.find((d) => d.sectionName == getCurrentAcademicYear(academicYearApprovedList))
            if (sessionsId) {
                localStorage.setItem("sessionValue", sessionsId._id)
                dispatch(sessionFunction(sessionsId._id))
            }

            if (role === 'Teacher') {
                navigate('/teacher-dashboard', { replace: true });
            } else if (role === 'Accountant') {
                navigate('/accountant-dashboard', { replace: true });
            } else {
                navigate('/librarian-dashboard', { replace: true });
            }

        }
    }, [errorMessage, successMessage, dispatch, navigate]);



    return (
        <div className="pt-5" style={{ display: "flex", alignItems: 'center', height: '100%' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="container">
                    <h4 className='pl-2 font-weight-bold'>Login as Employee</h4>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <input
                                    {...register('employeeId', {
                                        required: 'Employee email or phone number is required',
                                    })}
                                    id="employeeId"
                                    name="employeeId"
                                    type="text"
                                    className={`form-control ${errors.employeeId ? 'is-invalid' : ''}`}
                                    placeholder='Enter Email Or Phone Number'
                                />
                                {errors.employeeId && <p className="invalid-feedback">{errors.employeeId.message}</p>}
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group position-relative">
                                <input
                                    {...register('password', {
                                        required: 'Password is required',
                                    })}
                                    id="password"
                                    name="password"
                                    type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
                                    className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                    placeholder='Enter Password'
                                />
                                <span
                                    onClick={togglePasswordVisibility}
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '20px',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer'
                                    }}>
                                    {showPassword ? <FaEyeSlash size={18} /> : <FaEye size={18} />}
                                </span>
                                {errors.password && <p className="invalid-feedback">{errors.password.message}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="mt-3" style={{ marginLeft: "12px" }}>
                        <button disabled={loader ? true : false} type="submit" className="btn btn-secondary col-md-12 mt-3 mt-sm-0 fw-bold mx-auto">
                            {loader ? <ButtonLoader /> : 'Login'}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};


export default TeacherLogin;