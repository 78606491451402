import React, { useEffect, useState } from 'react';
import Layout from '../../../Layout/Layout';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { FaListUl, FaRegCopy, FaRegFilePdf } from 'react-icons/fa';
import { RiFileExcelLine } from "react-icons/ri";
import toast from 'react-hot-toast';
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import moment from 'moment';
import { DialogTitle } from '@mui/material';
import { IoIosEye } from "react-icons/io";
import { FiPrinter } from "react-icons/fi";
import Empty from '../../../../utils/page/Empty';
import { ButtonLoader, TableLoader } from '../../../../utils/Loader/LoaderComponent';
import { add_grade_range, get_grade_range, get_single_grade, messageClear, update_grade_range } from '../../../../store/Reducers/examMaster/markEntryReducer';
import { confirmMessagge } from '../../../../utils/other/aleartFunc';


const GradeRange = () => {

    const dispatch = useDispatch()

    const { handleSubmit, setValue, register, formState: { errors }, reset } = useForm();

    const { loader, successMessage, errorMessage, gradeRangeList, singlegradeRange } = useSelector(state => state.markEntry);


    const [open, setOpen] = useState(false);
    const [editId, setEditId] = useState();


    //  handleOpenDrawer
    const handleOpenDrawer = () => {
        setOpen(true)
    }


    // onSubmit
    const onSubmit = (data) => {
        const { gradeName, gradePoint, maxPercentage, minPercentage, remarks } = data;

        const obj = {
            gradeName,
            gradePoint,
            maxPercentage,
            minPercentage,
            remarks
        }

        if (editId) {

            dispatch(update_grade_range({ editId, obj }))

        } else {
            dispatch(add_grade_range(obj))
        }

    }


    // handleEditGradeRange
    const handleEditGradeRange = (editId) => {
        setEditId(editId)
        setOpen(true)
        dispatch(get_single_grade(editId))
    }

    // handleDeleteGradeRange
    const handleDeleteGradeRange = async (deleteId) => {
        const returnValue = await confirmMessagge('Delete');

        if (returnValue) {
            console.log("deleteId", deleteId)
        }
    }



    useEffect(() => {
        dispatch(get_grade_range())
    }, [successMessage])


    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage);
            dispatch(messageClear());
        }
        if (successMessage) {
            toast.success(successMessage);
            dispatch(messageClear());
            setOpen(false)
            setEditId('')
        }
    }, [errorMessage, successMessage]);


    useEffect(() => {
        reset()
    }, [open, successMessage])


    useEffect(() => {

        if (Object.keys(singlegradeRange)?.length > 0 && editId) {
            setValue('gradeName', singlegradeRange?.gradeName);
            setValue('gradePoint', singlegradeRange?.gradePoint);
            setValue('minPercentage', singlegradeRange?.minPercentage);
            setValue('maxPercentage', singlegradeRange?.maxPercentage);
            setValue('remarks', singlegradeRange?.remarks || '');
        }

    }, [singlegradeRange, setValue, editId])


    // console.log("singlegradeRange", singlegradeRange)


    return (
        <Layout>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="page-header mb-0">
                                <span style={{ fontSize: "18px", padding: "0px 8px 7px 0px" }}>
                                    <i className="fas fa-list" />
                                </span>
                                <h4 style={{ fontSize: "19px" }}>Grade List</h4>
                                <span className='px-4'>
                                    <FaRegCopy title='Copy' style={{ fontSize: '20px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <RiFileExcelLine title='Excel' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FaRegFilePdf title='PDF' style={{ fontSize: '18px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                    <FiPrinter title='Print' style={{ fontSize: '22px', marginBottom: "4px", marginRight: "12px", cursor: "pointer" }} />
                                </span>
                                <button onClick={handleOpenDrawer} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                    <span><i className="fa fa-plus pr-2" />Add</span>
                                </button>
                            </div>
                            <hr className='my-3' />


                            <div className='mt-3'>

                                {loader ? (
                                    // Display loader while data is being fetched
                                    <TableLoader />
                                ) : gradeRangeList?.length > 0 ? (
                                    // Render table if data exists
                                    <table className="tableStyle">
                                        <thead className=''>
                                            <tr>
                                                <th>Sl.</th>
                                                <th>Grade Name</th>
                                                <th>Grade Point</th>
                                                <th>Min Percentage</th>
                                                <th>Max Percentage</th>
                                                <th>Remarks</th>
                                                <th className='no-print'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className=''>
                                            {gradeRangeList?.map((d, i) => (
                                                <tr key={i}>
                                                    <td data-lable="Sl">{i + 1}</td>
                                                    <td data-lable="Grade Name">{d.gradeName}</td>
                                                    <td data-lable="Grade Point">{d.gradePoint}</td>
                                                    <td data-lable="Min Percentage">{d.minPercentage} %</td>
                                                    <td data-lable="Max Percentage">{d.maxPercentage} %</td>
                                                    <td data-lable="Remarks">{d.remarks}</td>

                                                    <td data-lable="Action" className='no-print'>
                                                        <span title='Edit' className='mr-1' onClick={() => handleEditGradeRange(d._id)}>
                                                            <FaEdit style={{ fontSize: '20px', color: 'green' }} />
                                                        </span>
                                                        <span title='Delete' className='ml-1' onClick={() => handleDeleteGradeRange(d._id)}>
                                                            <MdDeleteForever style={{ fontSize: '24px', color: 'red' }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    // Display empty state when classList is empty
                                    <Empty data={`Exam Setup Not Found !`} />
                                )}

                            </div>

                        </div>

                    </div>
                </div>
            </div>


            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <div style={{ width: '450px' }}>
                    <DialogTitle style={{ fontSize: '20px' }}>{editId ? 'Edit Grade' : 'Create Grade'}</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <div className='px-2'>
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="form-group">
                                <label htmlFor="gradeName">Grade Name <span className='text-danger'>*</span></label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.gradeName ? 'is-invalid' : ''}`}
                                    id="gradeName"
                                    {...register('gradeName', { required: 'Grade Name is required' })}
                                />
                                {errors.gradeName && <div className="invalid-feedback">{errors.gradeName.message}</div>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="gradePoint">Grade Point <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.gradePoint ? 'is-invalid' : ''}`}
                                    id="gradePoint"
                                    {...register('gradePoint', {
                                        required: 'Grade Point is required',
                                        pattern: {
                                            value: /^[0-9]+(\.[0-9]{1,2})?$/,
                                            message: 'Please enter a valid grade point'
                                        }
                                    })}
                                />
                                {errors.gradePoint && <div className="invalid-feedback">{errors.gradePoint.message}</div>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="minPercentage">Min Percentage <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.minPercentage ? 'is-invalid' : ''}`}
                                    id="minPercentage"
                                    {...register('minPercentage', {
                                        required: 'Min Percentage is required',
                                        pattern: {
                                            value: /^(100(\.00?)?|[1-9]?\d(\.\d{1,2})?)$/,
                                            message: 'Please enter a valid percentage (0-100)'
                                        }
                                    })}
                                />
                                {errors.minPercentage && <div className="invalid-feedback">{errors.minPercentage.message}</div>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="maxPercentage">Max Percentage <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.maxPercentage ? 'is-invalid' : ''}`}
                                    id="maxPercentage"
                                    {...register('maxPercentage', {
                                        required: 'Max Percentage is required',
                                        pattern: {
                                            value: /^(100(\.00?)?|[1-9]?\d(\.\d{1,2})?)$/,
                                            message: 'Please enter a valid percentage (0-100)'
                                        }
                                    })}
                                />
                                {errors.maxPercentage && <div className="invalid-feedback">{errors.maxPercentage.message}</div>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="remarks">Remarks</label>
                                <input
                                    className="form-control"
                                    id="remarks"
                                    {...register('remarks')}
                                />
                            </div>


                            <div className="my-3 mx-2" style={{ textAlign: "right" }}>
                                <button disabled={loader ? true : false} style={{ width: "90px" }} className="btn btn-secondary px-3 py-2 font-weight-bold ml-auto">
                                    {loader ? <ButtonLoader /> : (editId ? 'Edit' : 'Add')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Drawer>

        </Layout>
    )
}



export default GradeRange;