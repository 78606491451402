import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import { FaBirthdayCake } from "react-icons/fa";
import { GiHeavyTimer } from "react-icons/gi";
import { MdOutlineEmail } from "react-icons/md";
import { MdOutlinePermPhoneMsg } from "react-icons/md";
import { AiOutlineHome } from "react-icons/ai";
import StudentBasicDetails from '../administrator/students/studentDetails/StudentBasicDetails';
import FeesDetails from '../administrator/students/studentDetails/FeesDetails';
import PromotionHistory from '../administrator/students/studentDetails/PromotionHistory';
import BookIssue from '../administrator/students/studentDetails/BookIssue';
import ExamResult from '../administrator/students/studentDetails/ExamResult';
import Documents from '../administrator/students/studentDetails/Documents';
import toast from 'react-hot-toast';
import { getActiveClassName, getActiveSession } from '../../utils/other/returnFunction';
import { image_url, server_url } from '../../api/api';
import StudentLayout from '../Layout/StudentLayout/StudentLayout';
import { get_single_student, studentClear } from '../../store/Reducers/student/studentReducer';



const StudentProfile = () => {

    const dispatch = useDispatch();
    const { student } = useSelector(state => state.student);
    const { classList } = useSelector(state => state.administrator);

    const { studentInfo: { studentId, role, organisationId }, loader, successMessage, errorMessage } = useSelector(state => state.personalStudent);


    useEffect(() => {
        if (studentId) {
            dispatch(get_single_student({ studentId }))
        } else {
            dispatch(studentClear())
        }

    }, [studentId, successMessage])


    return (
        <StudentLayout>
            <div className="card">
                <div className="studentBgProfile">
                    <div className="studentBgColor">
                        <div className="container p-3">
                            <div className="row">
                                <div className="col-lg-3">

                                    <div className="card p-2 mt-4">
                                        <div className="studentProfile">
                                            <img s src={student?.studentImage ? `${server_url}/${student?.studentImage}` : `${image_url}/assets/img/student/male.png`} alt="Img" />
                                        </div>
                                    </div>

                                </div>

                                <div className="col-lg-6">
                                    <div className='p-2 mt-3 studentInfo'>
                                        <h5>{student?.basicDetails?.fullName}</h5>
                                        <p>Student / General</p>
                                        <div className='d-flex'>
                                            <FaBirthdayCake style={{ color: "yellow", fontSize: "18px" }} />
                                            <p className='pt-1 px-2' style={{ fontSize: "13px", color: "#fff", fontWeight: "800" }}>{moment(student?.basicDetails?.dateOfBirth).format('DD, MMM, YYYY')}</p>
                                        </div>
                                        <div className='d-flex' style={{ marginTop: "-10px" }}>
                                            <GiHeavyTimer style={{ color: "yellow", fontSize: "22px" }} />
                                            <p className='pt-1 px-2' style={{ fontSize: "13px", color: "#fff", fontWeight: "800" }}>{getActiveClassName(classList, student?.sessions)} ({getActiveSession(student?.sessions)})</p>
                                        </div>
                                        <div className='d-flex' style={{ marginTop: "-10px" }}>
                                            <MdOutlinePermPhoneMsg style={{ color: "yellow", fontSize: "20px" }} />
                                            <p className='pt-1 px-2' style={{ fontSize: "13px", color: "#fff", fontWeight: "800" }}>{student?.basicDetails?.phoneNumber}</p>
                                        </div>
                                        <div className='d-flex' style={{ marginTop: "-10px" }}>
                                            <MdOutlineEmail style={{ color: "yellow", fontSize: "20px" }} />
                                            <p className='pt-1 px-2' style={{ fontSize: "13px", color: "#fff", fontWeight: "800" }}>{student?.basicDetails?.email}</p>
                                        </div>
                                        <div className='d-flex' style={{ marginTop: "-10px" }}>
                                            <AiOutlineHome style={{ color: "yellow", fontSize: "20px" }} />
                                            <p className='pt-1 px-2' style={{ fontSize: "13px", color: "#fff", fontWeight: "800" }}>{student?.addressDetails?.fullAddress}</p>
                                        </div>

                                        {/* {
                                            imageShow && <div>
                                                <button disabled={loader ? true : false} onClick={uploadImage} style={{ width: "90px", fontWeight: 600 }} className='btn btn-danger px-2 py-1'>
                                                    {loader ? <ButtonLoader /> : 'Upload'}
                                                </button>
                                            </div>
                                        } */}

                                    </div>
                                </div>

                                <div className="col-md-3">

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div>
                <StudentBasicDetails student={student} classList={classList} />
                <FeesDetails />
                <PromotionHistory student={student} />
                <BookIssue />
                <ExamResult />
                <Documents />

            </div>

        </StudentLayout>
    )
}


export default StudentProfile;
