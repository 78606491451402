import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';


// add_academic_year
export const add_academic_year = createAsyncThunk(
    'academic/add_academic_year',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/add-academic-year', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_academic_year
export const get_academic_year = createAsyncThunk(
    'academic/get_academic_year',
    async ({ searchValue, page, parPage }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-academic-year?searchValue=${searchValue}&&page=${page}&&parPage=${parPage}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// delete_academic_year
export const delete_academic_year = createAsyncThunk(
    'academic/delete_academic_year',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.delete(`/delete-academic-year/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// get_single_academic_year
export const get_single_academic_year = createAsyncThunk(
    'academic/get_single_academic_year',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/get-single-academic-year/${id}`);
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// update_academic_year
export const update_academic_year = createAsyncThunk(
    'academic/update_academic_year',
    async ({ editId, obj }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/update-academic-year/${editId}`, obj)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

// update_academic_year_status
export const update_academic_year_status = createAsyncThunk(
    'student/update_academic_year_status',
    async ({ statusId, value }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/update-academic-year-status/${statusId}`, { value })
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)



export const academicReducer = createSlice({
    name: "academic",
    initialState: {
        errorMessage: '',
        successMessage: '',
        loader: false,
        academicYearList: [],
        academicYearApprovedList: [],
        academicYearCount: 0,
        singleAcademicYear: {}

    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ''
            state.successMessage = ''
        }
    },
    extraReducers: {
        [add_academic_year.pending]: (state, _) => {
            state.loader = true
        },
        [add_academic_year.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [add_academic_year.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [get_academic_year.fulfilled]: (state, { payload }) => {
            const filterData = payload?.academicYearList?.filter((d) => d.status === 'Approved')
            state.academicYearList = payload.academicYearList
            state.academicYearCount = payload.academicYearCount
            state.academicYearApprovedList = filterData
        },
        [delete_academic_year.pending]: (state, _) => {
            state.loader = true
        },
        [delete_academic_year.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [delete_academic_year.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [get_single_academic_year.fulfilled]: (state, { payload }) => {
            state.singleAcademicYear = payload.singleAcademicYear
        },
        [update_academic_year.pending]: (state, _) => {
            state.loader = true
        },
        [update_academic_year.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload.error
        },
        [update_academic_year.fulfilled]: (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message
        },
        [update_academic_year_status.rejected]: (state, { payload }) => {
            state.errorMessage = payload.error
        },
        [update_academic_year_status.fulfilled]: (state, { payload }) => {
            state.successMessage = payload.message
        },
    }
});




export const { messageClear } = academicReducer.actions;
export default academicReducer.reducer;