import React, { useEffect, useState } from 'react';
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../Layout/Pagination';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { delete_group_type, get_group_type, messageClear } from '../../../../store/Reducers/accounting/feesReducer';
import { confirmMessagge } from '../../../../utils/other/aleartFunc';
import Empty from '../../../../utils/page/Empty';
import { TableLoader } from '../../../../utils/Loader/LoaderComponent';



const ShowFeesGroup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { searchValue } = useSelector(state => state.default);
    const { feesGroupList, feesGroupCount, successMessage, errorMessage, loader } = useSelector(state => state.fees);


    const [currentPage, setCurrentPage] = useState(1)
    const [parPage, setParPage] = useState(10)

    // handleItemPerPageChange
    const handleItemPerPageChange = (e) => {
        setParPage(e.target.value)
    }

    // handleFeesEdit
    const handleFeesEdit = (editId) => {
        navigate(`/edit-fees-group/${editId}`)
    }

    // handleHallDelete
    const handleHallDelete = async (deleteId) => {
        const returnValue = await confirmMessagge('Delete');
        if (returnValue) {
            dispatch(delete_group_type(deleteId))
        }
    }


    useEffect(() => {
        const obj = {
            parPage: parseInt(parPage),
            page: parseInt(currentPage),
            searchValue
        }
        dispatch(get_group_type(obj))
    }, [searchValue, currentPage, parPage, successMessage])


    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage);
            dispatch(messageClear());
        }
        if (successMessage) {
            toast.success(successMessage);
            dispatch(messageClear());
        }
    }, [errorMessage, successMessage]);



    return (
        <>

            <div className="row">

                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="page-header mb-0">
                                <span style={{ fontSize: "18px", padding: "0px 8px 8px 0px" }}>
                                    <i className="fas fa-list" />
                                </span>
                                <h4 style={{ fontSize: "19px" }}>Fees Group List</h4>
                            </div>

                            <hr className='px-0 my-1 ' />

                            <div className='mt-3'>

                                <div className='mt-3'>

                                    {loader ? (
                                        // Display loader while data is being fetched
                                        <TableLoader />
                                    ) : feesGroupList?.length > 0 ? (
                                        // Render table if data exists
                                        <table className="tableStyle">
                                            <thead className=''>
                                                <tr>
                                                    <th>Sl.</th>
                                                    <th>Name</th>
                                                    <th>Fees Type</th>
                                                    <th>Description</th>
                                                    <th className='no-print'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className=''>
                                                {feesGroupList?.map((d, i) => (
                                                    <tr key={i}>
                                                        <td data-lable="Sl">{i + 1 + (currentPage - 1) * parPage}</td>
                                                        <td data-lable=" Name">{d.groupName}</td>
                                                        <td data-label="Fees Name">
                                                            {
                                                                d.feesList?.map((f, index) => (
                                                                    <span key={index}>
                                                                        {`${f.feesId?.feesName} - ₹${f.amount}`}
                                                                        <br />
                                                                    </span>
                                                                ))
                                                            }
                                                        </td>

                                                        <td data-lable="Description">{d.description || 'N/A'}</td>
                                                        <td data-lable="Action" className='no-print'>
                                                            <span title='Edit' className='mr-1' onClick={() => handleFeesEdit(d._id)}>
                                                                <FaEdit style={{ fontSize: '20px', color: 'green' }} />
                                                            </span>
                                                            <span title='Delete' className='ml-1' onClick={() => handleHallDelete(d._id)}>
                                                                <MdDeleteForever style={{ fontSize: '24px', color: 'red' }} />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        // Display empty state when classList is empty
                                        <Empty data={`Fees Group Not Found !`} />
                                    )}

                                </div>

                            </div>

                        </div>

                        {
                            feesGroupCount > 10 && <div class="d-flex justify-content-between">
                                <div className="col-md-4 d-flex justify-content-start align-items-center itemParPageStyle">
                                    <div class="d-flex mb-2">
                                        <label for="itemPerPage" class="col-sm-auto col-form-label">Select items per page :</label>
                                        <div class="col-sm-auto">
                                            <select
                                                id='itemPerPage'
                                                style={{ width: "65px", marginLeft: "-23px", marginTop: "7px", padding: "3px" }}
                                                onChange={handleItemPerPageChange}
                                                value={parPage}
                                            >
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="5000">All</option>

                                            </select>
                                        </div>
                                    </div>

                                </div>

                                <div className='px-4 pt-2'>
                                    {
                                        feesGroupCount >= parPage ?
                                            <Pagination
                                                pageNumber={currentPage}
                                                setPageNumber={setCurrentPage}
                                                totalItem={feesGroupCount}
                                                parPage={parPage}
                                                showItem={Math.floor(feesGroupCount / parPage)}
                                            /> : ''
                                    }
                                </div>

                            </div>
                        }

                    </div>
                </div>

            </div>

        </>
    )
}

export default ShowFeesGroup;